import { getInvoiceApi, updateInvoiceApi } from "../../util/api";
import { storableError } from "../../util/errors";

// ================ Action types ================ //

export const FETCH_INVOICE_REQUEST = 'app/InvoicePage/FETCH_INVOICE_REQUEST';
export const FETCH_INVOICE_SUCCESS = 'app/InvoicePage/FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_ERROR = 'app/InvoicePage/FETCH_INVOICE_ERROR';

export const UPDATE_INVOICE_REQUEST = 'app/InvoicePage/UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = 'app/InvoicePage/UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_ERROR = 'app/InvoicePage/UPDATE_INVOICE_ERROR';

export const FETCH_COMMISSION_REQUEST = 'app/TransactionPage/FETCH_COMMISSION_REQUEST';
export const FETCH_COMMISSION_SUCCESS = 'app/TransactionPage/FETCH_COMMISSION_SUCCESS';
export const FETCH_COMMISSION_ERROR = 'app/TransactionPage/FETCH_COMMISSION_ERROR';

// ================ Reducer ================ //

const initialState = {
    updateInProgress: false,
    fetchInProgress: false,
    updateError: null,
    fetchInvoiceError: null,
    invoice: null,
    commission: null,
    fetchCommissionError: null,
    fetchCommissionInProgress: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_INVOICE_REQUEST:
            return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
        case FETCH_INVOICE_SUCCESS: {
            return {
                ...state,
                fetchInProgress: false,
                invoice: payload,
            };
        }
        case FETCH_INVOICE_ERROR:
            console.error(payload); // eslint-disable-line
            return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

        case UPDATE_INVOICE_REQUEST:
            return { ...state, updateInProgress: payload, updateError: null };
        case UPDATE_INVOICE_SUCCESS: {
            return {
                ...state,
                updateInProgress: false,
                invoice: payload,
            };
        }
        case UPDATE_INVOICE_ERROR:
            console.error(payload); // eslint-disable-line
            return { ...state, updateInProgress: false, updateError: payload };

        case FETCH_COMMISSION_REQUEST:
            return { ...state, fetchCommissionInProgress: true, fetchCommissionError: null };
        case FETCH_COMMISSION_SUCCESS:
            return { ...state, fetchCommissionInProgress: false, commission: payload };
        case FETCH_COMMISSION_ERROR:
            return { ...state, fetchCommissionInProgress: false, fetchCommissionError: payload };

        default:
            return state;
    }
}

// ================ Action creators ================ //

const fetchInvoiceRequest = () => ({ type: FETCH_INVOICE_REQUEST });
const fetchInvoiceSuccess = response => ({
    type: FETCH_INVOICE_SUCCESS,
    payload: response,
});
const fetchInvoiceError = e => ({
    type: FETCH_INVOICE_ERROR,
    error: true,
    payload: e,
});

const updateInvoiceRequest = (payload) => ({ type: UPDATE_INVOICE_REQUEST, payload });
const updateInvoiceSuccess = response => ({
    type: UPDATE_INVOICE_SUCCESS,
    payload: response,
});
const updateInvoiceError = e => ({
    type: UPDATE_INVOICE_ERROR,
    error: true,
    payload: e,
});

export const fetchCommissionRequest = () => ({ type: FETCH_COMMISSION_REQUEST });
export const fetchCommissionSuccess = payload => ({
    type: FETCH_COMMISSION_SUCCESS,
    payload,
});
export const fetchCommissionError = error => ({
    type: FETCH_COMMISSION_ERROR,
    error: true,
    payload: error,
});

// ================ Thunks ================ //

export const updateInvoice = (payload, type = 'primary') => (dispatch) => {
    const { _id, ...rest } = payload;
    dispatch(updateInvoiceRequest(type)); 

    return updateInvoiceApi({ id: _id, payload: rest })
        .then(response => {
            dispatch(updateInvoiceSuccess(payload));
            getInvoiceApi({ query: { invoiceNumber: { $regex: rest.invoiceNumber } } })
                .then(res => {
                    dispatch(fetchInvoiceSuccess(res.data));
                });
            return response;
        })
        .catch(error => {
            dispatch(updateInvoiceError(error));
        });
}

export const fetchCommission = () => (dispatch, getState, sdk) => {
    dispatch(fetchCommissionRequest())

    return sdk.assetsByAlias({ paths: ['transactions/commission.json'], alias: 'latest' })
        .then((response) => {
            dispatch(fetchCommissionSuccess(response.data))
            return response.data;
        })
        .catch(err => dispatch(fetchCommissionError(storableError(err))));
};

export const loadData = (params) => (dispatch, getState, sdk) => {
    const { invoiceNumber } = params;
    dispatch(fetchInvoiceRequest());

    return getInvoiceApi({ query: { invoiceNumber: { $regex: invoiceNumber } } })
        .then(response => {
            dispatch(fetchCommission());
            dispatch(fetchInvoiceSuccess(response.data));
            return response;
        })
        .catch(e => {
            dispatch(fetchInvoiceError(e));
            throw e;
        });
};
