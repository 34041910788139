import { types as sdkTypes } from './util/sdkLoader';

import tampa from './assets/locations/tampa.png';
import miami from './assets/locations/miami.png';
import orlando from './assets/locations/orlando.png';
import jacksonville from './assets/locations/jacksonville.png';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'tampa',
    logo: tampa,
    predictionPlace: {
      address: 'Tampa',
      bounds: new LatLngBounds(
        new LatLng(27.820935477, -82.650082138),
        new LatLng(28.171471, -82.265257)
      ),
    },
    // description: "340 Food & Drink Vendors",
  },
  {
    id: 'miami',
    logo: miami,
    predictionPlace: {
      address: 'Miami',
      bounds: new LatLngBounds(
        new LatLng(25.515125, -80.8736),
        new LatLng(25.979434, -80.144468)
      ),
    },
    // description: "340 Food & Drink Vendors",
  },
  {
    id: 'orlando',
    logo: orlando,
    predictionPlace: {
      address: 'Orlando',
      bounds: new LatLngBounds(
        new LatLng(28.347057, -81.615457962),
        new LatLng(28.6402, -80.979331)
      ),
    },
    // description: "340 Food & Drink Vendors",
  },
  {
    id: 'jacksonville',
    logo: jacksonville,
    predictionPlace: {
      address: 'Jacksonville',
      bounds: new LatLngBounds(
        new LatLng(30.103842, -82.10356),
        new LatLng(30.586232, -81.365002017)
      ),
    },
    // description: "340 Food & Drink Vendors",
  },
];
export default defaultLocations;
