import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { array, string } from 'prop-types';

import routeConfiguration from '../../routeConfiguration';
import { statusEnumPlanner } from '../../marketplace-custom-config';

import { createSlug } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import { IconProfileSetup, PrimaryButton, ResponsiveImage, } from '..';

import css from './QuotesCard.module.css';

const GRID = 'grid';
const TABLEVIEW = 'table';

const calculateRequirement = (event) => {
  let requirements = [], checkedRequirements = [], percentage = 0;
  if (event && Object.keys(event).length && event.vendorResponse && Object.keys(event.vendorResponse).length) {
    requirements = event.vendorResponse.requirements;
    if (requirements && requirements.length) {
      requirements.map(key => event.vendorResponse[key] && checkedRequirements.push(event.vendorResponse[key]));
      percentage = Math.round((parseInt(checkedRequirements.length) / parseInt(requirements.length)) * 100)
    }
  }
  return { requirements, checkedRequirements, percentage }
}

const EventCard = (props) => {
  const {
    event,
    history,
    onFindOrCreateChannel,
  } = props || {};

  const {
    txId,
    title,
    status,
    eventId,
    vendor,
    categories,
    eventOwner,
    eventDates,
    eventDuration,
    vendorResponse,
  } = event || {};

  const eventDate = eventDates && eventDates.length
    ? moment(eventDates[0].start)
    : moment().subtract(10, 'minutes');

  const updatedAt = vendorResponse && vendorResponse.updatedAt
    ? moment(vendorResponse.updatedAt * 1000)
    : moment().subtract(10, 'minutes');

  const pendingDate = moment().diff(moment(updatedAt), 'days');
  const pendingHour = moment().diff(moment(updatedAt), 'hours') % 24;
  const pendingMins = moment().diff(moment(updatedAt), 'minutes'); // % 60
  const pendingSec = moment().diff(moment(updatedAt), 'seconds') % 60;

  return (<div className={css.leftMessage} onClick={() => onFindOrCreateChannel({
    senderId: eventOwner.id,
    receiverId: vendor.id,
    type: 'transactional',
    tab: txId,
    eventId: eventId,
    slug: createSlug(title),
  }).then(() => {
    const channelId = [eventOwner.id, vendor.id].sort().join('_');
    typeof window !== 'undefined' && window.sessionStorage.setItem('channelId', channelId);
    return history.push(createResourceLocatorString('EventDetailPage', routeConfiguration(), { eventId: eventId, slug: createSlug(title), tab: txId }));
  })}>
    <div className={css.messagesLeftOptions}>
      <div className={css.chatProfileImage}>
        <ResponsiveImage
          alt={vendor.displayName}
          url={vendor.publicData.profileImageURL}
        />

      </div>
      <div className={css.quotesBody}>
        <p className={css.days}>
          {pendingDate
            ? <FormattedMessage id="EventDetailPage.pendingDays" values={{ pendingDate, pendingMins, pendingSec, pendingHour }} />
            : <FormattedMessage id="EventDetailPage.pendingMins" values={{ pendingDate, pendingMins, pendingSec, pendingHour }} />}
          {" Ago"}
        </p>
        <h3>{vendor.publicData.businessName}</h3>
        <div className={css.quotesData}>
          {vendorResponse && vendorResponse.requirementQuotes
            ? <span>
              <FormattedMessage id="EventCard.youHaveReceiveMessage" />
              {' '}{categories.map((cat, ind) => cat.subCategory.map((subCat, i) => vendorResponse.requirementQuotes[subCat.key]
                ? (cat.label + ' > ' + subCat.label + (cat.subCategory.length < i ? ' ' : '') + (categories.length < ind ? ' | ' : '')) : ''))}
            </span>
            : null}
          {vendor.publicData.ratings
            ? <>
              | <IconProfileSetup type="star" /> <span>{vendor.publicData.ratings}</span>
            </>
            : null}
        </div>
      </div>
    </div>
    <div className={css.messagesRightOptionsDesktop}>
      <p
        className={classNames(css.status, css[status.toLowerCase()])}
      // onClick={() => setVendorResponseModal(event)}
      // disabled={status == 'REQUESTED' ? eventDate.isBefore(moment()) : true}
      >
        {['PENDING', 'ENQUIRY', 'INFO', 'REQUESTED'].includes(status) && moment(eventDate).isBefore(moment()) && eventDuration != 'not-determined'
          ? statusEnumPlanner['EXPIRED']
          : statusEnumPlanner[status]}
      </p>
      <p className={css.eventTitle}>{title}</p>
    </div>
  </div>);
}

const QuotesCard = props => {

  const {
    history,
    events = [],
    type = TABLEVIEW,
    onFindOrCreateChannel,
  } = props;

  switch (type) {
    case TABLEVIEW:
      return (
        <div className={css.mainWrapperListBox}>
          <div className={css.tableViewBox}>
            <table>
              <thead>
                <tr>
                  <th>
                    <div className={css.headingName}>
                      <FormattedMessage id="ProposalRequestsPage.category" />
                    </div>
                  </th>
                  <th>
                    <div className={css.headingName}>
                      <FormattedMessage id="ProposalRequestsPage.eventName" />
                    </div>
                  </th>
                  <th>
                    <div className={css.headingName}>
                      <FormattedMessage id="ProposalRequestsPage.quoteAmount" />
                    </div>
                  </th>
                  <th>
                    <div className={css.headingName}>
                      <FormattedMessage id="ProposalRequestsPage.fulfillment" />
                    </div>
                  </th>
                  <th>
                    <div className={css.headingName}>
                      <FormattedMessage id="ProposalRequestsPage.Status" />
                    </div>
                  </th>
                </tr>
              </thead>
              {events && events.length
                ? events
                  .map((event, i) => (<tbody key={'table_' + event._id} >
                    <tr>
                      <td>
                        <div className={css.tableData}>
                          {event.subCategoriesLabel}
                        </div>
                      </td>
                      <td>
                        <div className={css.tableDate}>
                          {vendor.publicData.businessName}
                        </div>
                      </td>
                      <td>
                        <div className={css.tableData}>
                          {event.vendorResponse && event.vendorResponse.quote ? '$ ' + event.vendorResponse.quote : '-'}
                        </div>
                      </td>
                      <td>
                        <div className={css.tableData}>
                          {calculateRequirement(event).percentage} %
                        </div>
                      </td>
                      <td>
                        <div className={classNames(css.tableButton, "ACCEPTED" === event.status ? css.tableYellowButton : '')}>
                          <PrimaryButton
                            onClick={() => setVendorResponseModal(event)}
                            disabled={event.status == 'EXPIRED'}
                          >
                            {statusEnumPlanner[event.status]}
                          </PrimaryButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>))
                : null}
            </table>
          </div>
        </div>
      );
    case GRID:
      return (
        <div className={css.mainWrapperListBox}>
          <div className={css.messageBox}>
            <div className={css.messageScroll}>
              {events && events.length
                ? events //.filter((event, index, self) => index === self.findIndex((e) => e.eventId === event.eventId))
                  .map((event) => <EventCard
                    key={event._id}
                    event={event}
                    history={history}
                    onFindOrCreateChannel={onFindOrCreateChannel}
                  />)
                : null}

            </div>
          </div>
        </div>
      );
    default:
      return null;
  }

};

QuotesCard.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

QuotesCard.propTypes = {
  events: array,
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(QuotesCard);
