import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEdit.module.css';

const IconEdit = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="5.25" stroke="#FFB169" strokeWidth="1.5" />
      <path d="M18 9.26242L14.7371 6L6 14.7376V18H9.26288L18 9.26242ZM14.7379 7.70449L16.2962 9.26255L15.6422 9.91727L14.0839 8.35921L14.7379 7.70449ZM13.2312 9.21098L14.7895 10.769L9.61501 15.9424L8.05686 14.3837L13.2312 9.21098ZM7.205 16.7943V15.2363L8.76328 16.7943H7.205Z" fill="#fff" stroke='#fff' />
    </svg>

  );
};

IconEdit.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconEdit.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconEdit;
