import React, { useState } from 'react';
import classNames from 'classnames';
import IconCollection from '../IconCollection/IconCollection';

import css from './Accordion.module.css';

const Accordion = ({ title, children, open = false }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={css.accordion}>
      <div className={css.header} onClick={handleToggle}>
        <h3>{title}</h3>
        <span className={classNames({ [css.icon]: !isOpen, [css.iconOpen]: isOpen })}>
          <IconCollection name="ACCORDION_DROPDOWN_ARROW" />
        </span>
      </div>
      {isOpen && <div className={css.content}>{children}</div>}
    </div>
  );
};

export default Accordion;
