import React from 'react'
import { Range } from 'react-range';

import css from './RangeSlider.module.css';

const RangeSlider = props => {
  const {
    rangeValue,
    setRangeValue
  } = props;

  return (
    <div className={css.rangeSlider}>
      <Range
        step={1}
        min={0}
        max={250}
        values={rangeValue}
        onChange={(values) => setRangeValue(values)}
        renderTrack={({ props: childProps, children }) => (
          <div
            {...childProps}
            style={{
              ...childProps.style,
              height: '6px',
              width: '100%',
              backgroundColor: '#ccc',
              borderRadius:'5px',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props: childProps }) => (
          <div
            {...childProps}
            style={{
              ...childProps.style,
              height: '32px',
              // width: '42px',
              backgroundColor: '#000',
              color:'#fff',
              borderRadius: '25px',
              padding:'0 12px',
              outline: 'none'
            }}
          >{rangeValue} Miles</div>
        )}
      />
    </div>
  )
};

export default RangeSlider;