import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconShare.module.css';

const Facebook = 'facebook';
const Instagram = 'instagram'
const Twitter = 'twitter';
const WhatsApp = 'whatsapp';
const Linkedin = 'linkedin';
const Email = 'email';
const Copy = 'copy';
const Browser = "browser";
const Tiktok = "tiktok";
const Message = "message";
const Share = "share";
const Like = "like";
const Pinterest = "pinterest";

const IconShare = props => {
    const { className, rootClassName, type } = props;
    const classes = classNames(rootClassName || css.root, className);

    switch (type) {
        case Browser:
            return (
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.8077 11.5059C12.8775 10.9125 12.9299 10.3192 12.9299 9.70785C12.9299 9.09651 12.8775 8.50316 12.8077 7.90981H15.7595C15.8993 8.48518 15.9866 9.08752 15.9866 9.70785C15.9866 10.3282 15.8993 10.9305 15.7595 11.5059M11.2619 16.5044C11.7859 15.5065 12.1876 14.4277 12.4671 13.3039H15.0434C14.1973 14.8037 12.8549 15.9398 11.2619 16.5044ZM11.0435 11.5059H6.95633C6.869 10.9125 6.8166 10.3192 6.8166 9.70785C6.8166 9.09651 6.869 8.49417 6.95633 7.90981H11.0435C11.1221 8.49417 11.1833 9.09651 11.1833 9.70785C11.1833 10.3192 11.1221 10.9125 11.0435 11.5059ZM8.99994 16.864C8.27507 15.7852 7.68994 14.5895 7.33187 13.3039H10.668C10.3099 14.5895 9.7248 15.7852 8.99994 16.864ZM5.5066 6.11177H2.95647C3.79387 4.6079 5.13526 3.46999 6.72927 2.91126C6.20527 3.90917 5.81227 4.98799 5.5066 6.11177ZM2.95647 13.3039H5.5066C5.81227 14.4277 6.20527 15.5065 6.72927 16.5044C5.1386 15.9396 3.7991 14.8032 2.95647 13.3039ZM2.24034 11.5059C2.1006 10.9305 2.01327 10.3282 2.01327 9.70785C2.01327 9.08752 2.1006 8.48518 2.24034 7.90981H5.1922C5.12233 8.50316 5.06993 9.09651 5.06993 9.70785C5.06993 10.3192 5.12233 10.9125 5.1922 11.5059M8.99994 2.54266C9.7248 3.62148 10.3099 4.82617 10.668 6.11177H7.33187C7.68994 4.82617 8.27507 3.62148 8.99994 2.54266ZM15.0434 6.11177H12.4671C12.1937 4.9983 11.789 3.92346 11.2619 2.91126C12.8688 3.47764 14.205 4.6194 15.0434 6.11177ZM8.99994 0.717651C4.1704 0.717651 0.266602 4.76324 0.266602 9.70785C0.266602 12.0922 1.18672 14.3789 2.82454 16.0649C3.6355 16.8997 4.59826 17.5619 5.65783 18.0137C6.71741 18.4655 7.85306 18.698 8.99994 18.698C11.3162 18.698 13.5375 17.7509 15.1753 16.0649C16.8132 14.3789 17.7333 12.0922 17.7333 9.70785C17.7333 8.52724 17.5074 7.35819 17.0685 6.26745C16.6296 5.17671 15.9863 4.18564 15.1753 3.35082C14.3644 2.516 13.4016 1.85379 12.342 1.40199C11.2825 0.95019 10.1468 0.717651 8.99994 0.717651Z" fill="white" />
                </svg>
            );
        case Like:
            return (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4998 4.29376L11.3795 3.1422C8.74984 0.439077 3.92796 1.37189 2.18734 4.77033C1.37015 6.36876 1.18577 8.67658 2.67796 11.6219C4.11546 14.4578 7.10609 17.8547 12.4998 21.5547C17.8936 17.8547 20.8826 14.4578 22.3217 11.6219C23.8139 8.67502 23.6311 6.36876 22.8123 4.77033C21.0717 1.37189 16.2498 0.437515 13.6201 3.14064L12.4998 4.29376ZM12.4998 23.4375C-11.458 7.60627 5.12327 -4.74999 12.2248 1.78595C12.3186 1.87189 12.4108 1.96095 12.4998 2.05314C12.588 1.96104 12.6797 1.87243 12.7748 1.78751C19.8748 -4.75311 36.4576 7.6047 12.4998 23.4375Z" fill="#939393" />
                </svg>
            );
        case Pinterest:
            return (
                <svg width="18px" height="18px" viewBox="0 0 0.337 0.337" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0.169C0 0.076 0.076 0 0.169 0C0.262 0 0.337 0.076 0.337 0.169C0.337 0.262 0.262 0.337 0.169 0.337C0.145 0.337 0.123 0.333 0.103 0.324L0.146 0.226C0.157 0.239 0.173 0.247 0.191 0.247C0.235 0.247 0.27 0.212 0.27 0.169V0.158C0.27 0.108 0.23 0.068 0.18 0.068H0.158C0.108 0.068 0.068 0.108 0.068 0.158V0.169C0.068 0.189 0.075 0.207 0.088 0.221L0.104 0.206C0.095 0.196 0.09 0.183 0.09 0.169V0.158C0.09 0.12 0.12 0.09 0.158 0.09H0.18C0.217 0.09 0.247 0.12 0.247 0.158V0.169C0.247 0.2 0.222 0.225 0.191 0.225C0.176 0.225 0.162 0.215 0.158 0.201L0.157 0.2L0.179 0.151L0.158 0.142L0.083 0.314C0.033 0.285 0 0.231 0 0.169Z" fill="#000000" /></svg>
            );
        case Instagram:
            return (
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66442 5.88116C7.15513 5.88116 5.92344 7.15137 5.92344 8.70786C5.92344 10.2643 7.15513 11.5345 8.66442 11.5345C10.1737 11.5345 11.4054 10.2643 11.4054 8.70786C11.4054 7.15137 10.1737 5.88116 8.66442 5.88116ZM16.8853 8.70786C16.8853 7.53731 16.8956 6.37737 16.8319 5.20895C16.7681 3.85179 16.4679 2.64732 15.5056 1.6549C14.5412 0.660366 13.3753 0.352886 12.0593 0.287149C10.9242 0.221412 9.79947 0.232015 8.66648 0.232015C7.53142 0.232015 6.40665 0.221412 5.27366 0.287149C3.95766 0.352886 2.78971 0.662487 1.82738 1.6549C0.862996 2.64944 0.564839 3.85179 0.501096 5.20895C0.437352 6.37949 0.447633 7.53943 0.447633 8.70786C0.447633 9.87628 0.437352 11.0383 0.501096 12.2068C0.564839 13.5639 0.865053 14.7684 1.82738 15.7608C2.79176 16.7553 3.95766 17.0628 5.27366 17.1286C6.40871 17.1943 7.53348 17.1837 8.66648 17.1837C9.80153 17.1837 10.9263 17.1943 12.0593 17.1286C13.3753 17.0628 14.5432 16.7532 15.5056 15.7608C16.47 14.7663 16.7681 13.5639 16.8319 12.2068C16.8977 11.0383 16.8853 9.8784 16.8853 8.70786ZM8.66442 13.0571C6.33057 13.0571 4.44705 11.1147 4.44705 8.70786C4.44705 6.30103 6.33057 4.35861 8.66442 4.35861C10.9983 4.35861 12.8818 6.30103 12.8818 8.70786C12.8818 11.1147 10.9983 13.0571 8.66442 13.0571ZM13.0545 5.19622C12.5096 5.19622 12.0696 4.74243 12.0696 4.18048C12.0696 3.61853 12.5096 3.16474 13.0545 3.16474C13.5994 3.16474 14.0395 3.61853 14.0395 4.18048C14.0396 4.31392 14.0143 4.44607 13.9648 4.56939C13.9154 4.6927 13.8428 4.80474 13.7513 4.89909C13.6599 4.99345 13.5512 5.06826 13.4316 5.11925C13.3121 5.17023 13.1839 5.19639 13.0545 5.19622Z" fill="white" />
                </svg>
            );
        case Share:
            return (
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 2.59375V18.4062C20 19.5971 19.0406 20.5625 17.8571 20.5625H2.14286C0.959375 20.5625 0 19.5971 0 18.4062V2.59375C0 1.40287 0.959375 0.4375 2.14286 0.4375H17.8571C19.0406 0.4375 20 1.40287 20 2.59375ZM13.5714 12.2969C12.9213 12.2969 12.3293 12.5467 11.8847 12.9559L8.85085 11.1242C8.95446 10.7146 8.95446 10.2854 8.85085 9.87572L11.8847 8.04403C12.3293 8.45331 12.9213 8.70312 13.5714 8.70312C14.9521 8.70312 16.0714 7.57684 16.0714 6.1875C16.0714 4.79816 14.9521 3.67188 13.5714 3.67188C12.1907 3.67188 11.0714 4.79816 11.0714 6.1875C11.0714 6.40299 11.0984 6.61215 11.1491 6.81178L8.11527 8.64347C7.67067 8.23419 7.07866 7.98438 6.42857 7.98438C5.04786 7.98438 3.92857 9.11066 3.92857 10.5C3.92857 11.8893 5.04786 13.0156 6.42857 13.0156C7.07866 13.0156 7.67067 12.7658 8.11531 12.3566L11.1492 14.1883C11.0974 14.3923 11.0713 14.602 11.0715 14.8125C11.0715 16.2019 12.1908 17.3282 13.5715 17.3282C14.9522 17.3282 16.0715 16.2019 16.0715 14.8125C16.0714 13.4232 14.9521 12.2969 13.5714 12.2969Z" fill="#212121" />
                </svg>
            );
        case Message:
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 6H4V4H16V6ZM16 9H4V7H16V9ZM16 12H4V10H16V12ZM20 2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H16L20 20V2Z" fill="#212121" />
                </svg>
            );
        case Tiktok:
            return (
                <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.3452 7.90792C14.7797 7.91152 13.2526 7.44301 11.9797 6.56859V12.6671C11.9792 13.7966 11.6195 14.8991 10.9485 15.8271C10.2775 16.7551 9.32733 17.4644 8.22493 17.8601C7.12254 18.2559 5.92051 18.3193 4.77958 18.0418C3.63865 17.7643 2.61319 17.1591 1.84033 16.3072C1.06747 15.4553 0.584044 14.3973 0.454693 13.2746C0.325342 12.152 0.556232 11.0182 1.11649 10.0248C1.67675 9.03147 2.53967 8.22594 3.58986 7.71594C4.64006 7.20594 5.82748 7.01577 6.99334 7.17086V10.2382C6.45984 10.0772 5.88696 10.082 5.3565 10.2521C4.82604 10.4221 4.36514 10.7487 4.03962 11.1851C3.71409 11.6215 3.54058 12.1455 3.54388 12.6821C3.54717 13.2188 3.7271 13.7408 4.05796 14.1735C4.38882 14.6062 4.8537 14.9275 5.3862 15.0916C5.91871 15.2556 6.49162 15.254 7.0231 15.0869C7.55459 14.9199 8.01748 14.5959 8.34567 14.1613C8.67386 13.7268 8.85056 13.2038 8.85055 12.6671V0.745117H11.9797C11.9775 0.998701 11.9996 1.25194 12.0458 1.50163C12.1546 2.05903 12.3806 2.58928 12.7102 3.05995C13.0398 3.53062 13.466 3.93181 13.9628 4.23899C14.6694 4.68741 15.498 4.92641 16.3452 4.92624V7.90792Z" fill="white" />
                </svg>
            );
        case Facebook:
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.57251 9.73344C0.57251 14.4585 4.00422 18.3876 8.49244 19.1843V12.3201H6.11646V9.68037H8.49244V7.56813C8.49244 5.19215 10.0234 3.87269 12.1887 3.87269C12.8745 3.87269 13.6143 3.97802 14.3001 4.08336V6.5124H13.086C11.9242 6.5124 11.6604 7.09293 11.6604 7.83265V9.68037H14.1948L13.7727 12.3201H11.6604V19.1843C16.1486 18.3876 19.5804 14.4593 19.5804 9.73344C19.5804 4.47698 15.3036 0.176453 10.0764 0.176453C4.84927 0.176453 0.57251 4.47698 0.57251 9.73344Z" fill="white" />
                </svg>
            );
        case Linkedin:
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.0312502 1.67519C0.0312502 1.29956 0.184993 0.939304 0.458658 0.673689C0.732322 0.408073 1.10349 0.258852 1.49051 0.258852H16.0371C16.2289 0.258548 16.4188 0.294964 16.5961 0.366015C16.7734 0.437066 16.9345 0.541357 17.0702 0.672917C17.2059 0.804477 17.3135 0.960722 17.3869 1.13271C17.4603 1.30469 17.498 1.48904 17.4979 1.67519V15.7939C17.4981 15.9801 17.4605 16.1645 17.3872 16.3366C17.3139 16.5086 17.2064 16.665 17.0708 16.7967C16.9352 16.9284 16.7741 17.0328 16.5969 17.1041C16.4197 17.1753 16.2297 17.2119 16.0379 17.2118H1.49051C1.29881 17.2118 1.10899 17.1751 0.931892 17.1039C0.754795 17.0327 0.593892 16.9283 0.458377 16.7967C0.322862 16.6651 0.21539 16.5089 0.142102 16.3369C0.0688139 16.165 0.0311459 15.9807 0.0312502 15.7947V1.67519ZM6.94487 6.72255H9.31002V7.87535C9.65141 7.21264 10.5247 6.61621 11.8371 6.61621C14.3531 6.61621 14.9494 7.93622 14.9494 10.3582V14.8445H12.4032V10.9099C12.4032 9.53057 12.0618 8.75228 11.1948 8.75228C9.99201 8.75228 9.49183 9.59145 9.49183 10.9099V14.8445H6.94487V6.72255ZM2.57821 14.739H5.12516V6.61621H2.57821V14.7382V14.739ZM5.48958 3.96692C5.49439 4.17858 5.45558 4.38904 5.37545 4.58595C5.29531 4.78285 5.17546 4.96224 5.02293 5.11358C4.8704 5.26492 4.68826 5.38517 4.4872 5.46726C4.28614 5.54936 4.07021 5.59164 3.85208 5.59164C3.63396 5.59164 3.41803 5.54936 3.21697 5.46726C3.01591 5.38517 2.83377 5.26492 2.68124 5.11358C2.5287 4.96224 2.40885 4.78285 2.32872 4.58595C2.24859 4.38904 2.20978 4.17858 2.21458 3.96692C2.22401 3.55146 2.40067 3.1561 2.70673 2.86551C3.0128 2.57492 3.42393 2.4122 3.85208 2.4122C4.28024 2.4122 4.69137 2.57492 4.99743 2.86551C5.3035 3.1561 5.48016 3.55146 5.48958 3.96692Z" fill="white" />
                </svg>
            );
        case Twitter:
            return (
                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#1DA1F2" fillRule="evenodd" d="M24,4.3086 C23.117,4.7006 22.168,4.9646 21.172,5.0836 C22.188,4.4746 22.969,3.5096 23.337,2.3596 C22.386,2.9246 21.332,3.3336 20.21,3.5556 C19.312,2.5976 18.032,1.9996 16.616,1.9996 C13.897,1.9996 11.692,4.2046 11.692,6.9236 C11.692,7.3096 11.736,7.6856 11.82,8.0456 C7.728,7.8406 4.099,5.8806 1.671,2.9006 C1.247,3.6286 1.004,4.4736 1.004,5.3766 C1.004,7.0846 1.873,8.5926 3.195,9.4756 C2.388,9.4486 1.628,9.2276 0.964,8.8596 L0.964,8.9206 C0.964,11.3066 2.661,13.2966 4.914,13.7486 C4.501,13.8626 4.065,13.9216 3.617,13.9216 C3.299,13.9216 2.991,13.8906 2.69,13.8336 C3.317,15.7896 5.135,17.2136 7.29,17.2536 C5.604,18.5736 3.481,19.3606 1.175,19.3606 C0.777,19.3606 0.385,19.3376 0,19.2926 C2.179,20.6886 4.767,21.5046 7.548,21.5046 C16.605,21.5046 21.557,14.0016 21.557,7.4946 C21.557,7.2816 21.552,7.0696 21.543,6.8586 C22.505,6.1636 23.34,5.2966 24,4.3086" />
                </svg>
            );
        case WhatsApp:
            return (
                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path fillRule="nonzero" d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z" />
                    </g>
                </svg>
            );
        case Email:
            return (
                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#494c4e" d="M23.99 6.13V6c0-.18-.01-.35-.06-.53C23.69 4.6 22.9 4 22 4H2C1.1 4 .31 4.6.07 5.47.02 5.65 0 5.82 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2l-.01-11.87zm-2-.13L12 12 2 6h19.99zM2 18V8.33l8.97 5.38c.32.19.67.29 1.03.29s.71-.1 1.03-.29l8.96-5.38L22 18H2z" />
                </svg>
            );
        case Copy:
            return (
                <svg style={{ fill: 'transparent' }} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
            );
        default:
            return (
                <svg
                    className={classes}
                    width="29"
                    height="19"
                    viewBox="0 0 29 19"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path
                            d="M26.58 19H2.42A2.4004 2.4004 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4004 2.4004 0 0 1 29 2.38v14.25c-.0165 1.3216-1.0984 2.3811-2.42 2.37zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
                            fill="#DADDE2"
                        />
                        <path
                            d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
                            fill="#B2B6C1"
                        />
                    </g>
                </svg>
            );
    }
};

IconShare.defaultProps = {
    className: null,
    rootClassName: null,
    type: 'default',
};

IconShare.propTypes = {
    className: string,
    rootClassName: string,
    type: string,
};

export default IconShare;
