import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { Form, PrimaryButton, FieldMultiSelect, InlineTextButton } from '../../components';

import css from './PreferredListForm.module.css';

const PreferredListFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        intl,
        values,
        invalid,
        preferredList,
        shareWithAll
      } = fieldRenderProps;

      const preferedListLabel = intl.formatMessage({
        id: 'PreferredListForm.preferedListLabel',
      });

      const classes = classNames(rootClassName || css.root, className);

      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !(values.preferredList && values.preferredList.length);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.preferListWrapper}>
            <div className={css.headingName}>
              <FormattedMessage id="PreferedListForm.shareEventProfile" />
            </div>
            <FieldMultiSelect
              isMulti={true}
              isRequired={true}
              isSearchable={true}
              className={css.field}
              id={"preferredList"}
              name={"preferredList"}
              label={preferedListLabel}
              autoComplete={"preferredList"}
              options={preferredList.map(user => ({key: user.id, label: user.name || user.id, value: user.name}))}
              fontClass={css.fontClass}
            />
            <div className={css.sendLink}>
              <InlineTextButton type='button' onClick={shareWithAll}>
                <FormattedMessage id="PreferredListForm.sendAllList" />
              </InlineTextButton>
            </div>

            <PrimaryButton
              className={css.button}
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="PreferredListForm.sendSelected" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

PreferredListFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  formId: null,
};

const { string, bool, func } = PropTypes;

PreferredListFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  formId: string,
};

const PreferredListForm = compose(injectIntl)(PreferredListFormComponent);
PreferredListForm.displayName = 'PreferredListForm';

export default PreferredListForm;
