import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import config from '../../config';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { obfuscatedCoordinates } from '../../util/maps';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { Accordion, Map, Modal, PrimaryButton, SliderTiles } from '..';
import { EditListingAvailabilityPlanForm } from '../../forms';

import css from './VendorAccessibility.module.css';

const { LatLng } = sdkTypes;

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const KEY_CODE_ENTER = 13;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

/////////////
// Weekday //
/////////////
const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan && availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

const Weekday = props => {
  const { availabilityPlan, dayOfWeek, openEditModal } = props;
  const hasEntry = findEntry(availabilityPlan, dayOfWeek);

  return (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      onClick={() => openEditModal(true)}
      role="button"
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => (
            <span className={css.entry} key={`${e.dayOfWeek}${moment(e.startTime, 'HH:mm').format('h:mm a')}`}>{`${moment(e.startTime, 'HH:mm').format('h:mm a')} - ${e.endTime === '00:00' ? '12:00 am' : moment(e.endTime, 'HH:mm').format('h:mm a')}`}</span>
          ))
          : null}
      </div>
    </div>
  );
};

// Create initial entry mapping for form's initial values
const createEntryDayGroups = (entries = {}) =>
  entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
        },
      ],
    };
  }, {});

// Create initial values
const createInitialValues = availabilityPlan => {
  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
  };
};

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
          dayOfWeek,
          seats: 1,
          startTime,
          endTime: endTime === '24:00' ? '00:00' : endTime,
        }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  type: 'availability-plan/time',
  timezone: values.timezone,
  entries: createEntriesFromSubmitValues(values),
});

const VendorAccessibilityComponent = props => {
  const {
    intl,
    className,
    currentUser,
    availabilityPlan,
    initialValues: values,
    updateInProgress,
    updateProfileError,
    onSubmit,
    address, // = "New York"
    onManageDisableScrolling,
    geolocation, // = new LatLng(41.917576401307, -74.7008392055224)
  } = props;

  const { publicData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
  const { userType } = publicData || {};
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  // const [initialValues, setInitialValues] = useState(values);
  const [rangeValue, setRangeValue] = useState(values && values.rangeValue && Array.isArray(values.rangeValue) ? values.rangeValue : [config.rangeValue]);
  const [updatedAvailabilityPlan, setUpdatedAvailabilityPlan] = useState(null);

  const radius = (rangeValue[0] * 1609);
  const mapProps = geolocation && address
    ? config.maps.fuzzy.enabled
      ? {
        radius,
        zoom: 13 - (rangeValue[0] < 23 ? 5 : rangeValue[0] < 73 ? 6 : rangeValue[0] > 111 ? 8 : Math.round(rangeValue[0] / 11) < 13 ? Math.round(rangeValue[0] / 13) : 10),
        obfuscatedCenter: obfuscatedCoordinates(geolocation),
      }
      : { address, center: geolocation }
    : null;

  return (
    <div className={css.root}>
      {geolocation && address && userType != 'venue'
        ? <div className={css.mapWrapper}>
          <h3 className={css.mapTitle}>Select The Maximum Delivery Range </h3>
          <div className={css.map}>
            <Map {...mapProps} />
          </div>
          <div className={css.rangeSliderBox}>
            <SliderTiles
              rangeValue={rangeValue}
              setRangeValue={setRangeValue}
            />
          </div>
          <hr className={css.dividerLine} />
        </div>
        : null}
      <Accordion title={intl.formatMessage({ id: "VendorAccessibilityForm.storeHours" }, { type: userType == 'venue' ? "Venue" : "Store" })}>
        <div className={css.weeksSection}>
          <h3 className={css.title}>
            <FormattedMessage id="VendorAccessibilityForm.createListingTitle" />
          </h3>
          <section className={css.section}>
            <div className={css.week}>
              {WEEKDAYS.map(w => (
                <Weekday
                  dayOfWeek={w}
                  key={w}
                  availabilityPlan={updatedAvailabilityPlan || availabilityPlan}
                  openEditModal={setIsEditPlanModalOpen}
                />
              ))}
            </div>
          </section>
        </div>
      </Accordion>
      <PrimaryButton type="button" inProgress={updateInProgress} onClick={e => {
        e.preventDefault();
        const updatedValues = { ...values, rangeValue, availabilityPlan: updatedAvailabilityPlan || availabilityPlan };
        onSubmit(updatedValues);
      }}>
        <FormattedMessage id="VendorAccessibilityForm.submit" />
      </PrimaryButton>
      <Modal
        id="EditAvailabilityPlan"
        isOpen={isEditPlanModalOpen}
        onClose={() => setIsEditPlanModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        usePortal
      >
        <EditListingAvailabilityPlanForm
          formId="EditListingAvailabilityPlanForm"
          listingTitle={"currentListing.attributes.title"}
          availabilityPlan={availabilityPlan}
          weekdays={WEEKDAYS}
          onSubmit={(e) => {
            setUpdatedAvailabilityPlan(createAvailabilityPlan(e));
            setIsEditPlanModalOpen(false);
          }}
          initialValues={(updatedAvailabilityPlan
            ? createInitialValues(updatedAvailabilityPlan)
            : availabilityPlan
              ? createInitialValues(availabilityPlan)
              : {})}
          inProgress={updateInProgress}
        // fetchErrors={errors}
        />
      </Modal>
    </div>
  );
}

VendorAccessibilityComponent.defaultProps = { inProgress: false, eventSizeOptions: config.custom.eventSizes };

const { bool, func } = PropTypes;

VendorAccessibilityComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const VendorAccessibility = compose(injectIntl)(VendorAccessibilityComponent);
VendorAccessibility.displayName = 'VendorAccessibility';

export default VendorAccessibility;
