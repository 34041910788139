import moment from 'moment';
import { denormalisedEntities, denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import { transitionsToRequested } from '../util/transaction';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { util as sdkUtil } from '../util/sdkLoader';
import { changeFlows, getDotCustomer, getTransactions } from '../util/api';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_CATEGORIES_REQUEST = 'app/user/FETCH_CURRENT_CATEGORIES_REQUEST';
export const FETCH_CURRENT_CATEGORIES_SUCCESS = 'app/user/FETCH_CURRENT_CATEGORIES_SUCCESS';
export const FETCH_CURRENT_CATEGORIES_ERROR = 'app/user/FETCH_CURRENT_CATEGORIES_ERROR';

export const FETCH_EVENT_TYPES_REQUEST = 'app/user/FETCH_EVENT_TYPES_REQUEST';
export const FETCH_EVENT_TYPES_SUCCESS = 'app/user/FETCH_EVENT_TYPES_SUCCESS';
export const FETCH_EVENT_TYPES_ERROR = 'app/user/FETCH_EVENT_TYPES_ERROR';

export const FETCH_CURRENT_USER_HAS_WISHLIST_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_WISHLIST_REQUEST';
export const FETCH_CURRENT_USER_HAS_WISHLIST_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_WISHLIST_SUCCESS';
export const FETCH_CURRENT_USER_HAS_WISHLIST_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_WISHLIST_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const FETCH_USER_CHANNELS_REQUEST = 'app/user/FETCH_USER_CHANNELS_REQUEST';
export const FETCH_USER_CHANNELS_SUCCESS = 'app/user/FETCH_USER_CHANNELS_SUCCESS';
export const FETCH_USER_CHANNELS_ERROR = 'app/user/FETCH_USER_CHANNELS_ERROR';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
      ? newCurrentUser
      : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  categories: [],
  subCategories: [],
  subChildCategories: [],
  currentUserHasWishlist: [],
  currentUserHasWishlistError: null,
  categoriesInProgress: false,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  eventTypes: [],
  eventTypesInProgress: false,
  eventTypesError: null,
  channels: [],
  fetchChannelsInProgress: false,
  fetchChannelsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return { ...state, currentUser: mergeCurrentUser(state.currentUser, payload) };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
        currentUserListing: null,
        currentUserListingFetched: false,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasListings: payload.hasListings,
        currentUserListing: payload.listing,
        currentUserListingFetched: true,
      };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };
    case FETCH_CURRENT_USER_HAS_WISHLIST_REQUEST:
      return { ...state, currentUserHasWishListError: null };
    case FETCH_CURRENT_USER_HAS_WISHLIST_SUCCESS: {
      return { ...state, currentUserHasWishList: payload.hasWishlists };
    }
    case FETCH_CURRENT_USER_HAS_WISHLIST_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasWishListError: payload };
    case FETCH_EVENT_TYPES_REQUEST:
      return { ...state, eventTypesError: null, eventTypesInProgress: true, };
    case FETCH_EVENT_TYPES_SUCCESS:
      return {
        ...state,
        eventTypes: payload,
        categoriesInProgress: false,
      };
    case FETCH_EVENT_TYPES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, eventTypesError: payload, eventTypesInProgress: false, };
    case FETCH_CURRENT_CATEGORIES_REQUEST:
      return { ...state, currentCategoriesError: null, categoriesInProgress: payload.requested, };
    case FETCH_CURRENT_CATEGORIES_SUCCESS:
      const filterData = [...(payload.clearPrev ? [] : state[payload.type]), ...payload.categories].filter((value, index, self) => index === self.findIndex((t) => (t.key === value.key && t.id == value.id)));
      return {
        ...state,
        [payload.type]: [...filterData],
        categoriesInProgress: false,
      };
    case FETCH_CURRENT_CATEGORIES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentCategoriesError: payload, categoriesInProgress: false, };
    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case FETCH_USER_CHANNELS_REQUEST:
      return {
        ...state,
        fetchChannelsInProgress: true,
        channels: [],
        fetchChannelsError: null,
      }
    case FETCH_USER_CHANNELS_SUCCESS:
      return {
        ...state,
        fetchChannelsInProgress: false,
        channels: payload,
      }
    case FETCH_USER_CHANNELS_ERROR:
      return {
        ...state,
        fetchChannelsInProgress: false,
        fetchChannelsError: payload,
        channels: [],
      }
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (hasListings, listing, categories) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings, listing, categories },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasWishListRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_WISHLIST_REQUEST,
});

const fetchCurrentUserHasWishListSuccess = hasWishlists => ({
  type: FETCH_CURRENT_USER_HAS_WISHLIST_SUCCESS,
  payload: { hasWishlists },
});

const fetchCurrentUserHasWishListError = e => ({
  type: FETCH_CURRENT_USER_HAS_WISHLIST_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentCategoriesRequest = (requested) => ({
  type: FETCH_CURRENT_CATEGORIES_REQUEST,
  payload: { requested }
});

export const fetchCurrentCategoriesSuccess = (type, categories, clearPrev) => ({
  type: FETCH_CURRENT_CATEGORIES_SUCCESS,
  payload: { type, categories, clearPrev },
});

const fetchCurrentCategoriesError = e => ({
  type: FETCH_CURRENT_CATEGORIES_ERROR,
  error: true,
  payload: e,
});

const fetchEventTypesRequest = (requested) => ({
  type: FETCH_EVENT_TYPES_REQUEST,
  payload: { requested }
});

export const fetchEventTypesSuccess = (data) => ({
  type: FETCH_EVENT_TYPES_SUCCESS,
  payload: data,
});

const fetchEventTypesError = e => ({
  type: FETCH_EVENT_TYPES_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const fetchUserChannelsRequest = () => ({
  type: FETCH_USER_CHANNELS_REQUEST,
});
export const fetchUserChannelsSuccess = (payload) => ({
  type: FETCH_USER_CHANNELS_SUCCESS,
  payload
});
export const fetchUserChannelsError = e => ({
  type: FETCH_USER_CHANNELS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = (payload = {}) => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  dispatch(fetchEventTypes());
  if (!currentUser || (currentUser && currentUser.id && !currentUser.attributes.profile.publicData.userListingId)) {
    dispatch(fetchCurrentCategories()).then(data => {
    });
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    id: currentUser.attributes.profile.publicData.userListingId,
    include: ['images'],
    'fields.image': ['variants.default', 'variants.square-small', 'variants.square-small2x', 'variants.landscape-crop'],
    page: 1,
    per_page: 100,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;
      if (hasListings) {
        Object.assign(response.data, {
          ...response.data,
          data: response.data.data.filter(d => d.id.uuid == currentUser.attributes.profile.publicData.userListingId)
        });
      }
      const { subCategories } = getState().user;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      if (response.data.data && Object.keys(response.data.data).length) {
        const listing = denormalisedResponseEntities(response)[0];

        try {
          // Get all categories and reduce into the state
          dispatch(fetchCurrentCategories()).then(data => {
            const { categories } = data;
            const { publicData } = currentUser.attributes.profile;
            const { userType } = publicData || {};
            if (payload.categoryName && categories && categories.length) {
              const index = categories.findIndex(fc => fc.key == payload.categoryName);
              if (index > -1) {
                dispatch(fetchCurrentCategories({ type: "subCategories", parentIds: [categories[index].id] }));
              } else {
                dispatch(fetchCurrentCategories({ type: "subCategories", parentIds: [categories[0].id] }));
              }
            } else if (listing && Object.keys(listing).length && listing.attributes.publicData.categories && listing.attributes.publicData.categories.length && categories && categories.length) {
              const categoriesId = [], subCategoriesId = [];
              if (!payload.categoryName) {
                listing.attributes.publicData.categories.map(cat => {
                  categoriesId.push(cat.id);
                  cat.subCategory.map(sCat => {
                    subCategoriesId.push(sCat.id);
                  });
                });
                if (userType == 'venue') {
                  dispatch(fetchCurrentCategories({ type: "subCategories", parentIds: ['6'] }));
                } else {
                  dispatch(fetchCurrentCategories({ type: "subCategories", parentIds: categoriesId }));
                }
                dispatch(fetchCurrentCategories({ type: "subChildCategories", parentIds: subCategoriesId }));
              }
            } else if (userType == 'venue') {
              dispatch(fetchCurrentCategories({ type: "subCategories", parentIds: ['6'] }));
            } else {
              dispatch(fetchCurrentCategories({ type: "subCategories" }));
            }
          });
        } catch (e) {
          console.error(e, '**** fetchCurrentCategories user duck **** => e');
        }

        dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings, listing));

        // If the listing doesn't have availabilityPlan yet
        // use the defaul timezone
        // const availabilityPlan = listing.attributes.availabilityPlan;

        // const tz = availabilityPlan
        //   ? listing.attributes.availabilityPlan.timezone
        //   : typeof window !== 'undefined'
        //     ? getDefaultTimeZoneOnBrowser()
        //     : 'Etc/UTC';

        // const today = new Date();
        // const start = resetToStartOfDay(today, tz, 0);
        // // Query range: today + 364 days
        // const exceptionRange = 364;
        // const end = resetToStartOfDay(today, tz, exceptionRange);

        // // NOTE: in this template, we don't expect more than 100 exceptions.
        // // If there are more exceptions, pagination kicks in and we can't use frontend sorting.
        // const params = {
        //   listingId: listing.id,
        //   start,
        //   end,
        // };
        // return dispatch(requestFetchAvailabilityExceptions(params));
        return listing;
      }
      return response;
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasWishList = (ids = []) => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasWishListRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasWishListSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    ids,
    page: 1,
    per_page: 100,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(fetchCurrentUserHasWishListSuccess(response.data.data));
      return response;
    })
    .catch(e => dispatch(fetchCurrentUserHasWishListError(storableError(e))));
};

export const addOrRemoveToConnected = (listingId, isAddPreferred, businessName, addPreferredVendor = []) => (dispatch, getState, sdk) => {
  // dispatch(updateProfileRequest()); // need to change find reason why
  const { currentUser } = getState().user;
  const index = (currentUser.id && currentUser.attributes.profile.publicData.addPreferredVendor || []).findIndex(w => w.id === listingId);

  addPreferredVendor = (currentUser.attributes.profile.publicData.addPreferredVendor) || [];

  if (isAddPreferred) {
    if (index > -1) {
      addPreferredVendor = currentUser.attributes.profile.publicData.addPreferredVendor.filter(w => w.id != listingId);
    }
  } else {
    addPreferredVendor.push({
      id: listingId,
      name: businessName,
      createdAt: moment().unix()
    });
  }

  addPreferredVendor = addPreferredVendor.filter(c => c.id);

  return sdk.currentUser.updateProfile({
    publicData: { addPreferredVendor }
  }, {
    expand: true,
  })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      if (currentUser.id && currentUser.attributes.profile.publicData.addPreferredVendor) {
        dispatch(fetchCurrentUserHasWishList(currentUser.attributes.profile.publicData.addPreferredVendor.map(w => w.id)));
      }
      // Update current user in state.user.currentUser through user.duck.js
      // dispatch(updateProfileSuccess(response)); // need to change find reason why
      return response;
    })
    .catch(e => { }
      // dispatch(updateProfileError(storableError(e))) // need to change find reason why
    );
};

export const fetchCurrentCategories = (params = {}) => (dispatch, getState, sdk) => {
  // const { currentUser } = getState().user;

  // if (!currentUser || (currentUser && currentUser.id && !currentUser.attributes.profile.publicData.userListingId)) {
  //   dispatch(fetchCurrentCategoriesSuccess(false));
  //   return Promise.resolve(null);
  // }

  try {
    const { type = "categories", parentIds = null, clearPrev = false } = params;
    dispatch(fetchCurrentCategoriesRequest(type));
    const payload = {
      tableName: "categories",
      query: {
        parentId: null
      }
    };

    if (parentIds && parentIds.length && Array.isArray(parentIds)) {
      Object.assign(payload.query, {
        parentId: {
          $in: parentIds.map(pid => pid + '')
        }
      })
    }

    return getTransactions(payload)
      .then(data => {
        const parsedData = JSON.parse(data.data);
        const newData = parsedData && parsedData.length
          ? parsedData.map(pd => ({
            ...pd,
            key: (pd.name.charAt(0).toLowerCase() + pd.name.slice(1)).replace(/ /g, "_").replace(/\//g, "_"),
            value: pd.name.charAt(0).toLowerCase() + pd.name.slice(1),
            label: pd.name,
          }))
          : [];
        dispatch(fetchCurrentCategoriesSuccess(type, newData, clearPrev));
        return { [type]: newData };
      })
      .catch(e => dispatch(fetchCurrentCategoriesError(storableError(e))));
  } catch (e) {
    console.error(e, '**** fetch Current Categories **** => e');
  }

};

export const fetchUserChannels = (userId) => (dispatch) => {
  dispatch(fetchUserChannelsRequest());
  const payload = {
    tableName: "channels",
    query: { 'members.userId': userId, isActive: '1' }
  };

  return getTransactions(payload)
    .then(data => {
      const parsedData = JSON.parse(data.data);
      dispatch(fetchUserChannelsSuccess(parsedData));
      return parsedData;
    })
    .catch(e => dispatch(fetchUserChannelsError(storableError(e))));
};

export const fetchEventTypes = () => (dispatch) => {
  dispatch(fetchEventTypesRequest());
  const payload = {
    tableName: "eventTypes",
    query: {}
  };

  return getTransactions(payload)
    .then(data => {
      const parsedData = JSON.parse(data.data);
      const newData = parsedData && parsedData.length
        ? parsedData.map(pd => ({
          ...pd,
          key: (pd.name.charAt(0).toLowerCase() + pd.name.slice(1)).replace(/ /g, "_").replace(/\//g, "_"),
          value: pd.name.charAt(0).toLowerCase() + pd.name.slice(1),
          label: pd.name,
        }))
        : [];
      dispatch(fetchEventTypesSuccess(newData));
      return newData;
    })
    .catch(e => dispatch(fetchEventTypesError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (params = null, categoryName, isDotUser = false) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    dispatch(fetchCurrentCategories()).then(({ categories }) => {
      const index = categories.findIndex(fc => fc.key == categoryName);
      if (index > -1) {
        dispatch(fetchCurrentCategories({ type: "subCategories", parentIds: [categories[index].id] }));
      } else {
        dispatch(fetchCurrentCategories({ type: "subCategories", parentIds: [categories[0].id] }));
      }
    });
    return Promise.resolve({});
  }
  console.log(isDotUser, '**** **** => isDotUser');

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': [
      'variants.default',
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings({ categoryName }));
      dispatch(fetchCurrentUserNotifications());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }
      dispatch(fetchUserChannels(currentUser.id.uuid));
      // Make sure auth info is up to date
      dispatch(authInfo());
      if (isDotUser && currentUser.attributes.profile.protectedData && currentUser.attributes.profile.protectedData.dotUserId) {
        try {
          getDotCustomer({ userId: currentUser.attributes.profile.protectedData.dotUserId })
            .then(res => {
              if (res && res.data) {
                Object.assign(currentUser.attributes.profile.protectedData, {
                  dotUser: res.data
                });
              }
              dispatch(currentUserShowSuccess(currentUser));
            });
        } catch (e) {
          console.error(e, '**** getDotCustomer user duck **** => e');
        }
      }
      if (isDotUser && currentUser.attributes.profile.protectedData.flow && currentUser.attributes.profile.protectedData.flow.id) {
        try {
          return changeFlows({
            flowId: currentUser.attributes.profile.protectedData.flow.id
          })
            .then(response => {
              const flow = response.data;
              if (flow && flow.id) {
                return sdk.currentUser.updateProfile({ protectedData: { flow } }, { expand: true })
                  .then(response => {
                    return response.data.data;
                  });
              }
            });
        } catch (e) {
          console.error(e, '**** changeFlows user duck **** => e');
        }
      }
      return currentUser;
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};
