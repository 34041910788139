import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import config from '../../config';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { formatMoney } from '../../util/currency';
import * as validators from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT } from '../../util/types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import {
  Form,
  PrimaryButton,
  FieldMultiSelect,
  FieldCheckbox,
  FieldTextInput,
  IconProfileSetup,
  FieldCurrencyInput,
} from '../../components';

import css from './AdditionalVendorsForm.module.css';

const { Money } = sdkTypes;

export class AdditionalVendorsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <FinalForm
      {...this.props}
      keepDirtyOnReinitialize={true}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          invalid,
          intl,
          form,
          values,
          categories,
          subCategories,
          updateInProgress,
          onFetchCurrentCategories,
        } = fieldRenderProps;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        const minTranslationKey = isNightly
          ? 'EditListingPricingForm.minPricePerNight'
          : isDaily
            ? 'EditListingPricingForm.minPricePerDay'
            : 'EditListingPricingForm.minPricePerUnit';

        const maxTranslationKey = isNightly
          ? 'EditListingPricingForm.maxPricePerNight'
          : isDaily
            ? 'EditListingPricingForm.maxPricePerDay'
            : 'EditListingPricingForm.maxPricePerUnit';

        const minPricePerUnitMessage = intl.formatMessage({
          id: minTranslationKey,
        });

        const minPricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.minPriceInputPlaceholder',
        });

        const minPriceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.minPriceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const miniPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.minPriceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const minPriceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(minPriceRequired, miniPriceRequired)
          : minPriceRequired;


        const maxPricePerUnitMessage = intl.formatMessage({
          id: maxTranslationKey,
        });

        const maxPricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.maxPriceInputPlaceholder',
        });

        const maxPriceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.maxPriceRequired',
          })
        );

        // categories
        const categoriesLabel = intl.formatMessage({
          id: 'EditListingPricingForm.categories',
        });
        const categoriesPlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.categoriesPlaceholder',
        });
        const subCategoriesPlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.subCategoriesPlaceholder',
        });
        const categoriesRequiredMessage = intl.formatMessage({
          id: 'EditListingPricingForm.categoriesRequired',
        });
        const categoriesRequired = validators.required(categoriesRequiredMessage);

        const requestMessage = intl.formatMessage({ id: 'EditListingPricingForm.request' });
        const requestPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.requestPlaceholder',
        });
        const requestRequiredMessage = intl.formatMessage({
          id: 'EditListingPricingForm.requestRequired',
        });

        const notAvailaleLabel = intl.formatMessage({
          id: 'EditListingPricingForm.notAvailaleLabel'
        });
        const deliveryRequiredLabel = intl.formatMessage({
          id: 'EditListingPricingForm.deliveryRequiredLabel'
        });

        const classes = classNames(css.root, className);
        const submitInProgress = updateInProgress;
        let isRequirementRequest = false;
        for (const key in values) {
          if (Object.hasOwnProperty.call(values, key)) {
            const element = values[key];
            if (key.search('requirement-request') > -1 && element) {
              isRequirementRequest = true;
            }
          }
        }
        const submitDisabled = invalid || submitInProgress || !isRequirementRequest;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <div className={css.headingName}>
                <FormattedMessage id="AdditionalVendorsForm.shareEventProfile" />
              </div>
              {/* Select the types of vendors your hope to hire */}
              <div className={css.multiSelectInput}>
                <FieldMultiSelect
                  isMulti={true}
                  isRequired={true}
                  isSearchable={true}
                  className={css.field}
                  id={"categories"}
                  name={"categories"}
                  label={categoriesLabel}
                  autoComplete={"categories"}
                  options={categories}
                  validate={categoriesRequired}
                  onSelection={(e) => e ? onFetchCurrentCategories({ type: 'subCategories', parentIds: e.map(e => e.id) }) : onFetchCurrentCategories()}
                  // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
                  fontClass={css.fontClass}
                  placeholder={categoriesPlaceholder}
                // defaultValue={values.categories || ""}
                />
                {/* <hr className={css.dividerLine} /> */}
              </div>

              {/* Event Rentals */}
              {values.categories && values.categories.length && subCategories && subCategories.length
                ? <div className={css.accordanceMainBox}>
                  <hr className={css.dividerLine} />
                  {values.categories.map((ca, ind) =>
                  (<div key={ca + '-' + ind} className={css.categoriesBox}>
                    {/* <h4>{ca.label}</h4> */}
                    <div className={css.categoriesCheckbox}>
                      <FieldMultiSelect
                        isMulti={true}
                        isRequired={true}
                        isSearchable={true}
                        className={css.field}
                        id={ca.key}
                        name={ca.key}
                        label={ca.label}
                        options={subCategories.filter(sc => sc.parentId == ca.id)}
                        validate={categoriesRequired}
                        // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
                        fontClass={css.fontClass}
                        placeholder={subCategoriesPlaceholder}
                        defaultValue={values[ca.key] || ""}
                      />
                      <hr className={css.dividerLine} />
                      {values[ca.key]
                        ? values[ca.key].map((cat, i) => {
                          const minPrice = values[cat.key + "_minPrice"] || new Money(config.listingMinimumPriceSubUnits, config.currency);
                          const maxiPriceRequired = validators.moneySubUnitAmountAtLeast(
                            intl.formatMessage(
                              {
                                id: 'EditListingPricingForm.maxPriceTooLow',
                              },
                              {
                                minPrice: formatMoney(intl, minPrice),
                              }
                            ),
                            minPrice.amount
                          );
                          const maxPriceValidators = minPrice
                            ? validators.composeValidators(maxPriceRequired, maxiPriceRequired)
                            : maxPriceRequired;
                          const requirementRequest = Object.keys(values).filter(v => v.search([cat.key + "_requirement-request"]) > -1);
                          return (
                            <div key={cat + '-' + i} className={css.accordanceBox}>
                              <details className={css.accordion}>
                                <summary className={css.accordionTitle}>{cat.label}</summary>
                                <div className={css.priceBoxWrap}>
                                  {/* Minimum Budget */}
                                  {values[cat.key + "_N/A"]
                                    ? null
                                    : <>
                                      <FieldCurrencyInput
                                        id={cat.key + "_minPrice"}
                                        name={cat.key + "_minPrice"}
                                        className={css.priceInput}
                                        label={minPricePerUnitMessage}
                                        placeholder={minPricePlaceholderMessage}
                                        currencyConfig={config.currencyConfig}
                                        validate={minPriceValidators}
                                      />
                                      {/* Maximum Budget */}
                                      <FieldCurrencyInput
                                        id={cat.key + "_maxPrice"}
                                        name={cat.key + "_maxPrice"}
                                        className={css.priceInput}
                                        label={maxPricePerUnitMessage}
                                        placeholder={maxPricePlaceholderMessage}
                                        currencyConfig={config.currencyConfig}
                                        validate={maxPriceValidators}
                                      />
                                    </>}

                                  {/* N/A */}
                                  <FieldCheckbox
                                    id={cat.key + "_N/A"}
                                    name={cat.key + "_N/A"}
                                    label={notAvailaleLabel}
                                    className={css.priceCheck}
                                  />
                                </div>

                                <div className={css.requestMessage}>
                                  {requirementRequest.length
                                    ? requirementRequest.map((v, i) => <FieldTextInput
                                      key={cat.key + "_requirement-request" + (parseInt(i) + 1)}
                                      id={cat.key + "_requirement-request" + (parseInt(i) + 1)}
                                      name={cat.key + "_requirement-request" + (parseInt(i) + 1)}
                                      className={css.title}
                                      type="text"
                                      label={i == 0 ? requestMessage : null}
                                      inputIcon={<span onClick={() => form.change((cat.key + "_requirement-request" + (parseInt(requirementRequest.length) + 1)), "")}><IconProfileSetup type="plus" /></span>}
                                      placeholder={requestPlaceholderMessage}
                                    />)
                                    : <FieldTextInput
                                      key={cat.key + "_requirement-request1"}
                                      id={cat.key + "_requirement-request1"}
                                      name={cat.key + "_requirement-request1"}
                                      className={css.title}
                                      type="text"
                                      label={requestMessage}
                                      inputIcon={<span onClick={() => form.change((cat.key + "_requirement-request" + 2), "")}><IconProfileSetup type="plus" /></span>}
                                      placeholder={requestPlaceholderMessage}
                                    />}
                                </div>
                                <div className={css.deliveryCheck}>
                                  <FieldCheckbox
                                    id={cat.key + "_isDelivery"}
                                    name={cat.key + "_isDelivery"}
                                    label={deliveryRequiredLabel}
                                    className={css.priceCheck}
                                  />
                                </div>
                              </details>
                              <hr className={css.dividerLine} />
                            </div>
                          );
                        })
                        : null}
                    </div>
                    {/* <hr className={css.dividerLine} /> */}
                  </div>)
                  )}
                </div>
                : null}
            </div>
            <div className={css.bottomWrapper}>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                className={css.submitButton}
              >
                <FormattedMessage id="AdditionalVendorsForm.publishRequests" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  }
};

AdditionalVendorsFormComponent.defaultProps = { inProgress: false, };

const { bool, func } = PropTypes;

AdditionalVendorsFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const AdditionalVendorsForm = compose(injectIntl)(AdditionalVendorsFormComponent);
AdditionalVendorsForm.displayName = 'AdditionalVendorsForm';

export default AdditionalVendorsForm;
