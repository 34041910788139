import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import ExternalLink from '../ExternalLink/ExternalLink';
import IconSocialMediaFacebook from '../IconSocialMediaFacebook/IconSocialMediaFacebook';
import IconSocialMediaTwitter from '../IconSocialMediaTwitter/IconSocialMediaTwitter';
import IconSocialMediaInstagram from '../IconSocialMediaInstagram/IconSocialMediaInstagram';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import css from './LayoutWrapperTopbar.module.css';
import Button from '../Button/Button';

const renderSocialMediaLinks = intl => {
    const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
    const siteTwitterPage = twitterPageURL(siteTwitterHandle);

    const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
    const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
    const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

    const fbLink = siteFacebookPage ? (
        <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
            <IconSocialMediaFacebook />
        </ExternalLink>
    ) : null;

    const twitterLink = siteTwitterPage ? (
        <ExternalLink
            key="linkToTwitter"
            href={siteTwitterPage}
            className={css.icon}
            title={goToTwitter}
        >
            <IconSocialMediaTwitter />
        </ExternalLink>
    ) : null;

    const instragramLink = siteInstagramPage ? (
        <ExternalLink
            key="linkToInstagram"
            href={siteInstagramPage}
            className={css.icon}
            title={goToInsta}
        >
            <IconSocialMediaInstagram />
        </ExternalLink>
    ) : null;
    return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const TopbarBanner = props => {
    const { className, rootClassName, children, intl } = props;
    const classes = classNames(rootClassName || css.root, className, css.banner);
    const { isAuthenticated } = useSelector(state => state.Auth);

    const socialMediaLinks = renderSocialMediaLinks(intl);

    return isAuthenticated
        ? null
        : <div className={classes}>
            <div onClick={() => {}}>
                <FormattedMessage id="TopbarDesktop.Subscribe" />
            </div>
            <div>Not sure where to start? Try our <b>Vendor Match</b> program!</div>
            <div className={css.socialMediaLinks}>{socialMediaLinks}</div>
        </div>;
};

TopbarBanner.defaultProps = {
    className: null,
    rootClassName: null,
};

const { node, string } = PropTypes;

TopbarBanner.propTypes = {

    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
};

export default injectIntl(TopbarBanner);
