import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import config from '../config';
import { sanitizeEntity } from './sanitize';
import { types as sdkTypes } from './sdkLoader';
import moment from 'moment';

const { Money } = sdkTypes;

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' &&
        value._ref &&
        value._ref?.type === 'imageAsset' &&
        value._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef =
        typeof value == 'object' && value._ref && value._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = { id: null, type: 'currentUser', attributes: { profile: {} }, profileImage: {} };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.displayName;

  if (hasDisplayName) {
    return user.attributes.profile.displayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

// Copy to clipboards
export const copyToClipboard = text => {
  let dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

// Get page url
export const getPageURL = () => {
  if (typeof window !== 'undefined') {
    const pageUrl = (process.env.REACT_APP_CANONICAL_ROOT_URL) + window.location.pathname + window.location.search;
    return pageUrl;
  }
}


export const camelCaseEventDuration = eventDuration => {
  if (typeof eventDuration != 'string') {
    throw new Error(`Invalid event duration value`);
  }
  const lowercase = eventDuration.replace(/-/g, ' ');
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const parseToFromSharetribe = (data, categories = [], subCategories = [], type) => {
  if (data && Object.keys(data).length) {
    if (categories && Array.isArray(categories) && categories.length && ['PROFILE_FORM', 'DETAILED_QUOTE_FORM'].includes(type)) {
      let price = 0;
      const categories = data['categories'].map((st) => ({
        id: st.id,
        key: st.key,
        value: st.value,
        label: st.label,
        biddingAcorn: st.biddingAcorn,
        winningAcorn: st.winningAcorn,
        refundAcorn: st.refundAcorn,
        subCategory: data[st.key]
          ? data[st.key].map((str) => {
            const requirements = {}, subChildCategories = [];
            for (const key in data) {
              if (Object.hasOwnProperty.call(data, key)) {
                if (key.search(str.label) > -1 || key.search(str.key) > -1) {
                  const newKeyName = key.split('_');
                  if (data[key] && Array.isArray(data[key]) && data[key].length) {
                    subChildCategories.push(...data[key]);
                  } else if (newKeyName && newKeyName.length > 1) {
                    if (key != 'price' && key.search('rice') > -1 && (data[str.label + '_N/A'] || data[str.key + '_N/A'])) {
                      delete data[key];
                    } else if (key != 'price' && key.search('rice') > -1) {
                      if (!price) {
                        price = data[key];
                      } else if (price && price.amount > data[key].amount) {
                        price = data[key];
                      }
                      Object.assign(requirements, { [newKeyName[1]]: data[key].amount });
                    } else {
                      Object.assign(requirements, { [newKeyName[1]]: data[key] });
                    }
                  }
                }
              }
            }
            return type == "PROFILE_FORM"
              ? { id: str.id, key: str.key, value: str.value, label: str.label, subChildCategories }
              : { id: str.id, key: str.key, value: str.value, label: str.label, discount: str.discount, requirements };
          })
          : []
      }));
      return { categories, price };
    } else if (categories && Array.isArray(categories) && categories.length && type == 'QUOTE_FORM') {
      let price = 0;
      const newCategories = categories.map((st) => ({
        id: st.id,
        key: st.key,
        value: st.value,
        label: st.label,
        subCategory: st.subCategory
          ? st.subCategory.map((str) => {
            const requirements = {}, subChildCategories = [];
            for (const key in data) {
              if (Object.hasOwnProperty.call(data, key)) {
                if (key.search(str.label) > -1) {
                  const newKeyName = key.split('_');
                  if (data[key] && Array.isArray(data[key]) && data[key].length) {
                    subChildCategories.push(...data[key]);
                  } else if (newKeyName && newKeyName.length > 1) {
                    if (key != 'price' && key.search('rice') > -1 && (data[str.label + '_N/A'] || data[str.key + '_N/A'])) {
                      delete data[key];
                    } else if (key != 'price' && key.search('rice') > -1) {
                      if (!price) {
                        price = data[key];
                      } else if (price && price.amount > data[key].amount) {
                        price = data[key];
                      }
                      Object.assign(requirements, { [newKeyName[1]]: data[key].amount });
                    } else {
                      Object.assign(requirements, { [newKeyName[1]]: data[key] });
                    }
                  }
                }
              }
            }
            return { id: str.id, key: str.key, value: str.value, label: str.label, discount: str.discount, requirements };
          })
          : []
      }));
      return { categories: newCategories, price };
    } else if (data['categories'] && Array.isArray(data['categories']) && data['categories'].length) {
      if (data['categories'][0].subCategory && data['categories'][0].subCategory.length) {
        const newData = {};

        if (categories && categories.length && subCategories && subCategories.length) {
          data['categories'].map((st) => {
            const catIndex = categories.findIndex(cat => cat.id == st.id);
            if (catIndex > -1) {
              if (newData['categories'] && newData['categories'].length) {
                newData['categories'].push(categories[catIndex]);
              } else {
                newData['categories'] = [categories[catIndex]];
              }
              st.subCategory.map(subCat => {
                const subCatIndex = subCategories.findIndex(sc => sc.id == subCat.id);
                if (subCatIndex > -1) {
                  if (newData[categories[catIndex].key] && newData[categories[catIndex].key].length) {
                    newData[categories[catIndex].key].push(subCategories[subCatIndex]);
                  } else {
                    newData[categories[catIndex].key] = [subCategories[subCatIndex]];
                  }
                  if (type == 'PROFILE') {
                    newData[subCategories[subCatIndex].key] = subCat.subChildCategories;
                  } else {
                    for (const key in subCat.requirements) {
                      if (Object.hasOwnProperty.call(subCat.requirements, key)) {
                        const element = subCat.requirements[key];
                        if (key != 'price' && key.search('rice') > -1) {
                          if (!subCat.requirements['N/A']) {
                            Object.assign(newData, { [subCategories[subCatIndex].label + "_" + key]: new Money(element, config.currency) });
                          }
                        } else {
                          Object.assign(newData, { [subCategories[subCatIndex].label + "_" + key]: element });
                        }
                      }
                    }
                  }
                }
              });
            }
          });
        }

        return newData;
      } else {
        let price = 0;
        const categories = data['categories'].map((st) => ({
          id: st.id,
          key: st.key,
          value: st.value,
          label: st.label,
          biddingAcorn: st.biddingAcorn,
          winningAcorn: st.winningAcorn,
          refundAcorn: st.refundAcorn,
          subCategory: data[st.key]
            ? data[st.key].map((str) => {
              const requirements = {}, subChildCategories = [];
              for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                  if (key.search(str.label) > -1) {
                    const newKeyName = key.split('_');
                    if (data[key] && Array.isArray(data[key]) && data[key].length) {
                      subChildCategories.push(...data[key]);
                    } else if (newKeyName && newKeyName.length > 1) {
                      if (key != 'price' && key.search('rice') > -1 && (data[str.label + '_N/A'] || data[str.key + '_N/A'])) {
                        delete data[key];
                      } else if (key != 'price' && key.search('rice') > -1) {
                        if (!price) {
                          price = data[key];
                        } else if (price && price.amount > data[key].amount) {
                          price = data[key];
                        }
                        Object.assign(requirements, { [newKeyName[1]]: data[key].amount });
                      } else {
                        Object.assign(requirements, { [newKeyName[1]]: data[key] });
                      }
                    }
                  }
                }
              }
              return type == "PROFILE"
                ? { id: str.id, key: str.key, value: str.value, label: str.label, subChildCategories }
                : { id: str.id, key: str.key, value: str.value, label: str.label, discount: str.discount, requirements };
            })
            : []
        }));
        return { categories, price };
      }
    }
  }
  return [];
}

export const calculateBiddingAcorns = (event, values) => {
  const requirementQuotes = {};
  const hoursDiff = moment().diff(moment(event.createdAt), 'hours');
  let totalDiscount = 0, totalAcorns = 0, discountCount = 0, winningAcorns = 0;

  for (const cat of event.categories) {
    let subCatValue = false;

    if (Array.isArray(cat.subCategory)) {
      for (const subCat of cat.subCategory) {
        if (values) {
          if (values[subCat.key + '_requirements'] && values[subCat.key + '_requirements'].length) {
            if (values[subCat.key + '_quote'] && values[subCat.key + '_quote'].amount) {
              Object.assign(requirementQuotes, {
                [subCat.key]: {
                  requirements: values[subCat.key + '_requirements'],
                  quote: values[subCat.key + '_quote'].amount,
                }
              });
            }
            subCatValue = true;
          } else if (values[subCat.key] && values[subCat.key].requirements && values[subCat.key].requirements.length && values[subCat.key].quote) {
            Object.assign(requirementQuotes, {
              [subCat.key + '_quote']: new Money(values[subCat.key].quote, config.currency),
              [subCat.key + '_requirements']: values[subCat.key].requirements,
            });
          }
        } else {
          subCatValue = true;
        }
        if (subCat.discount && subCat.discount[hoursDiff]) {
          totalDiscount += Number(subCat.discount[hoursDiff]);
          discountCount += 1;
        }
      }
    }
    if (subCatValue) {
      totalAcorns += Number(cat.biddingAcorn || 0);
      winningAcorns += Number(cat.winningAcorn || 0);
    }
  }

  if (discountCount) {
    totalDiscount < 50 ? totalDiscount = Math.ceil(totalDiscount) : totalDiscount = Math.floor(totalDiscount);
    return totalAcorns - Math.round(((totalDiscount / discountCount) * (totalAcorns / 100)));
  }

  return { acorns: totalAcorns, winningAcorns, requirementQuotes };
}

export const stringFromLength = (string, length, flag = false) => {
  const trimmedString = string.substring(0, length);
  return (string.length <= length
    ? string
    : trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + (flag
      ? "..."
      : ""));
}

export const extractZipCodeAndCity = (address) => {
  if (!address) return;

  const addressParts = address.split(',');
  const zipCodeAndCity = addressParts.slice(-2);
  const trimmedZipCodeAndCity = zipCodeAndCity.map(part => part.trim());
  const zipCode = trimmedZipCodeAndCity[0];
  const city = trimmedZipCodeAndCity[1];

  return [zipCode, city].join(', ');
}

export const renderCategory = (category, event) => {
  return category.subCategory.map((subCat, index) => (
    <div key={index} >
      {/* Subcategory title */}
      <h3>{category.label + ' > ' + subCat.label}</h3>
      <span><FormattedMessage id="EventRequest.requirements" /></span>
      <div className={css.requirementBox}>
        {/* Budget */}
        <div className={css.requirementSection}>
          <span className={css.icon}>
            <IconCardDashboard type="dollar" />
          </span>
          <span><FormattedMessage id="EventRequest.budget" /></span>
          <span className={css.boldText}>
            $ {(subCat.requirements.minPrice / 100).toFixed(2)} - $ {(subCat.requirements.maxPrice / 100).toFixed(2)}
          </span>
        </div>

        {/* Logistics */}
        <div className={css.requirementSection}>
          <span className={css.icon}>
            <IconCardDashboard type="delevery" />
          </span>
          <span><FormattedMessage id="EventRequest.logistics" /></span>
          <span className={css.boldText}>
            <FormattedMessage id={subCat.requirements.isDelivery
              ? "EventRequest.deliveryRequired"
              : "EventRequest.deliveryNotRequired"} />
          </span>
        </div>
      </div>

      {/* Requirements - Checkboxes */}
      <div className={css.checkboxWrapper}>
        {Object.keys(subCat.requirements).map((key, idx) => key.includes('requirement-request')
          ? (
            <div key={idx} className={css.checkboxItem}>
              <input type="checkbox" id={key} name={key} value={subCat.requirements[key]} />
              <label htmlFor={key}>{subCat.requirements[key]}</label>
            </div>
          )
          : null)}
      </div>
    </div>
  ));
};

export const fetchEventCategoryData = (eventCategories) => {
  let requirements = [], minPrice = 0, maxPrice = 0, isDelivery = false;

  if (eventCategories && eventCategories.length) {
    eventCategories.forEach(cat => {
      cat.subCategory.forEach(subCat => {
        if (subCat.requirements.isDelivery) {
          isDelivery = subCat.requirements.isDelivery;
        }
        if (subCat.requirements && Object.keys(subCat.requirements).length) {
          if (subCat.requirements.minPrice) {
            minPrice += parseInt(subCat.requirements.minPrice / 100);
          }
          if (subCat.requirements.maxPrice) {
            maxPrice += parseInt(subCat.requirements.maxPrice / 100);
          }
          const currentRequirements = Object.keys(subCat.requirements)
            .filter(key => key.startsWith('requirement-request'))
            .reduce((acc, key) => {
              acc[key] = subCat.requirements[key];
              return acc;
            }, {});
          requirements.push(...Object.values(currentRequirements));
        } else {
          requirements.push(subCat.label);
        }
      })
    });
  }

  return { requirements, minPrice, maxPrice, isDelivery };
}

export const getTotalQuoteAmount = (acceptedEvents) => {
  let committedAmount = 0, selectedRequirements = [];

  if (acceptedEvents && acceptedEvents.length) {
    acceptedEvents.filter(event => {
      if (event && event.plannerResponse && event.plannerResponse.requirementQuotes) {
        const requirementQuotes = event.plannerResponse.requirementQuotes;
        for (const key in requirementQuotes) {
          if (Object.prototype.hasOwnProperty.call(requirementQuotes, key)) {
            committedAmount += parseInt(requirementQuotes[key].quote);
            selectedRequirements.push(...requirementQuotes[key].requirements);
          }
        }
      } else if (event && event.vendorResponse && event.vendorResponse.requirementQuotes) {
        const requirementQuotes = event.vendorResponse.requirementQuotes;
        for (const key in requirementQuotes) {
          if (Object.prototype.hasOwnProperty.call(requirementQuotes, key)) {
            committedAmount += parseInt(requirementQuotes[key].quote);
            selectedRequirements.push(...requirementQuotes[key].requirements);
          }
        }
      }
    });
  }

  return { committedAmount, selectedRequirements };
}

export const uploadCloudinaryFiles = (files, folderName) => {
  /**
   * asset_id: A unique identifier for the asset within Cloudinary.
   * public_id: The unique identifier assigned to the asset, used in delivery URLs.
   * version: A timestamp indicating the version of the asset.
   * version_id: A unique identifier for the specific version of the asset.
   * signature: A hash used to verify the authenticity of the upload.
   * width and height: Dimensions of the uploaded image or video.
   * format: The file format (e.g., jpg, png, mp4).
   * resource_type: The type of asset (image, video, or raw).
   * created_at: The timestamp when the asset was uploaded.
   * tags: An array of tags associated with the asset.
   * bytes: The size of the asset in bytes.
   * type: The storage type, typically upload.
   * etag: A unique identifier representing the content of the asset.
   * placeholder: Indicates if the asset is a placeholder.
   * url and secure_url: URLs to access the uploaded asset over HTTP and HTTPS, respectively.
   * access_mode: The access level of the asset (public or authenticated).
   * original_filename: The original name of the uploaded file.
   */
  const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL;
  const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_PRESET; // Replace with your preset name
  const FOLDER_NAME = folderName || process.env.REACT_APP_CLOUDINARY_FOLDER_NAME; // Replace with your desired folder name
  const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB

  // Function to upload a single file
  const uploadFile = (file) => {
    const formData = new FormData();
    const fileType = file.type;

    formData.append('file', file);
    formData.append('folder', folderName);
    formData.append('resource_type', fileType);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
    formData.append('folder', FOLDER_NAME);

    return fetch(CLOUDINARY_URL, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Upload failed for ${file.name}: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(`File ${file.name} uploaded successfully!`, data);
        return { file: file.name, success: true, data };
      })
      .catch((error) => {
        console.error(`Error uploading file ${file.name}:`, error);
        return { file: file.name, success: false, error: error.message };
      });
  };

  // Function to handle multiple files with Promise.all
  const uploadMultipleFiles = async (files) => {
    // Validate file sizes
    const validFiles = Array.from(files).filter((file) => {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        console.error(`File ${file.name} exceeds the size limit of ${MAX_FILE_SIZE_MB} MB.`);
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) {
      console.error("No valid files to upload.");
      return [];
    }

    console.log(`Uploading ${validFiles.length} valid files...`);
    const uploadPromises = validFiles.map((file) => uploadFile(file));
    const results = await Promise.all(uploadPromises);

    console.log("All uploads completed:", results);
    return results;
  };

  return uploadMultipleFiles(files).then(uploadResults => uploadResults);
}
