import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { string } from 'prop-types';

import { createSlug } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import routeConfiguration from '../../routeConfiguration';
import { messageColorEnum } from '../../marketplace-custom-config';

import {
  // Menu,
  // MenuLabel,
  // MenuContent,
  // MenuItem,
  // IconCardDashboard,
  NamedLink,
  IconCollection,
  OutsideClickHandler,
  ResponsiveImage,
} from '..';

import css from './EventRightCard.module.css';

const sorting = [
  'Most Recent',
  'By Event',
  'Starred',
  'Approved Vendors',
  'Unread',
];

const EventRightCard = props => {
  const {
    search,
    history,
    isMobile = false,
    transactions = [],
    // className,
    // channels = [],
    // onUpdateEvent,
    // onReportVendor,
  } = props;

  const [expandList, setExpandList] = useState(true);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [filterMenu, setFilterMenu] = useState(sorting[0]);

  // const [MenuOpen, setMenuOpen] = useState(false);
  // const onToggleMenu = (listing) => {
  //   setMenuOpen(listing);
  // }
  const groupedCategories = [... new Set(transactions.map(tx => tx.categoriesLabel).flat(1))];
  const requestedVendorEvents = transactions.filter(tx => ['REQUESTED', 'ACCEPTED', 'DECLINED', 'EXPIRED'].includes(tx.status));

  // let unreadCount = 0;
  // channels && channels.length && Array.isArray(channels) && requestedVendorEvents && requestedVendorEvents.length && channels.filter(m => requestedVendorEvents.findIndex(event => event.vendorId == m.userId) > -1).map(channel => unreadCount += channel.unreadCount);

  return (
    <div className={classNames(css.rightSideFullHeading)}>
      <div className={css.rightheadingBox}>
        <div className={css.tabs}>
          <div className={classNames(css.tab, css.active)} onClick={() => setExpandList(!expandList)}>
            <span className={css.count}>{requestedVendorEvents.length}</span>
            {isMobile
              ? <span><FormattedMessage id={"EventRightCard.headingMobile"} /></span>
              : <span><FormattedMessage id={"EventRightCard.heading"} /></span>}

            <span className={classNames(css.dropdownIcon, !expandList && css.expandIcon)}>
              <IconCollection name="DROPDOWN_ARROW" />
            </span>
          </div>
          {/* <div
            className={classNames(css.tab)}
            onClick={() => history.push(
              createResourceLocatorString(
                'ChatPage',
                routeConfiguration(),
                {}
              ))}
          >
            <span className={css.count}>{unreadCount}</span>
            <span><FormattedMessage id={"EventRightCard.messages"} /></span>
          </div> */}
        </div>
        <div className={css.filtersButtons}>
          {/* <div className={css.filtersButton}>
            <IconCollection name={"ICON_SEARCH"} />
          </div> */}
          <OutsideClickHandler onOutsideClick={() => filterMenuOpen && setFilterMenuOpen(false)}>
            <div className={classNames(css.filtersButton, css.filterWithAccordion)} onClick={() => setFilterMenuOpen(true)}>
              <span>
                <IconCollection name={"ICON_FILTER"} />
                <span className={css.recentText}>{filterMenu}</span>
              </span>
              <IconCollection name={"ICON_ARROW_DOWN"} />
              {filterMenuOpen
                ? <div className={css.filterDropDown}>
                  <ul>
                    {sorting.map(sName => (<li key={sName} onClick={() => {
                      setFilterMenu(sName);
                      setTimeout(() => {
                        setFilterMenuOpen(false);
                      });
                    }}>{sName}</li>))}
                  </ul>
                </div>
                : null}
            </div>
          </OutsideClickHandler>
        </div>
      </div>

      {(isMobile ? expandList : true)
        ? <div className={css.MessageLists}>
          <div className={css.chatCategories}>
            <div className={classNames(css.chatCategory, !(search && search.categories) ? css.activeCategory : '')}>
              <NamedLink
                name={'ManageListingsPage'}
              >
                <FormattedMessage id={"EventRightCard.allCategories"} />
              </NamedLink>
            </div>
            {groupedCategories && groupedCategories.length
              ? groupedCategories.map(gc => (
                <div className={classNames(css.chatCategory, (search && search.categories == gc) ? css.activeCategory : '')} selected={search && gc == search.categories} key={gc}>
                  <NamedLink
                    name={'ManageListingsPage'}
                    to={{
                      search: '?categories=' + gc
                    }}
                  >
                    {gc}
                  </NamedLink>
                </div>
              ))
              : null}
          </div>

          <div className={css.messageBox}>
            <div className={css.messageScroll}>
              {requestedVendorEvents && requestedVendorEvents.length
                ? requestedVendorEvents.map(tx => {
                  const {
                    _id,
                    txId,
                    title,
                    vendor,
                    status,
                    eventId,
                    createdAt,
                    eventDates,
                    eventDuration,
                    vendorResponse,
                    categoriesLabel,
                    eventOwner,
                    // isWishListed,
                  } = tx;

                  const eventDate = eventDates && eventDates.length
                    ? moment(eventDates[0].start)
                    : null;
                  return (
                    <div className={css.transaction} key={txId} onClick={() => history.push(
                      createResourceLocatorString(
                        'EventDetailPage',
                        routeConfiguration(),
                        { eventId, slug: createSlug(title), tab: status == 'REQUESTED' ? txId : "quotes" }
                      ))}
                    >
                      <div className={css.messagesLeftOptions}>
                        <div className={css.chatProfileImage}>
                          <ResponsiveImage
                            alt={vendor?.displayName}
                            src={vendor.publicData
                              ? vendor.publicData.logoImageURL
                                ? vendor.publicData.logoImageURL
                                : vendor.publicData.profileImageURL
                              : ''}
                          />
                        </div>
                        <div className={css.chatDisplayData}>
                          <span className={css.timeText}>
                            {/* <FormattedMessage id="EventRightCard.new" values={{value:5}} /> |  */}
                            {moment().diff(moment(createdAt * 1000), 'minutes') > 60
                              ? moment(createdAt * 1000).format('LT')
                              : <FormattedMessage id="EventRightCard.minAgo" values={{ diff: moment().diff(moment(createdAt * 1000), 'minutes') }} />}
                          </span>
                          <div className={css.nameDate}>
                            {vendor.publicData.businessName}
                          </div>
                          {vendorResponse
                            ? <span>
                              {vendorResponse.quoteCheck == "requestMoreInfo"
                                ? vendor.displayName + " has sent an inquiry..."
                                : "You’ve received a new quote from a " + vendor.displayName + " vendor!"}
                            </span>
                            : null}
                          {status && messageColorEnum[status]
                            ? <div className={css.messageDetail}>
                              {messageColorEnum[status].message}
                              {categoriesLabel && Array.isArray(categoriesLabel)
                                ? <>
                                  <br />
                                  <span className={css.boldText}>
                                    <FormattedMessage
                                      id="EventRightCard.categoriesLabel"
                                      values={{ categoriesLabel: categoriesLabel.join(', ') }}
                                    />
                                  </span>
                                </>
                                : null}
                            </div>
                            : null}
                        </div>
                      </div>
                      <div className={css.messagesRightOptionsDesktop}>
                        {status && messageColorEnum[status]
                          ? <p className={classNames(css.status, css[(eventDate && moment(eventDate).isAfter(moment())) || eventDuration == 'not-determined' ? status.toLowerCase() : 'expired'])}><FormattedMessage id="EventRightCard.quote" values={{ status: (eventDate && moment(eventDate).isAfter(moment())) || eventDuration == 'not-determined' ? messageColorEnum[status].status : 'Expired' }} /></p>
                          : null}
                        <p className={css.eventTitle}>{((eventOwner.publicData && eventOwner.publicData.businessName) ? (eventOwner.publicData.businessName + ' - ') : eventOwner.firstName + ' - ') + title}</p>
                      </div>
                    </div>);
                })
                : null}
            </div>
          </div>

          {/* {eventOpen == 'EVENT'
          ? <div className={css.announceMentBanner}>
              <div className={css.iconMike}>
                  <IconCollection name="ICON_ANNOUNCE" />
              </div>

              <div className={css.bannerDetails}>
                  <h4>Send A Broadcast to update all approved Vendors</h4>
                  <p>Response back will be received in individual channels.</p>
              </div>
              <Button>
                  Create Broadcast
              </Button>
          </div>
          : null} */}
        </div>
        : null}
    </div>
  );

};

EventRightCard.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

EventRightCard.propTypes = {
  className: string,
  rootClassName: string,
};

export default injectIntl(EventRightCard);
