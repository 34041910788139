import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import trendingImage from '../../assets/blogimage.png';

import css from './SectionBlog.module.css';
import { BorderButton, PrimaryButton } from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import NamedLink from '../NamedLink/NamedLink';
import { IconProfileSetup } from '../../components';

export const trendingFood = [
    {
        key: 'key',
        image: trendingImage,
        title: "Something Relating to This Category To Help Boost SEO & Make The Page Feel Professional ",
        readTime: "5 Min Read",
        blogName: "Ross James",
        blog: "Blog"
    },
    {
        key: 'key',
        image: trendingImage,
        title: "Something Relating to This Category To Help Boost SEO & Make The Page Feel Professional ",
        readTime: "5 Min Read",
        blogName: "Ross James",
        blog: "Blog"
    },
    {
        key: 'key',
        image: trendingImage,
        title: "Something Relating to This Category To Help Boost SEO & Make The Page Feel Professional ",
        readTime: "5 Min Read",
        blogName: "Ross James",
        blog: "Blog"
    },
];
const SectionBlog = props => {
    const { className, rootClassName } = props;
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);

    return (
        <div className={classes}>
            <div className={css.sectionBlog}>
                {trendingFood.map((item, key) =>
                    <NamedLink name={"SearchPage"} className={css.blogCard}>
                        <div className={css.blogTop}>
                            <img src={item.image} className={css.cardImage} />
                        </div>
                        <div className={css.blogBody}>
                            <div className={css.categoriesText}>
                                <span>{item.blog}  |  {item.readTime}  |  {item.blogName}</span>
                            </div>
                            <h5>{item.title}</h5>
                            <div>
                                <BorderButton
                                    type="submit"
                                    // inProgress={submitInProgress}
                                    className={css.submitButton}
                                >
                                    <FormattedMessage id="SectionBlog.ReadNow" />
                                </BorderButton>
                            </div>
                        </div>
                    </NamedLink>
                )}
            </div>
        </div >
    );
};

const { node, string } = PropTypes;

SectionBlog.defaultProps = {
    className: null,
    rootClassName: null,
};

SectionBlog.propTypes = {
    className: string,
    rootClassName: string,
};

export default SectionBlog;
