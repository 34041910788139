import React from 'react'
import css from './ToolTip.module.css';
import InfoIcon from './InfoIcon';

const ToolTip = ({ text }) => (
    <div className={css.tooltip} ><InfoIcon />
        <span className={css.tooltipText}>{text}</span>
    </div>
    // <span className={css.tooltip} data-tooltip={text} onClick={(e) => e.stopPropagation()}><InfoIcon /></span>
);

export default ToolTip;