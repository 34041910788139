import React, { useState } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import IconProfileSetup from '../IconProfileSetup/IconProfileSetup';
import TabNav from '../TabNav/TabNav';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    intl,
    pagename,
    showList,
    onShowMap,
    categories,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);

  const [toggle, setToggle] = useState(false);
  const handleToggleState = () => {
    setToggle(prev => !prev);
    onShowMap()
  };

  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <button
      className={toggleSecondaryFiltersOpenButtonClasses}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      {/* <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      /> */}
      <IconProfileSetup type="filter" />
    </button>
  ) : null;

  // const keywordPlaceholder = intl.formatMessage({
  //   id: 'SearchFiltersPrimary.Keyword',
  // });

  const tabs = [
    {
      text: (
        <span>
          <FormattedMessage id="LandingPage.Venues" />
        </span>
      ),
      linkProps: {
        name: 'LandingPage',
        params: { tab: 'Venues' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="LandingPage.EventRentals" />
        </span>
      ),
      linkProps: {
        name: 'LandingPage',
        params: { tab: 'EventRentals' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="LandingPage.EventServices" />
        </span>
      ),
      linkProps: {
        name: 'LandingPage',
        params: { tab: 'EventServices' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="LandingPage.EventProduction" />
        </span>
      ),
      linkProps: {
        name: 'LandingPage',
        params: { tab: 'EventServices' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="LandingPage.FoodDrink" />
        </span>
      ),
      linkProps: {
        name: 'LandingPage',
        params: { tab: 'EventServices' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="LandingPage.Entertainment" />
        </span>
      ),
      linkProps: {
        name: 'LandingPage',
        params: { tab: 'EventServices' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="LandingPage.Merchants" />
        </span>
      ),
      linkProps: {
        name: 'LandingPage',
        params: { tab: 'EventServices' },
      },
    },
  ];

  const nav = tabs ? (
    <TabNav className={css.homeTabs} pagename={"LandingPage"} tabs={tabs} />
  ) : null;

  return (
    <div className={classes}>
      <div className={css.filters}>
        <div className={css.breadcrumbsBox}>
          <Breadcrumbs />
        </div>

        <div className={!showList ? css.searchTabs : css.navLinksBox}>{nav}</div>

        <div className={css.searchOptions}>
          {listingsAreLoaded ? (
            <div className={css.searchResultSummary}>
              <span className={css.resultsFound}>
                <FormattedMessage
                  id="SearchFiltersPrimary.foundResults"
                  values={{ count: resultsCount }}
                />
              </span>
            </div>
          ) : null}
        </div>

        <div className={css.box}>
          <div className={showList ? css.filterLess : css.filterList}>
            {children}
            {sortByComponent}
          </div>
          <div className={css.filterBox}>
            {toggleSecondaryFiltersOpenButton}
          </div>
          <div className={css.mapToogleButton}>
            <div className={css.mapLabel}>Map</div>
            <div className={css.checkboxWrapper}
              onClick={handleToggleState}>
              <div
                className={classNames(css.ball, {
                  [css.toggled]: toggle,
                })}
              >
                {' '}
              </div>
            </div>
          </div>
        </div>
      </div>

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <FormattedMessage id="SearchFiltersPrimary.noResults" />
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
  // intl: intlShape.isRequired,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
