import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import moment from 'moment';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  maxLength,
  required,
} from '../../util/validators';
import {
  Form,
  Button,
  TimeRange,
  IconClose,
  IconSpinner,
  FieldTextInput,
  IconProfileSetup,
  FieldMultiSelect,
  FieldRadioButton,
  LocationAutocompleteInputField,
  FieldSelect,
} from '../../components';

import css from './EditListingLocationForm.module.css';

const identity = v => v;
const TITLE_MAX_LENGTH = 100;

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        form,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        eventDuration,
        availabilityPlan,
        exceptionCount,
        eventTypeOptions,
        eventSizeOptions,
        eventFormatOptions,
        sortedAvailabilityExceptions,
        fetchExceptionsInProgress,
        onDeleteAvailabilityException,
        setIsEditExceptionsModalOpen,
      } = formRenderProps;

      const eventTitleMessage = intl.formatMessage({ id: 'EditListingLocationForm.eventTitle' });
      const eventTitlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.eventTitlePlaceholder',
      });
      const eventTitleRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.eventTitleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const eventTypeLabel = intl.formatMessage({ id: 'EditListingLocationForm.eventType' });
      const eventTypePlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.eventTypePlaceholder',
      });
      const eventTypeRequired = intl.formatMessage({
        id: 'EditListingLocationForm.eventTypeRequired',
      });
      const selectPlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.selectPlaceholder',
      });

      const eventFormatLabel = intl.formatMessage({ id: 'EditListingLocationForm.eventFormat' });
      const eventFormatPlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.eventFormatPlaceholder',
      });
      const eventFormatRequired = intl.formatMessage({
        id: 'EditListingLocationForm.eventFormatRequired',
      });

      const locationRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      // Estimated Guest Number
      const eventSizeLabel = intl.formatMessage({
        id: 'EditListingLocationForm.eventSize',
      });
      const eventSizePlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.eventSizePlaceholder',
      });
      const eventSizeRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.eventSizeRequired',
      });
      const eventSizeRequired = required(eventSizeRequiredMessage);

      // Date Not Determined
      const notDeterminedTextMessage = intl.formatMessage({
        id: 'EditListingLocationForm.notDeterminedText',
      });
      const notDeterminedTextPlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.notDeterminedTextPlaceholder' ,
      });
      const notDeterminedTextPlaceholderMobile = intl.formatMessage({
        id: 'EditListingLocationForm.notDeterminedTextPlaceholderMobile' ,
      });
      const notDeterminedTextRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.notDeterminedTextRequired',
      });
      const notDeterminedTextRequired = required(notDeterminedTextRequiredMessage);

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !(exceptionCount || values.eventDuration == 'not-determined');
      const notDeterminedPlaceholder = typeof window !== 'undefined' && window.innerWidth < 768 ? notDeterminedTextPlaceholderMobile : notDeterminedTextPlaceholder;
      return (
        <Form className={classes} onSubmit={(e) => handleSubmit(e).then(() => form.restart())}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={eventTitleMessage}
            placeholder={eventTitlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
          // validate={composeValidators(required(eventTitleRequiredMessage), maxLength60Message)}
          />

          <div className={css.rowBox}>
            <FieldSelect
              className={css.rowInput}
              id={'eventType'}
              name={'eventType'}
              label={eventTypeLabel}
              validate={required(eventTypeRequired)}
              // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
              placeholder={eventTypePlaceholder}
              defaultValue={values.eventType || ""}
            >
              <option disabled value="">
                {selectPlaceholder}
              </option>
              {eventTypeOptions.map(op => (
                <option key={op.key} value={op.value} > {op.label} </option>
              ))}
            </FieldSelect>
            <FieldSelect
              className={css.rowInput}
              id={'eventFormat'}
              name={'eventFormat'}
              label={eventFormatLabel}
              validate={required(eventFormatRequired)}
              placeholder={eventFormatPlaceholder}
              defaultValue={values.eventFormat || ""}>
              <option disabled value="">
                {selectPlaceholder}
              </option>
              {eventFormatOptions.map(op => (
                <option key={op.key} value={op.value} > {op.label} </option>
              ))}
            </FieldSelect>
          </div>
          <div>
            <FieldSelect
              className={css.eventSize}
              id={'eventSize'}
              name={'eventSize'}
              label={eventSizeLabel}
              validate={eventSizeRequired}
              placeholder={eventSizePlaceholder}
              defaultValue={values.eventSize || ""}
            >
              <option disabled value="">
                {selectPlaceholder}
              </option>
              {eventSizeOptions.map(op => (
                <option key={op.key} value={op.value} > {op.label} </option>
              ))}
            </FieldSelect>

          </div>

          <div>
            <h3 className={css.signupSubHeading}>
              <FormattedMessage id="EditListingLocationForm.eventDuration" />
            </h3>
            <div className={css.selectRadioButtons}>
              <FieldRadioButton
                className={css.radioBox}
                id={'singleDay'}
                name="eventDuration"
                label={intl.formatMessage({ id: 'EditListingLocationForm.singleDay' })}
                value="singleDay"
                checkedClassName={css.checkedAvailable}
                showAsRequired={pristine}
              />
              <FieldRadioButton
                className={css.radioBox}
                id={'multipleDays'}
                name="eventDuration"
                label={intl.formatMessage({ id: 'EditListingLocationForm.multipleDays' })}
                value="multipleDays"
                checkedClassName={css.checkedNotAvailable}
                showAsRequired={pristine}
              />
              <FieldRadioButton
                className={css.radioBox}
                id={'not-determined'}
                name="eventDuration"
                label={intl.formatMessage({ id: 'EditListingLocationForm.notDetermined' })}
                value="not-determined"
                checkedClassName={css.checkedNotAvailable}
                showAsRequired={pristine}
              />
            </div>
          </div>

          <div className={css.timeBox}>
            {(exceptionCount == 1 && 'singleDay' == values.eventDuration) || (exceptionCount && 'multipleDays' == values.eventDuration)
              ? <section className={css.weeksSection}>
                {fetchExceptionsInProgress ? (
                  <div className={css.exceptionsLoading}>
                    <IconSpinner />
                  </div>
                ) : (
                  <div className={css.exceptions}>
                    <div className={css.exception}>
                      <div className={css.exceptionHeader}>
                        <table className={css.eventTable}>
                          <thead>
                            <tr>
                              <th className={css.tableDate}>
                                <FormattedMessage id="EditListingLocationForm.eventDate" />
                              </th>
                              <th className={css.tableHeading}>
                                <FormattedMessage id="EditListingLocationForm.eventStartTime" />
                              </th>
                              <th className={css.tableHeading}>
                                <FormattedMessage id="EditListingLocationForm.eventEndTime" />
                              </th>
                              <th className={css.tableHeading}>
                                <FormattedMessage id="EditListingLocationForm.actionText" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {sortedAvailabilityExceptions.map(availabilityException => {
                              const { start, end, listingId } = availabilityException.attributes;
                              return (
                                <tr key={availabilityException.id.uuid}>
                                  <td className={css.tableDate}>
                                    {moment(start).tz(availabilityPlan.timezone).format("MMMM D, YYYY")}
                                  </td>
                                  <td className={css.tableData}>
                                    {moment(start).tz(availabilityPlan.timezone).format("h:mm a")}
                                  </td>
                                  <td className={css.tableData}>
                                    {moment(end).tz(availabilityPlan.timezone).format("h:mm a")}
                                  </td>
                                  <td className={css.tableData}>
                                    <button
                                      type={"button"}
                                      className={css.removeExceptionButton}
                                      onClick={() => onDeleteAvailabilityException({ id: availabilityException.id })}
                                    // , isLocal: listingId == null
                                    >
                                      <IconClose size="normal" className={css.removeIcon} />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </section>
              : ['singleDay', 'multipleDays'].includes(values.eventDuration)
                ? null
                : <FieldTextInput
                  id="notDeterminedText"
                  name="notDeterminedText"
                  className={css.title}
                  type="text"
                  label={notDeterminedTextMessage}
                  placeholder={notDeterminedPlaceholder}
                // validate={notDeterminedTextRequired}
                />}

            {values.eventDuration && ('multipleDays' == values.eventDuration || (exceptionCount == 0 && 'singleDay' == values.eventDuration))
              ? <div className={css.addAnotherDate}>
                <a onClick={() => setIsEditExceptionsModalOpen(values.eventDuration)}>
                  <FormattedMessage id={exceptionCount == 0 ? "EditListingLocationForm.addDate" : "EditListingLocationForm.addAnotherDate"} />
                </a>
              </div>
              : null}
          </div>

          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="location"
            label={locationRequiredMessage}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          />

          <p className={css.warningMessage}><FormattedMessage id="EditListingLocationForm.addressInfo" /></p>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
            <span className={css.arrowIcon}>
              <IconProfileSetup type="arrow" />
            </span>
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  eventTypeOptions: config.custom.eventType,
  eventSizeOptions: config.custom.eventSizes,
  eventFormatOptions: config.custom.eventFormat,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
