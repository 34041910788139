import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import { Button, ResponsiveImage } from '../../components';

import css from './SendBirdChat.module.css';

const InvoiceCard = props => {
    const {
        vendor,
        history,
        message,
        userType,
        currentUser,
        messageSentByYou,
        currentUserDetails,
    } = props;

    return (
        <div className={messageSentByYou ? css.rightMessage : css.leftMessage} key={message._id + '_InvoiceCard'}>
            {/* Invoice card */}
            <div style={{ width: '100%', maxWidth: '377px' }}>
                <div className={classNames((messageSentByYou ? null : css.leftHeader), css.invoiceHeader)} style={{ flexDirection: (messageSentByYou ? 'row' : 'row-reverse') }}>
                    <div className={css.chatInvoiceData}>
                        <div className={css.nameDate}>{messageSentByYou ? currentUserDetails.name : vendor.publicData.businessName}  <span>|  {moment(message.createdAt * 1000).calendar().split(' ')[0] + ', ' + moment(message.createdAt * 1000).format('LT')}</span>
                        </div>
                        <div className={css.messageDetail}>
                            <b>{vendor.publicData.businessName}</b> sent an Invoice
                        </div>
                    </div>
                    <div className={css.chatProfileImage}>
                        <ResponsiveImage
                            alt={'profile'}
                            url={messageSentByYou
                                ? currentUser.attributes.profile.publicData && (currentUser.attributes.profile.publicData.logoImageURL || currentUser.attributes.profile.publicData.profileImageURL)
                                    ? currentUser.attributes.profile.publicData.logoImageURL
                                        ? currentUser.attributes.profile.publicData.logoImageURL
                                        : currentUser.attributes.profile.publicData.profileImageURL
                                    : message.plannerImageURL
                                : message.plannerImageURL}
                        />
                    </div>
                </div>
                <div className={css.invoiceCard}>
                    <div className={css.invoiceContent}>
                        <div className={css.lineItem}>
                            <div>
                                <span className={css.largeText}><FormattedMessage id="ChatPage.invoice" /><br /></span>
                                <span className={css.smText}>{message.invoiceNumber}</span>
                            </div>
                            <div>
                                <span className={css.smText}>
                                    <FormattedMessage id="ChatPage.dueDate" />
                                </span><br />
                                <span className={css.smText}>{moment(message.createdAt * 1000).format('MMM D, YYYY')}</span>
                            </div>
                        </div>
                        <div className={css.lineItem}>
                            <div>
                                <span><FormattedMessage id="ChatPage.totalDue" /></span>
                            </div>
                            <div>
                                <span className={css.price}>${message.invoiceTotal}</span>
                            </div>
                        </div>
                        {message.paymentStyle == 'split'
                            ? <div className={classNames(css.lineItem, css.lastItem)}>
                                <div>
                                    <div>#1 Split Payment</div>
                                    <span>Amount: ${message.firstTotalDue}</span>
                                    <div>Due: {moment(message.firstDueDate * 1000).format('MMM D YYYY')}</div>
                                </div>
                                <div>
                                    <div>#2 Split Payment</div>
                                    <span>Amount: ${message.secondTotalDue}</span>
                                    <div>Due: {moment(message.secondDueDate * 1000).format('MMM D YYYY')}</div>
                                </div>
                            </div>
                            : null}
                    </div>
                    {userType == 'planner'
                        ? <Button
                            className={css.viewPayBtn}
                            disabled={message.status == 'PAID'}
                            onClick={() => {
                                history.push(createResourceLocatorString('InvoicePage', routeConfiguration(), {
                                    invoiceNumber: message.invoiceNumber.split('#') && message.invoiceNumber.split('#').length
                                        ? message.invoiceNumber.split('#')[1]
                                        : message.invoiceNumber
                                }));
                            }}
                        >
                            <FormattedMessage
                                id={message.status == 'PAID' ? "ChatPage.paidBtn" : "ChatPage.viewAndPayBtn"}
                                values={{ payPrice: <b>${message.invoiceTotal}</b> }}
                            />
                        </Button>
                        : <Button
                            className={css.viewPayBtn}
                            disabled={message.status == 'PAID'}
                            onClick={() => {
                                history.push(createResourceLocatorString('InvoicePage', routeConfiguration(), {
                                    invoiceNumber: message.invoiceNumber.split('#') && message.invoiceNumber.split('#').length
                                        ? message.invoiceNumber.split('#')[1]
                                        : message.invoiceNumber
                                }));
                            }}
                        >
                            <FormattedMessage
                                id={message.status == 'PAID' ? "ChatPage.paidBtn" : "ChatPage.markAsPAid"}
                                values={{ payPrice: <b>${message.invoiceTotal}</b> }}
                            />
                        </Button>}
                </div>
            </div>
        </div>
    );
};

export default InvoiceCard;
