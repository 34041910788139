import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug, parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import routeConfiguration from '../../routeConfiguration';

import { IconProfileSetup, NamedLink, PrimaryButton, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    history,
    hit,
    currentUser,
    categories,
    subCategories,
    setActiveListing,
    onAddOrRemoveToConnected,
  } = props;

  const {
    objectID,
    title,
    city,
    user,
    region = '',
    categories: hitCategories,
    categoriesLabel,
    subCategoriesLabel,
    businessName,
    profileImageURL,
    uploadedImagesUrls
  } = hit;

  const { profileImageURL: uProfileImageURL } = (user && user.publicData) || {};
  const currentUserId = currentUser && currentUser.id;

  const firstCategories = categories && Array.isArray(categories) && categories.length && hitCategories && Array.isArray(hitCategories) && hitCategories.length && categories.filter(c => c.id == hitCategories[0].id).length
    ? categories.filter(c => c.id == hitCategories[0].id)[0].label
    : "";

  const hasImages = uploadedImagesUrls && uploadedImagesUrls.length;
  const filteredCategories = categoriesLabel && categoriesLabel.length
    ? categories.filter(c => categoriesLabel.includes(c.label)).map(c => c.image)
    : [];
  const filteredSubCategories = subCategoriesLabel && subCategoriesLabel.length
    ? subCategories.filter(c => subCategoriesLabel.includes(c.label)).map(c => c.image)
    : [];

  const categoriesImages = hasImages
    ? []
    : [...filteredCategories, ...filteredSubCategories];

  const classes = classNames(rootClassName || css.root, className);
  const slug = createSlug(title);
  const isAddedPreferredVendor = currentUserId && currentUser.attributes.profile.publicData.addPreferredVendor && currentUser.attributes.profile.publicData.addPreferredVendor.filter(w => w.id == objectID).length;

  const params = {
    id: objectID,
    slug,
    city: hit.location && hit.location.address
      ? createSlug(hit.location.address.split(',')[0])
      : null,
    category: ((hit.categoriesLabel && createSlug(hit.categoriesLabel[0])) || (hit.categories && hit.categories.length && createSlug(hit.categories[0].label)) || createSlug(firstCategories)),
    subcategory: ((hit.subCategoriesLabel && createSlug(hit.subCategoriesLabel[0])) || (hit.categories && hit.categories.length && createSlug(hit.categories[0].subCategory[0].label) || createSlug(firstCategories))),
  };

  return (
    <div className={css.trendCard} onClick={(e) => {
      e.preventDefault();
      history.push(createResourceLocatorString(
        params && params.id && params.city && params.category && params.subcategory ? 'ListingCityPage' : 'ListingPage',
        routeConfiguration(),
        params
      ));
    }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(objectID)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.trendTop}>
          <ResponsiveImage
            alt={firstCategories}
            url={hasImages
              ? uploadedImagesUrls[0].url
              : (categoriesImages && categoriesImages.length && categoriesImages[0])}
          />
          <span className={css.brandLogo}>
            <ResponsiveImage
              url={uProfileImageURL || profileImageURL}
              className={css.logoBrand}
              alt={businessName}
            />
          </span>
        </div>
      </div>
      <div className={css.trendingBody}>
        <h5>{title}</h5>
        <div className={css.categoriesText}>
          <div className={css.categoriesTextWrapper}>
            <p>{firstCategories}</p>
            <span> &nbsp; | <IconProfileSetup type="star" />5.0</span>
          </div>
        </div>
        <div className={css.city}>
          {city ? city : params.city ? (params.city.charAt(0).toUpperCase() + params.city.substr(1)) : null}
          {region ? ', ' + region : null}
        </div>
        <div>
          <p className={css.viewDetailButton}>
            <FormattedMessage id="SectionTopVendors.viewDetails" /> </p>

          {currentUserId
            ? <PrimaryButton
              className={css.addList}
              onClick={(e) => {
                e.stopPropagation();
                isAddedPreferredVendor
                  ? onAddOrRemoveToConnected(objectID, isAddedPreferredVendor)
                  : onAddOrRemoveToConnected(objectID, isAddedPreferredVendor, title)
              }}
            >
              {isAddedPreferredVendor
                ? <>
                  <IconProfileSetup type='preferred-list' />
                  <FormattedMessage id="ListingCard.addedPreferredList" />
                </>
                : <FormattedMessage id="ListingCard.addPreferredList" />}
            </PrimaryButton>
            : <div onClick={(e) => {
              e.stopPropagation();
            }}>
              <NamedLink className={css.addList} name="LoginPage">
                {isAddedPreferredVendor
                  ? <>
                    <IconProfileSetup type='preferred-list' />
                    <FormattedMessage id="ListingCard.addedPreferredList" />
                  </>
                  : <FormattedMessage id="ListingCard.addPreferredList" />}
              </NamedLink>
            </div>}
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  // filtersConfig: array,
  // intl: intlShape.isRequired,
  // listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  // renderSizes: string,

  // setActiveListing: func,
};

export default withRouter(ListingCardComponent);
