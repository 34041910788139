import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  InstapaperShareButton, FacebookShareButton,
  PinterestShareButton, LinkedinShareButton,
  // EmailShareButton, WhatsappShareButton
} from "react-share";

import { copyToClipboard } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink, IconShare, Modal, OutsideClickHandler } from "../../components";

import css from './SocialShare.module.css';

const SocialShare = props => {
  const {
    url,
    className,
    isPlain,
    isOpen,
    onClose,
    PageName,
    publicData,
    // listingType, // 'VENDOR', 'PLANNER'
    onManageDisableScrolling,
  } = props;

  const {
    profileImageURL,
    tiktok,
    website,
    facebook,
    linkedin,
    instagram,
  } = publicData || {};

  const [copyText, setCopyText] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCopyText(false);
    }, 5000);
  }, [copyText]);

  const displayTitle = <FormattedMessage id={PageName == 'EventDetailPage'
    ? "SocialShare.eventDetails"
    : "SocialShare.vendorTreeProfile"} />;
  // const emailBody = "Share this profile on Vendor Tree";

  return (
    <>
      {isPlain && (instagram || facebook || website || tiktok || linkedin || url)
        ? PageName == 'EventDetailPage'
          ? <div className={className}>
            <ul className={css.socialMedialinks}>
              <li onClick={() => [copyToClipboard(url), setCopyText(true), onClose()]}>
                <div className={css.copyButton}>
                  <IconShare type='copy' />
                  <span><FormattedMessage id="SocialShare.copyLink" /></span>
                </div>
              </li>
              <li>
                <FacebookShareButton
                  url={url}
                  quote={displayTitle}
                  className={css.facebookButton}
                >
                  <IconShare type='facebook' />
                  <span><FormattedMessage id="SocialShare.facebook" /></span>
                </FacebookShareButton>
              </li>
              <li>
                <LinkedinShareButton
                  title={displayTitle}
                  url={url}
                  className={css.linkedinButton}
                >
                  <IconShare type='linkedin' />
                  <span><FormattedMessage id="SocialShare.linkedin" /></span>
                </LinkedinShareButton>
              </li>
            </ul>
          </div>
          : <div className={className}>
            <ul className={css.socialMedialinks}>
              {instagram
                ? <li>
                  <ExternalLink
                    href={instagram}
                    alt={displayTitle}
                  >
                    <IconShare type='instagram' />
                    {/* <span>Instagram</span> */}
                  </ExternalLink>
                </li>
                : null}
              {facebook
                ? <li>
                  <ExternalLink
                    href={facebook}
                    alt={displayTitle}
                  >
                    <IconShare type='facebook' />
                    {/* <span>Instagram</span> */}
                  </ExternalLink>
                </li>
                : null}
              {website
                ? <li>
                  <ExternalLink
                    href={website}
                    alt={displayTitle}
                  >
                    <IconShare type='browser' />
                    {/* <span>Instagram</span> */}
                  </ExternalLink>
                </li>
                : null}
              {tiktok
                ? <li>
                  <ExternalLink
                    href={tiktok}
                    alt={displayTitle}
                  >
                    <IconShare type='tiktok' />
                    {/* <span>Instagram</span> */}
                  </ExternalLink>
                </li>
                : null}
              {linkedin
                ? <li>
                  <ExternalLink
                    href={linkedin}
                    alt={displayTitle}
                  >
                    <IconShare type='linkedin' />
                    {/* <span>Instagram</span> */}
                  </ExternalLink>
                </li>
                : null}
            </ul>
          </div>
        : null}

      <Modal
        id="ListingPage.SocialShare"
        isOpen={isOpen}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.shareModal}
      >
        <OutsideClickHandler onOutsideClick={() => isOpen && onClose()} className={css.dblock}>
          <div className={css.enquirySuccessModal}>
            <div className={css.shareWith}>
              <ul className={css.socialMedialinksBox}>
                {/* <li>
                  <InstapaperShareButton
                    url={url}
                    quote={displayTitle}
                    className={css.instagramButton}
                  >
                    <IconShare type='instagram' />
                    <span>Instagram</span>
                  </InstapaperShareButton>
                </li> */}
                <li>
                  <FacebookShareButton
                    url={url}
                    quote={displayTitle}
                    className={css.facebookButton}
                  >
                    <IconShare type='facebook' />
                    <span><FormattedMessage id="SocialShare.facebook" /></span>
                  </FacebookShareButton>
                </li>
                <li>
                  <PinterestShareButton
                    url={profileImageURL}
                    media={profileImageURL}
                    description={displayTitle}
                    className={css.pinterestButton}
                  >
                    <IconShare type='pinterest' />
                    <span><FormattedMessage id="SocialShare.pinterest" /></span>
                  </PinterestShareButton>
                </li>
                <li>
                  <LinkedinShareButton
                    title={displayTitle}
                    url={url}
                    className={css.linkedinButton}
                  >
                    <IconShare type='linkedin' />
                    <span><FormattedMessage id="SocialShare.linkedin" /></span>
                  </LinkedinShareButton>
                </li>
                {/* <li>
                  <EmailShareButton
                    url={url}
                    subject={displayTitle}
                    body={emailBody}
                  >
                    <IconShare type='email' />
                    <span>Email</span>
                  </EmailShareButton>
                </li>
                <li>
                  <WhatsappShareButton
                    url={url}
                    title={displayTitle}
                  >
                    <IconShare type={'whatsapp'} />
                    <span>WhatsApp</span>
                  </WhatsappShareButton>
                </li> */}
                <li
                  onClick={() => [copyToClipboard(url), setCopyText(true), onClose()]}
                >
                  <div className={css.copyButton}>
                    <IconShare type='copy' />
                    <span><FormattedMessage id="SocialShare.copyLink" /></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </OutsideClickHandler>
      </Modal>

      <div className={classNames(css.textCopiedPopup, copyText ? css.popUpEntrada : css.popUpSaids)} onClick={() => setCopyText(false)} >
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" >
          <ellipse cx="10" cy="11" rx="10" ry="10.5" fill="#A9E7B7" />
          <path d="M15.1583 7.63492L8.01225 14.3049L5.85325 12.1469C5.65825 11.9519 5.34125 11.9519 5.14625 12.1469C4.95125 12.3419 4.95125 12.6589 5.14625 12.8539L7.64625 15.3539C7.74325 15.4509 7.87125 15.4999 7.99925 15.4999C8.12225 15.4999 8.24425 15.4559 8.34025 15.3659L15.8403 8.36592C16.0423 8.17792 16.0523 7.86092 15.8643 7.65892C15.6763 7.45692 15.3613 7.44592 15.1583 7.63492Z" stroke="#00A524" />
        </svg>
        <p><FormattedMessage id={"SocialShare.copiedLink"} /></p>
        <span className={css.close}>
          <IconShare type='copy' />
        </span>
      </div>
    </>
  );
};

export default SocialShare;
