import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import {
    Modal,
    IconCollection,
    ResponsiveImage,
    PrimaryButton,
    SecondaryButton,
    SkeletonLoader
} from '..';
import { ChatInputForm } from '../../forms';

import { updateWallet } from '../../containers/CheckoutPage/CheckoutPage.duck';

import css from './SendBirdChat.module.css';

const Chat = props => {
    const {
        state,
        updateState,
        isMobile,
        PageName,
        receiverDetails,
        channels,
        currentUser,
        socket,
        dispatch,
        createChannelSuccess,
        getChannelsInProgress,
        onManageDisableScrolling,
        CurrentAcorns,
        updateWalletInProgress,
        sendMessage,
        channelRef,
        renderMedia,
        currentUserDetails,
        isChannelUnlocked,
        onFileInputChange,
        chatLogo,
        channelChat,
        history,
        stateRef
    } = props;

    return (
        <>
            {(!state.openChatWindow || !isMobile) && PageName != 'TransactionPage' && receiverDetails && Object.keys(receiverDetails).length
                ? <div className={css.leftSidebar}>
                    <h3>
                        <FormattedMessage id="ChatPage.vendorDiscussion" />
                    </h3>
                    <div className={css.chatList}>
                        {channels.map(chanel => {
                            const membersToDisplay = chanel.members.find(member => member.userId != currentUser.id.uuid);
                            return <div
                                key={chanel._id}
                                className={classNames(css.chatUsers, (membersToDisplay && receiverDetails && (receiverDetails.userId == membersToDisplay.userId)) ? css.selectedChatUser : '')}
                                onClick={() => {
                                    updateState({ ...stateRef.current, openChatWindow: true });
                                    typeof window !== 'undefined' && window.sessionStorage.setItem('channelId', chanel.channelId);
                                    dispatch(createChannelSuccess((channelChat && chanel && (chanel._id == channelChat._id) ? channelChat : chanel), true));
                                    if (chanel.unreadCount > 0) {
                                        socket.emit('join', {
                                            markAllAsRead: Boolean(chanel.unreadCount),
                                            userId: currentUser.id.uuid,
                                            channel: chanel._id,
                                        });
                                    }
                                }}
                            >
                                {membersToDisplay && membersToDisplay.profileImageURL
                                    ? <div className={css.userImage}>
                                        <ResponsiveImage url={membersToDisplay.profileImageURL} />
                                        {chanel.unreadCount ? <span className={css.unreadCount}>{chanel.unreadCount}</span> : null}
                                    </div>
                                    : null}
                                {membersToDisplay
                                    ? <div className={css.chatData}>
                                        <div className={css.userName}>{membersToDisplay.name}</div>
                                        {isChannelUnlocked
                                            ? <div className={css.messageDetail}>{chanel.chats[chanel.chats.length - 1]?.body}</div>
                                            : null}
                                    </div>
                                    : null}
                            </div>
                        }
                        )}
                    </div>
                </div>
                : null}

            {!isMobile || state.openChatWindow || (receiverDetails && Object.keys(receiverDetails).length)
                ? <div className={state.openChatWindow || !isMobile ? css.rightSideFullHeading : css.rightMessageBox}>
                    {getChannelsInProgress
                        ? <SkeletonLoader isInboxPage />
                        : PageName == 'TransactionPage'
                            ? null
                            : receiverDetails && Object.keys(receiverDetails).length
                                ? <div className={css.rightheadingBox}>
                                    <div className={css.detailUser}>
                                        <div
                                            className={css.backPage}
                                            onClick={() => updateState({
                                                ...stateRef.current, openChatWindow: false
                                            }
                                            )}
                                        >
                                            <svg fill="#000000" width="40px" height="40px" viewBox="-3.925 0 25.6 25.6" xmlns="http://www.w3.org/2000/svg"><path d="m12.85 3.2 1.7 1.7 -8.15 8.2 8.15 8.2 -1.7 1.7L3.05 13.1 12.85 3.2Z" fill='#fff' /></svg>
                                        </div>
                                        <div className={css.chatProfileImage}>
                                            <ResponsiveImage alt='profile' url={receiverDetails.profileImageURL} />
                                        </div>
                                        {receiverDetails
                                            ? <div className={css.chatDisplayData}>
                                                <div className={css.userName}>
                                                    {receiverDetails.name}
                                                </div>
                                                {receiverDetails.vendor?.publicData.categoriesLabel && receiverDetails.vendor?.publicData.categoriesLabel.length
                                                    ? <div className={css.messageDetail}>
                                                        {receiverDetails.vendor?.publicData.categoriesLabel[0]}
                                                        {receiverDetails.vendor?.publicData.ratings
                                                            ? <> | <IconProfileSetup type="star" /> {receiverDetails.vendor?.publicData.ratings}</>
                                                            : null}
                                                    </div>
                                                    : null}
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                : receiverDetails && receiverDetails?.name
                                    ? <div className={css.rightheadingBox}>
                                        <div className={css.detailUser}>
                                            <div
                                                className={css.backPage}
                                                onClick={() => updateState({
                                                    ...stateRef.current, openChatWindow: false
                                                }
                                                )}
                                            >
                                                <svg fill="#000000" width="40px" height="40px" viewBox="-3.925 0 25.6 25.6" xmlns="http://www.w3.org/2000/svg"><path d="m12.85 3.2 1.7 1.7 -8.15 8.2 8.15 8.2 -1.7 1.7L3.05 13.1 12.85 3.2Z" fill='#fff' /></svg>
                                            </div>
                                            <div className={css.chatProfileImage}>
                                                <ResponsiveImage alt='profile' url={receiverDetails.profileImageURL} />
                                            </div>
                                            <div className={css.chatDisplayData}>
                                                <div className={css.userName}>
                                                    {receiverDetails?.name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                    <div className={css.MessageLists}>
                        {channelChat?.chats?.length
                            ? <div className={css.chatDateUpdate}>{moment(channelChat.chats[channelChat.chats.length - 1]?.createdAt).format("LL")}</div>
                            : null}

                        <div className={classNames(css.messageBox, channelChat?.chats && channelChat?.chats.length ? null : css.messageBox2)}>
                            {(channelChat?.chats && channelChat?.chats.length) || (receiverDetails && Object.keys(receiverDetails).length)
                                ? <div className={css.messageScroll} ref={channelRef}>
                                    {isChannelUnlocked && channelChat?.chats?.map((message) => {
                                        if (!message.sender) return null;
                                        const messageSentByYou = (message.sender === currentUser.id.uuid);
                                        return (
                                            <div className={messageSentByYou ? css.rightMessage : css.leftMessage} key={message._id}>
                                                <div className={css.chatProfileImage}>
                                                    <ResponsiveImage alt='profile' url={messageSentByYou ?
                                                        currentUser.attributes.profile.publicData.profileImageURL :
                                                        receiverDetails.profileImageURL} />
                                                </div>
                                                <div className={css.chatDisplayData}>
                                                    <div className={css.nameDate}>{
                                                        messageSentByYou ? currentUserDetails.name : receiverDetails.name
                                                    }  <span>|  {moment(message.createdAt).calendar().split(' ')[0] + ', ' + moment(message.createdAt).format('LT')}</span> </div>
                                                    <div className={css.messageDetail}>
                                                        {message.type !== 'text'
                                                            ? renderMedia(message)
                                                            : message.body}</div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    {state.uploading
                                        ? (<div className={css.rightMessage}>
                                            <div className={css.chatProfileImage}>
                                                <ResponsiveImage alt='profile' url={currentUserDetails.profileImageURL} />
                                            </div>
                                            <div className={css.chatDisplayData}>
                                                <div className={css.nameDate}>{
                                                    currentUserDetails.name
                                                }  <span>|  {moment().calendar().split(' ')[0] + ', ' + moment().format('LT')}</span> </div>
                                                <div className={css.messageDetail}>
                                                    {renderMedia({})}
                                                </div>
                                            </div>
                                        </div>)
                                        : null}
                                </div>
                                : <div>
                                    <div className={css.noEventCategoryImg}>
                                        <div className={css.sectionHeader}>
                                            <h4><FormattedMessage id="ChatPage.myEvents" /></h4>
                                        </div>
                                        <div className={css.noMessageText}>
                                            <IconCollection name={"ICON_NOMESSAGE"} />
                                            <h2><FormattedMessage id="ChatPage.noEvents" /></h2>
                                        </div>
                                    </div>
                                </div>}
                            <div>
                                {isChannelUnlocked
                                    ? <ChatInputForm
                                        receiverName={receiverDetails.name}
                                        inProgress={state.uploading}
                                        onSubmit={sendMessage}
                                        onFileInputChange={onFileInputChange}
                                    />
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            <Modal
                id='Chatpagemodal'
                isOpen={state.openModal && currentUserDetails.userType != 'planner'}
                onClose={() => updateState({ ...stateRef.current, openModal: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                className={css.chatPageModal}
            >
                <ResponsiveImage className={css.chatImage} url={chatLogo} alt="" />
                <h2><FormattedMessage id="ChatPage.AcornsNeeded" /></h2>
                <h3><FormattedMessage id="SendBirdChat.plannerSendYouMessage" /></h3>

                {CurrentAcorns
                    ? <PrimaryButton
                        inProgress={updateWalletInProgress}
                        onClick={() => dispatch(updateWallet({
                            acorns: 5,
                            type: "DEBIT",
                            channel: channelChat._id
                        })).then(() => {
                            dispatch(createChannelSuccess({ ...channelChat, isUnlocked: true }));
                        })}>
                        <FormattedMessage id="ChatPage.payAcorns" values={{ values: 5 }} />
                    </PrimaryButton>
                    : <PrimaryButton
                        onClick={() => history.push(createResourceLocatorString('PurchaseAcornsPage', routeConfiguration(), {}))}>
                        <FormattedMessage id="ChatPage.purchaseAcorns" />
                    </PrimaryButton>}

                <SecondaryButton
                    onClick={() => updateState({ ...stateRef.current, openModal: false })}
                >
                    <FormattedMessage id="ChatPage.noThankYou" />
                </SecondaryButton>

            </Modal>
        </>
    );
};

export default Chat;
