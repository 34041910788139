import React, { useState } from 'react';
import moment from 'moment';
import { string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';

import routeConfiguration from '../../routeConfiguration';
import { statusEnumVendor } from '../../marketplace-custom-config';

import { createSlug } from '../../util/urlHelpers';
import { camelCaseEventDuration } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import {
  Menu,
  MenuItem,
  MenuLabel,
  MenuContent,
  NamedLink,
  PrimaryButton,
  SkeletonLoader,
  ResponsiveImage,
  IconCardDashboard,
} from '../../components';

import css from './EventCard.module.css';

const MENU_CONTENT_OFFSET = -12;
const TABLEVIEW = 'table';
const GRID = 'grid';

const EventCard = props => {
  const {
    // className,
    history,
    type = GRID,
    events,
    onOpenListing,
    onCloseListing,
    fetchEventsInProgress,
    onFindOrCreateChannel,
  } = props;

  const [listingMenuOpen, setListingMenuOpen] = useState(false);

  switch (type) {
    case TABLEVIEW:
      return <div className={css.mainWrapperListBox}>
        <div className={css.tableViewBox}>
          <table>
            <thead>
              <tr>
                <th>
                  <div className={css.headingName}>
                    <FormattedMessage id="ProposalRequestsPage.eventName" />
                  </div>
                </th>
                <th>
                  <div className={css.headingName}>
                    <FormattedMessage id="ProposalRequestsPage.startDate" />
                  </div>
                </th>
                <th>
                  <div className={css.headingName}>
                    <FormattedMessage id="ProposalRequestsPage.endDate" />
                  </div>
                </th>
                <th>
                  <div className={css.headingName}>
                    <FormattedMessage id="ProposalRequestsPage.guestCount" />
                  </div>
                </th>
                <th>
                  <div className={css.headingName}>
                    <FormattedMessage id="ProposalRequestsPage.budget" />
                  </div>
                </th>
                <th className={css.location}>
                  <div className={css.headingName}>
                    <FormattedMessage id="ProposalRequestsPage.location" />
                  </div>
                </th>
                <th className={css.status}>
                  <div className={css.headingName}>
                    <FormattedMessage id="ProposalRequestsPage.Status" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {fetchEventsInProgress
                ? <tr>
                  <td colSpan="5" className={css.lazyLoad}>
                    <div className={css.lazySkeleton}>
                      <SkeletonLoader />
                    </div>
                  </td>
                </tr>
                : events && events.length && Array.isArray(events)
                  ? events.map((event) => {
                    const {
                      _id,
                      txId,
                      title,
                      price,
                      city,
                      region,
                      status,
                      vendor,
                      zipCode,
                      eventId,
                      eventSize,
                      eventOwner,
                      eventDates = [],
                      eventDuration,
                    } = event;
                    const eventDate = eventDates && eventDates.length ? moment(eventDates[0].start) : moment();
                    const isEventPassed = moment().unix() > moment(eventDate).unix();

                    return <tr key={_id + title}>
                      <td>
                        <div className={css.tableData}>
                          {title}
                        </div>
                      </td>
                      <td>
                        <div className={css.tableDate}>
                          {eventDuration == 'not-determined'
                            ? camelCaseEventDuration(eventDuration)
                            : eventDates.map(d => moment(d.start).format('LLL')).join(', ')}
                        </div>
                      </td>
                      <td>
                        <div className={css.tableDate}>
                          {eventDuration == 'not-determined'
                            ? camelCaseEventDuration(eventDuration)
                            : eventDates.map(d => moment(d.end).format('LLL')).join(', ')}
                        </div>
                      </td>
                      <td>
                        <div className={css.tableDate}>
                          {(eventSize && eventSize.value ? eventSize.value : eventSize)}
                        </div>
                      </td>
                      <td>
                        <div className={css.tableData}>
                          {(price && price.amount) ? "$ " + (parseInt(price.amount) / 100) : "N/A"}
                        </div>
                      </td>
                      <td>
                        <div className={css.tableData}>
                          {region && city
                            ? city + ', ' + region
                            : 'N/A'}
                        </div>
                      </td>
                      <td>
                        <div className={css.tableButton} onClick={() => {
                          if ((!['PENDING', 'REQUESTED', 'ENQUIRY', 'INFO', 'ACCEPTED'].includes(status) || (['PENDING', 'REQUESTED', 'ENQUIRY', 'INFO'].includes(status) && isEventPassed))) return;

                          if (['PENDING', 'ENQUIRY', 'INFO'].includes(status)) {
                            return history.push(createResourceLocatorString('ProposalTabPage',
                              routeConfiguration(),
                              {
                                txId,
                                slug: createSlug(title),
                                tab: 'request'
                              }));
                          }
                          onFindOrCreateChannel({
                            senderId: vendor.id,
                            receiverId: eventOwner.id,
                            type: 'transactional',
                            tab: txId,
                            eventId,
                            slug: createSlug(title),
                          }).then(() => {
                            const channelId = [eventOwner.id, vendor.id].sort().join('_');
                            typeof window !== 'undefined' && window.sessionStorage.setItem('channelId', channelId);
                            return history.push(createResourceLocatorString('ProposalTabPage',
                              routeConfiguration(),
                              {
                                txId,
                                slug: createSlug(title),
                                tab: ['PENDING', 'ENQUIRY', 'INFO'].includes(status) ? 'request' : 'messages'
                              }))
                          });
                        }}>
                          {(['PENDING', 'ENQUIRY', 'INFO', 'REQUESTED'].includes(status) && isEventPassed) || ['EXPIRED', 'DECLINED'].includes(status)
                            ? <PrimaryButton className={css.expiredButton} disabled={true}>
                              {status == 'DECLINED' ? statusEnumVendor[status] : 'EXPIRED'}
                            </PrimaryButton>
                            : ['PENDING', 'ENQUIRY', 'INFO', 'REQUESTED', 'ACCEPTED'].includes(status)
                              ? <PrimaryButton className={css.pendingButton}>
                                {statusEnumVendor[status]}
                              </PrimaryButton>
                              : null}
                        </div>
                      </td>
                    </tr>
                  })
                  : <tr>
                    <td colSpan="5" className={css.lazyLoad}>
                      <div className={css.lazySkeleton}>
                        <FormattedMessage id="ProposalRequestsPage.dontHaveAnyProposal" />
                      </div>
                    </td>
                  </tr>}
            </tbody>
          </table>
        </div>
      </div>;
    case GRID:
      return <div className={css.mainWrapperListBox}>
        <div className={css.cardViewBox}>
          <div className={events.length == 0 ? css.blankCard : css.eventCardsList}>
            {fetchEventsInProgress
              ? <div className={css.lazyLoad}>
                <div className={css.lazySkeleton}>
                  <SkeletonLoader />
                </div>
              </div>
              : events && events.length && Array.isArray(events)
                ? events.map((event) => {
                  const {
                    _id,
                    txId,
                    title,
                    city,
                    region,
                    distance = 0,
                    eventSize,
                    eventDuration,
                    eventDates = [],
                    price,
                    status,
                    vendor,
                    eventId,
                    eventType,
                    eventOwner,
                    categoriesLabel,
                    subCategoriesLabel,
                    eventTypeImageURL,
                    uploadedImagesUrls = [],
                    isClosed,
                  } = event;

                  const eventStartDate = eventDates && eventDates.length ? moment(eventDates[0].start) : moment();
                  const isEventPassed = moment().unix() > moment(eventStartDate).unix();
                  const isExpired = eventDuration != 'not-determined' && (eventStartDate && moment(eventStartDate).isBefore(moment()));

                  return (
                    <div
                      className={classNames(css.eventCardBox, ((!['PENDING', 'REQUESTED', 'ENQUIRY', 'INFO', 'ACCEPTED'].includes(status) || (['PENDING', 'REQUESTED', 'ENQUIRY', 'INFO'].includes(status) && isEventPassed)) ? css.eventCardDisabled : ''))}
                      key={_id + '-' + title}
                      onClick={() => {
                        if ((!['PENDING', 'REQUESTED', 'ENQUIRY', 'INFO', 'ACCEPTED'].includes(status) || (['PENDING', 'REQUESTED', 'ENQUIRY', 'INFO'].includes(status) && isEventPassed))) return;

                        if (['PENDING', 'ENQUIRY', 'INFO'].includes(status)) {
                          return history.push(createResourceLocatorString('ProposalTabPage',
                            routeConfiguration(),
                            {
                              txId,
                              slug: createSlug(title),
                              tab: 'request'
                            }))
                        }
                        onFindOrCreateChannel({
                          senderId: vendor.id,
                          receiverId: eventOwner.id,
                          type: 'transactional',
                          tab: txId,
                          eventId,
                          slug: createSlug(title),
                        }).then(() => {
                          const channelId = [eventOwner.id, vendor.id].sort().join('_');
                          typeof window !== 'undefined' && window.sessionStorage.setItem('channelId', channelId);
                          return history.push(createResourceLocatorString('ProposalTabPage',
                            routeConfiguration(),
                            {
                              txId,
                              slug: createSlug(title),
                              tab: ['PENDING', 'ENQUIRY', 'INFO'].includes(status) ? 'request' : 'messages'
                            }))
                        });
                      }}
                    >
                      <div>
                        {isExpired || true // Hide for now there is as such no requirement
                          ? null
                          : <div className={css.rightBoxIcon}>
                            <span className={css.dropdownIcon}>
                              <Menu
                                className={classNames(css.menu, css.cardIsOpen)}
                                contentPlacementOffset={MENU_CONTENT_OFFSET}
                                contentPosition="left"
                                useArrow={false}
                                onToggleActive={isOpen => {
                                  const listingOpen = isOpen ? event : null;
                                  setListingMenuOpen(listingOpen);
                                }}
                                isOpen={listingMenuOpen}
                              >
                                <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
                                  <div className={css.iconWrapper}>
                                    <IconCardDashboard type="threedots" isActive={listingMenuOpen} color='black' />
                                  </div>
                                </MenuLabel>
                                <MenuContent rootClassName={css.menuContent}>
                                  <MenuItem key="event-detail">
                                    <NamedLink
                                      className={css.manageLink}
                                      name={'ProposalTabPage'}
                                      params={{
                                        txId,
                                        slug: createSlug(title),
                                        tab: ['PENDING', 'ENQUIRY', 'INFO'].includes(status) ? 'request' : 'messages'
                                      }}
                                    >
                                      <FormattedMessage id="ManageListingCard.viewEvent" />
                                    </NamedLink>
                                  </MenuItem>
                                  <MenuItem key="open-close-event">
                                    <span
                                      className={css.manageLink}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        isClosed ? onOpenListing(id) : onCloseListing(id)
                                      }}
                                    >
                                      <FormattedMessage id="ManageListingCard.isClosedEvent" values={{ isClosed: isClosed ? 'Open' : 'Close' }} />
                                    </span>
                                  </MenuItem>
                                </MenuContent>
                              </Menu>
                            </span>
                          </div>}
                        <div className={css.eventTop}>
                          <ResponsiveImage
                            alt={uploadedImagesUrls[0]?.url || eventTypeImageURL}
                            url={uploadedImagesUrls[0]?.url || eventTypeImageURL}
                          />
                        </div>
                        <div className={css.eventBody}>
                          <h3>{title}</h3>
                          <ul>
                            {/* <li>{eventType}</li> */}
                            {region && city
                              ? <li>{city + ', ' + region + ' | ' + distance + ' Miles Away'}</li>
                              : null}

                            <li>{eventDates && eventDates.length ? eventDates.map(d => moment(d.start).format('LL')).join(', ') : 'Not-determined'} | {(eventSize && eventSize.value ? eventSize.value : eventSize) + ' Guests'}</li>
                            <li>{eventType && eventType.label ? eventType.label : eventType} | {categoriesLabel[0] + ' > ' + subCategoriesLabel[0]}</li>
                          </ul>
                        </div>
                      </div>
                      <div className={css.bottomButton}>
                        {(['PENDING', 'ENQUIRY', 'INFO', 'REQUESTED'].includes(status) && isEventPassed) || ['EXPIRED', 'DECLINED'].includes(status)
                          ? <PrimaryButton className={css.expiredButton} disabled={true}>
                            {status == 'DECLINED' ? statusEnumVendor[status] : 'EXPIRED'}
                          </PrimaryButton>
                          : ['PENDING', 'ENQUIRY', 'INFO', 'REQUESTED', 'ACCEPTED'].includes(status)
                            ? <PrimaryButton
                              className={classNames(css.pendingButton, status == 'REQUESTED'
                                ? css.requestedBtn
                                : status == 'PENDING'
                                  ? css.pendingBtn
                                  : status == "ACCEPTED"
                                    ? css.acceptedBtn
                                    : "")}
                            >
                              {statusEnumVendor[status]}
                            </PrimaryButton>
                            : null}
                      </div>
                    </div>)
                }) :
                <div className={css.lazyLoad}>
                  <div className={css.lazySkeleton}>
                    <FormattedMessage id="ProposalRequestsPage.dontHaveAnyProposal" />
                  </div>
                </div>}
          </div>
        </div>
      </div>;
    default:
      return null;
  }

};

EventCard.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

EventCard.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(EventCard);