import {
  paymentCustomer,
  createPaymentIntents,
  confirmPaymentIntents,
  getPaymentMethodApi,
  attachPaymentMethods,
  detachPaymentMethods,
  changeFlows,
  createTransfers,
} from '../../util/api';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { setInitialValues as setInitialValuesForPaymentMethods } from '../../ducks/paymentMethods.duck';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';

// ================ Action types ================ //

export const SETUP_INTENT_REQUEST = 'app/PaymentMethodsPage/SETUP_INTENT_REQUEST';
export const SETUP_INTENT_SUCCESS = 'app/PaymentMethodsPage/SETUP_INTENT_SUCCESS';
export const SETUP_INTENT_ERROR = 'app/PaymentMethodsPage/SETUP_INTENT_ERROR';

export const CREATE_TRANSFER_REQUEST = 'app/PaymentMethodsPage/CREATE_TRANSFER_REQUEST';
export const CREATE_TRANSFER_SUCCESS = 'app/PaymentMethodsPage/CREATE_TRANSFER_SUCCESS';
export const CREATE_TRANSFER_ERROR = 'app/PaymentMethodsPage/CREATE_TRANSFER_ERROR';

export const DETACH_PAYMENT_METHOD_REQUEST = 'app/PaymentMethodsPage/DETACH_PAYMENT_METHOD_REQUEST';
export const DETACH_PAYMENT_METHOD_SUCCESS = 'app/PaymentMethodsPage/DETACH_PAYMENT_METHOD_SUCCESS';
export const DETACH_PAYMENT_METHOD_ERROR = 'app/PaymentMethodsPage/DETACH_PAYMENT_METHOD_ERROR';

export const GET_PAYMENT_METHOD_REQUEST = 'app/PaymentMethodsPage/GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS = 'app/PaymentMethodsPage/GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_ERROR = 'app/PaymentMethodsPage/GET_PAYMENT_METHOD_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/PaymentMethodsPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/PaymentMethodsPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/PaymentMethodsPage/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  setupIntentInProgress: false,
  setupIntentError: null,
  setupIntent: null,
  stripeCustomerFetched: false,
  createTransferInProgress: false,
  createTransferError: null,
  createTransfer: null,
  getPaymentMethodInProgress: false,
  getPaymentMethodError: null,
  paymentMethod: null,
  detachPaymentMethodInProgress: false,
  detachPaymentMethodError: null,
};

export default function payoutMethodsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SETUP_INTENT_REQUEST:
      return { ...state, setupIntentInProgress: true, setupIntentError: null };
    case SETUP_INTENT_SUCCESS:
      return {
        ...state,
        setupIntentInProgress: false,
        setupIntentError: null,
        setupIntent: payload,
      };
    case SETUP_INTENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, setupIntentInProgress: false, setupIntentError: null };
    case CREATE_TRANSFER_REQUEST:
      return { ...state, createTransferInProgress: true, createTransferError: null };
    case CREATE_TRANSFER_SUCCESS:
      return {
        ...state,
        createTransferInProgress: false,
        createTransferError: null,
        createTransfer: payload,
      };
    case CREATE_TRANSFER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, createTransferInProgress: false, createTransferError: null };
    case DETACH_PAYMENT_METHOD_REQUEST:
      return { ...state, detachPaymentMethodInProgress: true, detachPaymentMethodError: null };
    case DETACH_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        detachPaymentMethodInProgress: false,
        detachPaymentMethodError: null,
        paymentMethod: payload,
      };
    case DETACH_PAYMENT_METHOD_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, detachPaymentMethodInProgress: false, detachPaymentMethodError: null };
    case GET_PAYMENT_METHOD_REQUEST:
      return { ...state, getPaymentMethodInProgress: true, getPaymentMethodError: null };
    case GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        getPaymentMethodInProgress: false,
        getPaymentMethodError: null,
        paymentMethod: payload,
      };
    case GET_PAYMENT_METHOD_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, getPaymentMethodInProgress: false, getPaymentMethodError: null };
    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setupIntentRequest = () => ({ type: SETUP_INTENT_REQUEST });
export const setupIntentSuccess = () => ({ type: SETUP_INTENT_SUCCESS });
export const setupIntentError = e => ({
  type: SETUP_INTENT_ERROR,
  error: true,
  payload: e,
});

export const detachPaymentMethodRequest = () => ({ type: DETACH_PAYMENT_METHOD_REQUEST });
export const detachPaymentMethodSuccess = () => ({ type: DETACH_PAYMENT_METHOD_SUCCESS });
export const detachPaymentMethodError = e => ({
  type: DETACH_PAYMENT_METHOD_ERROR,
  error: true,
  payload: e,
});

export const createTransfersRequest = () => ({ type: CREATE_TRANSFER_REQUEST });
export const createTransfersSuccess = () => ({ type: CREATE_TRANSFER_SUCCESS });
export const createTransfersError = e => ({
  type: CREATE_TRANSFER_ERROR,
  error: true,
  payload: e,
});

export const getPaymentMethodRequest = () => ({ type: GET_PAYMENT_METHOD_REQUEST });
export const getPaymentMethodSuccess = (payload) => ({ type: GET_PAYMENT_METHOD_SUCCESS, payload });
export const getPaymentMethodError = e => ({
  type: GET_PAYMENT_METHOD_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //
export const createPaymentIntent = (payload) => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());
  return createPaymentIntents(payload)
    .then(response => {
      const setupIntent = response.data;
      dispatch(setupIntentSuccess(setupIntent));
      return setupIntent;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const confirmPaymentIntent = (payload) => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());

  return confirmPaymentIntents(payload)
    .then(response => {
      const confirmIntent = response.data;
      dispatch(setupIntentSuccess(confirmIntent));
      return confirmIntent;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const createPaymentCustomer = (payload) => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());

  return paymentCustomer(payload)
    .then(response => {
      const paymentCustomer = response.data;
      dispatch(setupIntentSuccess(paymentCustomer));
      return paymentCustomer;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const getPaymentMethod = (payload) => (dispatch, getState, sdk) => {
  dispatch(getPaymentMethodRequest());

  return getPaymentMethodApi(payload)
    .then(response => {
      const paymentMethod = response.data;
      dispatch(getPaymentMethodSuccess(paymentMethod));
      return paymentMethod;
    })
    .catch(e => {
      return dispatch(getPaymentMethodError(e));
    });
};

export const attachPaymentMethod = (payload) => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());

  return attachPaymentMethods(payload)
    .then(response => {
      const paymentMethod = response.data;
      dispatch(updateProfile({ protectedData: payload }));
      dispatch(setupIntentSuccess(paymentMethod));
      return paymentMethod;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const detachPaymentMethod = (payload) => (dispatch, getState, sdk) => {
  dispatch(detachPaymentMethodRequest());
  return detachPaymentMethods(payload, { expand: true })
    .then(response => {
      const paymentMethod = response.data;
      dispatch(updateProfile({
        protectedData: {
          payment_method_id: ""
        }
      }));
      dispatch(detachPaymentMethodSuccess({}));
      return paymentMethod;
    })
    .catch(e => {
      log.error(storableError(e), 'add-payment-method-failed');
      dispatch(detachPaymentMethodError(storableError(e)));
    });
};

export const createDotTransfers = (payload) => (dispatch, getState, sdk) => {
  dispatch(createTransfersRequest());

  return createTransfers(payload)
    .then(response => {
      const dotUser = response.data;
      if (dotUser && dotUser.id) {
        const { currentUser } = getState().user;
        Object.assign(currentUser.attributes.profile.protectedData, { dotUser });
        dispatch(currentUserShowSuccess(currentUser));
      }
      dispatch(createTransfersSuccess(dotUser));
      return dotUser;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(createTransfersError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const setupPaymentFlows = (payload) => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());
  return changeFlows(payload)
    .then(response => {
      const flow = response.data;
      dispatch(updateProfile({ protectedData: { flow } }));
      dispatch(setupIntentSuccess(flow));

      return flow;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const createStripeSetupIntent = () => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());
  return sdk.stripeSetupIntents
    .create()
    .then(response => {
      const setupIntent = response.data.data;
      dispatch(setupIntentSuccess(setupIntent));
      return setupIntent;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }, null, true))
    .then(response => {
      const { currentUser } = getState().user || {};
      const { payment_method_id } = (currentUser && currentUser.id && currentUser.attributes.profile.protectedData) || {};
      if (payment_method_id) {
        dispatch(getPaymentMethod({ payment_method_id }));
      }
      dispatch(stripeCustomerSuccess());
      return response;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'fetch-stripe-customer-failed');
      dispatch(stripeCustomerError(error));
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(setInitialValuesForPaymentMethods());

  return dispatch(stripeCustomer());
};
