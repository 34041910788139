import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldMultiSelect } from '../../components';

import css from './VendorAccessibilityForm.module.css';

const KEY_CODE_ENTER = 13;

const VendorAccessibilityFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        updateInProgress,
        invalid,
        intl,
        values,
        eventSizeOptions
      } = fieldRenderProps;

      // eventSize
      const eventSizeLabel = intl.formatMessage({
        id: 'VendorAccessibilityForm.eventSize',
      });
      const eventSizePlaceholder = intl.formatMessage({
        id: 'VendorAccessibilityForm.eventSizePlaceholder',
      });
      const eventSizeRequiredMessage = intl.formatMessage({
        id: 'VendorAccessibilityForm.eventSizeRequired',
      });
      const eventSizeRequired = validators.required(eventSizeRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.multiselectInput}>
            <FieldMultiSelect
              isMulti={false}
              isRequired={true}
              className={css.field}
              id={"eventSize"}
              name={"eventSize"}
              label={eventSizeLabel}
              autoComplete={"eventSize"}
              options={eventSizeOptions}
              validate={eventSizeRequired}
              // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
              fontClass={css.fontClass}
              placeholder={eventSizePlaceholder}
              defaultValue={values.eventSize || ""}
            />
          </div>
          <div className={css.bottomWrapper}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="VendorAccessibilityForm.submit" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

VendorAccessibilityFormComponent.defaultProps = { inProgress: false, eventSizeOptions: config.custom.eventSizes };

const { bool, func } = PropTypes;

VendorAccessibilityFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const VendorAccessibilityForm = compose(injectIntl)(VendorAccessibilityFormComponent);
VendorAccessibilityForm.displayName = 'VendorAccessibilityForm';

export default VendorAccessibilityForm;
