import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import config from '../../config';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { isUploadImageOverLimitError } from '../../util/errors';
import getCountryCodes from '../../translations/countryCodes';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCheckbox,
  LocationAutocompleteInputField,
  FieldPhoneNumberInput,
  FieldSelect,
  DragAndDrop,
  IconSpinner,
  Avatar,
  ResponsiveImage,
  ImageFromFile,
  IconProfileSetup,
} from '../../components';
import * as normalizePhoneNumberUS from './normalizePhoneNumberUS';

import css from './PlannerBasicsForm.module.css';

const KEY_CODE_ENTER = 13;
const identity = v => v;

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

const AVATAR_IMAGE_VARIANTS = [
  'default',
  // // 40x40
  // 'square-xsmall',

  // // 80x80
  // 'square-xsmall2x',

  // // 240x240
  // 'square-small',

  // // 480x480
  // 'square-small2x',
];

export class PlannerBasicsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { uploadDelay: false, imageUploadRequested: false };
    this.handleDrop = this.handleDrop.bind(this);
    this.submittedImages = [];

    this.uploadDelayTimeoutId = null;
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  handleDrop(files, form, type) {
    const { onImageUpload, onLogoUpload, } = this.props;

    if (files && files.length) {
      const file = files[0];
      const filetype = file.type;
      if (filetype.search('image') > -1) {
        const tempId = `${file.name}_${Date.now()}`;
        type == "LOGO"
          ? onLogoUpload({ id: tempId, file, imageType: 'brandLogo' })
          : onImageUpload({ id: tempId, file });
      }
    }
  }

  render() {

    return (
      <FinalForm
        {...this.props}
        keepDirtyOnReinitialize={true}
        handleDrop={this.handleDrop}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            formId,
            handleSubmit,
            invalid,
            intl,
            form,
            values,
            handleDrop,
            onImageUpload,
            onLogoUpload,
            profileLogo,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            uploadLogoInProgress,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const uploadingLogoOverlay =
            uploadLogoInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const logoExists = !!(profileLogo && profileLogo.file);
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const delayAfterUploadLogo = profileLogo && (profileLogo.imageId || profileLogo.imageType == 'brandLogo') && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          const transientUserProfileLogo = profileLogo && profileLogo.uploadedImage;

          const logoFromFile =
            logoExists && delayAfterUploadLogo ? (
              <ImageFromFile
                id={profileLogo.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileLogo.file}
              >
                {uploadingLogoOverlay}
              </ImageFromFile>
            ) : null;

          const logoAvatar =
            transientUserProfileLogo ? (
              <ResponsiveImage
                rootClassName={css.avatarImage}
                alt={"brand logo"}
                image={transientUserProfileLogo}
                variants={AVATAR_IMAGE_VARIANTS}
                sizes={"(max-width: 767px) 96px, 240px"}
              />
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {/* {imageFromFile} */}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  {/* <FormattedMessage id="ProfileSettingsForm.changeAvatar" /> */}
                </div>
              </div>
            ) : (
              <div className={css.chooseImageText}>
                <IconProfileSetup type={"uploadImage"} />
                <div className={css.chooseImagedesktop}>
                  <FormattedMessage id="PlannerBasicsForm.profilePicAltText" />
                </div>
                <div className={css.chooseImagemobile}>
                  <FormattedMessage id="PlannerBasicsForm.profilePicAltTextmobile" />
                </div>
                <span className={css.imageTypes}>
                  <FormattedMessage id="PlannerBasicsForm.addImagesTip" />
                </span>
              </div>
            );

          const chooseLogoLabel =
            (profileLogo && (profileLogo.imageId || profileLogo.imageType == "brandLogo")) || logoExists ? (
              <div className={css.avatarContainer}>
                {logoFromFile}
                {logoAvatar}
                <div className={css.changeAvatar}>
                  {/* <FormattedMessage id="ProfileSettingsForm.changeAvatar" /> */}
                </div>
              </div>
            ) : (
              <div className={css.chooseImageText}>
                <IconProfileSetup type={"uploadImage"} />
                <div className={css.chooseImagedesktop}>
                  <FormattedMessage id="PlannerBasicsForm.logoAltText" />
                </div>
                <div className={css.chooseImagemobile}>
                  <FormattedMessage id="PlannerBasicsForm.logoAltTextmobile" />
                </div>
                <span className={css.imageTypes}>
                  <FormattedMessage id="PlannerBasicsForm.addLogoTip" />
                </span>
              </div>
            );

          const imageRequiredMessage = intl.formatMessage({
            id: 'PlannerBasicsForm.imageRequired',
          });

          // firstName
          const firstNameLabel = intl.formatMessage({
            id: 'PlannerBasicsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'PlannerBasicsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'PlannerBasicsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // lastName
          const lastNameLabel = intl.formatMessage({
            id: 'PlannerBasicsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'PlannerBasicsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'PlannerBasicsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // businessName
          const businessNameLabel = intl.formatMessage({
            id: 'PlannerBasicsForm.businessNameLabel',
          });
          const businessNamePlaceholder = intl.formatMessage({
            id: 'PlannerBasicsForm.businessNamePlaceholder',
          });
          const businessNameRequiredMessage = intl.formatMessage({
            id: 'PlannerBasicsForm.businessNameRequired',
          });
          const businessNameRequired = validators.required(businessNameRequiredMessage);

          // Phone
          const phoneLabel = intl.formatMessage({ id: 'PlannerBasicsForm.phoneLabel' });
          const phonePlaceholder = intl.formatMessage({ id: 'PlannerBasicsForm.personalPhonePlaceholder' });
          const phoneNumberForUSRequired = validators.required(
            intl.formatMessage({ id: 'PlannerBasicsForm.personalPhoneRequired' })
          );

          //country
          const countryLabel = intl.formatMessage({
            id: 'PlannerBasicsForm.counrtyNameLabel',
          });
          const countryPlaceholder = intl.formatMessage({
            id: 'PlannerBasicsForm.countryPlaceholder',
          });
          const countryNameRequiredMessage = intl.formatMessage({
            id: 'PlannerBasicsForm.countryNameRequired',
          });
          const countryRequired = validators.required(countryNameRequiredMessage);

          const titleRequiredMessage = intl.formatMessage({ id: 'PlannerBasicsForm.address' });
          const addressPlaceholderMessage = intl.formatMessage({
            id: 'PlannerBasicsForm.addressPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'PlannerBasicsForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'PlannerBasicsForm.addressNotRecognized',
          });

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || uploadInProgress || submitInProgress;
          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          // Radio
          const workRemoteLabel = intl.formatMessage({
            id: 'PlannerBasicsForm.workRemoteLabel',
          });
          const countryCodes = getCountryCodes(config.locale, ['CA', 'US', 'IN']);

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
                <div className={css.imageUpload}>
                  {/* Your Profile Picture Drag & Drop or Click */}
                  <DragAndDrop
                    dragdrop={css.thumbnail}
                    handleDrop={(e) => handleDrop(e, form)}
                  >
                    <Field
                      accept={ACCEPT_IMAGES}
                      id="profileImage"
                      name="profileImage"
                      label={chooseAvatarLabel}
                      type="file"
                      form={null}
                      uploadImageError={uploadImageError}
                      disabled={uploadInProgress}
                    >
                      {fieldProps => {
                        const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`profileImage`, file);
                          form.blur(`profileImage`);
                          if (file != null) {
                            const tempId = `${file.name}_${Date.now()}`;
                            onImageUpload({ id: tempId, file });
                          }
                        };

                        let error = null;

                        if (isUploadImageOverLimitError(uploadImageError)) {
                          error = (
                            <div className={css.error}>
                              <div className={css.chooseImagedesktop}>
                                <FormattedMessage id="PlannerBasicsForm.profilePicAltUploadText" />
                              </div>
                              <div className={css.chooseImagemobile}>
                                <FormattedMessage id="PlannerBasicsForm.profilePicAltTextmobile" />
                              </div>
                              <span className={css.imageTypes}>
                                <FormattedMessage id="PlannerBasicsForm.addImagesTip" />
                              </span>
                              <br /><br />
                              <span className={css.imageTypes}>
                                <FormattedMessage id="PlannerBasicsForm.addLogoTip" />
                              </span>
                            </div>
                          );
                        } else if (uploadImageError) {
                          error = (
                            <div className={css.error}>
                              <div className={css.chooseImagedesktop}>
                                <FormattedMessage id="PlannerBasicsForm.profilePicAltUploadText" />
                              </div>
                              <div className={css.chooseImagemobile}>
                                <FormattedMessage id="PlannerBasicsForm.profilePicAltTextmobile" />
                              </div>
                              <span className={css.imageTypes}>
                                <FormattedMessage id="PlannerBasicsForm.addImagesTip" />
                              </span>
                              <br /><br />
                              <span className={css.imageTypes}>
                                <FormattedMessage id="PlannerBasicsForm.addLogoTip" />
                              </span>
                            </div>
                          );
                        }

                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              <label className={css.addImage} htmlFor={id}>
                                {error ? error : label}
                              </label>
                              <input
                                accept={accept}
                                id={id}
                                name={name}
                                className={css.addImageInput}
                                disabled={disabled}
                                onChange={onChange}
                                type={type}
                              />
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  </DragAndDrop>

                  {/* Your Logo Drag & Drop or Click */}
                  <DragAndDrop
                    dragdrop={css.thumbnail}
                    handleDrop={(file) => handleDrop(file, form, "LOGO")}
                  >
                    <Field
                      accept={ACCEPT_IMAGES}
                      id="logoImage"
                      name="logoImage"
                      label={chooseLogoLabel}
                      type="file"
                      form={null}
                      uploadImageError={uploadImageError}
                      disabled={uploadInProgress}
                    >
                      {fieldProps => {
                        const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`logoImage`, file);
                          form.blur(`logoImage`);
                          if (file != null) {
                            const tempId = `${file.name}_${Date.now()}`;
                            onLogoUpload({ id: tempId, file, imageType: 'brandLogo' });
                          }
                        };

                        let error = null;

                        if (isUploadImageOverLimitError(uploadImageError)) {
                          error = (
                            <div className={css.error}>
                              <div className={css.chooseImagedesktop}>
                                <FormattedMessage id="PlannerBasicsForm.logoAltText" />
                              </div>
                              <div className={css.chooseImagemobile}>
                                <FormattedMessage id="PlannerBasicsForm.logoAltTextmobile" />
                              </div>
                              <span className={css.imageTypes}>
                                <FormattedMessage id="PlannerBasicsForm.addLogoTip" />
                              </span>
                              <br /><br />
                              <FormattedMessage id="PlannerBasicsForm.logoUploadFailedFileTooLarge" />
                            </div>
                          );
                        } else if (uploadImageError) {
                          error = (
                            <div className={css.error}>
                              <div className={css.chooseImagedesktop}>
                                <FormattedMessage id="PlannerBasicsForm.logoAltText" />
                              </div>
                              <div className={css.chooseImagemobile}>
                                <FormattedMessage id="PlannerBasicsForm.logoAltTextmobile" />
                              </div>
                              <span className={css.imageTypes}>
                                <FormattedMessage id="PlannerBasicsForm.addLogoTip" />
                              </span>
                              <br /><br />
                              <FormattedMessage id="PlannerBasicsForm.logoUploadFailed" />
                            </div>
                          );
                        }

                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              <label className={css.addImage} htmlFor={id}>
                                {error ? error : label}
                              </label>
                              <input
                                accept={accept}
                                id={id}
                                name={name}
                                className={css.addImageInput}
                                disabled={disabled}
                                onChange={onChange}
                                type={type}
                              />
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  </DragAndDrop>

                </div>
                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.firstName` : 'firstName'}
                    name="firstName"
                    autoComplete="given-name"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lastName` : 'lastName'}
                    name="lastName"
                    autoComplete="family-name"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>

                <FieldTextInput
                  className={css.inputBox}
                  type="text"
                  id={formId ? `${formId}.businessName` : 'businessName'}
                  name="businessName"
                  autoComplete="family-name"
                  label={businessNameLabel}
                  placeholder={businessNamePlaceholder}
                  validate={businessNameRequired}
                />

                <div className={css.name}>
                  <FieldPhoneNumberInput
                    id={formId ? `${formId}.phone` : 'phone'}
                    name={'phoneNumber'}
                    className={css.phoneInput}
                    autoComplete="tel-national"
                    label={phoneLabel}
                    format={normalizePhoneNumberUS.format}
                    parse={normalizePhoneNumberUS.parse}
                    placeholder={phonePlaceholder}
                    type="text"
                    validate={phoneNumberForUSRequired}
                    inputIcon={<IconProfileSetup type="phone" />}
                  />
                  <FieldSelect
                    id={`${formId}.country`}
                    name="country"
                    className={css.countryInput}
                    label={countryLabel}
                  // validate={countryRequired}
                  >
                    <option disabled value="">
                      {countryPlaceholder}
                    </option>
                    {countryCodes.map(country => {
                      return (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      );
                    })}
                  </FieldSelect>
                </div>
                {values.workRemote
                  ? null
                  : <div className={css.inputBox}>
                    <LocationAutocompleteInputField
                      className={css.locationAddress}
                      inputClassName={css.locationAutocompleteInput}
                      iconClassName={css.locationAutocompleteInputIcon}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      name={'location'}
                      label={titleRequiredMessage}
                      placeholder={addressPlaceholderMessage}
                      useDefaultPredictions={false}
                      format={identity}
                      // inputIcon={<IconCard brand="location" />}
                      valueFromForm={values.location}
                      validate={validators.composeValidators(
                        validators.autocompleteSearchRequired(addressRequiredMessage),
                        validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                      )}
                    />
                  </div>}
              </div>

              <div className={css.workRadioButton}>
                <FieldCheckbox
                  id={formId ? `${formId}.workRemote` : 'workRemote'}
                  name="workRemote"
                  label={workRemoteLabel}
                />
              </div>
              {submitError}
              <div className={css.bottomWrapper}>
                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                  <FormattedMessage id="PlannerBasicsForm.submit" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    )
  }
};

PlannerBasicsFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

PlannerBasicsFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const PlannerBasicsForm = compose(injectIntl)(PlannerBasicsFormComponent);
PlannerBasicsForm.displayName = 'PlannerBasicsForm';

export default PlannerBasicsForm;
