import React from 'react';
import PropTypes from 'prop-types';

// External link that opens in a new tab/window, ensuring that the
// opened page doesn't have access to the current page.
//
// See: https://mathiasbynens.github.io/rel-noopener/

function addProtocolIfNotExists(url) {
  const protocols = ['http://', 'https://'];
  const hasProtocol = protocols.some(protocol => url.startsWith(protocol));

  if (!hasProtocol) {
    // Assuming https:// as the default protocol, change as needed.
    return `https://${url}`;
  }

  return url;
}

const ExternalLink = props => {
  const { children, href, ...rest } = props;

  return (
    <a {...rest} href={addProtocolIfNotExists(href)} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

ExternalLink.defaultProps = { children: null };

const { node } = PropTypes;

ExternalLink.propTypes = { children: node };

export default ExternalLink;
