import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import { ensureCurrentUser } from '../../util/data';
import { useDispatch, useSelector } from 'react-redux';
import { withSocket, withViewport } from '../../util/contextHelpers';

import {
    IconSpinner,
    SkeletonLoader,
    ResponsiveImage,
} from '..';

import Chat from './Chat';
import chatLogo from '../../assets/chatLogo.png';
import TransactionChat from './TransactionChat';

import { addMessage, createChannelSuccess, loadData } from '../../containers/ChatPage/ChatPage.duck';

import css from './SendBirdChat.module.css';

const statusEnum = {
    'INFO': 'Message Awaiting',
    'ENQUIRY': 'Message Awaiting',
    'EXPIRED': 'Expired',
    'PENDING': 'Open',
    'ACCEPTED': 'Bid Won',
    'REQUESTED': 'Bid Pending',
    'DECLINED': 'Bid Declined'
};

const SendBirdChat = (props) => {
    const {
        params,
        socket,
        history,
        viewport,
        PageName,
        currentUser,
        eventDetailData,
        receivedQuotesHeight,
        onManageDisableScrolling,
        onFindOrCreateChannel,
        setVendorResponseModal
    } = props;

    const { publicData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
    const { userType } = publicData || {};
    const { updateWalletInProgress } = useSelector(state => state.CheckoutPage);

    const { CurrentAcorns = 0 } = ensureCurrentUser(currentUser).attributes.profile?.protectedData || {};

    const [state, updateState] = useState({
        openChatWindow: false,
        uploading: false,
        openModal: false,
    });

    const {
        _id,
        vendor,
        vendorResponse,
    } = eventDetailData || {};

    const {
        channel,
        channels = [],
        invoices = [],
        getChannelsInProgress,
    } = useSelector(state => state.ChatPage);

    const dispatch = useDispatch();

    //need to access state in message received callback
    const stateRef = useRef();
    stateRef.current = state;

    const mergedChats = eventDetailData && Object.keys(eventDetailData).length
        ? channel && channel.chats
            ? [eventDetailData, ...channel.chats, ...invoices].sort((a, b) => {
                const d1 = parseInt(parseInt(a.createdAt) / (10000)) > 0
                    ? a.createdAt
                    : moment(a.createdAt).unix();
                const d2 = parseInt(parseInt(b.createdAt) / (10000)) > 0
                    ? b.createdAt
                    : moment(b.createdAt).unix();
                return moment(d1 * 1000).isAfter(d2 * 1000) ? 1 : -1;
            })
            : [eventDetailData, ...invoices].sort((a, b) => {
                const d1 = parseInt(parseInt(a.createdAt) / (10000)) > 0
                    ? a.createdAt
                    : moment(a.createdAt).unix();
                const d2 = parseInt(parseInt(b.createdAt) / (10000)) > 0
                    ? b.createdAt
                    : moment(b.createdAt).unix();
                return moment(d1 * 1000).isAfter(d2 * 1000) ? 1 : -1;
            })
        : channel && channel.chats
            ? channel.chats
            : [];

    const channelRef = useRef();

    const scrollToBottom = (item, smooth) => {
        item?.scrollTo({
            top: item.scrollHeight,
            behavior: smooth
        })
    }

    const sendMessage = (values) => {
        const { message } = values;
        if (channel) {
            socket.emit('message', {
                channel: channel._id,
                seen: false,
                sender: currentUser.id.uuid,
                channelId: channel.channelId,
                receiver: receiverDetails.userId,
                type: 'text',
                body: message,
            });
        }
    }

    useEffect(() => {
        scrollToBottom(channelRef.current, 'smooth');
        if (channel && channel._id && userType) {
            updateState({ ...stateRef.current, openModal: (!channel.isUnlocked && userType != 'planner') });
            if (channel.unreadCount > 0) {
                socket.emit('join', {
                    markAllAsRead: Boolean(channel.unreadCount),
                    userId: currentUser.id.uuid,
                    channel: channel._id,
                });
            }
            if (typeof window !== 'undefined') {
                const message = window.sessionStorage.getItem('message');
                if (message) {
                    sendMessage({ message });
                    window.sessionStorage.removeItem('message');
                }
            }
        }
    }, [channel, userType]);

    useEffect(() => {
        if (currentUser && currentUser.id) {
            const payload = ['ProposalRequestsPage'].includes(PageName)
                ? { ...params, tab: params.txId }
                : params;
            if ((channels && !channels.length) || !channel) dispatch(loadData(payload));
            socket.off('message').on('message', message => {
                dispatch(addMessage(message))
                scrollToBottom(channelRef.current, 'smooth');
            });
            return () => {
                socket.off('message');
                socket.emit('join', { userId: currentUser.id.uuid })
            }
        }
    }, [currentUser]);

    const receiverDetails = channel?.members?.find(user => user.userId != currentUser.id.uuid) || {};
    const currentUserDetails = channel?.members?.find(user => user.userId === currentUser.id.uuid) || {};
    const isChannelUnlocked = channel
        ? (channel.isUnlocked
            ? true
            : ['EventDetailPage', 'ProposalRequestsPage'].includes(PageName)
                ? true
                : channel.creator
                    ? channel.creator == currentUserDetails.userId
                    : false)
        : eventDetailData && Object.keys(eventDetailData).length && eventDetailData.creatorId
            ? ['EventDetailPage', 'ProposalRequestsPage'].includes(PageName)
                ? true
                : eventDetailData.creatorId == currentUser.id.uuid
            : false;

    const onFileInputChange = async (e) => {
        if (channel && e.currentTarget.files && e.currentTarget.files.length > 0) {
            const file = e.currentTarget.files[0];

            const uploadURL = process.env.REACT_APP_CLOUDINARY_URL;
            const formData = new FormData();
            const fileType = file.type;

            formData.append('file', file);
            formData.append('folder', currentUser.id.uuid);
            formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET);
            formData.append('resource_type', fileType);
            formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY);

            updateState({ ...stateRef.current, uploading: true })
            fetch(uploadURL, { method: 'POST', body: formData })
                .then(response => response.text())
                .then(data => {
                    const res = JSON.parse(data);
                    if (res && res.secure_url) {
                        socket.emit('message', {
                            seen: false,
                            channel: channel._id,
                            sender: currentUser.id.uuid,
                            channelId: channel.channelId,
                            receiver: receiverDetails.userId,
                            type: res.resource_type,
                            body: res.original_filename,
                            url: res.url
                        });
                        updateState({ ...stateRef.current, uploading: false })
                    }
                });

        }
    }

    if (!socket) {
        return <SkeletonLoader />
    }

    const renderMedia = (message) => {
        const { type = '', url, body = '' } = message;

        if (!url) {
            return <IconSpinner />
        }

        if (type.includes('image')) {
            return (
                <a href={url} download={body}>
                    <ResponsiveImage className={css.chatMedia} url={message.url} alt={message.body} />
                </a>
            );
        }

        if (type.includes('video')) {
            return (
                <video className={css.chatMedia} alt={message.body} controls>
                    <source src={message.url} type="video/mp4" />
                </video>
            );
        }

        return <a href={url} download={body}>{body}</a>
    }
    const isMobile = viewport.width < 920;
    const createdDate = mergedChats && mergedChats.length && mergedChats[0].createdAt
        ? parseInt(parseInt(mergedChats[0].createdAt) / (10000)) > 0
            ? mergedChats[0].createdAt
            : moment(mergedChats[0].createdAt).unix()
        : moment().unix();

    return (['EventDetailPage', 'ProposalRequestsPage'].includes(PageName)
        ? <TransactionChat
            _id={_id}
            state={state}
            vendor={vendor}
            PageName={PageName}
            history={history}
            invoices={invoices}
            chatLogo={chatLogo}
            userType={userType}
            statusEnum={statusEnum}
            channelRef={channelRef}
            createdDate={createdDate}
            mergedChats={mergedChats}
            currentUser={currentUser}
            renderMedia={renderMedia}
            sendMessage={sendMessage}
            onFileInputChange={onFileInputChange}
            vendorResponse={vendorResponse}
            eventDetailData={eventDetailData}
            receiverDetails={receiverDetails}
            isChannelUnlocked={isChannelUnlocked}
            currentUserDetails={currentUserDetails}
            receivedQuotesHeight={receivedQuotesHeight}
            setVendorResponseModal={setVendorResponseModal}
            onManageDisableScrolling={onManageDisableScrolling}
        />
        : <Chat
            state={state}
            socket={socket}
            history={history}
            dispatch={dispatch}
            isMobile={isMobile}
            PageName={PageName}
            channel={channel}
            channels={channels}
            stateRef={stateRef}
            chatLogo={chatLogo}
            channelChat={channel}
            channelRef={channelRef}
            currentUser={currentUser}
            mergedChats={mergedChats}
            addMessage={addMessage}
            updateState={updateState}
            sendMessage={sendMessage}
            renderMedia={renderMedia}
            CurrentAcorns={CurrentAcorns}
            receiverDetails={receiverDetails}
            isChannelUnlocked={isChannelUnlocked}
            onFileInputChange={onFileInputChange}
            currentUserDetails={currentUserDetails}
            receivedQuotesHeight={receivedQuotesHeight}
            createChannelSuccess={createChannelSuccess}
            getChannelsInProgress={getChannelsInProgress}
            onFindOrCreateChannel={onFindOrCreateChannel}
            updateWalletInProgress={updateWalletInProgress}
            onManageDisableScrolling={onManageDisableScrolling}
        />
    );
};

export default withSocket(withViewport(SendBirdChat));
