import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToTz, getExclusiveEndDateWithTz } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getTransactions } from '../../util/api';
import algoliasearch from 'algoliasearch';

const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
const index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_LISTING_INDEX);

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const FETCH_CITIES_REQUEST = 'app/SearchPage/FETCH_CITIES_REQUEST';
export const FETCH_CITIES_SUCCESS = 'app/SearchPage/FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_ERROR = 'app/SearchPage/FETCH_CITIES_ERROR';

export const FETCH_ALL_CITIES_LISTING = 'app/SearchPage/FETCH_ALL_CITIES_LISTING';

// ================ Reducer ================ //

const initialState = {
  allCitiesSuccess: false,
  allCities: [],
  cities: null,
  citiesInProgress: false,
  citiesError: null,
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  venuesResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS: {
      const venuesResultIds = [];
      if (payload.data2) {
        venuesResultIds.push(...resultIds(payload.data2));
      }
      return {
        ...state,
        venuesResultIds,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    }
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    case FETCH_CITIES_REQUEST:
      return { ...state, citiesInProgress: true, citiesError: null };
    case FETCH_CITIES_SUCCESS:
      return { ...state, citiesInProgress: false, cities: payload.cities };
    case FETCH_CITIES_ERROR:
      return { ...state, citiesError: payload.error, citiesInProgress: false };

    case FETCH_ALL_CITIES_LISTING:
      return { ...state, allCities: payload, allCitiesSuccess: true };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (response, response2 = {}) => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data, data2: response2.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCitiesRequest = () => ({
  type: FETCH_CITIES_REQUEST
});

const fetchCitiesSuccess = (response) => ({
  type: FETCH_CITIES_SUCCESS,
  payload: { cities: response }
});

const fetchCitiesError = (error) => ({
  type: FETCH_CITIES_ERROR,
  payload: error
});

export const fetchAllCitiesSuccess = cities => ({
  type: FETCH_ALL_CITIES_LISTING,
  payload: cities,
});

export const searchListings = searchParams => async (dispatch, getState, sdk) => {

  if (searchParams.categoryName === 'LandingPage') {
    await Promise.all(['Jacksonville', 'Miami', 'Orlando', 'Tampa']
      .map(city => index.search(city, {
        hitsPerPage: 8,
        restrictSearchableAttributes: ['location.address'],
        filters: 'state:"published" AND (ListingType:"VENDOR_PROFILE" OR ListingType:"VENUE_PROFILE")'
      })))
      .then(cities => {
        dispatch(fetchAllCitiesSuccess(cities));
      });
  }

  dispatch(searchListingsRequest(searchParams));
  if (searchParams.categoryName) {
    await dispatch(fetchCurrentUser(null, searchParams.categoryName));
  }

  if (['LandingPage', 'CityLandingPage'].includes(searchParams.categoryName)) {
    dispatch(fetchCitiesRequest());
  }

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const availabilityParams = (datesParam, minDurationParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const startDate = hasDateValues ? dateValues[0] : null;
    const endDate = hasDateValues ? dateValues[1] : null;

    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
        ? { minDuration: minDurationParam }
        : {};

    // Find configs for 'dates-length' filter
    // (type: BookingDateRangeLengthFilter)
    const filterConfigs = config.custom.filters;
    const idOfBookingDateRangeLengthFilter = 'dates-length';
    const dateLengthFilterConfig = filterConfigs.find(
      f => f.id === idOfBookingDateRangeLengthFilter
    );
    // Extract time zone
    const timeZone = dateLengthFilterConfig.config.searchTimeZone;

    return hasDateValues
      ? {
        start: formatDateStringToTz(startDate, timeZone),
        end: getExclusiveEndDateWithTz(endDate, timeZone),

        // When we have `time-partial` value in the availability, the
        // API returns listings that don't necessarily have the full
        // start->end range available, but enough that the minDuration
        // (in minutes) can be fulfilled.
        //
        // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
        availability: 'time-partial',

        ...minDurationMaybe,
      }
      : {};
  };

  const { perPage, price, dates, minDuration, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const availabilityMaybe = availabilityParams(dates, minDuration);

  const params = {
    ...rest,
    ...priceMaybe,
    ...availabilityMaybe,
    isCompletedSteps: true,
    per_page: perPage,
  };

  const querySearch = "business";

  //   for (const key in searchFilters) {
  //     if (Object.hasOwnProperty.call(searchFilters, key)) {
  //         const value = searchFilters[key];
  //         filters += key + ":" + value;
  //     }
  // }
  // {
  //     // customRanking: [
  //     //   'desc(price)'
  //     // ],
  //     // searchableAttributes: [
  //     //   'title',
  //     //   'description',
  //     //   'categories'
  //     // ],
  //     query: "business",
  //     // Search for products with a price greater than or equal to $50 in the "prices" array
  //     // filters: 'prices >= 50'
  //     // Search for products with the "Red" color in the "colors" array
  //     // filters: 'colors:Red',
  //     // filters: 'colors:Red AND colors:Blue',
  //     // filters: 'title:Vendor business',
  //   };
  // return searchAlgoliaData({ querySearch })
  //   .then(res => {
  //     if (res.data && res.data.hits && res.data.hits.length) {
  //       Object.assign(params, { ids: res.data.hits.map(r => r.objectID) });
  //     }

  const promisify = [];

  if (params.pub_ListingType) {
    const splitted = params.pub_ListingType.split(',');
    if (splitted.length == 2) {
      promisify.push(sdk.listings.query({ ...params, pub_ListingType: splitted[0] }));
      promisify.push(sdk.listings.query({ ...params, pub_ListingType: splitted[1] }));
    } else {
      promisify.push(sdk.listings.query(params));
    }
  } else {
    promisify.push(sdk.listings.query(params));
  }
  
  return Promise.all(promisify)
    .then(response => {
      if (params.city) {
        getTransactions({
          tableName: "cities", query: {
            name: params.city
          }
        })
          .then(res => {
            const cities = JSON.parse(res.data);
            dispatch(fetchCitiesSuccess(cities));
            return cities;
          });
      } else {
        getTransactions({ tableName: "cities", query: {} })
          .then(res => {
            const cities = JSON.parse(res.data);
            dispatch(fetchCitiesSuccess(cities));
            return cities;
          });
      }
      dispatch(addMarketplaceEntities(response[0]));
      if (params.pub_ListingType) {
        const splitted = params.pub_ListingType.split(',');
        if (splitted.length == 2) {
          dispatch(addMarketplaceEntities(response[1]));
          dispatch(searchListingsSuccess(response[0], response[1]));
        } else {
          dispatch(searchListingsSuccess(response[0]));
        }
      } else {
        dispatch(searchListingsSuccess(response[0]));
      }
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
  // });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...params,
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'author.profileImage', 'images'],
    // 'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    // 'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x', 'variants.landscape-crop', 'variants.scaled-small'],
    'limit.images': 1,
  });
};
