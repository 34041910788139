import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import * as validators from '../../util/validators';

import { FieldCheckbox, FieldMultiSelect, FieldTextInput, Form, IconSpinner, LocationAutocompleteInputField, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';

const identity = v => v;

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      eventSizeOptions,
      addAnotherAddress,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    // if (!unitPrice) {
    //   return (
    //     <div className={classes}>
    //       <p className={css.error}>
    //         <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
    //       </p>
    //     </div>
    //   );
    // }
    // if (unitPrice.currency !== config.currency) {
    //   return (
    //     <div className={classes}>
    //       <p className={css.error}>
    //         <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
    //       </p>
    //     </div>
    //   );
    // }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
          } = fieldRenderProps;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                unitType,
                startDate,
                endDate,
                timeZone,
              }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          // eventSize
          const eventSizeLabel = intl.formatMessage({
            id: 'BookingTimeForm.eventSize',
          });
          const eventSizePlaceholder = intl.formatMessage({
            id: 'BookingTimeForm.eventSizePlaceholder',
          });
          const eventSizeRequiredMessage = intl.formatMessage({
            id: 'BookingTimeForm.eventSizeRequired',
          });
          const eventSizeRequired = validators.required(eventSizeRequiredMessage);

          // quotesDetails
          const quotesDetailsLabel = intl.formatMessage({
            id: 'BookingTimeForm.quotesDetailsLabel',
          });
          const quotesDetailsPlaceholder = intl.formatMessage({
            id: 'BookingTimeForm.quotesDetailsPlaceholder',
          });
          const quotesDetailsRequiredMessage = intl.formatMessage({
            id: 'BookingTimeForm.quotesDetailsRequired',
          });
          const quotesDetailsRequired = validators.required(quotesDetailsRequiredMessage);

          const titleRequiredMessage = intl.formatMessage({ id: 'BookingTimeForm.address' });
          const addressPlaceholderMessage = intl.formatMessage({
            id: 'BookingTimeForm.addressPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'BookingTimeForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'BookingTimeForm.addressNotRecognized',
          });

          const AddedInPrefferedListLabel = intl.formatMessage({
            id: 'BookingTimeForm.AddedInPrefferedListLabel',
          });

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              <div className={css.multiselectInput}>
                <FieldMultiSelect
                  isMulti={false}
                  isRequired={true}
                  className={css.field}
                  id={"eventSize"}
                  name={"eventSize"}
                  label={eventSizeLabel}
                  autoComplete={"eventSize"}
                  options={eventSizeOptions}
                  validate={eventSizeRequired}
                  // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
                  fontClass={css.fontClass}
                  placeholder={eventSizePlaceholder}
                />
              </div>

              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDatesText}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                />
              ) : null}

              <div className={css.inputBox}>
                <LocationAutocompleteInputField
                  className={css.locationAddress}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="location"
                  label={titleRequiredMessage}
                  placeholder={addressPlaceholderMessage}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={validators.composeValidators(
                    validators.autocompleteSearchRequired(addressRequiredMessage),
                    validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                />
                <p className={css.exactLocation}>*If you do not have an exact location, select the city or town.</p>
              </div>

              <FieldTextInput
                className={css.quotesDetailsRoot}
                type="textarea"
                id={'quotesDetails'}
                name="quotesDetails"
                label={quotesDetailsLabel}
                placeholder={quotesDetailsPlaceholder}
                validate={quotesDetailsRequired}
              />
              <div className={css.workcheckBox}>
                <FieldCheckbox
                  id={'AddedInPrefferedList'}
                  name="AddedInPrefferedList"
                  label={AddedInPrefferedListLabel}
                />
                <a onClick={() => { }} className={css.anotherAddressLink}>{addAnotherAddress}</a>
              </div>

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit">
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  eventSizeOptions: config.custom.eventType
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
