import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import {
    Button,
    IconCollection,
    ResponsiveImage,
} from '..';
import { ChatInputForm } from '../../forms';

import ChatCard from './ChatCard';
import ReviewCard from './ReviewCard';
import QuotesCard from './QuotesCard';
import InvoiceCard from './InvoiceCard';

import css from './SendBirdChat.module.css';

const TransactionChat = props => {
    const {
        _id,
        state,
        vendor,
        history,
        invoices,
        chatLogo,
        PageName,
        userType,
        statusEnum,
        createdDate,
        renderMedia,
        sendMessage,
        channelRef,
        mergedChats,
        currentUser,
        vendorResponse,
        eventDetailData,
        receiverDetails,
        isChannelUnlocked,
        onFileInputChange,
        currentUserDetails,
        receivedQuotesHeight,
        setVendorResponseModal,
        onManageDisableScrolling,
    } = props;

    const isCompleted = eventDetailData.eventDuration && eventDetailData.eventDuration == "not-determined"
        ? true
        : eventDetailData.eventDates && eventDetailData.eventDates.length
            ? moment(eventDetailData.eventDates[eventDetailData.eventDates.length - 1].end).isAfter(moment())
            : false;

    return (
        <div className={css.chatUi}>
            <div className={css.MessageLists}>
                {mergedChats && mergedChats.length
                    ? <div className={css.chatDateUpdate}>{moment(createdDate * 1000).format("LL")}</div>
                    : null}

                <div className={classNames(css.messageBox, mergedChats && mergedChats.length ? null : css.messageBox2)}>
                    {(mergedChats && mergedChats.length) || (receiverDetails && Object.keys(receiverDetails).length)
                        ? <div className={css.messageScroll} ref={channelRef} style={{ minHeight: receivedQuotesHeight ? (receivedQuotesHeight - 280) : 0 }}>
                            {isChannelUnlocked && mergedChats?.map(message => {
                                if (!message.sender && !message.txId) return null;

                                const index = invoices.findIndex(i => i._id == message._id);
                                const messageSentByYou = (message.vendor && message.vendor.id == currentUser.id.uuid) || (message.sender === currentUser.id.uuid) || (index > -1 && message.creatorId === currentUser.id.uuid);

                                return (index > -1
                                    ? <React.Fragment key={'TransactionChat_' + message._id}>
                                        {message.status == "ACCEPTED"
                                            ? <div className={classNames(css.leftMessage)}>
                                                {messageSentByYou
                                                    ? <div className={css.VendorSelectedFor}>
                                                        <p><FormattedMessage id="ChatPage.eventSelectedFor" /></p>
                                                    </div>
                                                    : <div className={css.VendorsideSuccess}>
                                                        {eventDetailData.categories && eventDetailData.categories.length
                                                            ? eventDetailData.categories.map(category => (<p key={'TransactionChat_' + category.label}>
                                                                <FormattedMessage id="ChatPage.plannerSelectedFor" />
                                                                {category.label + ' - ' + category.subCategory.map(subCat => subCat.label).join(', ')}
                                                            </p>))
                                                            : null}

                                                        <Button onClick={() => history.push(createResourceLocatorString
                                                            ('InvoiceDetailsPage',
                                                                routeConfiguration(),
                                                                { tab: 'create-invoice' }))}>
                                                            <FormattedMessage id="ChatPage.createInvoice" />
                                                        </Button>
                                                    </div>}
                                            </div>
                                            : null}
                                        <InvoiceCard
                                            history={history}
                                            message={message}
                                            vendor={vendor}
                                            userType={userType}
                                            currentUser={currentUser}
                                            messageSentByYou={messageSentByYou}
                                            currentUserDetails={currentUserDetails}
                                        />
                                        {isCompleted && message.payoutStatus && message.payoutStatus == "PAID"
                                            ? <ReviewCard
                                                history={history}
                                                message={message}
                                                vendor={vendor}
                                                userType={userType}
                                                currentUser={currentUser}
                                                eventDetailData={eventDetailData}
                                                messageSentByYou={messageSentByYou}
                                                currentUserDetails={currentUserDetails}
                                                onManageDisableScrolling={onManageDisableScrolling}
                                            />
                                            : null}
                                    </React.Fragment>
                                    : eventDetailData && message._id == _id
                                        ? <QuotesCard
                                            key={'QuotesCard-' + message._id}
                                            vendor={vendor}
                                            message={message}
                                            PageName={PageName}
                                            userType={userType}
                                            statusEnum={statusEnum}
                                            vendorResponse={vendorResponse}
                                            eventDetailData={eventDetailData}
                                            messageSentByYou={messageSentByYou}
                                            setVendorResponseModal={(e) => userType == 'planner' && setVendorResponseModal(eventDetailData)}
                                        />
                                        : <ChatCard
                                            key={'ChatCard-' + message._id}
                                            message={message}
                                            renderMedia={renderMedia}
                                            currentUser={currentUser}
                                            receiverDetails={receiverDetails}
                                            messageSentByYou={messageSentByYou}
                                            currentUserDetails={currentUserDetails}
                                        />);
                            })}

                            {state.uploading
                                ? (<div className={css.rightMessage}>
                                    <div className={css.chatProfileImage}>
                                        <ResponsiveImage
                                            alt='profile'
                                            url={currentUserDetails.profileImageURL} />
                                    </div>
                                    <div className={css.chatDisplayData}>
                                        <div className={css.nameDate}>{
                                            currentUserDetails.name
                                        }  <span>|  {moment().calendar().split(' ')[0] + ', ' + moment().format('LT')}</span> </div>
                                        <div className={css.messageDetail}>
                                            {renderMedia({})}
                                        </div>
                                    </div>
                                </div>)
                                : null}
                        </div>
                        : <div>
                            <div className={css.noEventCategoryImg}>
                                <div className={css.sectionHeader}>
                                    <h4><FormattedMessage id="ChatPage.myEvents" /></h4>
                                </div>
                                <div className={css.noMessageText}>
                                    <IconCollection name={"ICON_NOMESSAGE"} />
                                    <h2><FormattedMessage id="ChatPage.noEvents" /></h2>
                                </div>
                            </div>
                        </div>}
                    <div>
                        {isChannelUnlocked
                            ? <ChatInputForm
                                receiverName={receiverDetails.name}
                                inProgress={state.uploading}
                                onSubmit={sendMessage}
                                onFileInputChange={onFileInputChange}
                            />
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionChat;
