import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import { NamedLink, PrimaryButton, IconProfileSetup, ResponsiveImage } from '..';

import css from './SectionTopVendors.module.css';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="-0.5"
                    width="35"
                    height="35"
                    rx="17.5"
                    transform="matrix(1 0 0 -1 0 35)"
                    fill="#212121"
                    stroke="#212121"
                />
                <path
                    d="M20.3372 22.3274C20.2945 22.3786 20.2416 22.4204 20.1818 22.45C20.122 22.4796 20.0567 22.4965 19.9901 22.4995C19.9235 22.5025 19.8569 22.4916 19.7947 22.4675C19.7325 22.4434 19.6761 22.4065 19.6289 22.3594C19.5817 22.3122 19.5449 22.2557 19.5208 22.1935C19.4966 22.1313 19.4857 22.0648 19.4887 21.9981C19.4917 21.9315 19.5086 21.8662 19.5382 21.8065C19.5679 21.7467 19.6097 21.6938 19.6609 21.651L23.1115 18.1964H11.706C11.5829 18.1899 11.467 18.1364 11.3821 18.0469C11.2973 17.9574 11.25 17.8388 11.25 17.7155C11.25 17.5922 11.2973 17.4736 11.3821 17.3842C11.467 17.2947 11.5829 17.2412 11.706 17.2347H23.1115L19.6555 13.7841C19.5685 13.693 19.52 13.5719 19.52 13.4459C19.52 13.32 19.5685 13.1989 19.6555 13.1078C19.6998 13.0633 19.7525 13.028 19.8106 13.0039C19.8686 12.9798 19.9308 12.9674 19.9936 12.9674C20.0565 12.9674 20.1187 12.9798 20.1767 13.0039C20.2347 13.028 20.2874 13.0633 20.3318 13.1078L24.6061 17.3821C24.6516 17.4259 24.6878 17.4784 24.7125 17.5365C24.7373 17.5946 24.75 17.6571 24.75 17.7203C24.75 17.7834 24.7373 17.8459 24.7125 17.904C24.6878 17.9621 24.6516 18.0146 24.6061 18.0584L20.3372 22.3274Z"
                    fill="#ffffff"
                    stroke="#ffffff"
                />
            </svg>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    width="36"
                    height="36"
                    rx="18"
                    transform="matrix(-1 -1.74846e-07 -1.74846e-07 1 36 0)"
                    fill="#212121"
                />
                <path
                    d="M15.6628 13.6726C15.7055 13.6214 15.7584 13.5796 15.8182 13.55C15.878 13.5204 15.9433 13.5035 16.0099 13.5005C16.0765 13.4975 16.1431 13.5084 16.2053 13.5325C16.2675 13.5566 16.3239 13.5935 16.3711 13.6406C16.4183 13.6878 16.4551 13.7443 16.4792 13.8065C16.5034 13.8687 16.5143 13.9352 16.5113 14.0019C16.5083 14.0685 16.4914 14.1338 16.4618 14.1935C16.4321 14.2533 16.3903 14.3062 16.3391 14.349L12.8885 17.8036L24.294 17.8036C24.4171 17.8101 24.533 17.8636 24.6179 17.9531C24.7027 18.0426 24.75 18.1612 24.75 18.2845C24.75 18.4078 24.7027 18.5264 24.6179 18.6158C24.533 18.7053 24.4171 18.7588 24.294 18.7653L12.8885 18.7653L16.3445 22.2159C16.4315 22.307 16.48 22.4281 16.48 22.5541C16.48 22.68 16.4315 22.8011 16.3445 22.8922C16.3002 22.9367 16.2475 22.972 16.1894 22.9961C16.1314 23.0202 16.0692 23.0326 16.0064 23.0326C15.9435 23.0326 15.8813 23.0202 15.8233 22.9961C15.7653 22.972 15.7126 22.9367 15.6682 22.8922L11.3939 18.6179C11.3484 18.5741 11.3122 18.5216 11.2875 18.4635C11.2627 18.4054 11.25 18.3429 11.25 18.2797C11.25 18.2166 11.2627 18.1541 11.2875 18.096C11.3122 18.0379 11.3484 17.9854 11.3939 17.9416L15.6628 13.6726Z"
                    fill="white"
                    stroke="white"
                />
            </svg>
        </div>
    );
}

const SectionTopVendors = props => {
    const {
        className,
        rootClassName,
        city,
        history,
        topVendors,
        currentUser,
        categories,
        subCategories,
        isAuthenticated,
        onAddOrRemoveToConnected,
    } = props;
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);

    // Filter and map vendors
    const filteredVendors = topVendors.filter((item, i) => i < 8 && item.categoriesLabel && item.subCategoriesLabel);

    const settings = {
        speed: 800,
        slidesToShow: 4,
        arrows: true,
        infinite: filteredVendors.length > 4 ? true : false,
        dots: false,
        centerMode: false,
        slidesToScroll: 1,
        draggable: false,
        cssEase: 'linear',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: filteredVendors.length > 3 ? true : false,
                },
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 2,
                    infinite: filteredVendors.length > 2 ? true : false,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: filteredVendors.length > 1 ? true : false,
                },
            },
        ],
    };

    return (
        <div className={classes}>
            <div className={css.titleTop}>
                <h3>
                    <FormattedMessage id="SectionTopVendors.localVenues" values={{ city }} />
                </h3>
                <div>
                    <NamedLink
                        name={"AlgoliaSearchPage"}
                        className={css.seeAll}
                    >
                        <FormattedMessage id="SectionTopVendors.seeAll" />
                    </NamedLink>
                </div>
            </div>
            <div className={css.tredingFoodBox}>
                <Slider {...settings} className={css.swiperSlide}>
                    {topVendors.map((item, i) => {
                        const {
                            country,
                            city: hitCity,
                            rating = "5.0",
                            categories: hitCategories,
                            profileImageURL,
                            categoriesLabel,
                            subCategoriesLabel,
                            uploadedImagesUrls
                        } = item || {},
                            isAddedPreferredVendor = currentUser && currentUser.id && currentUser.attributes.profile.publicData.addPreferredVendor && currentUser.attributes.profile.publicData.addPreferredVendor.filter(w => w.id == item.objectID).length;

                        const hasImages = uploadedImagesUrls && uploadedImagesUrls.length;
                        const categoriesImages = hasImages
                            ? []
                            : categoriesLabel && subCategoriesLabel
                                ? [...categories.filter(c => categoriesLabel.includes(c.label)).map(c => c.image), ...subCategories.filter(sc => subCategoriesLabel.includes(sc.label)).map(sc => sc.image)]
                                : [];

                        const params = item.objectID
                            ? {
                                id: item.objectID,
                                city: item && item.location && item.location.address
                                    ? createSlug(item.location.address.split(',')[0])
                                    : null,
                                category: item.categoriesLabel
                                    ? createSlug(item.categoriesLabel[0])
                                    : "",
                                subcategory: item.subCategoriesLabel
                                    ? createSlug(item.subCategoriesLabel[0])
                                    : item.categoriesLabel
                                        ? createSlug(item.categoriesLabel[0])
                                        : "",
                                slug: createSlug(item.title || '')
                            }
                            : {};

                        return i < 8 && (categoriesLabel && subCategoriesLabel)
                            ? (
                                <div key={item.objectID + "-" + i} className={css.sliderCard} onClick={(e) => {
                                    e.preventDefault();
                                    history.push(createResourceLocatorString(
                                        params && params.id && params.city && params.category && params.subcategory ? 'ListingCityPage' : 'ListingPage',
                                        routeConfiguration(),
                                        params
                                    ));
                                }}>
                                    <div className={css.trendCard}>
                                        <div className={css.trendTop}>
                                            <ResponsiveImage
                                                url={uploadedImagesUrls && uploadedImagesUrls.length
                                                    ? uploadedImagesUrls[0].url
                                                    : (categoriesImages && categoriesImages.length && categoriesImages[0])}
                                                alt={item.title || 'vendor_profile'}
                                            />
                                            <span className={css.brandLogo}>
                                                <ResponsiveImage url={profileImageURL} className={css.logoBrand} alt={item.title || 'vendor_profile'} />
                                            </span>
                                        </div>
                                        <div className={css.trendingBody}>
                                            <h5>{item.title}</h5>
                                            <div className={css.categoriesText}>
                                                <div className={css.categoriesTextWrapper}>
                                                    <p>
                                                        {hitCategories && hitCategories.length && hitCategories[0].label
                                                            ? hitCategories[0].label
                                                            : null}
                                                        &nbsp; | </p>
                                                    <span><IconProfileSetup type="star" /> {rating} Stars</span>
                                                </div>
                                                {hitCity && country
                                                    ? <div className={css.city}>{hitCity + ', ' + country}</div>
                                                    : null}
                                            </div>
                                            <div>
                                                <p className={css.viewDetailButton}>
                                                    <FormattedMessage id="SectionTopVendors.viewDetails" /></p>
                                                {currentUser && isAuthenticated
                                                    ? <PrimaryButton
                                                        className={css.addList}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            isAddedPreferredVendor
                                                                ? onAddOrRemoveToConnected(item.objectID, isAddedPreferredVendor)
                                                                : onAddOrRemoveToConnected(item.objectID, isAddedPreferredVendor, item.title)
                                                        }}
                                                    >
                                                        {isAddedPreferredVendor
                                                            ? <>
                                                                <IconProfileSetup type='preferred-list' />
                                                                <FormattedMessage id="SectionTrendingVendors.addedPreferredList" />
                                                            </>
                                                            : <FormattedMessage id="SectionTrendingVendors.addPreferredList" />}
                                                    </PrimaryButton>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : null;
                    })}
                </Slider>
            </div>
        </div >
    );
};

const { node, string } = PropTypes;

SectionTopVendors.defaultProps = {
    className: null,
    rootClassName: null,
};

SectionTopVendors.propTypes = {
    className: string,
    rootClassName: string,
};

export default withRouter(SectionTopVendors);
