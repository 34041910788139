import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { string } from 'prop-types';
import { createSlug } from '../../util/urlHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import smalllogo from '../../assets/smalllogo.png';
import { NamedLink, ResponsiveImage } from '..';

import css from './FinishListing.module.css';

const FinishListing = props => {
    const {
        rootClassName,
        className,
        intl,
        logo,
        state,
        heading,
        subHeading,
        finishEvent,
        currentUser,
        currentUserListing,
        primaryButtonText,
        secondaryButtonText,
        anchorText,
        onUpdateProfile,
        subHeadingSecondLine,
        comingFrom = ''
    } = props;

    const classes = classNames(rootClassName || css.root, className);

    const { publicData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
    const { userType } = publicData || {};

    const params = {
        id: currentUserListing && currentUserListing.id && currentUserListing.id.uuid,
        city: currentUserListing && currentUserListing.id && currentUserListing.attributes.publicData.location && currentUserListing.attributes.publicData.location.address
            ? createSlug(currentUserListing.attributes.publicData.location.address.split(',')[0])
            : null,
        category: currentUserListing && currentUserListing.id
            ? currentUserListing.attributes.publicData.categoriesLabel
                ? createSlug(currentUserListing.attributes.publicData.categoriesLabel[0])
                : currentUserListing.attributes.publicData.categories
                    ? createSlug(currentUserListing.attributes.publicData.categories[0].label)
                    : ""
            : "",
        subcategory: currentUserListing && currentUserListing.id
            ? currentUserListing.attributes.publicData.subCategoriesLabel
                ? createSlug(currentUserListing.attributes.publicData.subCategoriesLabel[0])
                : currentUserListing.attributes.publicData.categories
                    ? createSlug(currentUserListing.attributes.publicData.categories[0].subCategory[0].label)
                    : ""
            : "",
        slug: (currentUserListing && currentUserListing.id && createSlug(currentUserListing.attributes.title)) || '',
    };

    const InnerData = () => {
        return (
            <>
                {comingFrom != 'listing' ?
                    <div className={css.loginCenterLogo}>
                        <ResponsiveImage className={css.chatImage} url={logo || smalllogo} alt='profile' />
                    </div> : null}
                {heading
                    ? <h4 className={css.tosterHeadingText}>{heading}</h4>
                    : null}
                <h2 className={css.headingText}>
                    {subHeading}{" "}
                    {subHeadingSecondLine}
                </h2>
                <div
                    className={css.BottomButtonBox}
                    onClick={() => {
                        console.log(typeof onUpdateProfile, '**** **** => typeof onUpdateProfile');
                        typeof onUpdateProfile == 'function' && onUpdateProfile({
                            publicData: {
                                completedStepDate: moment().unix()
                            }
                        })
                    }}
                >
                    <NamedLink
                        name={'REQUEST' == finishEvent
                            ? 'ManageListingsPage'
                            : 'PROFILE' == finishEvent
                                ? userType == 'planner'
                                    ? 'NewListingPage'
                                    : 'ProposalRequestsPage'
                                : 'ProfileSetupBasePage'}
                        params={params}
                        className={css.requestButton}
                    >
                        {primaryButtonText}
                    </NamedLink>
                    {'REQUEST' == finishEvent
                        ? null
                        : <NamedLink
                            name={'PROFILE' == finishEvent
                                ? userType == 'planner'
                                    ? 'ManageListingsPage'
                                    : 'ListingPage'
                                // params && params.id && params.city && params.category && params.subcategory
                                //     ? 'ListingCityPage'
                                //     : 'ListingPage'
                                : finishEvent == 'EVENT'
                                    ? 'ManageListingsPage'
                                    : params && params.id && params.city && params.category && params.subcategory
                                        ? 'ListingCityPage'
                                        : 'ListingPage'}
                            params={params}
                            className={css.borderButton}
                        >
                            {secondaryButtonText}
                        </NamedLink>}
                </div>
                {anchorText && typeof setModalState == 'function' ?
                    <div className={css.thanksText}>
                        <a onClick={() => setModalState(false)}>{anchorText}</a>
                    </div>
                    : null}
            </>
        )
    }

    return (
        comingFrom != 'listing' ?
            <div className={css.welcomeBackground}>
                <div className={css.content}>
                    <InnerData />
                </div>
            </div> :
            <div >
                <InnerData />
            </div>
    );
};

FinishListing.defaultProps = {
    rootClassName: null,
    className: null,
};

FinishListing.propTypes = {
    rootClassName: string,
    className: string,
    intl: intlShape.isRequired,
};

export default injectIntl(FinishListing);