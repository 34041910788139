import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { string } from 'prop-types';

import { ensureOwnListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import {
  NamedLink,
  IconCollection,
  ResponsiveImage,
} from '../../components';

import css from './EventLeftCard.module.css';

const EventLeftCard = props => {
  const {
    channel,
    viewport,
    channels = [],
    listings = [],
    transactions = [],
  } = props;

  const [expandList, setExpandList] = useState(true)
  const isMobile = viewport.width < 767;

  return (
    <div className={css.leftSidebar}>
      <div className={classNames(css.sidebarHeader, css.blueSidebarHeader)}>
        <h3>All Event Quotes</h3>
        {isMobile
          ? <p><FormattedMessage id={"EventLeftCard.subHeading"} /></p>
          : <p><FormattedMessage id={"EventLeftCard.subHeadingMobile"} /></p>}
        <span onClick={() => setExpandList(!expandList)} className={classNames(css.dropdownIcon, !expandList && css.expandIcon)}>
          <IconCollection name="DROPDOWN_ICON" />
        </span>
      </div>
      {(isMobile ? expandList : true)
        ? <div className={css.eventList}>
          {listings.map((listing, i) => {

            const currentListing = ensureOwnListing(listing);
            const eventId = currentListing.id.uuid;
            const { title = '', publicData } = currentListing.attributes;
            const { uploadedImagesUrls = [], eventTypeImageURL = '' } = publicData || {};
            const firstImage = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
            const requestedVendorEvents = transactions.filter(tx => {
              const eventDate = tx.eventDates && tx.eventDates.length ? moment(tx.eventDates[0].start) : moment();
              const isEventPassed = moment().unix() < moment(eventDate).unix();
              return eventId == tx.eventId && ['REQUESTED'].includes(tx.status) && isEventPassed;
            });

            let unreadCount = 0;
            channels && channels.length && requestedVendorEvents && requestedVendorEvents.length && channels.filter(channel => channel.type == 'transactional' && channel.members.filter(m => requestedVendorEvents.findIndex(event => event.vendorId == m.userId) > -1)).map(channel => unreadCount += channel.unreadCount);
            unreadCount += ((channel && channel.unreadCount) || 0);
            const slug = createSlug(title);

            return (requestedVendorEvents.length
              ? <NamedLink
                key={eventId + '-' + i}
                name={'EventDetailPage'}
                className={css.chatUsers}
                params={{ eventId, slug, tab: "quotes" }}
              >
                <div className={css.userImage}>
                  <ResponsiveImage
                    alt={title}
                    url={firstImage ? '' : (uploadedImagesUrls[0] || eventTypeImageURL)}
                    image={firstImage}
                    variants={['square-small', 'square-small2x']}
                  />
                </div>
                <div className={css.chatData}>
                  <div className={css.userName}>{title}</div>
                  <span className={css.messagesText}>
                    <FormattedMessage id={"EventLeftCard.newQuotes"} values={{ events: requestedVendorEvents.length }} />{unreadCount
                      ? ('| ' + unreadCount + " new messages")
                      : null}
                  </span>
                </div>
                {/* <span className={css.menuIcon}>
                <Menu
                  className={classNames(css.menu, css.cardIsOpen)}
                  contentPosition="left"
                  useArrow={false}
                  onToggleActive={isOpen => {
                    const listingOpen = isOpen ? { id: { uuid: null } } : null;
                    onToggleMenu(null);
                  }}
                  isOpen={MenuOpen}
                >
                  <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
                    <div className={css.iconWrapper}>
                      <IconCollection name="MENU_ICON" />
                    </div>
                  </MenuLabel>
                  <MenuContent rootClassName={css.menuContent}>
                    <MenuItem key="star" className={css.menuItem}>
                      Star
                    </MenuItem>
                    <MenuItem key="delete" className={css.menuItem}>
                      Delete
                    </MenuItem>
                  </MenuContent>
                </Menu>
              </span> */}
              </NamedLink>
              : null)
          })}
        </div>
        : null}
    </div>
  );
};

EventLeftCard.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

EventLeftCard.propTypes = {
  className: string,
  rootClassName: string,
};
export default injectIntl(EventLeftCard);