import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.module.css';
import roundLogo from '../../assets/smalllogo.png';
import { FormattedMessage } from 'react-intl';

const Tab = props => {
  const { className, id, disabled, text, selected, linkProps, pageName, completed, } = props;

  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.completedLink]: completed,
    [css.disabled]: disabled,
  });

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {pageName == 'EditListingPage' ? (
          completed ? (
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8.5" cy="8.5" r="8.5" fill="#D9D9D9" />
            </svg>
          ) : selected ? (
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8.5" cy="8.5" r="8.5" fill="#FFB169" />
            </svg>
          ) :
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle opacity="0.5" cx="8.5" cy="8.5" r="8.5" fill="#E6E6E6" />
            </svg>
        ) : null}
        <span className={selected ? css.activeTab : css.tabsNameBox}>{text}</span>
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const { className, rootClassName, tabRootClassName, tabs, name } = props;
  const selectedTab = tabs.filter((e) => e.selected);


  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {name == "EditListingPage" ?
        <div className={css.logoBox}>
          <img src={roundLogo} />
          <h3 className={css.selectedTabName}>
            {tabs.map(e => e.selected == true ? e.text : null)}
          </h3>
        </div>
        : null}
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return <Tab key={id} id={id} className={tabClasses} {...tab} />;
      })}
      {name == "EditListingPage" && selectedTab && selectedTab.length && selectedTab[0].text == "basics"
        ? <div className={css.tabsDescription}>
          <FormattedMessage id="TabNav.title" />
        </div>
        : null}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
