import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from './../../util/reactIntl';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: November 22, 2019</p> */}

      {/* <p>
        Thank you for using Yogatime! Every marketplace business needs Terms of Service and
        Privacy Policy agreements. To help you launch your marketplace faster, we've compiled 
        two templates you can use as a baseline for the agreements between your online marketplace 
        business and its users. You can access these templates at 
        https://www.sharetribe.com/docs/operator-guides/free-templates/
      </p> */}

      <h2><FormattedMessage id="TermsOfServicePage.term1Heading" /></h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term1Details" />
      </p>

      <h2><FormattedMessage id="TermsOfServicePage.term2Heading" /></h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term2Details" />
      </p>

      <h2><FormattedMessage id="TermsOfServicePage.term3Heading" /></h2>
      <ul style={{ paddingLeft: 20 }}>
        <li style={{ listStyle: "disc" }}>
          <FormattedMessage id="TermsOfServicePage.term3SubHeading1" values={{ accountFor: <b>Registration:</b> }} />
        </li>
        <li style={{ listStyle: "disc" }}>
          <FormattedMessage id="TermsOfServicePage.term3SubHeading2" values={{ accountFor: <b>Account Security:</b> }} />
        </li>
        <li style={{ listStyle: "disc" }}>
          <FormattedMessage id="TermsOfServicePage.term3SubHeading3" values={{ accountFor: <b>Account Termination:</b> }} />
        </li>
      </ul>
      <h2><FormattedMessage id="TermsOfServicePage.term4Heading" /></h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term4SubHeading" />
      </p>
      <ul style={{ paddingLeft: 20 }}>
        <li style={{ listStyle: "disc" }}>
          <FormattedMessage id="TermsOfServicePage.term4content1" />
        </li>
        <li style={{ listStyle: "disc" }}>
          <FormattedMessage id="TermsOfServicePage.term4content2" />
        </li>
        <li style={{ listStyle: "disc" }}>
          <FormattedMessage id="TermsOfServicePage.term4content3" />
        </li>
      </ul>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term5Heading" />
      </h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term5content" />
      </p>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term6Heading" />
      </h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term6content" />
      </p>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term7Heading" />
      </h2>
      <ul style={{ paddingLeft: 20 }}>
        <li style={{ listStyle: "disc" }}>
          <FormattedMessage id="TermsOfServicePage.term7SubHeading1" values={{ PaymentFor: <b>Fees:</b> }} />
        </li>
        <li style={{ listStyle: "disc" }}>
          <FormattedMessage id="TermsOfServicePage.term7SubHeading2" values={{ PaymentFor: <b>Payment Processing:</b> }} />
        </li>
      </ul>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term8Heading" />
      </h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term8content" />
      </p>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term9Heading" />
      </h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term9content" />
      </p>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term10Heading" />
      </h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term10content" />
      </p>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term11Heading" />
      </h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term11content" />
      </p>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term12Heading" />
      </h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term12content" />
      </p>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term13Heading" />
      </h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term13content" />
      </p>
      <h2>
        <FormattedMessage id="TermsOfServicePage.term14Heading" />
      </h2>
      <p>
        <FormattedMessage id="TermsOfServicePage.term14content" />
      </p>
      <hr />
      <p>
        <FormattedMessage id="TermsOfServicePage.termFooter" />
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
