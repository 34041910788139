import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import {
  Button,
  Form,
  FieldCheckbox,
  FieldTextInput,
  FieldMultiSelect,
  IconProfileSetup,
  FieldCurrencyInput,
} from '../../components';

import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    keepDirtyOnReinitialize={true}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        form,
        invalid,
        pristine,
        saveActionMsg,
        values,
        updated,
        updateInProgress,
        fetchErrors,
        onFetchCurrentCategories,
        categories,
        subCategories,
        isMobileLayout,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const minTranslationKey = isNightly
        ? 'EditListingPricingForm.minPricePerNight'
        : isDaily
          ? 'EditListingPricingForm.minPricePerDay'
          : 'EditListingPricingForm.minPricePerUnit';

      const maxTranslationKey = isNightly
        ? 'EditListingPricingForm.maxPricePerNight'
        : isDaily
          ? 'EditListingPricingForm.maxPricePerDay'
          : 'EditListingPricingForm.maxPricePerUnit';

      const minPricePerUnitMessage = intl.formatMessage({
        id: minTranslationKey,
      });

      const minPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.minPriceInputPlaceholder',
      });

      const minPriceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.minPriceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const miniPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.minPriceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const minPriceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(minPriceRequired, miniPriceRequired)
        : minPriceRequired;


      const maxPricePerUnitMessage = intl.formatMessage({
        id: maxTranslationKey,
      });

      const maxPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.maxPriceInputPlaceholder',
      });

      const maxPriceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.maxPriceRequired',
        })
      );

      // categories
      const categoriesLabel = intl.formatMessage({
        id: 'EditListingPricingForm.categories',
      });
      const categoriesPlaceholder = intl.formatMessage({
        id: 'EditListingPricingForm.categoriesPlaceholder',
      });
      const subCategoriesPlaceholder = intl.formatMessage({
        id: 'EditListingPricingForm.subCategoriesPlaceholder',
      });
      const categoriesRequiredMessage = intl.formatMessage({
        id: 'EditListingPricingForm.categoriesRequired',
      });
      const categoriesRequired = validators.required(categoriesRequiredMessage);

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      let isRequirementRequest = false;
      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const element = values[key];
          if (key.search('requirement-request') > -1 && element) {
            isRequirementRequest = true;
          }
        }
      }

      const priceError = {};
      let splitted = '';
      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key) && key.search('Price') > -1 && values[key] && values[key].amount) {
          const element = values[key].amount;
          if (key == (splitted + 'maxPrice') && element > values[splitted + 'minPrice'].amount) {
            priceError[splitted] = false;
          } else if (key == (splitted + 'minPrice') && element < values[splitted + 'maxPrice'].amount) {
            priceError[splitted] = false;
          } else if (key.search('maxPrice') > -1) {
            splitted = key.split('maxPrice')[0];
            priceError[splitted] = true;
          } else if (key.search('minPrice') > -1) {
            splitted = key.split('minPrice')[0];
            priceError[splitted] = true;
          }
        }
      }

      const priceErrorDisabled = priceError && Object.keys(priceError).length && Object.values(priceError).includes(true);
      const submitDisabled = invalid || disabled || submitInProgress || !!priceErrorDisabled; // || !isRequirementRequest;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const requestMessage = intl.formatMessage({ id: 'EditListingPricingForm.request' });
      const requestPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.requestPlaceholder',
      });
      const requestRequiredMessage = intl.formatMessage({
        id: 'EditListingPricingForm.requestRequired',
      });

      const notAvailaleLabel = intl.formatMessage({
        id: 'EditListingPricingForm.notAvailaleLabel'
      });
      const deliveryRequiredLabel = intl.formatMessage({
        id: 'EditListingPricingForm.deliveryRequiredLabel'
      });

      return (
        <Form onSubmit={(e) => handleSubmit(e).then(() => form.restart())} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          {/* Select the types of vendors your hope to hire */}
          <div className={css.multiSelectInput}>
            <FieldMultiSelect
              isMulti={true}
              isRequired={true}
              isSearchable={true}
              className={css.field}
              id={"categories"}
              name={"categories"}
              label={categoriesLabel}
              autoComplete={"categories"}
              options={categories}
              isMobileLayout={isMobileLayout}
              validate={categoriesRequired}
              onSelection={(e) => Array.isArray(e) ? onFetchCurrentCategories({ type: 'subCategories', parentIds: e.map(e => e.id) }) : onFetchCurrentCategories()}
              // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
              fontClass={css.fontClass}
              placeholder={categoriesPlaceholder}
            // defaultValue={values.categories || ""}
            />
            {/* <hr className={css.dividerLine} /> */}
          </div>

          {/* Event Rentals */}
          {values.categories && values.categories.length && subCategories && subCategories.length
            ? <div className={css.accordanceMainBox}>
              <hr className={css.dividerLine} />
              {values.categories.map((ca, ind) =>
              (<div key={ca + '-' + ind} className={css.categoriesBox}>
                {/* <h4>{ca.label}</h4> */}
                <div className={css.categoriesCheckbox}>
                  <FieldMultiSelect
                    isMulti={true}
                    isRequired={true}
                    isSearchable={true}
                    className={css.field}
                    id={ca.key}
                    name={ca.key}
                    label={ca.label}
                    options={subCategories.filter(sc => sc.parentId == ca.id)}
                    validate={categoriesRequired}
                    // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
                    fontClass={css.fontClass}
                    placeholder={subCategoriesPlaceholder}
                    defaultValue={values[ca.key] || ""}
                  />
                  <hr className={css.dividerLine} />
                  {values[ca.key]
                    ? values[ca.key].map((cat, i) => {
                      const minPrice = values[cat.label + "_minPrice"] || new Money(config.listingMinimumPriceSubUnits, config.currency);
                      const maxiPriceRequired = validators.moneySubUnitAmountAtLeast(
                        intl.formatMessage(
                          {
                            id: 'EditListingPricingForm.maxPriceTooLow',
                          },
                          {
                            minPrice: formatMoney(intl, minPrice),
                          }
                        ),
                        minPrice.amount
                      );
                      const maxPriceValidators = minPrice && minPrice.amount
                        ? validators.composeValidators(maxPriceRequired, maxiPriceRequired)
                        : null;
                      const requirementRequest = Object.keys(values).filter(v => v.search([cat.label + "_requirement-request"]) > -1);
                      return (
                        <div key={cat + '-' + i} className={css.accordanceBox}>
                          <details className={css.accordion}>
                            <summary className={css.accordionTitle}>{cat.label}</summary>
                            <div className={css.priceBoxWrap}>
                              {/* Minimum Budget */}
                              {values[cat.label + "_N/A"]
                                ? null
                                : <>
                                  <FieldCurrencyInput
                                    id={cat.label + "_minPrice"}
                                    name={cat.label + "_minPrice"}
                                    className={css.priceInput}
                                    label={minPricePerUnitMessage}
                                    placeholder={minPricePlaceholderMessage}
                                    currencyConfig={config.currencyConfig}
                                  // validate={minPriceValidators}
                                  />
                                  {/* Maximum Budget */}
                                  <FieldCurrencyInput
                                    id={cat.label + "_maxPrice"}
                                    name={cat.label + "_maxPrice"}
                                    className={css.priceInput}
                                    label={maxPricePerUnitMessage}
                                    placeholder={maxPricePlaceholderMessage}
                                    currencyConfig={config.currencyConfig}
                                    validate={maxPriceValidators}
                                  />
                                </>}

                              {/* N/A */}
                              <FieldCheckbox
                                id={cat.label + "_N/A"}
                                name={cat.label + "_N/A"}
                                label={notAvailaleLabel}
                                className={css.priceCheck}
                              />
                            </div>

                            <div className={css.requestMessage}>
                              {/* {requestMessage} */}
                              {requirementRequest.length
                                ? requirementRequest.map((v, i) => <FieldTextInput
                                  key={cat.label + "_requirement-request" + (parseInt(i) + 1)}
                                  id={cat.label + "_requirement-request" + (parseInt(i) + 1)}
                                  name={cat.label + "_requirement-request" + (parseInt(i) + 1)}
                                  className={css.title}
                                  type="text"
                                  // label={requestMessage}
                                  inputIcon={<span onClick={() => form.change((cat.label + "_requirement-request" + (parseInt(requirementRequest.length) + 1)), "")}><IconProfileSetup type="plus" /></span>}
                                  placeholder={requestPlaceholderMessage}
                                />)
                                : <FieldTextInput
                                  key={cat.label + "_requirement-request1"}
                                  id={cat.label + "_requirement-request1"}
                                  name={cat.label + "_requirement-request1"}
                                  className={css.title}
                                  type="text"
                                  label={requestMessage}
                                  inputIcon={<span onClick={() => form.change((cat.label + "_requirement-request" + 2), "")}><IconProfileSetup type="plus" /></span>}
                                  // validate={validators.required(requestRequiredMessage)}
                                  placeholder={requestPlaceholderMessage}
                                />}
                            </div>
                            <div className={css.deliveryCheck}>
                              <FieldCheckbox
                                id={cat.label + "_isDelivery"}
                                name={cat.label + "_isDelivery"}
                                label={deliveryRequiredLabel}
                              />
                            </div>
                          </details>
                        </div>
                      );
                    })
                    : null}
                </div>
                {/* <hr className={css.dividerLine} /> */}
              </div>)
              )}
            </div>
            : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
            <span className={css.arrowIcon}>
              <IconProfileSetup type="arrow" />
            </span>
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
