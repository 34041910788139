import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import Slider from "react-slick";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from '../../config';

import { createSlug } from '../../util/urlHelpers';
import { obfuscatedCoordinates } from '../../util/maps';
import { types as sdkTypes } from '../../util/sdkLoader';
import { camelCaseEventDuration } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import {
  Map,
  ExternalLink,
  IconCardDashboard,
  NamedLink,
  IconSpinner,
  ResponsiveImage,
} from '..';

import slick1 from '../../assets/slick1.png';
import trendingFood from '../../assets/trendingfood.png';

import { isScrollingDisabled } from '../../ducks/UI.duck';

import css from './ProposalDetails.module.css';

const { LatLng } = sdkTypes;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 23.448L12.4265 12.4266L1.40517 1.00011" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.2241 1L1.19603 12.4201L12.6161 23.4481" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}
const windowWidth = typeof window != 'undefined' && window.innerWidth;

const ProposalDetailsComponent = props => {
  const {
    intl,
    event,
    events,
    fetchEventInProgress,
    address = "New York",
  } = props;

  const {
    txId,
    title,
    theme,
    location,
    eventDates,
    eventDuration,
    createdAt,
    status,
    eventSize,
    eventType,
    eventFormat,
    categories,
    categoriesLabel,
    subCategoriesLabel,
    colorCodes = [],
    description,
    pinterestLink,
    optionalMessage,
    selectedColorCodes,
    uploadedImagesUrls,
    vendorResponse = {},
    plannerResponse = {},
    geolocation = new LatLng(37.43216, -120.778534),
  } = event || {};

  const slick = [slick1, trendingFood, slick1, trendingFood];

  const statusColorEnum = {
    REQUESTED: '#8B8000',
    ACCEPTED: 'green',
    DECLINED: 'red',
    PENDING: 'black'
  }

  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img className={css.smallImage} src={slick[i]} />
        </a>
      );
    },
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const mapProps = config.maps.fuzzy.enabled
    ? { obfuscatedCenter: obfuscatedCoordinates(geolocation) }
    : { address, center: geolocation };

  return (
    <div className={css.content}>
      {fetchEventInProgress
        ? <IconSpinner />
        : <>
          <div className={css.sectionHeader}>
            <h1>{title}</h1>

            <div className={css.evenData}>
              <span>{moment(createdAt * 1000).format('LL')}</span>
              {events && events.length
                ? <>
                  | <span><FormattedMessage id="ProposalDetails.requestedByYou" /></span>
                </>
                : null}
              {events && events.length
                ? null
                : <>
                  | <span><FormattedMessage id="ProposalDetails.bidAccepted" /></span>
                </>}
              {status
                ? <>
                  | <span style={{
                    color: statusColorEnum[status],
                    fontWeight: 'bolder'
                  }} >{status}</span>
                </>
                : null}
            </div>

            {uploadedImagesUrls && uploadedImagesUrls.length
              ? <div className={css.detailImageBox}>
                <ResponsiveImage url={uploadedImagesUrls[0].url}alt='profile' />
              </div>
              : null}
          </div>

          <div className={css.sectionDetailsEditBox}>
            <div className={css.editSectionTop}>
              <h3>
                <FormattedMessage id="ProposalDetails.details" />
              </h3>
              {/* <div>
            <NamedLink name="NewListingPage">
              <span className={css.signupText}>
                <FormattedMessage id="ProposalDetails.edit" />
              </span>
            </NamedLink>
          </div> */}
            </div>
            <div className={css.eventsMapDetails}>
              <div className={css.eventsData}>
                <ul>
                  {eventDates && eventDates.length
                    ? <>
                      <li>
                        <span className={css.eventHeading}><FormattedMessage id="ProposalDetails.eventDate" /></span>
                        <span className={css.boldEventsName}>{moment(eventDates[0].start).format("dddd, MMMM Do YYYY")}</span>
                      </li>
                      <li>
                        <span className={css.eventHeading}><FormattedMessage id="ProposalDetails.startTime" /></span>
                        <span className={css.boldEventsName}>{moment(eventDates[0].start).format("h:mm A")}</span>  |
                        <span className={css.eventHeading}><FormattedMessage id="ProposalDetails.wrapTime" /></span>
                        <span className={css.boldEventsName}>{moment(eventDates[0].end).format("h:mm A")}</span>
                      </li>
                    </>
                    : eventDuration
                      ? <li>
                        <span className={css.eventHeading}><FormattedMessage id="ProposalDetails.eventDate" /></span>
                        <span className={css.boldEventsName}>{camelCaseEventDuration(eventDuration)}</span>
                      </li>
                      : null}
                  <li>
                    <span className={css.eventHeading}>
                      <FormattedMessage id="ProposalDetails.location" />
                    </span>
                    <span className={css.boldEventsName}>{location?.address}</span>
                  </li>

                  {eventSize
                    ? <li>
                      <span className={css.eventHeading}><FormattedMessage id="ProposalDetails.guestEstimate" /></span>
                      <span className={css.boldEventsName}>
                        {(eventSize && eventSize.label ? eventSize.label : config.custom.eventSizes.find(e => e.key === eventSize)?.label)}
                      </span>
                    </li>
                    : null}

                  {eventType
                    ? <li>
                      <span className={css.eventHeading}><FormattedMessage id="ProposalDetails.eventType" /></span>
                      <span className={css.boldEventsName}>{eventType && eventType.label ? eventType.label : eventType}</span>
                    </li>
                    : null}
                  {eventFormat
                    ? <li>
                      <span className={css.eventHeading}><FormattedMessage id="ProposalDetails.eventFormat" /></span>
                      {eventFormat}
                    </li>
                    : null}
                </ul>
              </div>
              <div className={css.eventsMap}>
                <Map {...mapProps} />
              </div>
            </div>
            <div className={css.sectionRequirement}>
              <div className={css.editSectionTop}>
                <h3>
                  <FormattedMessage id="ProposalDetails.requirements" />
                </h3>
                {txId && !(plannerResponse && Object.keys(plannerResponse).length)
                  ? <div>
                    <NamedLink
                      name={'ProposalTabPage'}
                      params={{
                        txId,
                        slug: createSlug(title),
                        tab: 'request'
                      }}
                    >
                      <span className={css.signupText}>
                        <FormattedMessage id="ProposalDetails.edit" />
                      </span>
                    </NamedLink>
                  </div>
                  : null}
              </div>

              {subCategoriesLabel && subCategoriesLabel.length
                ? subCategoriesLabel.map(scl => <div className={css.requirementList} key={scl}>
                  <div className={css.listName}>
                    {scl}
                  </div>
                </div>)
                : null}
            </div>
            {categories && categories.length
              ? categories.map(c => c.subCategory && c.subCategory.length
                ? c.subCategory.map(sc => {
                  let currentRequirements = [];
                  Object.keys(sc.requirements)
                    .filter(key => key.startsWith('requirement-request'))
                    .map(key => currentRequirements.push(sc.requirements[key]));

                  currentRequirements = currentRequirements.filter(cr => (vendorResponse && vendorResponse.requirement && vendorResponse.requirement.includes(cr)));

                  return currentRequirements && currentRequirements.length
                    ? <div className={css.sectionEvent} key={sc.label}>
                      <div className={css.editSectionTopBox}>
                        <h3>
                          <FormattedMessage id="ProposalDetails.eventRentals" values={{ name: sc.label }} />
                        </h3>
                        {/* <div>
                        <NamedLink className={css.editButton} name="ProposalDetails">
                        <FormattedMessage id="ProposalDetails.update" />
                        </NamedLink>
                      </div> */}
                      </div>
                      <div className={css.eventsTop}>
                        <div className={css.eventsListName}>
                          <span className={css.eventHeading}><FormattedMessage id="ProposalDetails.budget" /></span>
                          <span className={css.textBold}>{sc.requirements.minPrice && sc.requirements.maxPrice
                            ? `$ ${(sc.requirements.minPrice / 100).toFixed(2)} - $ ${(sc.requirements.maxPrice / 100).toFixed(2)}`
                            : <FormattedMessage id="ProposalDetails.notMentioned" />}</span>
                        </div>
                        {sc.requirements.isDelivery
                          ? <div className={css.eventsListName}>
                            <span className={css.eventHeading}><FormattedMessage id="ProposalDetails.logistics" /></span>
                            <span className={css.textBold}><FormattedMessage id="ProposalDetails.deliveryRequired" /></span>
                          </div>
                          : null}
                      </div>
                      <div className={css.eventsRequestedList}>
                        <ul>
                          {currentRequirements.map(cr => <li key={cr}>
                            <span className={(vendorResponse && vendorResponse.requirement && vendorResponse.requirement.includes(cr)) ? css.vendorPass : css.vendorSkip}>{c.label} {" > "} {cr}</span>
                          </li>)}
                        </ul>
                      </div>
                    </div>
                    : null;
                })
                : null)
              : null}
            <div className={css.eventMoodboard}>
              {colorCodes && colorCodes.length
                ? <div className={css.editSectionTop}>
                  <h3>
                    <FormattedMessage id="ProposalDetails.eventMoodboard" />
                  </h3>
                  <div className={css.moodboardHead}>
                    <div className={css.selectedColorCombination}>
                      {colorCodes.map(cc => <span key={cc}>
                        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="19" cy="19" r="19" fill={cc} />
                        </svg>
                      </span>)}
                    </div>
                    {/* <NamedLink className={css.editButton} name="ProposalDetails">
                <FormattedMessage id="ProposalDetails.edit" />
              </NamedLink> */}
                  </div>
                </div>
                : null}
              <div className={css.moodboardBody}>
                <div className={css.bodyLeft}>
                  {pinterestLink
                    ? <ExternalLink
                      className={css.socialButton}
                      href={"pinterest"}
                      alt={"pinterest"}
                    >
                      <span className={css.pinterestIcon}>
                        <IconCardDashboard type="pinterest" />
                      </span>
                      <FormattedMessage id="ProposalDetails.pinterestLink" />
                    </ExternalLink>
                    : null}
                  {(selectedColorCodes && selectedColorCodes.length) || theme
                    ? <div>
                      {selectedColorCodes && selectedColorCodes.length
                        ? <div className={css.eventBox}>
                          <span className={css.eventName}><FormattedMessage id="ProposalDetails.textiles" /></span>
                          <span className={css.eventNameBold}>{selectedColorCodes.join(', ')}</span>
                        </div>
                        : null}
                      {theme
                        ? <div className={css.eventBox}>
                          <span className={css.eventName}><FormattedMessage id="ProposalDetails.theme" /></span>
                          <span className={css.eventNameBold}>{theme}</span>
                        </div>
                        : null}
                    </div>
                    : null}
                  {description || optionalMessage
                    ? <div className={css.hintMessage}>
                      <span className={css.hintIcon}><IconCardDashboard type="hint" /></span>
                      {description || optionalMessage}
                    </div>
                    : null}
                </div>
                {uploadedImagesUrls && uploadedImagesUrls.length
                  ? <div className={css.bodyRight}>
                    <div className={css.sliderWrapper}>
                      <Slider {...settings}>
                        {uploadedImagesUrls.map((st) => <div key={st}>
                          <ResponsiveImage url={st.url}  alt='profile' className={css.mainImage} />
                        </div>)}
                      </Slider>
                    </div>
                  </div>
                  : null}
              </div>
            </div>

          </div>
        </>}
    </div>
  );
};

const { bool, func } = PropTypes;

ProposalDetailsComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const ProposalDetails = compose(
  connect(mapStateToProps),
  injectIntl,
)(ProposalDetailsComponent);


export default ProposalDetails;
