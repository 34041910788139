import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, SecondaryButton } from '../../components';

import css from './FAQForm.module.css';

const KEY_CODE_ENTER = 13;
const TITLE_MAX_LENGTH = 100;
const DESCRIPTION_MAX_LENGTH = 500;

const FAQFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        updateInProgress,
        invalid,
        intl,
        values,
        onDelete,
        onSaveAndAddAnother,
      } = fieldRenderProps;

      // title
      const titleLabel = intl.formatMessage({
        id: 'FAQForm.titleTitle',
      });
      const titlePlaceholder = intl.formatMessage({
        id: 'FAQForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'FAQForm.titleRequired',
      });
      const titleRequired = validators.required(titleRequiredMessage);
      const titleMaxLengthMessage = intl.formatMessage(
        {
          id: 'FAQForm.titleTooLong',
        },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const titleMaxLength = validators.maxLength(
        titleMaxLengthMessage,
        TITLE_MAX_LENGTH
      );

      // description
      const descriptionLabel = intl.formatMessage({
        id: 'FAQForm.descriptionLabel',
      });
      const descriptionPlaceholder = intl.formatMessage({
        id: 'FAQForm.descriptionPlaceholder',
      });
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'FAQForm.descriptionRequired',
      });
      const descriptionRequired = validators.required(descriptionRequiredMessage);
      const descriptionMaxLengthMessage = intl.formatMessage(
        {
          id: 'FAQForm.descriptionTooLong',
        },
        {
          maxLength: DESCRIPTION_MAX_LENGTH,
        }
      );
      const descriptionMaxLength = validators.maxLength(
        descriptionMaxLengthMessage,
        DESCRIPTION_MAX_LENGTH
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <h2><FormattedMessage id={'FAQForm.heading'} /></h2>
          <div className={css.titleInput}>
            <FieldTextInput
              className={css.field}
              type="text"
              id={"question"}
              name={"question"}
              label={titleLabel}
              validate={validators.composeValidators(titleRequired, titleMaxLength)}
              placeholder={titlePlaceholder}
            />
          </div>

          <FieldTextInput
            className={css.descriptionRoot}
            type="textarea"
            id={'answer'}
            name={"answer"}
            label={descriptionLabel}
            placeholder={descriptionPlaceholder}
            validate={validators.composeValidators(descriptionRequired, descriptionMaxLength)}
          />

          <div className={css.bottomWrapper}>
            <PrimaryButton
              type="button"
              onClick={() => onSaveAndAddAnother(values)}
              disabled={submitDisabled}
              inProgress={submitInProgress}
            >
              <FormattedMessage id="FAQForm.saveAddAnotherButton" />
            </PrimaryButton>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="FAQForm.saveExitButton" />
            </PrimaryButton>
            <SecondaryButton
              type="button"
              onClick={() => onDelete(values)}
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="FAQForm.deleteButton" />
            </SecondaryButton>
          </div>
        </Form>
      );
    }}
  />
);

FAQFormComponent.defaultProps = { inProgress: false, eventsOptions: config.custom.eventType };

const { bool, func } = PropTypes;

FAQFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const FAQForm = compose(injectIntl)(FAQFormComponent);
FAQForm.displayName = 'FAQForm';

export default FAQForm;
