import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldRadioButton, FieldCheckbox, NamedLink } from '../../components';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
        form,
        pristine,
        onOpenTermsOfService,
        isLogin,
        loginOrSignupError
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // retry password
      const retryPasswordLabel = intl.formatMessage({
        id: 'SignupForm.retryPasswordLabel',
      });
      const retryPasswordPlaceholder = intl.formatMessage({
        id: 'SignupForm.retryPasswordPlaceholder',
      });
      const retryPasswordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.retryPasswordRequired',
      });
      const retryPasswordRequired = validators.requiredStringNoTrim(retryPasswordRequiredMessage);
      const retryPasswordValidators = validators.composeValidators(
        retryPasswordRequired,
        validators.validatePasswords(retryPasswordRequiredMessage, values.password)
      );
      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordValidMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordValid',
        });

      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordFormatValid = validators.passwordFormatValid(
        passwordValidMessage
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordFormatValid,
        passwordMinLength,
        passwordMaxLength,
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !values.agreedTC;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={() => {
            onOpenTermsOfService();
            setTimeout(() => form.change('agreedTC', true), 500);
          }}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      //checkbox
      const agreedTCLabel = intl.formatMessage({
        id: 'SignupForm.termsAndConditionsAcceptText',
      }, { termsLink });
      const agreedTCValidateMessage = intl.formatMessage({
        id: 'SignupForm.termsAndConditionsAcceptTextValidate',
      })
      const agreedTCValidate = validators.required(agreedTCValidateMessage);

      const plannerAgreedLabel = intl.formatMessage({
        id: 'SignupForm.plannerAgree',
      });
      const plannerAgreeValidateMessage = intl.formatMessage({
        id: 'SignupForm.plannerAgreeValidate',
      })
      const plannerAgreeValidate = validators.required(plannerAgreeValidateMessage);

      const helpFulContentLabel = intl.formatMessage({
        id: 'SignupForm.helpFulContent',
      });
      const helpFulContentValidateMessage = intl.formatMessage({
        id: 'SignupForm.helpFulContentValidate',
      })
      const helpFulContentValidate = validators.required(helpFulContentValidateMessage);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.signupFirstWrapper}>
            <h2 className={css.signupHeading}>
              <FormattedMessage id="SignupForm.title" />
            </h2>
            <h2 className={css.signupHeadingMobile}>
              <span>
                <FormattedMessage id="SignupForm.titlefirstLine" />
              </span>
              <span>
                <FormattedMessage id="SignupForm.titlesecondLine" />
              </span>
              <span>
                <FormattedMessage id="SignupForm.titlethirdLine" />
              </span>
            </h2>
            <h3 className={css.signupSubHeading}>
              <FormattedMessage id="SignupForm.userTypeTitle" />
            </h3>
            <div className={css.selectRadioButtons}>
              <FieldRadioButton
                className={css.radioBox}
                id={formId ? `${formId}.vender` : 'vender'}
                name="userType"
                label={intl.formatMessage({ id: 'SignupForm.vendor' })}
                value="vendor"
                checkedClassName={css.checkedAvailable}
                showAsRequired={pristine}
              />
              <FieldRadioButton
                className={css.radioBox}
                id={formId ? `${formId}.venue` : 'venue'}
                name="userType"
                label={intl.formatMessage({
                  id: 'SignupForm.venue',
                })}
                value="venue"
                checkedClassName={css.checkedNotAvailable}
                showAsRequired={pristine}
              />
              <FieldRadioButton
                className={css.radioBox}
                id={formId ? `${formId}.planner` : 'planner'}
                name="userType"
                label={intl.formatMessage({
                  id: 'SignupForm.planner',
                })}
                value="planner"
                checkedClassName={css.checkedNotAvailable}
                showAsRequired={pristine}
              />
            </div>
            <hr className={css.dividerLine} />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoFocus
                autoComplete="given-name"
                label={firstNameLabel}
                // placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                // placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <FieldTextInput
              className={css.emailInput}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              // placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <div className={css.passwordWrapper}>
                <FieldTextInput
                  className={css.inputBox}
                  type={values.passwordType ? "text" : "password"}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  // placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />
                <span
                  type="button"
                  className={css.passwordShow}
                  onClick={() => form.change('passwordType', !values.passwordType)}
                >
                  {intl.formatMessage({ id: values.passwordType ? "SignupForm.hideText" : "SignupForm.showText" })}
                </span>
              </div>
              <div className={css.passwordWrapper}>
                <FieldTextInput
                  className={css.inputBox}
                  type={values.retryPasswordType ? "text" : "password"}
                  id={formId ? `${formId}.retryPassword` : 'retryPassword'}
                  name="retryPassword"
                  autoComplete="new-password"
                  label={retryPasswordLabel}
                  // placeholder={retryPasswordPlaceholder}
                  validate={retryPasswordValidators}
                />
                <span
                  type="button"
                  className={css.passwordShow}
                  onClick={() => form.change('retryPasswordType', !values.retryPasswordType)}
                >
                  {intl.formatMessage({ id: values.retryPasswordType ? "SignupForm.hideText" : "SignupForm.showText" })}
                </span>
              </div>
            </div>
          </div>

          <div className={css.bottomWrapper}>
            <FieldCheckbox
              id={formId ? `${formId}.agreedTC` : 'agreedTC'}
              name="agreedTC"
              label={agreedTCLabel}
              validate={agreedTCValidate}
              className={css.singupcheckLink}
            />
            <FieldCheckbox
              id={formId ? `${formId}.helpfulContent` : 'helpfulContent'}
              name="helpfulContent"
              label={helpFulContentLabel}
              className={css.singupcheck}
            />
            {loginOrSignupError}
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              className={css.submitButton}
            >
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
            {isLogin ? (
              <p className={css.authLinksLogin}>
                <FormattedMessage id="AuthenticationPage.DontHaveAnAccount" />
                <NamedLink name="SignupPage" className={css.memberFreeText}>
                  <FormattedMessage id="SignupPage.BecomeMemberForFree" />
                </NamedLink>
              </p>
            ) : (
              <p className={css.authLinksSignup} >
                <span className={css.loginWrapperText}>
                  {values.userType == "planner" ?
                    <FormattedMessage id="SignupForm.alreadyVendor" /> :
                    <FormattedMessage id="SignupForm.Alreadyhaveanaccount" />
                  }
                </span>
                <NamedLink name="LoginPage" >
                  {intl.formatMessage({ id: 'SignupForm.loginLabel' })}
                </NamedLink>
              </p>
            )}
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
