import React, { useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { ValidationError } from '../../components';

import css from './FieldMultiSelect.module.css';

export const customSelectStyles = {
  menuList: styles => ({
    ...styles,
    background: '#eee',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? 'hsla(291, 64%, 42%, 0.5)'
      : isSelected
        ? 'hsla(291, 64%, 42%, 1)'
        : undefined,
    zIndex: 1,
  }),
  menu: base => ({
    ...base,
    zIndex: 100,
    background: "#FFB169"
  }),
  control: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: '#fff',
    minHeight: '45px',
    boxShadow: "none",
    borderColor: "#939393 !important",
    cursor: "pointer"
  }),
  valueContainer: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#303030' : '#B2B2B2',
    height: '100%',
    paddingLeft: '10px',
    margin: '0px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.05em',
    color: '#212121',
    fontSize: '16px',
    fontFamily: 'Montserrat',
  }),
  placeholder: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: '#595858',
    // paddingLeft: '15px',
    margin: '0px',
    fontWeight: '400',
  }),
  indicatorsContainer: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    padding: '0px',
    margin: '0px',
    color: isSelected ? "#000" : "#000",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '0px',
    margin: '0px',
    transition: 'all .2s ease',
    transform: state.isFocused.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  input: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#303030' : '#B2B2B2',
    height: '100%',
    padding: '0px',
    margin: '0px',
    fontWeight: '500',
  }),
  indicatorSeparator: styles => ({ display: 'none', padding: '0px', margin: '0px' }),
  option: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: '#303030',
    padding: '15px',
    fontSize: '15px',
    lineHeight: '100%',
    letterSpacing: '-0.04em',
    cursor: 'pointer !important',
    borderLeft: '1px solid #eee',
    borderRight: '1px solid #eee',
    // boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    backgroundColor: isDisabled ? null : isFocused ? '#FFB169' : isSelected ? '#FFF' : null,
  }),
  singleValue: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#303030' : '#303030',
    fontWeight: '400',
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    backgroundColor: '#fff',
    color: '#303030',
    position: 'absolute',
    top: '100%',
    left: '0',
    right: '0',
    width: '100%',
    minWidth: '150px',
    padding: '0px',
  }),
  // menuList: base => ({
  //   ...base,
  //   padding: '0px',
  //   backgroundColor: '#fff',
  //   fontSize: '15px',
  //   lineHeight: '100%',
  //   letterSpacing: '-0.04em',
  //   color: '#303030',
  // }),
  dropdownIndicator: base => ({
    ...base,
    color: '#303030',
  }),
};
const FieldMultiSelect = props => {
  const selectRef = useRef(null);  // Step 1: Create a ref
  const [customInput, setCustomInput] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSelected, setSelected] = useState(false);

  // REACT-SELECT DOESNT PLAY WELL WITH SSR
  // IF NO WINDOW DONT RENDER
  if (typeof window === 'undefined') {
    return null;
  }
  // CONDITIONALLY IMPORT SELECT MODULES
  const { default: Select, components } = require('react-select'); // eslint-disable-line global-require
  const {
    validate,
    name,
    options,
    label,
    className,
    placeholder,
    meta,
    isMulti,
    isSearchable,
    isCustomValue,
    isClearable,
    isRequired,
    disabled,
    defaultValue,
    customErrorText,
    onSelection,
    isMobileLayout,
    ...rest
  } = props;

  const status =
    isSelected && isRequired && !selectedOptions
      ? css.error
      : selectedOptions && selectedOptions.value !== ''
        ? css.success
        : css.attention;

  const Option = props => (
    <div>
      <components.Option {...props}>
        <label className={css.multiselectValueLabel}>{props.label}</label>
      </components.Option>
    </div>
  );

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span className={css.multiSelectLabel}>{props.data.label}</span>
    </components.MultiValue>
  );
  const renderSelect = typeof window !== 'undefined';

  return options && renderSelect ? (
    <Field name={name} validate={validate}>
      {props => {
        const { input, meta } = props;
        // PULLING INPUT ONCHANGE OUT OF OBJECT SO THAT WE CAN IMPLEMENT OUT OWN
        const { onChange, ...rest } = input;
        const { invalid, touched, error } = meta;
        const errorText = customErrorText || error;

        // Error message and input error styles are only shown if the
        // field has been touched and the validation has failed.
        const hasError = !!customErrorText || !!(touched && invalid && error);

        const fieldMeta = { touched: hasError, error: errorText };

        return (
          <div className={className}>
            {label ? <label className={css.gap}>{label}</label> : null}
            <div className={status}>
              <Select
                // closeMenuOnSelect={!isMulti}
                ref={selectRef}  // Step 2: Attach the ref to Select
                hideSelectedOptions={false}
                isMulti={isMulti}
                components={[Option, MultiValue]}
                defaultValue={
                  defaultValue && Object.keys(defaultValue).length ? defaultValue : undefined
                }
                options={options}
                isClearable={isClearable}
                isSearchable={isSearchable}
                styles={customSelectStyles}
                isDisabled={disabled}
                placeholder={placeholder}
                selected={selectedOptions}
                getOptionValue={value => {
                  setSelected(true);
                  return value.value || value.lable;
                }}
                {...rest}
                onBlur={() => {
                  input.onBlur(input.value);
                  typeof onSelection == 'function' && onSelection(selectedOptions);
                }}
                onChange={item => {
                  setSelectedOptions(item);
                  input.onChange(item);
                  // isMobileLayout && 
                  typeof onSelection == 'function' && onSelection(item);
                }}
                onInputChange={(inputValue) => {
                  isCustomValue && setCustomInput(inputValue);
                }}
                noOptionsMessage={({ inputValue }) => (isCustomValue && inputValue
                  ? <div
                    className=" css-azlokv-option"
                    aria-disabled="false"
                    id="react-select-3-option-0"
                    tabindex="-1"
                    onClick={() => {
                      if (customInput) {
                        const newOption = {
                          option: customInput.toLowerCase().replace(/\s+/g, '_'),
                          label: customInput
                        };
                        input.onChange(newOption);
                        setCustomInput('');
                        // Step 3: Close the menu after adding the option
                        selectRef.current?.blur();
                      }
                    }}
                  >
                    Add {inputValue}
                  </div>
                  : "No options")}
              />

              <ValidationError fieldMeta={fieldMeta} />
            </div>
          </div>
        );
      }}
    </Field>
  ) : null;
};

export default FieldMultiSelect;
