import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import * as validators from '../../util/validators';
import { FormattedMessage } from '../../util/reactIntl';
import { fetchEventCategoryData } from '../../util/data';

import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  SecondaryButton,
  IconCardDashboard,
} from '../../components';

import css from './PlannerBidReviewForm.module.css';

const PlannerBidReviewForm = (props) => {
  const { categories, onSubmit, requirementQuotes } = props;

  return (
    <FinalForm
      {...props}
      onSubmit={onSubmit}
      render={fieldRenderProps => {
        const {
          intl,
          handleSubmit,
          invalid,
          values,
          declinedInProgress,
          acceptedInProgress,
        } = fieldRenderProps;

        const checkBoxRequired = validators.required(intl.formatMessage({
          id: 'PlannerBidReviewForm.checkMessageRequired',
        }));
        const submitInProgress = declinedInProgress || acceptedInProgress;
        const submitDisabled = invalid || submitInProgress || !values || (values && Object.keys(values).filter(v => v && values[v] && Array.isArray(values[v]) ? values[v].length : Object.keys(values[v]).filter(d => values[v][d]).length).length == 0);
        
        return (
          <Form onSubmit={handleSubmit} className={css.eventForm}>
            {/* Loop over categories */}
            {categories && categories.length
              ? categories.map((category, index) => (
                <div key={index} className={css.categoryContainer}>
                  <div>
                    {/* Loop over subcategories */}
                    {category.subCategory && category.subCategory.map(subCat => {
                      // Dynamically add `css.active` class based on checkbox value
                      const { requirements } = fetchEventCategoryData(categories);
                      const isSelectedByVendor = requirements && requirements.length
                        ? requirementQuotes[subCat.key] && requirements.findIndex(requirement => requirementQuotes[subCat.key].requirements.includes(requirement)) > -1
                        : requirementQuotes[subCat.key] && requirementQuotes[subCat.key].requirements.includes(subCat.label);
                      const isChecked = values[`${subCat.key}`];

                      return isSelectedByVendor
                        ? (<div
                          key={category.label + ' > ' + subCat.label}
                          className={classNames(css.requirementBox, { [css.active]: isChecked })}
                        >
                          <div className={css.requirementSection}>
                            <div className={css.ChekcBoxItem}>
                              {requirements && requirements.length == 0
                                ? <FieldCheckbox
                                  disabled={true}
                                  id={subCat.key}
                                  name={subCat.key}
                                  label={''}
                                  // value={subCat.label}
                                  className={css.checkboxItem}
                                  validate={checkBoxRequired}
                                />
                                : null}
                              <b>{category.label + ' > ' + subCat.label}</b>
                            </div>

                            <div>
                              <span><FormattedMessage id="EventDetailPage.quoteEstimate" />:</span>
                              <span className={css.boldText}>
                                {requirementQuotes[subCat.key].quote
                                  ? '$' + (requirementQuotes[subCat.key].quote / 100)
                                  : 'N/A'}  &nbsp;
                              </span>
                            </div>

                            <span className={css.icon}>
                              <IconCardDashboard type="delevery" /> &nbsp;
                            </span>
                            <span>
                              <FormattedMessage id="EventRequest.logistics" />
                            </span>
                            <span className={css.boldText}>
                              <FormattedMessage id={subCat.requirements.isDelivery ? 'EventRequest.deliveryRequired' : 'EventRequest.deliveryNotRequired'} />
                            </span>
                          </div>

                          {/* Checkboxes for each subcategory requirement */}

                          {requirements && requirements.length
                            ? <>
                              <p><FormattedMessage id="EventRequest.vendorServices" /></p>
                              <div className={css.checkboxWrapper}>
                                {requirements.map(requirement => requirementQuotes[subCat.key].requirements.includes(requirement)
                                  ? <FieldCheckbox
                                    disabled={true}
                                    key={requirement}
                                    label={requirement}
                                    className={css.checkboxItem}
                                    id={requirement != subCat.key ? subCat.key + '.' + requirement : subCat.key}
                                    name={requirement != subCat.key ? subCat.key + '.' + requirement : subCat.key}
                                  />
                                  : null)}
                              </div>
                            </>
                            : null}
                        </div>)
                        : null;
                    })}
                  </div>
                </div>
              ))
              : null}
            <div className={css.CategoryBottomButton}>
              <PrimaryButton
                className={css.yellowButton}
                type={'submit'}
                disabled={submitDisabled}
                inProgress={acceptedInProgress}
              >
                <IconCardDashboard type="checked" />
                <FormattedMessage id="EventDetailPage.selectVendorRespond" />
              </PrimaryButton>
              <SecondaryButton
                type={'button'}
                inProgress={declinedInProgress}
                onClick={() => handleSubmit('DECLINED')}
              >
                <FormattedMessage id="EventDetailPage.declineOffer" />
              </SecondaryButton>
            </div>
          </Form>
        )
      }}
    />
  );

};

export default PlannerBidReviewForm;