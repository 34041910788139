import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import config from '../../config';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm } from 'react-final-form';
// import { parseToFromSharetribe } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldMultiSelect, FieldCheckboxGroup, IconSpinner, ToolTip } from '../../components';

import css from './VendorCategoryForm.module.css';

const VendorCategoryFormComponent = props => {
  const [iniVal, SetIniVal] = useState({});

  return (
    <FinalForm
      {...props}
      initialValues={{ ...props.initialValues, ...iniVal }}
      keepDirtyOnReinitialize={true}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          updateInProgress,
          invalid,
          intl,
          values,
          categories,
          subCategories,
          subChildCategories,
          onSelectSubCategory,
          categoriesInProgress,
          currentUser,
          isMobileLayout,
          onFetchCurrentCategories,
        } = fieldRenderProps;

        // categories
        const categoriesLabel = (
          <label>
            <FormattedMessage id='VendorCategoryForm.categories' />
            {' '}
            <ToolTip text={intl.formatMessage({ id: "VendorCategoryForm.categoryTooltip" })} />
          </label>
        )
        const categoriesPlaceholder = intl.formatMessage({
          id: 'VendorCategoryForm.categoriesPlaceholder',
        });
        const categoriesRequiredMessage = intl.formatMessage({
          id: 'VendorCategoryForm.categoriesRequired',
        });
        const categoriesRequired = validators.required(categoriesRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = updateInProgress;
        const { userType } = currentUser.attributes?.profile?.publicData || {}
        const categoriesCheck = values.categories && values.categories.length && values.categories.filter(c => values[c.key]).length == values.categories.length;
        const submitDisabled = invalid || submitInProgress || !categoriesCheck;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {userType == 'venue'
              ? null
              : <div className={css.multiSelectInput}>
                <FieldMultiSelect
                  isMulti={true}
                  isRequired={true}
                  isSearchable={true}
                  className={css.field}
                  id={"categories"}
                  name={"categories"}
                  label={categoriesLabel}
                  autoComplete={"categories"}
                  options={userType == 'vendor' ? categories.filter(c => c.value != "venues") : categories}
                  validate={categoriesRequired}
                  isMobileLayout={isMobileLayout}
                  onSelection={(e) => Array.isArray(e)
                    ? [onFetchCurrentCategories({ type: 'subCategories', parentIds: e.map(e => e.id) }), SetIniVal({ ...values })]
                    : onFetchCurrentCategories()}
                  // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
                  fontClass={css.fontClass}
                  placeholder={categoriesPlaceholder}
                  defaultValue={values.categories || ""}
                />
                <hr className={css.dividerLine} />
              </div>}

            {categoriesInProgress == 'subCategories' && !subCategories
              ? <IconSpinner />
              : values.categories && values.categories.length && subCategories && subCategories.length
                ? <div>
                  {values.categories.map((cat, i) => (
                    <div key={cat + '-' + i} className={css.categoriesBox}>
                      <FieldMultiSelect
                        isMulti={true}
                        isRequired={true}
                        isSearchable={true}
                        className={css.field}
                        id={cat.key}
                        name={cat.key}
                        label={userType == 'venue'
                            ? intl.formatMessage({ id: "VendorCategoryForm.selectAllCategory" })
                            : cat.label}
                        options={subCategories.filter(sc => sc.parentId == cat.id)}
                        validate={categoriesRequired}
                        isMobileLayout={isMobileLayout}
                        onSelection={(e) => [onSelectSubCategory(e), SetIniVal({ ...values })]}
                        // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
                        fontClass={css.fontClass}
                        placeholder={categoriesPlaceholder}
                        defaultValue={values[cat.key] || ""}
                      />
                    </div>
                  ))}
                  <hr className={css.dividerLine} />
                </div>
                : null}

            {categoriesInProgress == 'subChildCategories' && userType == 'venue' && !subChildCategories
              ? <IconSpinner />
              : values.categories && values.categories.length && userType == 'venue'
                ? <div>
                  {values.categories.filter(c => values[c.key]).map(ca => values[ca.key].map((cat, i) => {
                    const filtered = subChildCategories.filter(scc => scc.parentId == cat.id);
                    return (
                      <div key={cat + '-' + i} className={css.categoriesBox}>
                        <h4>{cat.label}</h4>
                        <p className={css.selectAllHeading}>
                          <FormattedMessage id="VendorCategoryForm.selectAllThatApply" />
                        </p>
                        <div className={css.categoriesCheckbox}>
                          <FieldCheckboxGroup
                            className={css.features}
                            id={cat.key}
                            name={cat.key}
                            options={filtered}
                          />
                        </div>
                      </div>
                    )
                  }))}
                </div>
                : null}

            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              className={css.submitButton}
            >
              <FormattedMessage id="VendorCategoryForm.submit" />
            </PrimaryButton>

          </Form>
        );
      }}
    />
  );
};

VendorCategoryFormComponent.defaultProps = { inProgress: false, categoriesOptions: config.custom.categories, };

const { bool, func } = PropTypes;

VendorCategoryFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const VendorCategoryForm = compose(injectIntl)(VendorCategoryFormComponent);
VendorCategoryForm.displayName = 'VendorCategoryForm';

export default VendorCategoryForm;
