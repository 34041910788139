import React from 'react';
import Slider from "react-slick";
import { FormattedMessage } from "../../util/reactIntl";
import { BorderButton, IconCardDashboard } from "../../components";

import css from './AcornsCard.module.css';

const AcornsCard = props => {
  const {
    packages,
    onSubmit,
  } = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrow: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className={css.contentMain}>
      <Slider {...settings}>
        {packages.map((pack, i) => {
          const { name, description, amount, noOfAcorns, features = [] } = pack;
          delete pack._id;
          return (<div className={css.card} key={name + i}>
            <h3 className={css.packageName}>{name}</h3>
            <div className={css.packagePrice}>
              <FormattedMessage id="AcornsCard.acorns" values={{noOfAcorns}} />
            </div>
            <div className={css.acornsCount}>
              ${amount} (${(amount/noOfAcorns).toFixed(2)} per Acorn)
            </div>
            <BorderButton
              onClick={() => onSubmit(pack)}
            >
              <FormattedMessage id="AcornsCard.purchase" />
            </BorderButton>
            {/* <div className={css.dividerLine} />
            {features && features.length
              ? <ul>
                {features.map(feature => <li key={feature}>
                  <span className={css.checkIcon}>
                    <IconCardDashboard type="checkbox" />
                  </span>
                  {feature}
                </li>)}
              </ul>
              : null}
            <p className={css.acornsDescription}>{description}</p> */}
          </div>);
        })}
      </Slider>
    </div>
  );
};

export default AcornsCard;
