import React from 'react';
import { bool, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './OwnListingLink.module.css';
// its my code please dont remove it @anurag sirji
const OwnListingLink = props => {
  const { className, listing, listingFetched, children } = props;

  if (!listingFetched) {
    return null;
  }

  if (!listing) {
    return (
      <NamedLink className={className ? className : css.defaultLinkStyle} name="NewListingPage">
        {children ? children : <FormattedMessage id="OwnListingLink.addYourListingLink" />}
      </NamedLink>
    );
  }

  return (
    <NamedLink
      className={className ? className : css.yourListingsLink}
      name='ProposalRequestsPage'
    >
      <span className={css.menuItemBorder} />
      {children ? children : <FormattedMessage id="OwnListingLink.editYourListingLink" />}
    </NamedLink>
  );
};

OwnListingLink.defaultProps = {
  className: null,
  listing: null,
  listingFetched: false,
  children: null,
};

OwnListingLink.propTypes = {
  className: string,
  listing: propTypes.ownListing,
  listingFetched: bool,
  children: object,
};

export default OwnListingLink;
