import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { InlineTextButton, ListingLink } from '../../components';

import css from './EditListingPhotosPanel.module.css';

class EditListingPhotosPanel extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
      eventTypes,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes || {};
    const { uploadedImagesUrls = [] } = publicData || {};
    const imageIds = images && images.length ? images.map(image => image.id.uuid) : [];
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPhotosPanel.titleName"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingPhotosPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
    );

    return (
      <div className={classes}>
        <h1 className={css.title}>
          {images.length > 0
            ? panelTitle
            : <FormattedMessage id="EditListingPhotosPanel.titleNameBlank" />}
          <InlineTextButton
            className={css.skipBtn}
            onClick={() => {
              const eventTypeImageURL = eventTypes && eventTypes.length && publicData.eventType
                ? eventTypes.find(et => et.name == publicData.eventType).image
                : '';
              onSubmit({
                id: listing.id,
                images: (images && images.length ? images.filter(img => !img.imageId) : []),
                ...{
                  ...publicData,
                  eventTypeImageURL,
                  uploadedImagesUrls: (uploadedImagesUrls && uploadedImagesUrls.length
                    ? uploadedImagesUrls.filter(item => imageIds.length && imageIds.includes(item.id))
                    : [])
                }
              }, "SKIP");
            }}
          >
            Skip & Publish
          </InlineTextButton>
        </h1>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          // ready={ready}
          fetchErrors={errors}
          initialValues={{ images, ...publicData }}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, uploadedImagesUrls = [], colorCodes, ...updateValues } = values;

            const eventTypeImageURL = eventTypes && eventTypes.length && publicData.eventType
              ? eventTypes.find(et => et.name == publicData.eventType).image
              : '';
            Object.assign(updateValues, { uploadedImagesUrls, eventTypeImageURL });
            if (images && images.length) {
              updateValues.uploadedImagesUrls = updateValues.uploadedImagesUrls.filter(i => images.findIndex(ri => ri.id && ri.id.uuid && (ri.id.uuid == i.id)) > -1);
            } else {
              Object.assign(updateValues, { uploadedImagesUrls: [], eventTypeImageURL });
            }
            if (colorCodes && colorCodes.length) {
              Object.assign(updateValues, { colorCodes: [...new Set(colorCodes)] });
            }
            return onSubmit(updateValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
        />
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  // ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;