import React from 'react';

import css from './LocationAutocompleteInput.module.css';

const IconHourGlass = () => (
  <svg className={css.iconSvg} width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1109 23.4C12.5156 20.3906 18 13.0969 18 9C18 4.03125 13.9688 0 9 0C4.03125 0 0 4.03125 0 9C0 13.0969 5.48438 20.3906 7.88906 23.4C8.46562 24.1172 9.53438 24.1172 10.1109 23.4ZM9 12C7.34531 12 6 10.6547 6 9C6 7.34531 7.34531 6 9 6C10.6547 6 12 7.34531 12 9C12 10.6547 10.6547 12 9 12Z" fill="black" />
  </svg>

);

export default IconHourGlass;
