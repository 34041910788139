import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  DragAndDrop,
  BorderButton,
  PrimaryButton,
  FieldTextInput,
  FieldMultiSelect,
  ResponsiveImage,
  IconClose,
  IconProfileSetup,
} from '../../components';

import css from './ListServiceForm.module.css';

const KEY_CODE_ENTER = 13;
const ACCEPT_IMAGES = 'image/*';
const ACCEPT_VIDEOS = 'video/*';
const ACCEPT_DOCS = '.pdf,.docs';
const MAX_DOCUMENTS = 1;

const ListServiceFormComponent = props => {
  const { initialValues, currentUserId } = props;
  const { uploadImage, uploadVideo, docs } = initialValues || {};
  const [image, setImage] = useState(uploadImage || null);
  const [uploadInProgress, setUploadImageInProgress] = useState(false);
  const [video, setVideo] = useState(uploadVideo || null);
  const [uploadVideoInProgress, setUploadVideoInProgress] = useState(false);
  const [doc, setDoc] = useState(docs || null);
  const [uploadDocsInProgress, setUploadDocInProgress] = useState(false);

  const onFileUploadHandler = (file, form, event, name) => {
    const uploadURL = process.env.REACT_APP_CLOUDINARY_URL;
    const formData = new FormData();
    const fileType = file.type;

    formData.append('file', file);
    formData.append('folder', currentUserId);
    formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET);
    formData.append('resource_type', fileType);
    formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY);
    // formData.append('api_secret', 'gAkFjUdQaidnsnEUadyQerGeT0A');

    name == 'uploadImage'
      ? setUploadImageInProgress(true)
      : name == 'uploadVideo'
        ? setUploadVideoInProgress(true)
        : setUploadDocInProgress(true);
    fetch(uploadURL, { method: 'POST', body: formData })
      .then(response => response.text())
      .then(data => {
        const res = JSON.parse(data);
        if (res && res.secure_url) {
          form.change(name, res);
          name == 'uploadImage'
            ? [setImage(res), setUploadImageInProgress(false)]
            : name == 'uploadVideo'
              ? [setVideo(res), setUploadVideoInProgress(false)]
              : [setDoc(res), setUploadDocInProgress(false)];
          if (event && event.target) {
            event.target.value = null;
          }
        }
      });
  };

  const onFileRemove = (form, name) => {
    // e.stopPropagation();
    form.change(name, '');

    switch (name) {
      case 'uploadImage':
        setImage(null);
        return;
      case 'uploadVideo':
        setVideo(null);
        return;
      case 'docs':
        setDoc(null);
        return;
      default:
        return;
    }
  };

  const handleDrop = (files, form, type) => {
    if (files && files.length) {
      onFileUploadHandler(files[0], form, null, type);
    }
  };

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          invalid,
          intl,
          form,
          values,
          onDelete,
          subCategories,
          updateInProgress,
        } = fieldRenderProps;

        const chooseImageLabel =
          image ? (
            <div className={css.avatarContainer}>
              <div key={image.public_id}>
                <ResponsiveImage alt={image.original_filename} url={image.secure_url} />
                {/* <div className={css.date}>
                  {intl.formatDate(image.created_at, dateFormatOptions)}
                </div> */}

              </div>
              <div className={css.changeAvatar}>
                {/* <FormattedMessage id="ProfileSettingsForm.changeAvatar" /> */}
              </div>
            </div>
          ) : (
            <div className={css.pchooseImageText}>
              <IconProfileSetup type={'uploadImage'} />
              <div className={css.chooseImageBox}>
                {/* <div className={css.chooseImagedesktop}>
                  <FormattedMessage id="PlannerBasicsForm.profilePicAltText" />
                </div> */}
                {/* <div className={css.chooseImagedesktop}>
                  <FormattedMessage id="PlannerBasicsForm.profilePicAltTextmobile" />
                </div> */}
                {/* <span className={css.imageTypes}>
                  <FormattedMessage id="PlannerBasicsForm.addImagesTip" />
                </span> */}
              </div>
            </div>
          );

        const chooseDocsLabel =
          doc ? (
            <div className={css.avatarContainer}>
              <div key={doc.public_id} className={css.uploadDocument}>
                <a target="_blank" href={doc.secure_url}>
                  {doc.original_filename}
                </a>
                {/* <div className={css.date}>
                  {intl.formatDate(doc.created_at, dateFormatOptions)}
                </div> */}

              </div>
              <div className={css.changeAvatar}>
                {/* <FormattedMessage id="ProfileSettingsForm.changeAvatar" /> */}
              </div>
            </div>
          ) : (
            <div className={css.chooseImageText}>
              <IconProfileSetup type={'uploadDocs'} />
              <div className={css.chooseImageBox}>
                {/* <div className={css.chooseImagedesktop}>
                  <FormattedMessage id="PlannerBasicsForm.profilePicAltText" />
                </div>
                <div className={css.chooseImagedesktop}>
                  <FormattedMessage id="PlannerBasicsForm.profilePicAltTextmobile" />
                </div> */}
                {/* <span className={css.imageTypes}>
                  <FormattedMessage id="PlannerBasicsForm.addImagesTip" />
                </span> */}
              </div>
            </div>
          );

        const chooseVideoLabel =
          video ? (
            <div className={css.avatarContainer}>
              <div key={video.public_id}>
                <div className={css.videoBox}>
                  <video width="102" height="102" controls>
                    <source alt={video.original_filename} src={video.secure_url} type="video/mp4" />
                  </video>
                </div>
                {/* <div className={css.date}>
                  {intl.formatDate(video.created_at, dateFormatOptions)}
                </div> */}

              </div>
              <div className={css.changeAvatar}>
                {/* <FormattedMessage id="ProfileSettingsForm.changeAvatar" /> */}
              </div>
            </div>
          ) : (
            <div className={css.chooseImageText}>
              <IconProfileSetup type={'uploadVideo'} />
              <div className={css.chooseImageBox}>
                {/* <div className={css.chooseImagedesktop}>
                  <FormattedMessage id="PlannerBasicsForm.profilePicAltText" />
                </div>
                <div className={css.chooseImagedesktop}>
                  <FormattedMessage id="PlannerBasicsForm.profilePicAltTextmobile" />
                </div> */}
                {/* <span className={css.imageTypes}>
                  <FormattedMessage id="PlannerBasicsForm.addImagesTip" />
                </span> */}
              </div>
            </div>
          );

        // customTitle
        const customTitleLabel = intl.formatMessage({
          id: 'ListServiceForm.customTitle',
        });
        const customTitlePlaceholder = intl.formatMessage({
          id: 'ListServiceForm.customTitlePlaceholder',
        });
        const customTitleRequiredMessage = intl.formatMessage({
          id: 'ListServiceForm.customTitleRequired',
        });
        const customTitleRequired = validators.required(customTitleRequiredMessage);

        // subCategories
        const subCategoriesLabel = intl.formatMessage({
          id: 'ListServiceForm.subCategories',
        });
        const subCategoriesPlaceholder = intl.formatMessage({
          id: 'ListServiceForm.subCategoriesPlaceholder',
        });
        const subCategoriesRequiredMessage = intl.formatMessage({
          id: 'ListServiceForm.subCategoriesRequired',
        });
        const subCategoriesRequired = validators.required(subCategoriesRequiredMessage);

        // description
        const descriptionLabel = intl.formatMessage({
          id: 'ListServiceForm.descriptionLabel',
        });
        const descriptionPlaceholder = intl.formatMessage({
          id: 'ListServiceForm.descriptionPlaceholder',
        });
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'ListServiceForm.descriptionRequired',
        });
        const descriptionRequired = validators.required(descriptionRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={e => {
            e.stopPropagation();
            form.change('uploadImage', image);
            form.change('uploadVideo', video);
            form.change('docs', doc);
            handleSubmit(e);
          }}>
            <h2><FormattedMessage id="ListServiceForm.listServices" /></h2>
            <FieldTextInput
              className={css.customTitleRoot}
              type="text"
              id={'customTitle'}
              name="customTitle"
              label={customTitleLabel}
              placeholder={customTitlePlaceholder}
              validate={customTitleRequired}
            />

            <div className={css.multiselectInput}>
              <FieldMultiSelect
                isMulti={false}
                isRequired={true}
                isSearchable={true}
                isCustomValue={true}
                className={css.field}
                id={'subCategories'}
                name={'subCategories'}
                label={subCategoriesLabel}
                autoComplete={'subCategories'}
                options={subCategories}
                validate={subCategoriesRequired}
                // customErrorText={values.showMultiError && values.stage && Array.isArray(values.stage) && values.stage.length == 0 && stageRequiredMessage}
                fontClass={css.fontClass}
                placeholder={subCategoriesPlaceholder}
              />
            </div>

            <div className={css.imageUploadWrapper}>
              {/* Your Profile Picture Drag & Drop or Click */}
              <DragAndDrop
                dragdrop={css.thumbnail}
                handleDrop={file => handleDrop(file, form, 'uploadImage')}
              >
                <Field
                  accept={ACCEPT_IMAGES}
                  id="uploadImage"
                  name="uploadImage"
                  label={chooseImageLabel}
                  type="file"
                  form={null}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`uploadImage`, file);
                      form.blur(`uploadImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onFileUploadHandler(file, form, e, 'uploadImage');
                      }
                    };

                    return (
                      <div className={css.addImageWrapper}>
                        {image && image.secure_url && (
                          <div className={css.removeItem} onClick={() => onFileRemove(form, 'uploadImage')}>
                            <IconClose size="normal" className={css.removeIcon} />
                          </div>
                        )}
                        <div className={css.aspectRatioWrapper}>
                          <label className={css.addImage} htmlFor={id}>
                            {label}
                          </label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.addImageInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                        </div>
                      </div>
                    );
                  }}
                </Field>
              </DragAndDrop>

              {/* Your Profile Picture Drag & Drop or Click */}
              <DragAndDrop
                dragdrop={css.thumbnail}
                handleDrop={file => handleDrop(file, form, 'docs')}
              >
                <Field
                  accept={ACCEPT_DOCS}
                  id="docs"
                  name="docs"
                  label={chooseDocsLabel}
                  type="file"
                  form={null}
                  disabled={uploadVideoInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`docs`, file);
                      form.blur(`docs`);
                      if (file != null) {
                        onFileUploadHandler(file, form, e, 'docs');
                      }
                    };

                    return (
                      <div className={css.addImageWrapper}>
                        {doc && doc.secure_url && (
                          <div className={css.removeItem} onClick={() => onFileRemove(form, 'docs')}>
                            <IconClose size="normal" className={css.removeIcon} />
                          </div>
                        )}

                        <div className={css.aspectRatioWrapper}>
                          <label className={css.addImage} htmlFor={id}>
                            {label}
                          </label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.addImageInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                        </div>
                      </div>
                    );
                  }}
                </Field>
              </DragAndDrop>
              {/* Your Profile Picture Drag & Drop or Click */}
              <DragAndDrop
                dragdrop={css.thumbnail}
                handleDrop={file => handleDrop(file, form, 'uploadVideo')}
              >
                <Field
                  accept={ACCEPT_VIDEOS}
                  id="uploadVideo"
                  name="uploadVideo"
                  label={chooseVideoLabel}
                  type="file"
                  form={null}
                  disabled={uploadDocsInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`uploadVideo`, file);
                      form.blur(`uploadVideo`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onFileUploadHandler(file, form, e, 'uploadVideo');
                      }
                    };

                    return (
                      <div className={css.addImageWrapper}>
                        {video && video.secure_url && (
                          <div className={css.removeItem} onClick={() => onFileRemove(form, 'uploadVideo')}>
                            <IconClose size="normal" className={css.removeIcon} />
                          </div>
                        )}

                        <div className={css.aspectRatioWrapper}>
                          <label className={css.addImage} htmlFor={id}>
                            {label}
                          </label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.addImageInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                        </div>
                      </div>
                    );
                  }}
                </Field>
              </DragAndDrop>
            </div>
            <FieldTextInput
              className={css.descriptionRoot}
              type="textarea"
              id={'description'}
              name="description"
              label={descriptionLabel}
              placeholder={descriptionPlaceholder}
              validate={descriptionRequired}
            />
            <div className={css.bottomWrapper}>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                {values.index > -1
                  ? <FormattedMessage id="ListServiceForm.editService" />
                  : <FormattedMessage id="ListServiceForm.saveService" />}
              </PrimaryButton>
              {values.index > -1
                ? <BorderButton
                  type="button"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onClick={() => onDelete(values)}
                >
                  <FormattedMessage id="ListServiceForm.deleteService" />
                </BorderButton>
                : null}
            </div>
          </Form>
        );
      }}
    />
  );
};

ListServiceFormComponent.defaultProps = {
  inProgress: false,
  eventsOptions: config.custom.eventType,
};

const { bool, func } = PropTypes;

ListServiceFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const ListServiceForm = compose(injectIntl)(ListServiceFormComponent);
ListServiceForm.displayName = 'ListServiceForm';

export default ListServiceForm;