import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import algoliasearch from 'algoliasearch';
import { withRouter } from 'react-router-dom';

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

const AlgoliaAutocomplete = (props) => {

    const { className, onChange } = props;
    // Algolia credentials
    const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
    const index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_LISTING_INDEX);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const debouncedFetchSearchSuggestions = useDebounce(searchTerm, 300);

    // Fetch search suggestions from Algolia
    const fetchSearchSuggestions = async (value) => {
        if (value.length > 0) {
            try {
                const { hits } = await index.search(value, {
                    hitsPerPage: 5,
                    attributesToRetrieve: ['title', 'services.customTitle'],
                    restrictSearchableAttributes: ['title', 'services.customTitle', 'categoriesLabel', 'subCategoriesLabel'],
                    filters: 'state:"published" AND (ListingType:"VENDOR_PROFILE" OR ListingType:"VENUE_PROFILE")'
                });
                if (hits && hits.length && Array.isArray(hits)) {
                    setSearchResults([ ...hits.filter(h => h.title) ]);
                }
            } catch (error) {
                console.error('Error retrieving search suggestions:', error);
            }
        } else {
            setSearchResults([]);
        }
    };

    // Handle user selection from autocomplete dropdown
    const handleSelect = (value, item) => {
        setSearchTerm(item.title);
        onChange({ query: item.title })
        // Perform action with the selected item, e.g., redirect or display details
    };

    useEffect(() => {
        fetchSearchSuggestions(debouncedFetchSearchSuggestions);
    }, [debouncedFetchSearchSuggestions]);

    return (
        <Autocomplete
            items={searchResults}
            getItemValue={(item) => item.title}
            className={className}
            renderItem={(item, isHighlighted) => (
                <div
                    key={item.objectID}
                    style={{
                        background: isHighlighted ? 'lightgray' : 'white',
                        height: '50px',
                        padding: 'var(--marketplaceButtonSmallDesktopPadding)',
                        cursor: 'pointer'
                    }}
                >
                    {item.title}
                </div>
            )}
            value={searchTerm}
            onChange={(e) => {
                setSearchTerm(e.target.value);
                onChange({ query: e.target.value })
            }}
            onSelect={handleSelect}
            wrapperStyle={{ width: '100%' }}
            menuStyle={{
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '0px 0',
                position: 'absolute',
                overflow: 'auto',
                top: '60px',
                left: '0 !important',
            }}
            renderInput={(props) => (
                <input
                    {...props}
                    type="text"
                    className={className}
                    placeholder="Search by keyword or business name..."
                />
            )}
        />
    );
};

export default withRouter(AlgoliaAutocomplete);
