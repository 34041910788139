import React, { useState, useRef } from 'react';
import moment from 'moment';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';

import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString } from '../../util/routes';
import { calculateBiddingAcorns, camelCaseEventDuration, uploadCloudinaryFiles } from '../../util/data';

import {
  Modal,
  Button,
  NamedLink,
  SendBirdChat,
  SkeletonLoader,
  ResponsiveImage,
  IconCollection,
  IconCardDashboard,
} from '..';
import { UserReportForm, EventDetailForm } from '../../forms';

import SectionMapMaybe from '../../containers/ListingPage/SectionMapMaybe';

import css from './EventRequest.module.css';

const { LatLng, Money } = sdkTypes;

const EventRequest = props => {
  const {
    event,
    events: allEvents,
    fetchEventInProgress,
    files,
    params,
    history,
    invoices,
    currentUser,
    currentUserListing,
    onUpdateEvent,
    onUpdateWallet,
    onReportAUser,
    uploadInProgress,
    onClearUploadFile,
    onRemoveListingFile,
    onRequestFileUpload,
    onUpdateEventRequest,
    onUpdateProfile,
    reportUserInProgress,
    updateEventInProgress,
    onFindOrCreateChannel,
    onManageDisableScrolling,
  } = props;

  const {
    _id,
    txId,
    title,
    vendor,
    city,
    status,
    region,
    postcode,
    eventId,
    location,
    createdAt,
    eventOwner,
    eventDates,
    geolocation,
    eventType,
    categoriesLabel,
    subCategoriesLabel,
    eventDuration,
    eventSize,
    categories = [],
    eventFormat,
    eventTypeImageURL,
    uploadedImagesUrls,
    vendorResponse = {},
    plannerResponse,
    theme,
    description,
    pinterestLink,
    colorCodes = [],
    selectedColorCodes = [],
  } = event || {};

  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [quoteCheck, setQuoteCheck] = useState(""); // Add state for quoteCheck
  const [actionModal, setActionModal] = useState(false);
  const rightWrapperRef = useRef(null);  // Reference for rightWrapper

  const events = allEvents && allEvents.length ? allEvents.filter(event => event?.status === 'PENDING') : [event];
  const routeIndex = events && events.length ? events.findIndex(e => e?._id == event?._id) : -1;
  const { email, profile } = (currentUser && currentUser.id && currentUser.attributes) || {};
  const { publicData, protectedData } = profile || {};
  const { acceptPayment, introMessage, userType, profileImageURL } = publicData || {};
  const { CurrentAcorns = 0 } = protectedData || {};
  const { publicData: LPublicData } = (currentUserListing && currentUserListing.id && currentUserListing.attributes) || {};

  let minPrice = 0, maxPrice = 0, isDelivery, requirements = [];

  if (categories && categories.length && Array.isArray(categories)) {
    categories.forEach(category => {
      if (category.subCategory && category.subCategory.length) {
        category.subCategory.forEach(subCat => {
          if (!minPrice || !maxPrice) {
            minPrice = subCat.requirements.minPrice;
            maxPrice = subCat.requirements.maxPrice;
          } else {
            if (minPrice > subCat.requirements.minPrice) {
              minPrice = subCat.requirements.minPrice;
            }
            if (maxPrice < subCat.requirements.maxPrice) {
              maxPrice = subCat.requirements.maxPrice;
            }
          }
          if (!isDelivery) {
            isDelivery = subCat.requirements.isDelivery;
          }
          if (subCat.requirements && Object.keys(subCat.requirements).length) {
            for (const key in subCat.requirements) {
              if (Object.hasOwnProperty.call(subCat.requirements, key)) {
                const element = {
                  label: (category.label + " > " + subCat.label + " > " + subCat.requirements[key]),
                  value: subCat.requirements[key]
                };
                if (key.includes('requirement-request')) {
                  requirements.push(element);
                }
              }
            }
          }
        });
      }
    });

    if (requirements && requirements.length == 0) {
      categories.forEach(category => {
        requirements.push({
          label: category.label,
          value: category.value
        });
      });
    }
  }

  const handleSubmitDetails = async (values) => {
    const additionalInfo = [];
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        const splitAccept = key.split('acceptPayment');
        const splitIntro = key.split('introMessage');
        if (splitAccept.length > 1) {
          if (additionalInfo[parseInt(splitAccept[1]) - 1]) {
            additionalInfo[parseInt(splitAccept[1]) - 1]['acceptPayment'] = values[key];
          } else {
            additionalInfo.push({
              acceptPayment: values[key],
              introMessage: '',
            });
          }
        }
        if (splitIntro.length > 1) {
          if (additionalInfo[parseInt(splitIntro[1]) - 1]) {
            additionalInfo[parseInt(splitIntro[1]) - 1]['introMessage'] = values[key];
          } else {
            additionalInfo.push({
              acceptPayment: '',
              introMessage: values[key],
            });
          }
        }
      }
    }

    const {
      quote,
      winningBid,
      quoteCheck,
      vendorMessage,
      introMessage1,
      acceptPayment1,
      optionalNote,
      ...rest
    } = values;

    onUpdateEventRequest(event.id);
    const { acorns, requirementQuotes } = calculateBiddingAcorns(event, rest);
    const vendorResponse = {
      requirements,
      acceptPayment1,
      introMessage1,
      optionalNote,
      quote,
      quoteCheck,
      winningBid,
      quote: quote?.amount ? (quote.amount / 100) : 0,
      updatedAt: moment().unix()
    };

    if (vendorMessage) {
      Object.assign(vendorResponse, {
        vendorMessage,
        vendorMessageCount: (vendorResponse.vendorMessageCount && ++vendorResponse.vendorMessageCount) || 1,
      });
    } else {
      Object.assign(vendorResponse, { requirementQuotes });
    }

    const currentUserId = currentUser && currentUser.id && currentUser.id.uuid;
    const uploadedFiles = await uploadCloudinaryFiles(files.map(file => file.file), currentUserId);
    if (uploadedFiles && uploadedFiles.length) {
      Object.assign(vendorResponse, {
        uploadedFiles: uploadedFiles.map(file => ({
          id: file.data.public_id,
          url: file.data.secure_url,
          resource_type: file.data.resource_type,
        }))
      });
    }

    return onUpdateEvent({
      ...event,
      vendorResponse,
      status: quoteCheck == "requestMoreInfo" ? "ENQUIRY" : "REQUESTED"
    }).then(() => {
      onClearUploadFile();
      if (acorns) {
        onUpdateWallet({
          tx_id: event.tx_id || event.txId,
          type: "DEBIT",
          acorns,
          RNT_TOKEN_ID: eventId,
          _id
        });
      }

      if (quoteCheck != "requestMoreInfo") {
        return setTimeout(() => { setActionModal('congratulation') }, 500);
      }
    });
  };

  const previousParams = (typeof window != 'undefined' && JSON.parse(window.sessionStorage.getItem('PurchaseAcornsPage'))) || {};
  const initialValues = previousParams && previousParams.values
    ? previousParams.values
    : {
      quoteCheck: requirements && requirements.length && Array.isArray(requirements)
        ? ""
        : "requestMoreInfo",
      additionalInfo: [{
        acceptPayment: acceptPayment || "",
        introMessage: introMessage || ""
      }],
      acceptPayment1: acceptPayment || "",
      introMessage1: introMessage || "",
      ...vendorResponse,
      ...(vendorResponse.requirementQuotes
        ? calculateBiddingAcorns(event, vendorResponse.requirementQuotes).requirementQuotes
        : {}),
      quote: vendorResponse.quote ? new Money((vendorResponse.quote * 100), config.currencyConfig.currency) : undefined,
    };

  if (categories && categories.length && vendorResponse.requirementQuotes) {
    categories.map(cat => {
      cat.subCategory && cat.subCategory.map((subCat, index) => {
        const requirements = Object.keys(subCat.requirements).filter(requirement => requirement.includes('requirement-request'));
        if (vendorResponse.requirementQuotes[subCat.key] && vendorResponse.requirementQuotes[subCat.key].requirements && vendorResponse.requirementQuotes[subCat.key].requirements.length == requirements.length) {
          Object.assign(initialValues, {
            [subCat.key + "_requirement"]: true
          });
        }
      });
    })
  }

  Object.keys(initialValues).map((key) => (initialValues[key] && initialValues[key].currency
    ? initialValues[key] = new Money(initialValues[key].amount, initialValues[key].currency)
    : null));

  const handleCloseModal = () => {
    setActionModal(false);
    if (previousParams.txId) {
      window.sessionStorage.removeItem('PurchaseAcornsPage');
    }
    return history.push(createResourceLocatorString
      ('ProposalTabPage', routeConfiguration(), {
        txId,
        slug: createSlug(title),
        tab: 'messages'
      }, {}));
  }

  const handleQuoteCheckChange = (newQuoteCheck) => setQuoteCheck(newQuoteCheck);
  // Conditionally apply class name based on quoteCheck value
  const leftWrapperClass = classNames(css.leftWrapper, {
    [css.submitProposalSelected]: ['submitProposal', 'requestMoreInfo'].includes(quoteCheck), // Add different class here
  });

  return (
    <div className={css.content}>
      <div className={css.topbarBoxMain}>
        {routeIndex > 0
          ? <h4 className={css.headingText} onClick={() => history.push(createResourceLocatorString
            ('ProposalTabPage', routeConfiguration(), {
              txId: events[routeIndex - 1].txId,
              slug: createSlug(events[routeIndex - 1].title),
              tab: 'request'
            }, {}))}>
            <IconCardDashboard type="requestleftarrow" />
            <div className={css.purchaseMoreLink}>
              <FormattedMessage id="ProposalRequestsPage.headingPrev" />
            </div>
          </h4>
          : CurrentAcorns < 5
            ? <h4 className={css.headingText} onClick={() => history.push(createResourceLocatorString('PurchaseAcornsPage', routeConfiguration()))}>
              <IconCardDashboard type="leaf" />
              <div className={css.headingRightBox}>
                <FormattedMessage id="ProposalRequestsPage.headingLeft" values={{ noOfAcorns: CurrentAcorns }} />
                <div className={css.purchaseMoreLink}
                >
                  <FormattedMessage id="ProposalRequestsPage.headingLeftLink" />
                </div>
              </div>
            </h4>
            : null}
        {routeIndex > -1 && (routeIndex < (events.length - 1))
          ? <h4 onClick={() => history.push(createResourceLocatorString
            ('ProposalTabPage', routeConfiguration(), {
              txId: events[routeIndex + 1].txId,
              slug: createSlug(events[routeIndex + 1].title),
              tab: 'request'
            }, {}))}
            className={css.headingText}>
            <div className={css.headingRightlink}>
              <FormattedMessage id="ProposalRequestsPage.headingRight" />
            </div>
            <IconCardDashboard type="requestrightarrow" />
          </h4>
          : null}
      </div>
      <div className={leftWrapperClass}>
        <h3><FormattedMessage id="ProposalRequestsPage.eventDetails" /></h3>
        <div className={css.scrollLeftBox} style={{ maxHeight: (rightWrapperRef.current ? (rightWrapperRef.current?.offsetHeight - 20) : 487), minHeight: 280 }}>
          <div className={css.topImage}>
            <ResponsiveImage
              alt={(uploadedImagesUrls && uploadedImagesUrls.length)
                ? (uploadedImagesUrls[0].url)
                : eventTypeImageURL
                  ? eventTypeImageURL
                  : 'No image'}
              url={(uploadedImagesUrls && uploadedImagesUrls.length)
                ? uploadedImagesUrls[0].url
                : eventTypeImageURL}
            />
          </div>
          {fetchEventInProgress
            ? <SkeletonLoader pageName={'LeftSideView'} />
            : <>
              <div className={css.eventList}>
                <div className={css.eventTitle}>{title}</div>
                <ul>
                  {eventDates && eventDates.length
                    ? <>
                      <li className={css.dateInfo}>
                        <FormattedMessage id="EventRequest.eventDate" />
                        <div>
                          {eventDates.map(ed => (<span className={css.boldText} key={ed.start}>
                            {moment(ed.start).format("dddd, MMMM D, YYYY")}
                          </span>))}
                        </div>
                      </li>
                      <li>
                        <FormattedMessage id="EventRequest.startTime" />
                        <span className={css.boldText}>{moment(eventDates[0].start).format("h:mm A")}  |</span> <FormattedMessage id="ProposalRequestsPage.wrapTime" />
                        <span className={css.boldText}>{moment(eventDates[0].end).format("h:mm A")}</span>
                      </li>
                    </>
                    : eventDuration == 'not-determined'
                      ? <li className={css.dateInfo}>
                        <FormattedMessage id="EventRequest.eventDate" />
                        <div>{camelCaseEventDuration(eventDuration)}</div>
                      </li>
                      : null}
                  {city && region && postcode
                    ? <li>
                      <FormattedMessage id="EventRequest.location" />
                      <span className={css.boldText}>
                        {city + ', ' + region + ', ' + postcode}
                      </span>
                    </li>
                    : null}
                  {eventSize
                    ? <li>
                      <FormattedMessage id="EventRequest.guestEstimate" />
                      <span className={css.boldText}>{(eventSize && eventSize.label ? eventSize.label : eventSize) + ' Guests'}</span>
                    </li>
                    : null}
                  {/* {eventDuration
                ? <li>
                  <FormattedMessage id="EventRequest.eventDuration" />
                  <span className={css.boldText}>{eventDuration.charAt(0).toUpperCase() + eventDuration.substr(1)}</span>
                </li>
                : null} */}
                  {eventType
                    ? <li>
                      <FormattedMessage id="EventRequest.eventType" />
                      <span className={css.boldText}>{eventType && eventType.label ? eventType.label : eventType}</span>
                    </li>
                    : null}
                  {eventFormat
                    ? <li>
                      <FormattedMessage id="EventRequest.eventFormat" />
                      <span className={css.boldText}>
                        {eventFormat}
                      </span>
                    </li>
                    : null}
                </ul>
              </div>
              <div>
                {geolocation && geolocation.lat && geolocation.lng && eventId
                  ? <SectionMapMaybe
                    publicData={LPublicData}
                    geolocation={new LatLng(geolocation.lat, geolocation.lng)}
                    isOwnListing={false}
                    listingId={eventId}
                    userType={userType}
                    eventStatus={(plannerResponse && plannerResponse.status) ? plannerResponse.status : status}
                  />
                  : null}
              </div>
              <div className={css.EventStyleWrapper}>
                {colorCodes && colorCodes.length
                  ? <div className={css.EventStyleHeading}>
                    <h4>Event Style</h4>
                    <div className={css.eventColors}>
                      {colorCodes.map((colorCode, i) => <div key={colorCode + '_' + i + '-code'} className={css.colorBox} style={{ backgroundColor: colorCode }} />)}
                    </div>
                  </div>
                  : null}
                <div className={css.sliderContainer}>

                  {uploadedImagesUrls && uploadedImagesUrls.length
                    ? uploadedImagesUrls.length == 1
                      ? <div className={css.sliderSingleImg}>
                        <ResponsiveImage url={uploadedImagesUrls[0].url} alt='profile' />
                      </div>
                      : <Slider arrows={true} className={css.singleImgSlider} asNavFor={slider2} ref={slider => setSlider1(slider)}>
                        {uploadedImagesUrls.map((image, i) => <div className={css.sliderSingleImg} key={image.url + '-' + i}>
                          {image.url
                            ? <ResponsiveImage url={image.url} alt='profile' />
                            : 'No image'}
                        </div>)}
                      </Slider>
                    : null}

                  {uploadedImagesUrls && uploadedImagesUrls.length
                    ? uploadedImagesUrls.length == 1
                      ? <div className={css.sliderNav}>
                        <ResponsiveImage url={uploadedImagesUrls[0].url} alt='profile' />
                      </div>
                      : <Slider
                        asNavFor={slider1}
                        ref={(slider) => setSlider2(slider)}
                        slidesToShow={uploadedImagesUrls.length > 5 ? 6 : uploadedImagesUrls.length}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        className='slider-navs'
                      >
                        {uploadedImagesUrls.map((image, i) => <div className={css.sliderNav} key={image.url + '_' + i}>
                          <ResponsiveImage url={image.url} alt='profile' />
                        </div>)}
                      </Slider>
                    : null}
                </div>

                {pinterestLink
                  ? <div
                    className={css.pinterestLink}
                    onClick={() => {
                      window.open(pinterestLink, '_blank');
                    }}
                  >
                    <IconCollection name="ICON_PINTEREST" />
                    Pinterest Link
                  </div>
                  : null}
                <div className={css.styleDetails}>
                  {selectedColorCodes && selectedColorCodes.length
                    ? <>
                      <span>
                        Textiles: <b>{selectedColorCodes.join(', ')}</b>
                      </span>
                      <br />
                    </>
                    : null}
                  {theme
                    ? <span>
                      Theme: <b>{theme}</b>
                    </span>
                    : null}
                </div>
                {description
                  ? <div className={css.eventStyleInfo}>
                    <IconCollection name="ICON_EVENT_INFO" />
                    <span>{description}</span>
                  </div>
                  : null}
              </div>
            </>}
        </div>
      </div>
      <div className={css.rightWrapper}>
        <div className={css.tabs}>
          <div
            className={classNames(css.tab, css.makeBid, params.tab == 'request' && css.active)}
            onClick={() => event && event.txId && history.push(createResourceLocatorString
              ('ProposalTabPage', routeConfiguration(), {
                txId: event.txId,
                slug: createSlug(event.title),
                tab: 'request'
              }, {}))}
          >
            <IconCollection name="ICON_EVENT_TAB" />
            <span>
              {/* <FormattedMessage id="EventRequest.projectDetails" /> */}
              {['ACCEPTED'].includes(event?.status)
                ? <FormattedMessage id="EventRequest.projectDetails" />
                : <FormattedMessage id="EventRequest.makeBid" />}
            </span>
          </div>
          <div
            className={classNames(css.tab, params.tab == 'messages' && css.active, ['PENDING'].includes(event?.status) ? css.disabled : '')}
            onClick={() => {
              if (!['PENDING', 'EXPIRED', 'DECLINED'].includes(event?.status)) {
                onFindOrCreateChannel({
                  senderId: vendor?.id,
                  receiverId: eventOwner?.id,
                  type: 'transactional',
                  tab: txId,
                  eventId,
                  slug: createSlug(title),
                }).then(() => {
                  const channelId = [eventOwner?.id, vendor?.id].sort().join('_');
                  typeof window !== 'undefined' && window.sessionStorage.setItem('channelId', channelId);
                  history.push(createResourceLocatorString
                    ('ProposalTabPage', routeConfiguration(), {
                      txId: event.txId,
                      slug: createSlug(event.title),
                      tab: 'messages'
                    }, {}))
                });
              }
            }}
          >
            <IconCollection name="ICON_MESSAGE2" />
            <span><FormattedMessage id="EventRequest.message" /></span>
            {['PENDING'].includes(event?.status)
              ? <IconCollection name="LOCK" />
              : null}
          </div>

          {['PENDING'].includes(event?.status)
            ? <div className={classNames(css.topbarBox, css.purchaseAcorns)}>
              <h4 className={css.headingText}>
                <IconCardDashboard type="purchasemore" />
                <div className={css.headingRightBox}>
                  <FormattedMessage id="ProposalRequestsPage.headingLeft" values={{ noOfAcorns: CurrentAcorns }} />
                  <NamedLink name={"PurchaseAcornsPage"} className={css.purchaseMoreLink}>
                    <FormattedMessage id="ProposalRequestsPage.headingLeftLink" />
                  </NamedLink>
                </div>
              </h4>
            </div>
            : null}

          <div className={css.buttonsGroup}>
            <span className={css.reportBtn} onClick={() => setActionModal('report')}>
              <FormattedMessage id="EventRequest.reportUser" />
            </span>
            {fetchEventInProgress || (invoices && invoices.length)
              ? null
              : ['ACCEPTED'].includes(event?.status)
                ? <Button
                  onClick={() => history.push(createResourceLocatorString
                    ('InvoiceDetailsPage',
                      routeConfiguration(),
                      { tab: 'create-invoice' }))}
                >
                  <FormattedMessage id="EventRequest.createInvoice" />
                </Button>
                : null}
          </div>
        </div>

        {fetchEventInProgress
          ? <div className={css.eventRental}>
            <SkeletonLoader pageName={'RightSideView'} />
          </div>
          : <>
            {params.tab == 'messages'
              ? <div className={css.eventChatTab}>
                <SendBirdChat
                  PageName={'ProposalRequestsPage'}
                  params={params}
                  history={history}
                  eventDetailData={event}
                  currentUser={currentUser}
                  onUpdateEvent={onUpdateEvent}
                  onFindOrCreateChannel={onFindOrCreateChannel}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              </div>
              : params.tab == 'request'
                ? <div className={css.eventRental} ref={rightWrapperRef}>
                  <div className={css.headerTitle}>
                    <h2><FormattedMessage id="EventRequest.projectDetails" /></h2>
                  </div>
                  <div className={css.requirementSection}>
                    <span className={css.icon}>
                      <IconCardDashboard type="user" />
                    </span>
                    {' '}
                    {eventOwner && eventOwner.publicData.userListingId
                      ? <span>
                        <FormattedMessage id="EventRequest.plannedBy" />
                        {' '}
                        <NamedLink
                          name={'ListingPage'}
                          params={{
                            id: eventOwner.publicData.userListingId,
                            slug: createSlug(eventOwner.publicData.businessName || eventOwner.displayName),
                          }}
                        >
                          {eventOwner.publicData.businessName}
                        </NamedLink>
                      </span>
                      : null}
                  </div>

                  {/* <h3>
                <FormattedMessage id="EventRequest.vendorType" />
                {categoriesLabel[0] + ' > ' + subCategoriesLabel[0]}
              </h3>
              <span><FormattedMessage id="EventRequest.requirements" /></span>
              <div className={css.requirementBox}>
                {minPrice && maxPrice
                  ? <div className={css.requirementSection}>
                    <span className={css.icon}>
                      <IconCardDashboard type="dollar" />
                    </span>
                    <span><FormattedMessage id="EventRequest.budget" /></span>
                    <span className={css.boldText}>
                      $ {(minPrice / 100).toFixed(2)} - $ {(maxPrice / 100).toFixed(2)}
                    </span>
                  </div>
                  : null}
                <div className={css.requirementSection}>
                  <span className={css.icon}>
                    <IconCardDashboard type="delevery" />
                  </span>
                  <span><FormattedMessage id="EventRequest.logistics" /></span>
                  <span className={css.boldText}>
                    <FormattedMessage id={isDelivery
                      ? "EventRequest.deliveryRequired"
                      : "EventRequest.deliveryNotRequired"} />
                  </span>
                </div>
              </div> */}

                  {['PENDING', 'ENQUIRY', 'INFO'].includes(event?.status)
                    ? <EventDetailForm
                      initialValues={initialValues}
                      event={event}
                      files={files}
                      categories={categories}
                      currentUser={currentUser}
                      requirements={requirements}
                      CurrentAcorns={CurrentAcorns}
                      onUpdateProfile={onUpdateProfile}
                      inProgress={updateEventInProgress}
                      onSubmit={handleSubmitDetails}
                      uploadInProgress={uploadInProgress}
                      onRemoveListingFile={onRemoveListingFile}
                      onRequestFileUpload={onRequestFileUpload}
                      onFindOrCreateChannel={onFindOrCreateChannel}
                      handleQuoteCheckChange={handleQuoteCheckChange}
                    />
                    : vendorResponse && vendorResponse.requirementQuotes
                      ? categories && categories.length
                        ? categories.map((category, ind) => <div className={css.categoryContainer} key={category.label}>
                          {category.subCategory.map(subCat => {
                            const requirements = Object.keys(subCat.requirements).filter(requirement => requirement.includes('requirement-request'));

                            const subProps = { key: (category.label + ' > ' + subCat.label) };
                            if (ind) Object.assign(subProps, { className: css.subCategoryContainer });

                            return (
                              <div {...subProps}>
                                {/* Subcategory title */}
                                <h3>{category.label + ' > ' + subCat.label}</h3>
                                <span><FormattedMessage id="EventRequest.requirements" /></span>
                                <div className={css.requirementBox}>
                                  {/* Budget */}
                                  <div className={css.requirementSection}>
                                    <span className={css.icon}>
                                      <IconCardDashboard type="dollar" />
                                    </span>
                                    <span><FormattedMessage id="EventRequest.budget" /></span>
                                    <span className={css.boldText}>
                                      {subCat.requirements.maxPrice && subCat.requirements.minPrice
                                        ? 'Max $' + (subCat.requirements.maxPrice / 100) + ' - Min $' + (subCat.requirements.minPrice / 100)
                                        : 'N/A'}
                                    </span>
                                  </div>

                                  {/* Logistics */}
                                  <div className={css.requirementSection}>
                                    <span className={css.icon}>
                                      <IconCardDashboard type="delevery" />
                                    </span>
                                    <span><FormattedMessage id="EventRequest.logistics" /></span>
                                    <span className={css.boldText}>
                                      <FormattedMessage id={subCat.requirements.isDelivery
                                        ? "EventRequest.deliveryRequired"
                                        : "EventRequest.deliveryNotRequired"} />
                                    </span>
                                  </div>
                                </div>

                                {/* Requirements - Checkboxes */}
                                <div className={css.checkboxWrapper}>
                                  <br />
                                  {requirements && requirements.length
                                    ? requirements.map(requirement => {
                                      return (vendorResponse.requirementQuotes[subCat.key] && vendorResponse.requirementQuotes[subCat.key].requirements.includes(subCat.requirements[requirement])
                                        ? <span key={category.label + ' > ' + subCat.label + ' ' + subCat.requirements[requirement]} className={css.checkboxItem}>
                                          <input
                                            checked={vendorResponse.requirementQuotes[subCat.key] && vendorResponse.requirementQuotes[subCat.key].requirements.includes(subCat.requirements[requirement])}
                                            disabled
                                            type="checkbox"
                                            id={requirement}
                                            name={requirement}
                                            value={subCat.requirements[requirement]}
                                          />
                                          <label htmlFor={requirement}>
                                            <span>&nbsp;</span>
                                            <span>{category.label + ' > ' + subCat.label + ' ' + subCat.requirements[requirement]}</span>
                                          </label>
                                        </span>
                                        : null);
                                    })
                                    : vendorResponse.requirementQuotes[subCat.key] && vendorResponse.requirementQuotes[subCat.key].requirements.includes(subCat.label)
                                      ? (<span key={category.label + ' > ' + subCat.label} className={css.checkboxItem}>
                                        <input
                                          checked={vendorResponse.requirementQuotes[subCat.key] && vendorResponse.requirementQuotes[subCat.key].requirements.includes(subCat.label)}
                                          disabled
                                          type="checkbox"
                                          id={subCat.label}
                                          name={subCat.label}
                                        />
                                        <label htmlFor={subCat.label}>
                                          <span>&nbsp;</span>
                                          <span>{category.label + ' > ' + subCat.label}</span>
                                        </label>
                                      </span>)
                                      : null}
                                </div>
                              </div>
                            )
                          })}
                        </div>)
                        : null
                      : null}
                  {/* : null} */}
                </div>
                : null}
          </>}
      </div>

      <Modal
        id="actionModal"
        isOpen={!!actionModal}
        onClose={() => handleCloseModal()}
        containerClassName={css.modalContainer}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {actionModal == 'congratulation'
          ? <>
            <div className={css.confirmModelHeading}>
              <IconCollection name="ICON_TICK" />
              <h3><FormattedMessage id={"EventRequest.proposalRequestTitle"} /></h3>
              <p><FormattedMessage id={"EventRequest.proposalRequestSubTitle"} /></p>
            </div>

            <div className={css.confirmButton}>
              <Button
                type="button"
                onClick={() => handleCloseModal()}
              >
                <FormattedMessage id={"EventRequest.proposalRequestButtonText"} />
              </Button>
            </div>
          </>
          : actionModal == 'report'
            ? <UserReportForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              listingTitle={eventOwner && eventOwner.id && eventOwner.publicData.businessName}
              onSubmit={(values) => onReportAUser({
                dynamic_template_data: {
                  profileImageURL,
                  email,
                  reason: values.message,
                  deepLink: process.env.REACT_APP_CANONICAL_ROOT_URL + '/proposal/' + createSlug(title) + '/' + txId + '/request'
                }
              }).then(() => setActionModal(false))}
              inProgress={reportUserInProgress}
            />
            : null}
      </Modal>
    </div>
  );
};

const { bool, func } = PropTypes;

EventRequest.propTypes = {
  onUpdateEvent: func.isRequired,
};

export default EventRequest;
