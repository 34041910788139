import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  // ListingLink,
  OwnListingLink,
  IconProfileSetup,
  IconCardDashboard,
  IconShare,
  ResponsiveImage
} from '../../components';
// import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    messages,
    quotes,
    channels,
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    active,
    onClearAllQuotes
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && currentPage !== 'PurchaseAcornsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const { publicData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
  const { userType, userListingId } = publicData || {};

  const menuItems = [];

  if (userType == 'planner') {
    menuItems.push(
      {
        key: "ManageListingsPage",
        id: "TopbarDesktop.eventsDashboard",
        css: css.yourListingsLink,
        pageCss: "ManageListingsPage",
        type: "NamedLink",
        name: "ManageListingsPage",
      });
  } else {
    // menuItems.push({
    //   key: "DashboardPage",
    //   id: userType == 'vendor' ? "TopbarDesktop.vendorDashboard" : "TopbarDesktop.venueDashboard",
    //   css: css.yourListingsLink,
    //   pageCss: "DashboardPage",
    //   type: "NamedLink",
    //   name: "ProposalRequestsPage",
    // });

    menuItems.push({
      key: "ProposalRequestsPage",
      id: "TopbarDesktop.proposalRequests",
      css: css.yourListingsLink,
      pageCss: "ProposalRequestsPage",
      type: "NamedLink",
      name: "ProposalRequestsPage",
    });
  }

  const params = {
    id: currentUserListing && currentUserListing.id && currentUserListing.id.uuid,
    slug: (currentUserListing && currentUserListing.id && createSlug(currentUserListing.attributes.title)) || "",
    city: currentUserListing && currentUserListing.id && currentUserListing.attributes.publicData.location && currentUserListing.attributes.publicData.location.address
      ? createSlug(currentUserListing.attributes.publicData.location.address.split(',')[0])
      : null,
    category: currentUserListing && currentUserListing.id
      ? currentUserListing.attributes.publicData.categoriesLabel
        ? createSlug(currentUserListing.attributes.publicData.categoriesLabel[0])
        : currentUserListing.attributes.publicData.categories
          ? createSlug(currentUserListing.attributes.publicData.categories[0].label)
          : ""
      : "",
    subcategory: currentUserListing && currentUserListing.id
      ? currentUserListing.attributes.publicData.subCategoriesLabel && typeof currentUserListing.attributes.publicData.subCategoriesLabel[0] == 'string'
        ? createSlug(currentUserListing.attributes.publicData.subCategoriesLabel[0])
        : currentUserListing.attributes.publicData.categories && currentUserListing.attributes.publicData.categories[0] && currentUserListing.attributes.publicData.categories[0].subCategory && currentUserListing.attributes.publicData.categories[0].subCategory[0] && currentUserListing.attributes.publicData.categories[0].subCategory[0].label
          ? createSlug(currentUserListing.attributes.publicData.categories[0].subCategory[0].label)
          : ""
      : "",
  };

  if (params && params.id) {
    if (params.city && params.category && params.subcategory) {
      menuItems.push({
        key: "ListingPage",
        id: "TopbarDesktop.profileLink",
        css: css.yourListingsLink,
        pageCss: "ListingPage",
        type: "NamedLink",
        name: 'ListingCityPage',
        params
      });
    } else {
      menuItems.push({
        key: "ListingPage",
        id: "TopbarDesktop.profileLink",
        css: css.yourListingsLink,
        pageCss: "ListingPage",
        type: "NamedLink",
        name: 'ListingPage',
        params
      });
    }
  } else {
    menuItems.push({
      key: "ProfileSetupBasePage",
      id: "TopbarDesktop.editProfile",
      css: classNames(css.profileSettingsLink, currentPageClass("ProfileSetupBasePage")),
      pageCss: "ProfileSetupBasePage",
      type: "NamedLink",
      name: "ProfileSetupBasePage",
    });
  }

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  if (['vendor', 'venue'].includes(userType)) {
    menuItems.push({
      key: "PurchaseAcornsPage",
      id: "TopbarDesktop.purchaseAcorns",
      css: classNames(css.profileSettingsLink, currentPageClass("PurchaseAcornsPage")),
      pageCss: "PurchaseAcornsPage",
      type: "NamedLink",
      name: "PurchaseAcornsPage",
    });
  }

  if (['vendor', 'venue'].includes(userType)) {
    menuItems.push(...[{
      key: "InvoiceBasePage",
      id: "TopbarDesktop.createAnInvoice",
      css: classNames(css.yourListingsLink, currentPageClass('InvoiceBasePage')),
      pageCss: "InvoiceBasePage",
      type: "NamedLink",
      name: "InvoiceBasePage",
    }]);
  } else {
    menuItems.push({
      key: "PaymentBaseDashboardPage",
      id: "TopbarDesktop.viewPayments",
      css: classNames(css.yourListingsLink, currentPageClass('PaymentBaseDashboardPage')),
      pageCss: "PaymentBaseDashboardPage",
      type: "NamedLink",
      name: "PaymentBaseDashboardPage",
    });
    menuItems.push({
      key: "PreferredVendorsPage",
      id: "TopbarDesktop.PreferredVendors",
      css: classNames(css.profileSettingsLink, currentPageClass("PreferredVendorsPage")),
      pageCss: "PreferredVendorsPage",
      type: "NamedLink",
      name: "PreferredVendorsPage",
    });
  }

  menuItems.push(...[
    {
      key: "AccountSettingsPage",
      id: "TopbarDesktop.accountSettings",
      css: classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage')),
      pageCss: "AccountSettingsPage",
      type: "NamedLink",
      name: "AccountSettingsPage",
    },
    {
      key: "logout",
      id: "TopbarDesktop.logout",
      css: classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage')),
      type: "button",
      fn: onLogout,
    }]);

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <span className={css.downArrowProfile}><IconCardDashboard type="downarrow" /></span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {menuItems.map(m =>
          <MenuItem key={m.key}>
            {m.type == "NamedLink"
              ? <NamedLink
                className={classNames(m.css, currentPageClass(m.pageCss))}
                name={m.name}
                params={m.params || {}}
              >
                {m.key == 'InboxPage' && notificationDot
                  ? <span className={css.inbox}>
                    {notificationDot}
                  </span>
                  : <span className={css.menuItemBorder} />}
                <FormattedMessage id={m.id} />
              </NamedLink>
              : m.type == "button"
                ? <InlineTextButton rootClassName={css.logoutButton} onClick={m.fn}>
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.logout" />
                </InlineTextButton>
                : <OwnListingLink
                  listing={currentUserListing}
                  listingFetched={currentUserListingFetched}
                  className={css.yourListingsLink}
                >
                  <div>
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.addYourListingLink" />
                  </div>
                </OwnListingLink>}
          </MenuItem>
        )}
      </MenuContent>
    </Menu >
  ) : null;

  const requestQuotesLink = (
    <NamedLink
      listingFetched={currentUserListingFetched}
      className={css.authButtonWrap}
      name={"ManageListingsPage"}
    >
      <span className={css.signupText}>
        <FormattedMessage id="TopbarDesktop.myDashboard" />
      </span>
    </NamedLink>
  );

  const myDashboard = (
    <NamedLink
      listing={currentUserListing}
      name={"ProposalRequestsPage"}
      listingFetched={currentUserListingFetched}
      className={css.authButtonWrap}
    >
      <span className={css.signupText}>
        <FormattedMessage id={userType == 'vendor' ? "TopbarDesktop.vendorDashboard" : "TopbarDesktop.venueDashboard"} />
      </span>
    </NamedLink>
  );

  const newMessagesLength = messages && messages.length
    ? messages.filter(m => !m.seen).length
    : 0;

  const chatLink = isAuthenticated ? (
    <>
      <Menu contentPosition="left">
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>

          <div className={css.chatIcon}>
            <span className={css.signupText}>
              {newMessagesLength
                ? <span className={css.countNumber}>{newMessagesLength}</span>
                : null}
            </span>
            <span >
              <IconCardDashboard type="chat" />
            </span>
          </div>

        </MenuLabel>
        <MenuContent className={css.OfferMenuContent}>
          <MenuItem key="ManageListingsPage">
            <div className={css.offerDropwownHeader}>
              <h3>
                <FormattedMessage id="TopbarDesktop.MessageDropDownText" />
              </h3>

              {newMessagesLength
                ? <div>
                  <b>{newMessagesLength} <FormattedMessage id="TopbarDesktop.newText" /></b>
                </div>
                : null}
            </div>

            <ul className={css.itemList}>
              {messages && messages.length && channels && channels.length
                ? messages.reverse().map((message, i) => {
                  const sender = {}, receiver = {}, chanel = {};
                  channels.forEach(channel => {
                    if (channel.members && channel.members.length) {
                      const send = channel.members.find(m => m.userId == message.sender);
                      const receive = channel.members.find(m => m.userId != message.sender);
                      if (message.channelId == channel.channelId) {
                        Object.assign(chanel, { ...channel });
                      }
                      Object.assign(sender, { ...send });
                      Object.assign(receiver, { ...receive });
                    }
                  });
                  return sender && Object.keys(sender).length
                    ? (<li key={sender.userId + '-' + i} onClick={() => {
                      const channelId = [sender.userId, receiver.userId].sort().join('_');
                      typeof window !== 'undefined' && window.sessionStorage.setItem('channelId', channelId);
                    }}>
                      <NamedLink
                        name={chanel.type == 'transactional'
                          ? userType == 'planner'
                            ? 'EventDetailPage'
                            : 'ProposalTabPage'
                          : 'ChatPage'}
                        params={userType == 'planner'
                          ? {
                            tab: chanel.tab,
                            slug: chanel.slug,
                            eventId: chanel.eventId,
                          }
                          : {
                            tab: 'messages',
                            slug: chanel.slug,
                            txId: chanel.tab,
                          }}
                        className={css.listItem}
                      >
                        <div className={css.userImg}>
                          <ResponsiveImage
                            alt='Event Image'
                            url={sender.profileImageURL || ''}
                            variants={["scaled-large"]}
                          />
                          {message.seen
                            ? null
                            : <span className={css.highlightMessage}></span>}
                        </div>
                        <div className={css.userMsg}>
                          {sender.name} <FormattedMessage id="TopbarDesktop.sentYouText" /><b><FormattedMessage id="TopbarDesktop.messageText" /></b>.
                        </div>
                      </NamedLink>
                    </li>)
                    : null
                })
                : null}
            </ul>
            {newMessagesLength
              ? <div className={css.seeAllMessage}>
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
                  <path d="M6.83333 7.04167H16.1667M6.83333 11.875H13.8333M18.5 1C19.4283 1 20.3185 1.38192 20.9749 2.06174C21.6312 2.74156 22 3.66359 22 4.625V14.2917C22 15.2531 21.6312 16.1751 20.9749 16.8549C20.3185 17.5347 19.4283 17.9167 18.5 17.9167H12.6667L6.83333 21.5417V17.9167H4.5C3.57174 17.9167 2.6815 17.5347 2.02513 16.8549C1.36875 16.1751 1 15.2531 1 14.2917V4.625C1 3.66359 1.36875 2.74156 2.02513 2.06174C2.6815 1.38192 3.57174 1 4.5 1H18.5Z" stroke="#4661ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <NamedLink
                  name={userType == 'planner' ? 'ManageListingsPage' : 'ProposalRequestsPage'}
                >
                  <FormattedMessage id="TopbarDesktop.sellAllQuotes" />
                </NamedLink>
              </div>
              : null}
          </MenuItem>
        </MenuContent>
      </Menu>
    </>
  ) : null;

  const notificationLink = isAuthenticated ? (
    <Menu contentPosition="left">
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen} disabled={!(quotes && quotes.length)} >

        <div className={css.chatIcon}>
          <span className={css.signupText}>
            {quotes && quotes.length ? <span className={css.countNumber}>{quotes.length}</span> : null}
          </span>
          <span >
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
              <path d="M18 33C19.65 33 21 31.65 21 30H15C15 30.7956 15.3161 31.5587 15.8787 32.1213C16.4413 32.6839 17.2044 33 18 33ZM27 24V16.5C27 11.895 24.54 8.04 20.25 7.02V6C20.25 4.755 19.245 3.75 18 3.75C16.755 3.75 15.75 4.755 15.75 6V7.02C11.445 8.04 9 11.88 9 16.5V24L6 27V28.5H30V27L27 24Z" fill="black" />
            </svg>
          </span>
        </div>

      </MenuLabel>
      <MenuContent className={css.OfferMenuContent}>
        <MenuItem key="ManageListingsPage">
          <div className={css.offerDropwownHeader}>
            <h3>
              <FormattedMessage id="TopbarDesktop.NotificationDropDownText" />
            </h3>

            <div>
              {quotes && quotes.length
                ? <div>
                  <b>{quotes.length} <FormattedMessage id="TopbarDesktop.newText" /></b>
                </div>
                : null}
              <span
                className={css.clearBtn}
                onClick={() => onClearAllQuotes({ userId: currentUser.id.uuid })}
              >
                <u>Clear</u>
              </span>
            </div>
          </div>

          <ul className={css.itemList}>
            {quotes && quotes.length
              ? quotes.map((quote, i) => {
                const { txId, title, eventId, vendor, status, seen, eventOwner } = quote;

                return vendor && vendor.publicData && (<li key={eventId + '-' + i}>
                  <NamedLink
                    name={userType == 'planner' ? 'EventDetailPage': 'ProposalRequestsPage'}
                    params={{
                      txId,
                      eventId,
                      slug: createSlug(title),
                      tab: userType == 'planner' ? "quotes" : "messages",
                    }}
                    className={css.listItem}
                  >
                    <div className={css.userImg}>
                      <ResponsiveImage alt='Event Image' url={vendor.publicData.profileImageURL} variants={["scaled-large"]} />
                      {seen
                        ? null
                        : <span className={css.highlightMessage}></span>}
                    </div>

                    {status == 'EXPIRED'
                      ? <div className={css.userMsg}>
                        <FormattedMessage id="TopbarDesktop.thisText" /> {vendor.publicData.businessName} <FormattedMessage id="TopbarDesktop.qouteHasBeenText" /><b>{status.charAt(0).toUpperCase() + status.substr(1).toLowerCase()}</b>
                      </div>
                      : <div className={css.userMsg}>
                        <FormattedMessage id="TopbarDesktop.doNotForgetReview" />
                          {userType == 'planner'
                            ? <b>{" " + vendor.publicData.businessName}</b>
                            : <b>{" " + eventOwner.publicData.businessName}</b>}
                      </div>}
                  </NamedLink>
                </li>);
              })
              : null}
            {/* <li className={css.listItem}>
              <div className={css.userImg}>
                <ResponsiveImage alt='Event Image' image={""} variants={["scaled-large"]} />
              </div>
              <div className={css.userMsg}>
                Reminder! Your event starts in 24h
              </div>
            </li> */}
          </ul>

        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;


  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.authButtonWrap}>
      <span className={css.signupText}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const signinLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.authButtonWrap}>
      <span className={css.signupText}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const forVendorLink = (
    <NamedLink
      name="CMSPage"
      params={{ pageId: "vendor-information" }}
      className={classNames(css.menuLink, active == "CMSPage" && css.activeLink)}
    >
      <span className={css.menuText}>
        <FormattedMessage id="TopbarDesktop.forVendorLink" />
      </span>
    </NamedLink>
  );

  const forPlannerLink = (
    <NamedLink
      name="CMSPage"
      params={{ pageId: "event-planner-information" }}
      className={classNames(css.menuLink, active == "CMSPage" && css.activeLink)}
    >
      <span className={css.menuText}>
        <FormattedMessage id="TopbarDesktop.forPlannerLink" />
      </span>
    </NamedLink>
  );

  const categoryLink = (
    <NamedLink
      className={classNames(css.menuLink, active == "AlgoliaSearchPage" && css.activeLink)}
      name="AlgoliaSearchPage"
    >
      <span className={css.menuText}>
        <FormattedMessage id="TopbarDesktop.categoryLink" />
      </span>
    </NamedLink>
  );

  const forVenueLink = (
    <NamedLink
      name="CMSPage"
      params={{ pageId: "venue-information" }}
      className={classNames(css.menuLink, active == "CMSPage" && css.activeLink)}
    >
      <span className={css.menuText}>
        <FormattedMessage id="TopbarDesktop.forVenueLink" />
      </span>
    </NamedLink>
  );

  const contactLink = (
    <NamedLink
      name="CMSPage"
      params={{ pageId: "contact-vendortree" }}
      className={classNames(css.menuLink, active == "AboutPage" && css.activeLink)}
    >
      <span className={css.menuText}>
        <FormattedMessage id="TopbarDesktop.contactLink" />
      </span>
    </NamedLink>
  );

  return (
    <div className={css.headerMain}>
      <nav className={classes}>
        <div className={css.navMain}>
          <NamedLink className={css.logoLink} name="LandingPage">
            <Logo
              format="desktop"
              className={css.logo}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            />
          </NamedLink>
          <div className={css.menuContainer}>
            <div className={css.menuList}>
              {categoryLink}
              {isAuthenticated ? null : forVendorLink}
              {isAuthenticated ? null : forVenueLink}
              {isAuthenticated ? null : forPlannerLink}
              {/* {isAuthenticated ? contactLink : null} */}
            </div>
            <div className={css.beforeLoginButtons}>
              {isAuthenticated ? userType == 'planner' ? requestQuotesLink : myDashboard : null}
              {notificationLink}
              {chatLink}
              {signupLink}
              {signinLink}
              {profileMenu}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
