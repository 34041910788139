import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { Modal, NamedLink } from '../../components';
import { EditListingAvailabilityExceptionForm, EditListingLocationForm } from '../../forms';

import css from './EditListingLocationPanel.module.css';

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const { UUID } = sdkTypes;

const KEY_CODE_ENTER = 13;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};
class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
      isEditExceptionsModalOpen: false,
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { title, geolocation, publicData } = currentListing.attributes;
    const { location, city, region, postcode, ...rest } = publicData || {};
    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const { address, building } = location || {};

    return {
      title,
      building,
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin: geolocation, city, region, postcode },
        }
        : null,
      eventDuration: 'singleDay',
      ...rest
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      eventTypes,
      errors,
      availabilityExceptions,
      onManageDisableScrolling,
      fetchExceptionsInProgress,
      onAddAvailabilityException,
      onClearAvailabilityException,
      onDeleteAvailabilityException,
      onFetchCurrentCategories,
      categories,
      subCategories,
      subChildCategories,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    const { publicData } = currentListing.attributes;
    const { eventDuration } = publicData || {};
    const defaultAvailabilityPlan = {
      type: 'availability-plan/time',
      timezone: defaultTimeZone(),
      entries: [
        // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
        // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
        // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
        // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
        // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
        // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
        // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
      ],
    };
    const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;

    // Save exception click handler
    const saveException = values => {
      const { availability, exceptionStartTime, exceptionEndTime } = values;

      // TODO: add proper seat handling
      const seats = 1; // availability === 'available' ? 1 : 0;

      // if (availabilityExceptions && availabilityExceptions.length) {
      //   availabilityExceptions.forEach(ae => onDeleteAvailabilityException({
      //     id: ae.id && ae.id.uuid ? ae.id : new UUID(ae.availabilityExceptionId)
      //   }));
      // };
      return onAddAvailabilityException({
        listingId: listing.id,
        seats,
        start: timestampToDate(exceptionStartTime),
        end: timestampToDate(exceptionEndTime),
      })
        .then(() => {
          this.setState({ isEditExceptionsModalOpen: false });
        })
        .catch(e => {
          // Don't close modal if there was an error
        });
    };

    const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
    const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);

    return (
      <div className={classes}>
        <header className={css.heading}>
          <h2>
            <FormattedMessage id={"EditListingLocationPanel.requestQuotes"} />
          </h2>
          <p>
            <FormattedMessage id={"EditListingLocationPanel.inviteVendorVenue"} />
          </p>
        </header>
        <EditListingLocationForm
          className={css.form}
          eventDuration={eventDuration || this.state.eventDuration}
          initialValues={{ ...this.getInitialValues() }}
          onSubmit={values => {
            const { title, building = '', location, eventType, ...rest } = values;
            const {
              selectedPlace: { address, origin, city, region, postcode },
            } = location;
            const eventTypeImageURL = eventTypes && eventTypes.length && eventType
                ? eventTypes.find(et => et.name == eventType).image
                : '';
            const updateValues = {
              title: title.trim(),
              geolocation: origin,
              publicData: {
                ListingType: 'EVENT',
                location: { address, building },
                city,
                region,
                postcode,
                ...rest,
                eventType,
                eventTypeImageURL,
                datesCount: exceptionCount,
                eventDates: sortedAvailabilityExceptions.map(sae => ({
                  start: sae.attributes.start.getTime(),
                  end: sae.attributes.end.getTime()
                })),
              },
            };
            if (rest.eventSize) {
              // eventSize
              Object.assign(updateValues.publicData, {
                eventSizeLabel: rest.eventSize.label
              });
            }
            this.setState({
              initialValues: {
                building,
                location: { search: address, selectedPlace: { address, origin } },
                ...rest
              },
            });
            return onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          WEEKDAYS={WEEKDAYS}
          availabilityPlan={availabilityPlan}
          exceptionCount={exceptionCount}
          fetchExceptionsInProgress={fetchExceptionsInProgress}
          setIsEditExceptionsModalOpen={(e) => {
            if (this.state.eventDuration != e && e == "singleDay") {
              onClearAvailabilityException();
            }
            this.setState({ isEditExceptionsModalOpen: e, eventDuration: e });
          }}
          sortedAvailabilityExceptions={sortedAvailabilityExceptions}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
        />

        <NamedLink className={css.saveLater} name="LandingPage">
          <FormattedMessage id={"EditListingLocationPanel.saveRequest"} />
        </NamedLink>

        <Modal
          id="EditAvailabilityExceptions"
          isOpen={!!this.state.isEditExceptionsModalOpen}
          onClose={() => this.setState({ isEditExceptionsModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
          className={css.locationTimeModal}
        >
          <EditListingAvailabilityExceptionForm
            formId="EditListingAvailabilityExceptionForm"
            onSubmit={saveException}
            timeZone={availabilityPlan.timezone}
            availabilityExceptions={sortedAvailabilityExceptions}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationPanel;
