import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  AddImages,
  DragAndDrop,
  PrimaryButton,
  FieldTextInput,
  IconProfileSetup,
} from '../../components';

import css from './PlannerBusinessForm.module.css';

const ACCEPT_IMAGES = 'image/*';

export class PlannerBusinessFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false };
    this.handleDrop = this.handleDrop.bind(this);
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  handleDrop(files) {
    if (files && files.length) {
      const filetype = files[0].type;
      if (filetype.search('image') > -1) {
        this.onImageUploadHandler(files);
      }
    }
  };

  onImageUploadHandler = async (files) => {
    if (files && files.length) {
      this.setState({ imageUploadRequested: true });
      this.props.onImagesUpload({ files })
        .then(() => this.setState({ imageUploadRequested: false }))
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        handleDrop={this.handleDrop}
        keepDirtyOnReinitialize={true}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            updateInProgress,
            invalid,
            intl,
            form,
            values,
            images,
            fetchErrors,
            handleDrop,
            onRemoveImage,
            imageUploadRequested,
            onImageUploadHandler,
            initialValues
          } = fieldRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <IconProfileSetup type={"uploadImage"} />
              <span className={css.chooseImage}>
                <FormattedMessage id="PlannerBusinessForm.profilePicAltText" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="PlannerBusinessForm.addImagesTip" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'PlannerBusinessForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="PlannerBusinessForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="PlannerBusinessForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // professionalBio
          const professionalBioLabel = intl.formatMessage({
            id: 'PlannerBusinessForm.professionalBioLabel',
          });
          const professionalBioPlaceholder = intl.formatMessage({
            id: 'PlannerBusinessForm.professionalBioPlaceholder',
          });
          const professionalBioRequiredMessage = intl.formatMessage({
            id: 'PlannerBusinessForm.professionalBioRequired',
          });
          const professionalBioRequired = validators.required(professionalBioRequiredMessage);
          const professionalBioMaxLengthMessage = intl.formatMessage(
            {
              id: 'PlannerBusinessForm.professionalBioTooLong',
            },
            {
              maxLength: validators.DESCRIPTION_MAX_LENGTH,
            }
          );
          const professionalBioMaxLength = validators.maxLength(
            professionalBioMaxLengthMessage,
            validators.DESCRIPTION_MAX_LENGTH
          );

          // website
          const websiteLabel = intl.formatMessage({
            id: 'PlannerBusinessForm.websiteLabel',
          });
          const websitePlaceholder = intl.formatMessage({
            id: 'PlannerBusinessForm.websitePlaceholder',
          });
          const websiteValidMessage = intl.formatMessage({
            id: 'PlannerBusinessForm.websiteValid',
          });
          const websiteValid = validators.websiteFormatValid(websiteValidMessage);

          // instagram
          const instagramLabel = intl.formatMessage({
            id: 'PlannerBusinessForm.instagramLabel',
          });
          const instagramPlaceholder = intl.formatMessage({
            id: 'PlannerBusinessForm.instagramPlaceholder',
          });
          const instagramRequiredMessage = intl.formatMessage({
            id: 'PlannerBusinessForm.instagramRequired',
          });
          const instagramRequired = validators.required(instagramRequiredMessage);

          // facebook
          const facebookLabel = intl.formatMessage({
            id: 'PlannerBusinessForm.facebookLabel',
          });
          const facebookPlaceholder = intl.formatMessage({
            id: 'PlannerBusinessForm.facebookPlaceholder',
          });
          const facebookRequiredMessage = intl.formatMessage({
            id: 'PlannerBusinessForm.facebookRequired',
          });
          const facebookRequired = validators.required(facebookRequiredMessage);


          // linkedin
          const linkedinLabel = intl.formatMessage({
            id: 'PlannerBusinessForm.linkedinLabel',
          });
          const linkedinPlaceholder = intl.formatMessage({
            id: 'PlannerBusinessForm.linkedinPlaceholder',
          });
          const linkedinRequiredMessage = intl.formatMessage({
            id: 'PlannerBusinessForm.linkedinRequired',
          });
          const linkedinRequired = validators.required(linkedinRequiredMessage);

          // tiktok
          const tiktokLabel = intl.formatMessage({
            id: 'PlannerBusinessForm.tiktokLabel',
          });
          const tiktokPlaceholder = intl.formatMessage({
            id: 'PlannerBusinessForm.tiktokPlaceholder',
          });
          const tiktokRequiredMessage = intl.formatMessage({
            id: 'PlannerBusinessForm.tiktokRequired',
          });
          const tiktokRequired = validators.required(tiktokRequiredMessage);

          // Showcase Your Past Events

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || submitInProgress || (!images || (images && (images.length < 1 || images.length > 12)));

          return (
            <Form className={classes} onSubmit={(e => {
              e.preventDefault();
              if (!values.instagram) {
                Object.assign(values, { instagram: null })
              }
              if (!values.tiktok) {
                Object.assign(values, { tiktok: null })
              }
              if (!values.linkedin) {
                Object.assign(values, { linkedin: null })
              }
              if (!values.facebook) {
                Object.assign(values, { facebook: null })
              }
              if (!values.website) {
                Object.assign(values, { website: null })
              }
              handleSubmit(values)
            })}>
              <div className={css.plannerBusinessForm}>
                <FieldTextInput
                  className={css.professionalBioRoot}
                  type="textarea"
                  id={formId ? `${formId}.professionalBio` : 'professionalBio'}
                  name="professionalBio"
                  autoComplete="family-name"
                  label={professionalBioLabel}
                  placeholder={professionalBioPlaceholder}
                  validate={validators.composeValidators(professionalBioRequired, professionalBioMaxLength)}
                />
                <FieldTextInput
                  className={css.inputBoxWebSite}
                  type="text"
                  id={formId ? `${formId}.website` : 'website'}
                  name="website"
                  autoComplete="family-name"
                  label={websiteLabel}
                  placeholder={websitePlaceholder}
                  validate={websiteValid}
                />

                <div className={css.name}>
                  <FieldTextInput
                    className={css.socialInput}
                    type="text"
                    id={formId ? `${formId}.instagram` : 'instagram'}
                    name="instagram"
                    autoComplete="given-name"
                    label={instagramLabel}
                    placeholder={instagramPlaceholder}
                    // validate={instagramRequired}
                    inputIcon={<IconProfileSetup type="instagram" />}
                  />
                  <FieldTextInput
                    className={css.socialInput}
                    type="text"
                    id={formId ? `${formId}.facebook` : 'facebook'}
                    name="facebook"
                    autoComplete="family-name"
                    label={facebookLabel}
                    placeholder={facebookPlaceholder}
                    // validate={facebookRequired}
                    inputIcon={<IconProfileSetup type="facebook" />}
                  />
                </div>

                <div className={css.name}>
                  <FieldTextInput
                    className={css.socialInput}
                    type="text"
                    id={formId ? `${formId}.linkedin` : 'linkedin'}
                    name="linkedin"
                    autoComplete="given-name"
                    label={linkedinLabel}
                    placeholder={linkedinPlaceholder}
                    // validate={linkedinRequired}
                    inputIcon={<IconProfileSetup type="linkedin" />}
                  />
                  <FieldTextInput
                    className={css.socialInput}
                    type="text"
                    id={formId ? `${formId}.tiktok` : 'tiktok'}
                    name="tiktok"
                    autoComplete="family-name"
                    label={tiktokLabel}
                    placeholder={tiktokPlaceholder}
                    // validate={tiktokRequired}
                    inputIcon={<IconProfileSetup type="tiktok" />}
                  />
                </div>
                <hr className={css.dividerLine} />
                <div>
                  <h4 className={css.eventTitle}>
                    <FormattedMessage id="PlannerBusinessForm.addImagesLabel" />
                    {" "}
                    <span className={css.infoTooltip}>
                      <svg fill="#000000" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M12,10 C12.5522847,10 13,10.4477153 13,11 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,11 C11,10.4477153 11.4477153,10 12,10 Z M12,6 C12.5522847,6 13,6.44771525 13,7 C13,7.55228475 12.5522847,8 12,8 C11.4477153,8 11,7.55228475 11,7 C11,6.44771525 11.4477153,6 12,6 Z" />
                      </svg>
                      <div className={css.showTooltip}>
                        <div>
                          <b><FormattedMessage id="PlannerBusinessForm.addImageTipOne" /></b>
                        </div>
                        <div><FormattedMessage id="PlannerBusinessForm.addImageTipTwo" /></div>
                      </div>
                    </span>
                  </h4>
                  {/* Drag & Drop or Click to Add Photos 6-12 recommended. */}
                  <AddImages
                    className={classNames({
                      [css.imagesField]: images?.length,
                      [css.fullWidthField]: !images?.length
                    })}
                    images={images}
                    thumbnailClassName={css.thumbnail}
                    savedImageAltText={intl.formatMessage({
                      id: 'PlannerBusinessForm.profilePicAltText',
                    })}
                    onRemoveImage={onRemoveImage}
                  >
                    <DragAndDrop
                      dragdrop={css.thumbnail}
                      handleDrop={handleDrop}
                    >
                      <Field
                        id="addImage"
                        name="addImage"
                        accept={ACCEPT_IMAGES}
                        form={null}
                        label={chooseImageText}
                        type="file"
                        disabled={imageUploadRequested}
                      >
                        {fieldprops => {
                          const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange = e => {
                            const file = e.target.files[0];
                            form.change(`addImage`, file);
                            form.blur(`addImage`);
                            onImageUploadHandler(e.target.files);
                          };
                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <div className={css.addImageWrapper}>
                              <div className={css.aspectRatioWrapper}>
                                {fieldDisabled ? null : (
                                  <input {...inputProps} multiple className={css.addImageInput} />
                                )}
                                <label htmlFor={name} className={classNames(css.addImage, {
                                  [css.fullWidthWrapper]: !images?.length
                                })}>
                                  {label}
                                </label>
                              </div>
                            </div>
                          );
                        }}
                      </Field>
                    </DragAndDrop>
                    {/* {(!images || (images && images.length < 6)) &&  <p className={css.error}>{imageRequiredMessage}</p>} */}
                  </AddImages>
                  {uploadImageFailed}
                </div>
              </div>

              <div className={css.bottomWrapper}>
                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                  <FormattedMessage id="PlannerBusinessForm.submit" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    )
  }
};

PlannerBusinessFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

PlannerBusinessFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const PlannerBusinessForm = compose(injectIntl)(PlannerBusinessFormComponent);
PlannerBusinessForm.displayName = 'PlannerBusinessForm';

export default PlannerBusinessForm;
