import React from 'react';
import { NamedLink } from '..';

import css from './Breadcrumbs.module.css';

const Breadcrumbs = props => {
  const {
    lvl1 = { name: "AlgoliaSearchPage", label: "Search" },
    lvl2,
    lvl3
  } = props;

  // Homepage / Search / Food & Drink Vendors | Florida / Digby’s Doughnuts
  return <div className={css.breadcrumb}>
    <NamedLink
      name={"LandingPage"}
      className={css.breadcrumbLink}
    >
      Homepage
    </NamedLink>
    {lvl1 && lvl1.name
      ? <>
        <span className={css.breadcrumbIcon}> / </span>
        <NamedLink
          name={lvl1.name}
          className={css.breadcrumbLink}
        >
          {lvl1.label}
        </NamedLink>
      </>
      : null}
    {lvl2 && lvl2.name
      ? <>
        <span className={css.breadcrumbIcon}> / </span>
        <NamedLink
          name={lvl2.name}
          className={css.breadcrumbLink}
          to={{ search: lvl2.search }}
        >
          {lvl2.label}
        </NamedLink>
      </>
      : null}
    {lvl3
      ? <>
        <span className={css.breadcrumbIcon}> / </span>
        <span className={css.breadcrumbItem}>{lvl3.label}</span>
      </>
      : null}
  </div>;
};

export default Breadcrumbs;