import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm, Field } from 'react-final-form';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { isUploadImageOverLimitError } from '../../util/errors';
import { composeValidators, websiteFormatValid, DESCRIPTION_MAX_LENGTH, maxLength, required } from '../../util/validators';
import {
  Form,
  Button,
  AddImages,
  FieldTextInput,
  IconProfileSetup,
  FieldCheckboxGroup,
  DragAndDrop,
  ColorPicker,
  IconClose
} from '../../components';

import css from './EditListingPhotosForm.module.css';

const ACCEPT_IMAGES = 'image/*';

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false };
    this.handleDrop = this.handleDrop.bind(this);
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  handleDrop(files) {
    if (files && files.length) {
      const filetype = files[0].type;
      if (filetype.search('image') > -1) {
        this.onImageUploadHandler(files);
      }
    }
  };

  onImageUploadHandler = async (files) => {
    if (files && files.length) {
      this.setState({ imageUploadRequested: true });
      this.props.onImageUpload({ files })
        .then(() => this.setState({ imageUploadRequested: false }));
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        handleDrop={this.handleDrop}
        keepDirtyOnReinitialize={true}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            values,
            invalid,
            handleDrop,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            // ready,
            saveActionMsg,
            updateInProgress,
            colorCodesOptions,
          } = formRenderProps;

          const chooseImageText = (
            <div className={css.uploadBox}>
              <IconProfileSetup type="upload" />
              <div className={css.uploadText}>
                <FormattedMessage id="EditListingPhotosForm.dragDropClick" />
                <FormattedMessage id="EditListingPhotosForm.recommended" />
              </div>
            </div>
          );

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const pinterestLinkLabel = intl.formatMessage({ id: 'EditListingPhotosForm.pinterestLink' });
          const pinterestLinkPlaceholder = intl.formatMessage({
            id: 'EditListingPhotosForm.pinterestLinkPlaceholder',
          });

          const pinterestValidMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.pinterestValid',
          });
          const pinterestValid = composeValidators(websiteFormatValid(pinterestValidMessage)); //, required('This Field is Required')

          const colorCodesLabel = values.colorCodes && values.colorCodes.length
            ? intl.formatMessage({ id: "EditListingPhotosForm.anotherColor" })
            : intl.formatMessage({ id: 'EditListingPhotosForm.colorCodes' });

          const themeLabel = intl.formatMessage({ id: 'EditListingPhotosForm.theme' });
          const themePlaceholder = intl.formatMessage({
            id: 'EditListingPhotosForm.themePlaceholder',
          });
          const themeRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.themeRequired',
          });
          const descriptionLabel = intl.formatMessage({ id: 'EditListingPhotosForm.description' });
          const descriptionPlaceholder = intl.formatMessage({
            id: 'EditListingPhotosForm.descriptionPlaceholder',
          });

          const maxLengthMessage = intl.formatMessage(
            { id: 'EditListingPhotosForm.maxLength' },
            {
              maxLength: DESCRIPTION_MAX_LENGTH,
            }
          );
          const maxLength350Message = maxLength(maxLengthMessage, DESCRIPTION_MAX_LENGTH);
          const descriptionRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.descriptionRequired',
          });

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          // const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const colorCodeRequired = (values.colorCodes && values.colorCodes.length);
          const submitDisabled = invalid || disabled || submitInProgress || imageUploadRequested; // || (!images || (images && (images.length < 1 || images.length > 12))) || !colorCodeRequired;
          const removeColor = (val) => {
            let filterColor = (values && values.colorCodes && values.colorCodes.length) ? values.colorCodes.filter((color) => color != val) : null;
            form.change('colorCodes', filterColor)
          }
          const classes = classNames(css.root, className);

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e).then(() => form.restart());
              }}
            >
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}
              <AddImages
                className={images.length > 0 ? css.imagesField : css.fullField}
                images={images}
                thumbnailClassName={css.thumbnail}
                savedImageAltText={intl.formatMessage({
                  id: 'EditListingPhotosForm.savedImageAltText',
                })}
                onRemoveImage={onRemoveImage}
              >
                <DragAndDrop
                  dragdrop={css.thumbnail}
                  handleDrop={handleDrop}
                >
                  <Field
                    id="addImage"
                    name="addImage"
                    accept={ACCEPT_IMAGES}
                    form={null}
                    label={chooseImageText}
                    type="file"
                    disabled={uploadImageFailed ? false : imageUploadRequested}
                  >
                    {fieldprops => {
                      const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        form.change(`addImage`, file);
                        form.blur(`addImage`);
                        onImageUploadHandler(e.target.files);
                      };
                      const inputProps = { accept, id: name, name, onChange, type };
                      return (
                        <div className={images.length > 0 ? css.addImageWrapper : css.blankUpload}>
                          <div className={css.aspectRatioWrapper}>
                            {fieldDisabled ? null : (
                              <input {...inputProps} multiple className={css.addImageInput} />
                            )}
                            <label htmlFor={name} className={css.addImage}>
                              {label}
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                </DragAndDrop>
              </AddImages>
              {uploadImageFailed}

              <div className={css.photoInputSection}>
                <FieldTextInput
                  className={css.inputBoxWebSite}
                  type="text"
                  id={'pinterestLink'}
                  name="pinterestLink"
                  label={pinterestLinkLabel}
                  placeholder={pinterestLinkPlaceholder}
                  validate={pinterestValid}
                />
                <div className={css.colorCodeInput}>
                  <div className={css.colorPicker}>
                    <label htmlFor={'colorCode'}>{colorCodesLabel}</label>
                    <ColorPicker
                      name='colorCode'
                      error={colorCodeRequired}
                      colorCodes={[...new Set(values.colorCodes)]}
                      onChange={(colors) => form.change("colorCodes", colors)}
                    />
                  </div>

                  {/* {values.colorCodes && values.colorCodes.length
                    ? values.colorCodes.map(cc =>
                      <span key={cc} className={css.addColorCode}>
                        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="22.5" cy="22.5" r="22.5" fill={cc} />
                        </svg>
                        <span className={css.removelabel} ><IconClose onClick={() => removeColor(cc)} /></span>
                      </span>)
                    : null} */}
                </div>

                <div className={css.categoriesCheckbox}>
                  <FieldCheckboxGroup
                    className={css.features}
                    id={"selectedColorCodes"}
                    name={"selectedColorCodes"}
                    options={colorCodesOptions}
                  />
                </div>
                {/* Theme */}
                <FieldTextInput
                  className={css.themeInput}
                  type="text"
                  id={"theme"}
                  name={"theme"}
                  label={themeLabel}
                  placeholder={themePlaceholder}
                  // validate={composeValidators(required(themeRequiredMessage), maxLength350Message)}
                />
                {/* Describe The Vibe */}
                <FieldTextInput
                  className={css.descriptionBox}
                  type="textarea"
                  id={'description'}
                  name="description"
                  label={descriptionLabel}
                  placeholder={descriptionPlaceholder}
                  // validate={composeValidators(required(descriptionRequiredMessage), maxLength350Message)}
                />
              </div>
              {publishListingFailed}
              {showListingFailed}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              // ready={submitReady}
              >
                {saveActionMsg}
                <span className={css.arrowIcon}>
                  <IconProfileSetup type="arrow" />
                </span>
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [], colorCodesOptions: config.custom.colorCodes };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  // ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
