import React, { useState } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import routeConfiguration from '../../routeConfiguration';
import {
  createSlug,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { timestampToDate } from '../../util/dates';
import { updatePublishedEvent } from '../../util/api';
import { createResourceLocatorString } from '../../util/routes';
import { ensureListing, parseToFromSharetribe } from '../../util/data';

import {
  EditListingAvailabilityPanel,
  EditListingDescriptionPanel,
  EditListingFeaturesPanel,
  EditListingLocationPanel,
  EditListingPhotosPanel,
  EditListingPoliciesPanel,
  EditListingPricingPanel,
  Modal,
  PrimaryButton,
} from '../../components';

import css from './EditListingWizard.module.css';

export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const POLICY = 'policy';
export const LOCATION = 'basics';
export const PRICING = 'requirement';
export const PHOTOS = 'style';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  DESCRIPTION,
  FEATURES,
  POLICY,
  LOCATION,
  PRICING,
  AVAILABILITY,
  PHOTOS,
];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const { type, slug } = params;
  const currentPathParams = {
    ...params,
    type: type != 'new' ? type : LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);

    // Redirect to next tab
    const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
    const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
    history.push(to);
    return { listingId, draftURI };
  }

  if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
    return history.push(createResourceLocatorString(
      'EventDetailPage',
      routeConfiguration(),
      { eventId: listingId, slug, tab: "details" }
    ));
  }
  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onAddAvailabilityException,
    onClearAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    fetchExceptionsInProgress,
    availabilityExceptions,
    onFetchCurrentCategories,
    categories,
    subCategories,
    subChildCategories,
    isMobileLayout,
    eventTypes,
  } = props;

  const { id, type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isEditMode = type === LISTING_PAGE_PARAM_TYPE_EDIT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const [isOpenConfirmationModal, toggleConfirmationModal] = useState(false);
  const [inProgress, setProgress] = useState(false);

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const onCompleteEditListingWizardTab = (tab, updateValues, passThrownErrors = false) => {
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== AVAILABILITY && tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            return redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            handlePublishListing(currentListing.id);
          }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id })
        .then(r => {
          // After successful saving of draft data, user should be redirected to next tab
          redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
        });
    }
  };

  const onUpdatePublishedListing = (id, title) => {
    if (inProgress) return;
    setProgress(true);
    return updatePublishedEvent({ listingId: id })
      .then(res => {
        setProgress(false);
        return history.push(createResourceLocatorString(
          'EventDetailPage',
          routeConfiguration(),
          { eventId: id, slug: createSlug(title), tab: "details" }
        ));
      });
  }


  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
    };
  };

  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingDescriptionPanel
          {...panelProps(DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingFeaturesPanel
          {...panelProps(FEATURES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case POLICY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPolicies'
        : 'EditListingWizard.saveEditPolicies';
      return (
        <EditListingPoliciesPanel
          {...panelProps(POLICY)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          {...panelProps(LOCATION)}
          {...panelProps(AVAILABILITY)}
          eventTypes={eventTypes}
          fetchExceptionsInProgress={fetchExceptionsInProgress}
          availabilityExceptions={availabilityExceptions}
          onAddAvailabilityException={onAddAvailabilityException}
          onClearAvailabilityException={onClearAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            return onCompleteEditListingWizardTab(tab, values)
              .then(res => {
                const { listingId } = res || {};
                const { eventDates } = values.publicData || {};
                if (eventDates && eventDates.length && (isNewURI && id === '00000000-0000-0000-0000-000000000000') && listingId) {
                  onClearAvailabilityException();
                  onAddAvailabilityException({
                    listingId,
                    seats: 1,
                    start: timestampToDate(eventDates[0].start),
                    end: timestampToDate(eventDates[0].end),
                  });
                  return listingId;
                }
              });
          }}
        />
      );
    }
    case PRICING: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricing'
        : 'EditListingWizard.saveEditPricing';
      return (
        <EditListingPricingPanel
          {...panelProps(PRICING)}
          onFetchCurrentCategories={onFetchCurrentCategories}
          categories={categories}
          subCategories={subCategories}
          isMobileLayout={isMobileLayout}
          subChildCategories={subChildCategories}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            const { id, ...rest } = values;
            const { categories: newCategories, price } = parseToFromSharetribe(rest, categories, null, 'DETAILED_QUOTE_FORM');
            const subCategoriesLabel = [],
              categoriesLabel = newCategories && newCategories.length
                ? newCategories.map(d => {
                  if (d.subCategory && d.subCategory.length) {
                    subCategoriesLabel.push(...d.subCategory.map(s => s.label));
                  }
                  return d.label;
                })
                : [];
            const updateListingVariables = {
              id,
              publicData: {
                categories: newCategories,
                categoriesLabel,
                subCategoriesLabel,
              }
            }
            if (price) {
              Object.assign(updateListingVariables, { price });
            }
            return onCompleteEditListingWizardTab(tab, updateListingVariables);
          }}
        />
      );
    }
    case AVAILABILITY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAvailability'
        : 'EditListingWizard.saveEditAvailability';
      return (
        <EditListingAvailabilityPanel
          {...panelProps(AVAILABILITY)}
          fetchExceptionsInProgress={fetchExceptionsInProgress}
          availabilityExceptions={availabilityExceptions}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onAddAvailabilityException={onAddAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          onSubmit={values => {
            // We want to return the Promise to the form,
            // so that it doesn't close its modal if an error is thrown.
            return onCompleteEditListingWizardTab(tab, values, true);
          }}
          onNextTab={() =>
            redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
          }
        />
      );
    }
    case PHOTOS: {
      const submitButtonTranslationKey = 'EditListingWizard.saveNewPhotos';

      return (
        <div>
          <Modal
            onManageDisableScrolling={onManageDisableScrolling}
            id='confirmationModal'
            usePortal
            onClose={() => toggleConfirmationModal(false)}
            isOpen={isOpenConfirmationModal} >
            <h3>{intl.formatMessage({ id: "EditListingWizard.confirmationModal" })} </h3>

            <div className={css.actionBtns}>
              <PrimaryButton
                onClick={() => type == "SKIP" && isEditMode
                  ? onUpdatePublishedListing(listing.id.uuid, listing.attributes.title)
                  : handlePublishListing(currentListing.id)}
                inProgress={inProgress}
              >
                Continue
              </PrimaryButton>
              <PrimaryButton
                onClick={() => toggleConfirmationModal(false)}
              >
                Cancel
              </PrimaryButton>
            </div>
          </Modal>
          <EditListingPhotosPanel
            {...panelProps(PHOTOS)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            images={images}
            eventTypes={eventTypes}
            onImageUpload={onImageUpload}
            onRemoveImage={onRemoveImage}
            onSubmit={(values, type) => {
              const { id, images, ...rest } = values;
              return onCompleteEditListingWizardTab(tab, {
                id, images, publicData: {
                  ...rest
                }
              })
                .then(res => type == "SKIP" && toggleConfirmationModal(type));

            }}
            onUpdateImageOrder={onUpdateImageOrder}
          />

        </div>
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
};

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
