import React from 'react';
import { arrayOf, func, node, oneOf, shape, string } from 'prop-types';
import Slider from "react-slick";
// Block components
import BlockDefault from './BlockDefault';

import css from './BlockBuilder.module.css';

///////////////////////////////////////////
// Mapping of block types and components //
///////////////////////////////////////////

const defaultBlockComponents = {
  defaultBlock: { component: BlockDefault },
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="30px" height="30px" viewBox="0 0 1.8 1.8" fill="none" xmlns="http://www.w3.org/2000/svg"><path width="48" height="48" fill="white" fillOpacity="0.01" d="M0 0H1.8V1.8H0V0z" /><path d="m0.713 0.45 0.45 0.45 -0.45 0.45" stroke="#000000" strokeWidth="0.15" strokeLinecap="round" strokeLinejoin="round" /></svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="30px" height="30px" viewBox="0 0 1.8 1.8" fill="none" xmlns="http://www.w3.org/2000/svg"><path width="48" height="48" fill="white" fillOpacity="0.01" d="M0 0H1.8V1.8H0V0z" /><path d="M1.162 1.35 0.713 0.9l0.45 -0.45" stroke="#000000" strokeWidth="0.15" strokeLinecap="round" strokeLinejoin="round" /></svg>
    </div>
  );
}

////////////////////
// Blocks builder //
////////////////////

const BlockBuilder = props => {
  const { blocks, options, sectionId, ...otherProps } = props;

  // Extract block & field component mappings from props
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const { blockComponents, fieldComponents } = options || {};
  const blockOptionsMaybe = fieldComponents ? { options: { fieldComponents } } : {};

  // If there's no block, we can't render the correct block component
  if (!blocks || blocks.length === 0) {
    return null;
  }

  // Selection of Block components
  // Combine component-mapping from props together with the default one:
  const components = { ...defaultBlockComponents, ...blockComponents };

  const MAX_MOBILE_SCREEN_WIDTH = 1024;

  const settings = {
    speed: 800,
    slidesToShow: 6,
    arrows: true,
    infinite: true,
    dots: false,
    centerMode: false,
    slidesToScroll: 2,
    draggable: false,
    cssEase: 'linear',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          speed: 600,
          infinite: true,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          infinite: true,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };


  const eventSizeSettings = {
    centerMode: true,
    initialSlide: 0,
    swipeToSlide: true,
    speed: 800,
    slidesToShow: typeof window !== 'undefined' && window.innerWidth < MAX_MOBILE_SCREEN_WIDTH ? 1 : 3.8,
    arrows: true,
    infinite: true,
    dots: false,
    // slidesToScroll: 2,
    draggable: true,
    // cssEase: 'linear',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 3,
                infinite: true,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                infinite: true,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1.7,
                slidesToScroll: 1,
                initialSlide: 1,
                centerMode: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                centerMode: false,
            },
        },
    ],
};


  if (sectionId === 'location-hero') {
    return (
      <div>
        <Slider {...settings} className={css.swiperSlide}>
          {blocks.map(block => {
            const config = components[block.blockType];
            const Block = config?.component;
            if (Block) {
              return <Block key={block.blockId} {...block} {...blockOptionsMaybe} {...otherProps} />;
            } else {
              // If the block type is unknown, the app can't know what to render
              console.warn(`Unknown block type (${block.blockType}) detected.`);
              return null;
            }
          })}
        </Slider>
      </div>
    )
  }
  if (sectionId === 'event-sizes') {
    return (
      <div>
      <Slider {...eventSizeSettings} className={css.swiperSlide}>
        {blocks.map(block => {
          const config = components[block.blockType];
          const Block = config?.component;
          if (Block) {
            return <Block key={block.blockId} {...block} {...blockOptionsMaybe} {...otherProps} />;
          } else {
            // If the block type is unknown, the app can't know what to render
            console.warn(`Unknown block type (${block.blockType}) detected.`);
            return null;
          }
        })}
      </Slider>
    </div>
    )
  }


  return (
    <>

      {blocks.map(block => {
        const config = components[block.blockType];
        const Block = config?.component;
        if (Block) {
          return <Block key={block.blockId} {...block} {...blockOptionsMaybe} {...otherProps} />;
        } else {
          // If the block type is unknown, the app can't know what to render
          console.warn(`Unknown block type (${block.blockType}) detected.`);
          return null;
        }
      })}
    </>
  );
};

const propTypeBlock = shape({
  blockId: string.isRequired,
  blockType: oneOf(['defaultBlock']).isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
});

BlockBuilder.defaultProps = {
  blocks: [],
  options: null,
  responsiveImageSizes: null,
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
};

BlockBuilder.propTypes = {
  blocks: arrayOf(propTypeBlock),
  options: propTypeOption,
  responsiveImageSizes: string,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
};

export default BlockBuilder;
