import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import IconClose from '../IconClose/IconClose';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ColorPicker.module.css';

class ColorPicker extends React.Component {

    state = {
        displayColorPicker: false,
        color: {
            r: '241',
            g: '112',
            b: '19',
            a: '1',
        },
        colors: [],
        selectedColorIndex: null,
        showPicker: false,
        newColor: '#FFB163', // Default color
    };
    componentDidMount() {
        const { colorCodes } = this.props;
        this.setState({ colors: colorCodes });
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    // handleClose = () => {
    //     this.setState({ displayColorPicker: false, touched: true })
    // };

    // handleChange = (color) => {
    //     this.setState({ color: color.rgb })
    // };

    handleAddColor = () => {
        const { showPicker, selectedColorIndex } = this.state;

        if (showPicker && selectedColorIndex !== null) {
            // If the picker is open for an existing color, change the color
            this.setState((prevState) => {
                const updatedColors = [...prevState.colors];
                updatedColors[selectedColorIndex] = prevState.newColor;
                this.props.onChange(updatedColors)
                return {
                    colors: updatedColors,
                    showPicker: false,
                    selectedColorIndex: null,
                };
            });
        } else {
            // If the picker is open for adding a new color, confirm the new color
            this.setState((prevState) => {
                this.props.onChange([...prevState.colors, prevState.newColor]);

                return {
                    colors: [...prevState.colors, prevState.newColor],
                    showPicker: false,
                }
            });
        }
    };

    handleShowPicker = (index) => {
        this.setState((prevState) => ({
            showPicker: !prevState.showPicker,
            selectedColorIndex: index,
        }));
    };

    handleRemoveColor = (index) => {
        this.setState((prevState) => {
            const updatedColors = [...prevState.colors];
            updatedColors.splice(index, 1);
            this.props.onChange(updatedColors)
            return { colors: updatedColors };
        });
    };


    render() {
        const {
            colors,
            showPicker,
            newColor,
            selectedColorIndex
        } = this.state;

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state?.newColor
                        ? this.state.newColor
                        : `#ffb16`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    left: '5px',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
                error: {
                    color: 'var(--failColor)'
                }
            },
        });

        return (
            <div>
                <button type={'button'} onClick={() => [this.setState({ showPicker: !showPicker }), this.handleClick]} className={css.buttonStyle}>
                    {/* <div style={styles.color} /> */}
                    <FormattedMessage id="ColorPicker.chooseColor" />
                </button>
                {this.state.touched && !this.props.error
                    ? <p style={styles.error}><FormattedMessage id="ColorPicker.selectOneColor" /></p>
                    : null}

                {showPicker && (
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleAddColor} />
                        <SketchPicker
                            color={newColor}
                            onChange={(color) => this.setState({ newColor: color.hex })}
                        />
                        <button
                            type={'button'}
                            onClick={this.handleAddColor}
                            style={{
                                fontWeight: 700,
                                cursor: "pointer",
                                width: "100%",
                                display: "block",
                                backgroundColor: "#000",
                                color: "#fff",
                                cursor: "pointer",
                                position: "relative",
                                zIndex: 20
                            }}>
                                <FormattedMessage id="ColorPicker.addColor" />
                        </button>
                    </div>
                )}

                <div style={{ marginTop: '10px' }}>
                    {colors.map((color, index) => (
                        <div key={index} style={{ display: 'inline-block', marginRight: '15px', position: "relative" }}>
                            <div
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundColor: color,
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    cursor: 'pointer',

                                }}
                                onClick={() => this.handleShowPicker(index)}
                            />
                            <span style={{ position: "absolute", right: "-3px", top: "-10px", color: "#000", }} onClick={() => this.handleRemoveColor(index)}><IconClose /></span>

                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default ColorPicker;