import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import config from '../../config';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import getCountryCodes from '../../translations/countryCodes';
import { isUploadImageOverLimitError } from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import {
  Form,
  Avatar,
  FieldSelect,
  DragAndDrop,
  IconSpinner,
  ImageFromFile,
  FieldCheckbox,
  PrimaryButton,
  FieldTextInput,
  IconProfileSetup,
  FieldPhoneNumberInput,
  LocationAutocompleteInputField,
  IconClose,
} from '../../components';

import * as normalizePhoneNumberUS from './normalizePhoneNumberUS';

import css from './VendorBasicsForm.module.css';

const KEY_CODE_ENTER = 13;
const identity = v => v;

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

export class VendorBasicsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadDelay: false,
      imageUploadRequested: false,
    };

    this.handleDrop = this.handleDrop.bind(this);
    this.submittedImages = [];

    this.uploadDelayTimeoutId = null;
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  handleDrop(files, form) {
    const { onImageUpload } = this.props;

    if (files && files.length) {
      const file = files[0];
      const filetype = file.type;
      if (filetype.search('image') > -1) {
        const tempId = `${file.name}_${Date.now()}`;
        onImageUpload({ id: tempId, file });
      }
    }
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        keepDirtyOnReinitialize={true}
        handleDrop={this.handleDrop}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            formId,
            handleSubmit,
            invalid,
            intl,
            form,
            values,
            handleDrop,
            onImageUpload,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  {/* <FormattedMessage id="ProfileSettingsForm.changeAvatar" /> */}
                </div>
              </div>
            ) : (
              <div className={css.chooseImageText}>
                <IconProfileSetup type={"uploadImage"} />
                <div className={css.chooseImageBox}>
                  <div className={css.chooseImagedesktop}>
                    <FormattedMessage id="PlannerBasicsForm.profilePicAltUploadText" />
                  </div>
                  {/* <div className={css.chooseImagedesktop}>
                    <FormattedMessage id="PlannerBasicsForm.profilePicAltTextmobile" />
                  </div> */}
                  <span className={css.imageTypes}>
                    <FormattedMessage id="PlannerBasicsForm.addImagesTip" />
                  </span>
                </div>
              </div>
            );

          // firstName
          const firstNameLabel = intl.formatMessage({
            id: 'VendorBasicsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'VendorBasicsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'VendorBasicsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // lastName
          const lastNameLabel = intl.formatMessage({
            id: 'VendorBasicsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'VendorBasicsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'VendorBasicsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // businessName
          const businessNameLabel = intl.formatMessage({
            id: 'VendorBasicsForm.businessNameLabel',
          });
          const businessNamePlaceholder = intl.formatMessage({
            id: 'VendorBasicsForm.businessNamePlaceholder',
          });
          const businessNameRequiredMessage = intl.formatMessage({
            id: 'VendorBasicsForm.businessNameRequired',
          });
          const businessNameRequired = validators.required(businessNameRequiredMessage);

          // Phone
          const phoneLabel = intl.formatMessage({ id: 'VendorBasicsForm.phoneLabel' });
          const phonePlaceholder = intl.formatMessage({ id: 'VendorBasicsForm.personalPhonePlaceholder' });
          const phoneNumberForUSRequired = validators.required(
            intl.formatMessage({ id: 'VendorBasicsForm.personalPhoneRequired' })
          );

          const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
          const countryPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.countryPlaceholder' });
          const countryRequired = validators.required(
            intl.formatMessage({
              id: 'StripePaymentAddress.countryRequired',
            })
          );

          const titleRequiredMessage = intl.formatMessage({ id: 'VendorBasicsForm.address' });
          const addressPlaceholderMessage = intl.formatMessage({
            id: 'VendorBasicsForm.addressPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'VendorBasicsForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'VendorBasicsForm.addressNotRecognized',
          });

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || submitInProgress || !(profileImage && Object.keys(profileImage).length && profileImage.imageId);
          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          // Radio
          // const workRemoteLabel = intl.formatMessage({
          //   id: 'VendorBasicsForm.workRemoteLabel',
          // });
          const countryCodes = getCountryCodes(config.locale, ['CA', 'US', 'IN']);

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.vendorPage}>
                <div className={css.topBoxWrapper}>
                  <div className={css.imageUploadWrapper}>
                    {/* Your Profile Picture Drag & Drop or Click */}
                    <DragAndDrop
                      dragdrop={css.thumbnail}
                      handleDrop={(e) => handleDrop(e, form)}
                    >
                      <Field
                        accept={ACCEPT_IMAGES}
                        id="profileImage"
                        name="profileImage"
                        label={chooseAvatarLabel}
                        type="file"
                        form={null}
                        uploadImageError={uploadImageError}
                        disabled={uploadInProgress}
                      >
                        {fieldProps => {
                          const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                          const { name, type } = input;
                          const onChange = e => {
                            const file = e.target.files[0];
                            form.change(`profileImage`, file);
                            form.blur(`profileImage`);
                            if (file != null) {
                              const tempId = `${file.name}_${Date.now()}`;
                              onImageUpload({ id: tempId, file });
                            }
                          };

                          let error = null;

                          if (isUploadImageOverLimitError(uploadImageError)) {
                            error = (
                              <div className={css.error}>
                                <div className={css.chooseImagedesktop}>
                                  <FormattedMessage id="PlannerBasicsForm.profilePicAltUploadText" />
                                </div>
                                <span className={css.imageTypes}>
                                  <FormattedMessage id="PlannerBasicsForm.addImagesTip" />
                                </span>
                                <br /><br />
                                <FormattedMessage id="PlannerBasicsForm.imageUploadFailedFileTooLarge" />
                              </div>
                            );
                          } else if (uploadImageError) {
                            error = (
                              <div className={css.error}>
                                <div className={css.chooseImagedesktop}>
                                  <FormattedMessage id="PlannerBasicsForm.profilePicAltUploadText" />
                                </div>
                                <span className={css.imageTypes}>
                                  <FormattedMessage id="PlannerBasicsForm.addImagesTip" />
                                </span>
                                <br /><br />
                                <FormattedMessage id="PlannerBasicsForm.imageUploadFailed" />
                              </div>
                            );
                          }

                          return (
                            <div className={css.addImageWrapper}>
                              <div className={css.aspectRatioWrapper}>
                                <label className={css.addImage} htmlFor={id}>
                                  {error ? error : label}
                                </label>
                                <input
                                  accept={accept}
                                  id={id}
                                  name={name}
                                  className={css.addImageInput}
                                  disabled={disabled}
                                  onChange={onChange}
                                  type={type}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </Field>
                    </DragAndDrop>

                  </div>
                  <div className={css.namesBox}>
                    <FieldTextInput
                      className={css.firstNameRoot}
                      type="text"
                      id={formId ? `${formId}.firstName` : 'firstName'}
                      name="firstName"
                      autoComplete="given-name"
                      label={firstNameLabel}
                      placeholder={firstNamePlaceholder}
                      validate={firstNameRequired}
                    />
                    <FieldTextInput
                      className={css.firstNameRoot}
                      type="text"
                      id={formId ? `${formId}.lastName` : 'lastName'}
                      name="lastName"
                      autoComplete="family-name"
                      label={lastNameLabel}
                      placeholder={lastNamePlaceholder}
                      validate={lastNameRequired}
                    />
                  </div>
                </div>
                <FieldTextInput
                  className={css.businessNameRoot}
                  type="text"
                  id={formId ? `${formId}.businessName` : 'businessName'}
                  name="businessName"
                  label={businessNameLabel}
                  placeholder={businessNamePlaceholder}
                  validate={businessNameRequired}
                />

                <div className={css.name}>
                  <FieldPhoneNumberInput
                    id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                    name={'phoneNumber'}
                    className={css.textInputRow}
                    autoComplete="tel-national"
                    label={phoneLabel}
                    format={normalizePhoneNumberUS.format}
                    parse={normalizePhoneNumberUS.parse}
                    placeholder={phonePlaceholder}
                    inputIcon={<IconProfileSetup type="phone" />}
                    type="text"
                    validate={phoneNumberForUSRequired}
                  />
                  <FieldSelect
                    id={`${formId}.country`}
                    name="country"
                    className={css.countryBox}
                    label={countryLabel}
                    validate={countryRequired}
                  >
                    <option disabled value="">
                      {countryPlaceholder}
                    </option>
                    {countryCodes.map(country => {
                      return (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      );
                    })}
                  </FieldSelect>
                </div>

                {values.workRemote
                  ? null
                  : <div className={css.inputBox}>
                    <LocationAutocompleteInputField
                      className={css.locationAddress}
                      inputClassName={css.locationAutocompleteInput}
                      iconClassName={css.locationAutocompleteInputIcon}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      name={'location'}
                      label={titleRequiredMessage}
                      placeholder={addressPlaceholderMessage}
                      useDefaultPredictions={false}
                      format={identity}
                      valueFromForm={values.location}
                      validate={validators.composeValidators(
                        validators.autocompleteSearchRequired(addressRequiredMessage),
                        validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                      )}
                    />
                  </div>}
              </div>
              {/* <div className={css.workcheckBox}>
                <FieldCheckbox
                  id={formId ? `${formId}.workRemote` : 'workRemote'}
                  name="workRemote"
                  label={workRemoteLabel}
                  className={css.workRemote}
                />
              </div> */}
              {submitError}
              <div className={css.bottomWrapper}>
                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                  <FormattedMessage id="VendorBasicsForm.submit" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    )
  }
};

VendorBasicsFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

VendorBasicsFormComponent.propTypes = {
  updateInProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const VendorBasicsForm = compose(injectIntl)(VendorBasicsFormComponent);
VendorBasicsForm.displayName = 'VendorBasicsForm';

export default VendorBasicsForm;
