import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';

import qs from 'qs';
import { orderBy } from 'lodash';
import algoliasearch from 'algoliasearch/lite';
import { string, func, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Configure, InstantSearch, MenuSelect } from 'react-instantsearch-dom';

import { stringify } from '../../util/urlHelpers';
import { stringFromLength } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';

import {
  Button,
  NamedLink,
  SkeletonLoader,
  ResponsiveImage,
  IconProfileSetup,
  AlgoliaAutoCompleteSearchField,
} from '../../components';
import CategoryDropdown from './CategoryDropdown';

// import { TopbarSearchForm } from '../../forms';
import { fetchCurrentCategories } from '../../ducks/user.duck';

import css from './SectionHero.module.css';
import '../../styles/autocomplete-theme-classic.module.css';

const updateAfter = 700;
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  // 'latency',
  // '6be0576ff61c053d5f9a3225e2a90f76',
  {
    _useRequestCache: true,
  }
);

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="30px" height="30px" viewBox="0 0 1.8 1.8" fill="none" xmlns="http://www.w3.org/2000/svg"><path width="48" height="48" fill="white" fillOpacity="0.01" d="M0 0H1.8V1.8H0V0z" /><path d="m0.713 0.45 0.45 0.45 -0.45 0.45" stroke="#000000" strokeWidth="0.15" strokeLinecap="round" strokeLinejoin="round" /></svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="30px" height="30px" viewBox="0 0 1.8 1.8" fill="none" xmlns="http://www.w3.org/2000/svg"><path width="48" height="48" fill="white" fillOpacity="0.01" d="M0 0H1.8V1.8H0V0z" /><path d="M1.162 1.35 0.713 0.9l0.45 -0.45" stroke="#000000" strokeWidth="0.15" strokeLinecap="round" strokeLinejoin="round" /></svg>
    </div>
  );
}
const SectionHero = props => {
  const {
    intl,
    history,
    rootClassName,
    className,
    categories,
    currentUser,
    subCategories,
    onSearchSubmit,
    selectedCategory,
    initialSearchFormValues,
  } = props;

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 920;

  const myCategoryRef = useRef(null)
  const dispatch = useDispatch();
  const { categoriesInProgress } = useSelector(state => state.user);

  const parameters = { 
    filters: 'state:"published" AND (ListingType:"VENDOR_PROFILE" OR ListingType:"VENUE_PROFILE")'
  };
  const [searchState, setSearchState] = useState(typeof window !== 'undefined' && qs.parse(window.location.search.slice(1)));
  const [dropDownOpen, setdropDownOpen] = useState(false);
  const [inputHasFocus, setInputHasFocus] = useState(false);

  const handleSearchChange = (value) => setSearchState(Object.assign(searchState, value));

  const handleSearchSubmit = () => {
    history.push(`/search?${stringify({ ...searchState?.location?.selectedPlace || {}, query: searchState?.query })}`)
  }

  const classes = classNames(rootClassName || css.root, className);
  const settings = {
    speed: 800,
    slidesToShow: 6,
    arrows: true,
    infinite: true,
    dots: false,
    centerMode: false,
    slidesToScroll: 2,
    draggable: false,
    cssEase: 'linear',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          speed: 600,
          infinite: true,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          infinite: true,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const steps = [{
    PageName: intl.formatMessage({ id: 'LandingPage.redirectionTab1' }),
    description: intl.formatMessage({ id: 'LandingPage.browserVendors' })
  },
  {
    PageName: intl.formatMessage({ id: 'LandingPage.redirectionTab2' }),
    description: intl.formatMessage({ id: 'LandingPage.bidRespond' })
  },
  {
    PageName: intl.formatMessage({ id: 'LandingPage.redirectionTab3' }),
    description: intl.formatMessage({ id: 'LandingPage.bestFitPay' })
  },
  {
    PageName: intl.formatMessage({ id: 'LandingPage.redirectionTab4' }),
    description: intl.formatMessage({ id: 'LandingPage.leaveReview' })
  }];

  const onSearchStateChange = (searchState) => {
    setTimeout(() => {
      history.push(`/search?${qs.stringify(searchState)}`);
    }, updateAfter);
  }

  return (
    <div className={classes}>
      <div className={css.heroSection}>
        <div className={css.heroContent}
        >
          <div className={css.mainCover}>
            <h1><FormattedMessage id="SectionHero.headingTitle" /></h1>
            <div className={css.searchBar}>
              <div className={css.searchLeft}>
                <div className={css.locationDropDown}>
                  <InstantSearch
                    searchClient={searchClient}
                    indexName={process.env.REACT_APP_ALGOLIA_LISTING_INDEX}
                    searchState={searchState}
                    onSearchStateChange={onSearchStateChange}
                  >
                    <Configure {...parameters} />
                    <MenuSelect
                      limit={30}
                      attribute={"region"}
                      className={css.searchForm}
                      transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      translations={{ seeAllOption: intl.formatMessage({ id: "AlgoliaSearchPage.byRegion" }) }}
                    />
                  </InstantSearch>
                  {/* <TopbarSearchForm
                    className={css.searchForm}
                    desktopInputRoot={css.topbarSearchWithLeftPadding}
                    onSubmit={handleSearchChange}
                    initialValues={initialSearchFormValues}
                    showPredictions={inputHasFocus}
                    setInputHasFocus={setInputHasFocus}
                  /> */}
                  {/* <span
                    className={classNames(css.downArrow, inputHasFocus
                      ? css.open
                      : css.close)}
                    onClick={() => setInputHasFocus(!inputHasFocus)}
                  >
                    <IconProfileSetup type="dropdownarrow" />
                  </span> */}
                </div>

                <div className={css.searchWrapper}>
                  <div className={css.keyWordBox} onClick={() => setdropDownOpen(!dropDownOpen)}>
                    <span className={css.searchIcon}>
                      <IconProfileSetup type="search" />
                    </span>
                    <AlgoliaAutoCompleteSearchField
                      className={css.searchKeyword}
                      onChange={handleSearchChange}
                    />
                  </div>
                  {dropDownOpen
                    ? <CategoryDropdown
                      isMobile={isMobile}
                      categories={categories}
                      subCategories={subCategories}
                      dropDownOpen={dropDownOpen}
                      setdropDownOpen={setdropDownOpen}
                      onFetchCurrentCategories={(payload) => dispatch(fetchCurrentCategories(payload)).then(() => setdropDownOpen(true))}
                    />
                    : null}

                </div>
              </div>

              <Button
                className={isMobile ? css.goFullButton : css.goButton}
                type="button"
                onClick={handleSearchSubmit}>
                <FormattedMessage id={isMobile ? "SectionHero.findVendersText" : "SectionHero.goText"} />
              </Button>

            </div>
          </div>
        </div>
        <div>
          <div className={css.stepsContainer}>
            {steps.map((step, index) => (
              <div key={step.description + '_' + index} className={css.step}>
                <div className={css.stepNumber}>{(index + 1)}</div>
                <div className={css.stepDescription}>{step.description}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={css.coverSlider} ref={myCategoryRef}>
          <Slider {...settings} className={css.swiperSlide}>
            {categories.map(l => {
              return (
                <div className={css.imageBack} key={l.id} onClick={() => dispatch(fetchCurrentCategories({ type: "subCategories", parentIds: [l.id], clearPrev: true })).then(() => {
                  myCategoryRef.current.scrollIntoView({ behavior: "smooth" });
                })}>
                  <div key={l} className={css.imageBack}>
                    <div className={css.roundIcon}
                      style={{ backgroundImage: `url(${l.image})` }}
                    >
                      <div className={css.textName}>{l.label}</div>
                    </div>
                  </div>
                </div>)
            })}
          </Slider>
        </div>
        <div className={css.heroCategoriesBox}>
          {!categoriesInProgress ?
            subCategories.sort((a, b) => ('' + a.label).localeCompare(b.label)).map((item, index) => index < 8 && (
              <NamedLink
                name={"AlgoliaSearchPage"}
                key={item.key}
                className={css.heroCategories}
                to={{
                  search: `refinementList[subCategoriesLabel][0]=${item.label}&page=1&configure[aroundLatLngViaIP]=true&configure[aroundRadius]=all&configure[hitsPerPage]=8`
                }}
              >
                {item.image
                  ? <div className={css.categoriesImage}>
                    <ResponsiveImage url={item.image} className={css.logoBrand} alt={item.label} />
                  </div>
                  : <div className={css.noImage}><FormattedMessage id="SectionHero.noImageText" /></div>}
                <div className={css.categoriesText}>
                  <h4>{item.label}</h4>
                  <p>{item.shortDescription1 ? stringFromLength(item.shortDescription1, 100, true) : ""}</p>
                </div>
              </NamedLink>
            )) : <SkeletonLoader />}
        </div>
      </div>
      {/* : null} */}
    </div >
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null, initialSearchFormValues: {}, };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
};

export default SectionHero;
