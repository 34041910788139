import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Form,
  BorderButton,
  FieldTextInput,
  IconCardDashboard,
} from '../../components';

import css from './ChatInputForm.module.css';

const ChatInputFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        intl,
        form,
        invalid,
        values,
        receiverName,
        onFileInputChange,
      } = fieldRenderProps;

      const messagePlaceholder = intl.formatMessage({
        id: 'ChatInputForm.typeMessage',
      }, {
        receiverName
      });

      const classes = classNames(rootClassName || css.root, className);

      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || Object.keys(values).length == 0;

      return (
        <Form className={classes} onSubmit={(e) => [handleSubmit(e), form.restart()]}>
          <div className={css.chatDetailsForm}>
            <div className={css.chatLeft}>
              <FieldTextInput
                className={css.messageBox}
                type="textarea"
                id="message"
                name="message"
                placeholder={messagePlaceholder}
                onKeyPress={event => values.message && event.key == 'Enter' && !event.shiftKey && [handleSubmit(values), form.restart()]}
              // validate={passwordValidators}
              />
              <div className={css.buttonBrowse}>
                <BorderButton
                  className={css.borderButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="ChatInputForm.send" />
                </BorderButton>

                <label className={css.uploadicon} htmlFor="upload">
                  <IconCardDashboard type="upload" />
                </label>
                <input
                  id="upload"
                  className="file-upload-button"
                  type='file'
                  hidden={true}
                  onChange={onFileInputChange}
                  onClick={() => { }}
                />
              </div>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

ChatInputFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  formId: null,
};

const { string, bool, func } = PropTypes;

ChatInputFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  formId: string,
};

const ChatInputForm = compose(injectIntl)(ChatInputFormComponent);
ChatInputForm.displayName = 'ChatInputForm';

export default ChatInputForm;
