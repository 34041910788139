import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getTransactions } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_PACKAGES_REQUEST = 'app/PurchaseAcornsPage/FETCH_PACKAGES_REQUEST';
export const FETCH_PACKAGES_SUCCESS = 'app/PurchaseAcornsPage/FETCH_PACKAGES_SUCCESS';
export const FETCH_PACKAGES_ERROR = 'app/PurchaseAcornsPage/FETCH_PACKAGES_ERROR';

export const ADMIN_LISTING_SUCCESS = 'app/PurchaseAcornsPage/ADMIN_LISTING_SUCCESS';
export const ADMIN_LISTING_ERROR = 'app/PurchaseAcornsPage/ADMIN_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  packages: [],
  adminListingIds: [],
  adminListingError: false,
  fetchPackagesError: false,
  fetchPackagesInProgress: false,
};
const resultIds = data => data.data.map(l => l.id);

export default function PurchaseAcornsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {

    case FETCH_PACKAGES_REQUEST:
      return { ...state, fetchPackagesInProgress: true };
    case FETCH_PACKAGES_SUCCESS:
      return { ...state, packages: payload, fetchPackagesInProgress: false, fetchPackagesError: false };
    case FETCH_PACKAGES_ERROR:
      return { ...state, fetchPackagesInProgress: false, fetchPackagesError: payload };

    case ADMIN_LISTING_SUCCESS:
      return { ...state, adminListingIds: resultIds(payload.data), adminListingError: false, };
    case ADMIN_LISTING_ERROR:
      return { ...state, adminListingError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchProposalRequest = () => ({
  type: FETCH_PACKAGES_REQUEST
});

const fetchProposalSuccess = (response) => ({
  type: FETCH_PACKAGES_SUCCESS,
  payload: response
});

const fetchProposalError = (error) => ({
  type: FETCH_PACKAGES_ERROR,
  payload: error
});

const adminListingSuccess = (response) => ({
  type: ADMIN_LISTING_SUCCESS,
  payload: response
});

const adminListingError = (error) => ({
  type: ADMIN_LISTING_ERROR,
  payload: error
});

export const fetchEvents = (query) => (dispatch) => {
  dispatch(fetchProposalRequest());

  return getTransactions({ tableName: "packages", query })
    .then(response => {
      const packages = JSON.parse(response.data);
      dispatch(fetchProposalSuccess(packages));
      return packages;
    })
    .catch(error => {
      dispatch(fetchProposalError(error));
    });
};

export const loadData = () => async (dispatch, getState, sdk) => {
  dispatch(fetchProposalRequest());

  const query = {};

  const promisify = [
    dispatch(fetchEvents(query)),
    sdk.listings
      .query({
        pub_ListingType: 'ADMIN',
        include: ['author', 'images'],
      })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(adminListingSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(adminListingError(storableError(e)));
        throw e;
      })
  ];

  return Promise.all(promisify);
}
