/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';

import {
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../components';
import IconCollection from '../IconCollection/IconCollection';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    unreads,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const { publicData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
  const { userType } = publicData || {};
  const vendorParams = {};
  const isPlanner = userType === 'planner';
  const isVendor = ['vendor', 'venue'].includes(userType);

  if (currentUserListingFetched && currentUserHasListings) {
    Object.assign(vendorParams, {
      id: currentUserListing && currentUserListing.id && currentUserListing.id.uuid,
      slug: (currentUserListing && currentUserListing.id && createSlug(currentUserListing.attributes.title)) || "",
      city: currentUserListing && currentUserListing.id && currentUserListing.attributes.publicData.location && currentUserListing.attributes.publicData.location.address
        ? createSlug(currentUserListing.attributes.publicData.location.address.split(',')[0])
        : null,
      category: currentUserListing && currentUserListing.id
        ? currentUserListing.attributes.publicData.categoriesLabel
          ? createSlug(currentUserListing.attributes.publicData.categoriesLabel[0])
          : currentUserListing.attributes.publicData.categories
            ? createSlug(currentUserListing.attributes.publicData.categories[0].label)
            : ""
        : "",
      subcategory: currentUserListing && currentUserListing.id
        ? currentUserListing.attributes.publicData.subCategoriesLabel
          ? createSlug(currentUserListing.attributes.publicData.subCategoriesLabel[0])
          : currentUserListing.attributes.publicData.categories && currentUserListing.attributes.publicData.categories[0]
            ? createSlug(currentUserListing.attributes.publicData.categories[0].subCategory[0].label)
            : ""
        : "",
    });
  }

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorized"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <div className={css.menu}>
            <NamedLink
              className={classNames(css.navLink)}
              name="AlgoliaSearchPage"
            >
              <IconCollection name={"ICON_CATEGORIES"} />
              <FormattedMessage id="TopbarDesktop.categoryLink" />
            </NamedLink>
            <NamedLink
              name="CMSPage"
              params={{ pageId: "venue-information" }}
              className={classNames(css.navLink)}
            >
              <IconCollection name={"ICON_VENUE"} />
              <FormattedMessage id="TopbarDesktop.forVenueLink" />
            </NamedLink>
            <NamedLink
              name="CMSPage"
              params={{ pageId: "event-planner-information" }}
              className={classNames(css.navLink, css.planner)}
            >
              <IconCollection name={"ICON_EVENT_TAB"} />
              <FormattedMessage id="TopbarDesktop.forPlannerLink" />
            </NamedLink>
            <NamedLink
              name="CMSPage"
              params={{ pageId: "vendor-information" }}
              className={classNames(css.navLink)}
            >
              <IconCollection name={"ICON_VENDOR"} />
              <FormattedMessage id="TopbarDesktop.forVendorLink" />
            </NamedLink>
            <NamedLink
              name="CMSPage"
              params={{ pageId: "about-vendor" }}
              className={classNames(css.navLink)}
            >
              <IconCollection name={"ICON_VENDOR"} />
              <FormattedMessage id="TopbarDesktop.aboutVendor" />
            </NamedLink>
            <NamedLink
              name="CMSPage"
              params={{ pageId: "contact" }}
              className={classNames(css.navLink)}
            >
              <IconCollection name={"ICON_VENDOR"} />
              <FormattedMessage id="TopbarDesktop.contactLink" />
            </NamedLink>
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    unreads > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={unreads} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      {/* <AvatarLarge className={css.avatar} user={currentUser} /> */}
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>

        {isVendor
          ? <>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('DashboardPage'), css.navLink)}
              name="ProposalRequestsPage"
            >
              <IconCollection name={"ICON_PROPOSAL"} />
              <FormattedMessage id={userType == 'vendor' ? "TopbarDesktop.vendorDashboard" : "TopbarDesktop.venueDashboard"} />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ProposalRequestsPage'), css.navLink)}
              name="ProposalRequestsPage"
            >
              <IconCollection name={"ICON_PROPOSAL"} />
              <FormattedMessage id="TopbarDesktop.proposalRequests" />
            </NamedLink>
          </>
          : <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'), css.navLink)}
            name="ManageListingsPage"
          >
            <IconCollection name={"ICON_PROPOSAL"} />
            <FormattedMessage id="TopbarDesktop.eventsDashboard" />
          </NamedLink>}

        {vendorParams && vendorParams.id && vendorParams.slug
          ? <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfilePage'), css.navLink)}
            name={vendorParams.city && vendorParams.category && vendorParams.subcategory ? 'ListingCityPage' : 'ListingPage'}
            params={vendorParams}
          >
            <svg fill="#000000" width="36px" height="36px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,11A5,5,0,1,0,7,6,5.006,5.006,0,0,0,12,11Zm0-8A3,3,0,1,1,9,6,3,3,0,0,1,12,3ZM3,22V18a5.006,5.006,0,0,1,5-5h8a5.006,5.006,0,0,1,5,5v4a1,1,0,0,1-2,0V18a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v4a1,1,0,0,1-2,0Z" /></svg>
            <FormattedMessage id="TopbarDesktop.profileLink" />
          </NamedLink>
          : <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'), css.navLink)}
            name="ProfileSetupBasePage"
          >
            <IconCollection name={"ICON_PROFILE"} />
            <FormattedMessage id="TopbarDesktop.editProfile" />
          </NamedLink>}

        {isPlanner
          ? <>
            <NamedLink
              className={classNames(css.inbox, currentPageClass('PaymentBaseDashboardPage'), css.navLink)}
              name="PaymentBaseDashboardPage"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="36px" height="36px" viewBox="0 0 24 24"><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg>
              <FormattedMessage id="TopbarDesktop.viewPayments" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('NewListingPage'), css.navLink)}
              name="PreferredVendorsPage"
            >
              <IconCollection name={"ICON_REQUEST"} />
              <FormattedMessage id="TopbarDesktop.PreferredVendors" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'), css.navLink)}
              name="ManageListingsPage"
            >
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M18 33C19.65 33 21 31.65 21 30H15C15 30.7956 15.3161 31.5587 15.8787 32.1213C16.4413 32.6839 17.2044 33 18 33ZM27 24V16.5C27 11.895 24.54 8.04 20.25 7.02V6C20.25 4.755 19.245 3.75 18 3.75C16.755 3.75 15.75 4.755 15.75 6V7.02C11.445 8.04 9 11.88 9 16.5V24L6 27V28.5H30V27L27 24Z" fill="black" /></svg>
              <FormattedMessage id="TopbarDesktop.NotificationDropDownText" />
            </NamedLink>

            <NamedLink
              className={classNames(css.inbox, currentPageClass('QuotesPage'), css.navLink)}
              name="ManageListingsPage"
            >
              <IconCollection name={"ICON_CHAT"} />
              <FormattedMessage id="TopbarMobileMenu.sellAllQuotes" />
              {notificationCountBadge}
            </NamedLink>
          </>
          : <>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('PurchaseAcornsPage'), css.navLink)}
              name="PurchaseAcornsPage"
            >
              <svg width="36px" height="36px" viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M17.73,13H6.27a1.91,1.91,0,0,1-1.91-1.9h0a5.73,5.73,0,0,1,5.73-5.73h3.82a5.73,5.73,0,0,1,5.73,5.73h0A1.91,1.91,0,0,1,17.73,13Z"/><path d="M15.82,1.5h0A3.82,3.82,0,0,0,12,5.32h0"/><path d="M18.68,13c0,5.28-3,9.55-6.68,9.55S5.32,18.23,5.32,13"/></svg>
              <FormattedMessage id="TopbarDesktop.purchaseAcorns" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('InvoiceDetailsPage'), css.navLink)}
              name="InvoiceBasePage"
            >
              <svg fill="#000000" width="36px" height="36px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15,8a1,1,0,0,1-1,1H6A1,1,0,0,1,6,7h8A1,1,0,0,1,15,8Zm-1,3H6a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm-4,4H6a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm13-3v8a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1H16a3,3,0,0,1,3,3v7h3A1,1,0,0,1,23,12ZM17,4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H17Zm4,9H19v8h1a1,1,0,0,0,1-1Z"/></svg>
              <FormattedMessage id="TopbarDesktop.createAnInvoice" />
            </NamedLink>

            {/* <NamedLink
              className={classNames(css.inbox, currentPageClass('PaymentBaseDashboardPage'), css.navLink)}
              name="PaymentBaseDashboardPage"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="36px" height="36px" viewBox="0 0 24 24"><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg>
              <FormattedMessage id="TopbarDesktop.viewPayments" />
            </NamedLink> */}
          </>}

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'), css.navLink)}
          name="AccountSettingsPage"
        >
          <IconCollection name={"ICON_SETTINGS"} />
          <FormattedMessage id="TopbarDesktop.accountSettings" />
        </NamedLink>
        <InlineTextButton rootClassName={classNames(css.logoutButton, css.navLink)} onClick={onLogout}>
          <IconCollection name={"ICON_LOGOUT"} />
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  unreads: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  unreads: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
