import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import EventImage from '../../assets/eventImage.png';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { camelCaseEventDuration, ensureOwnListing } from '../../util/data';
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';

import config from '../../config';

import {
  Menu,
  MenuItem,
  MenuLabel,
  MenuContent,
  Button,
  NamedLink,
  ResponsiveImage,
  IconCollection,
  IconCardDashboard,
} from '../../components';

import css from './ManageListingCard.module.css';

// Menu content needs the same padding
const MENU_CONTENT_OFFSET = -12;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);
  const isPendingApproval = listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  const variant = isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : isPendingApproval
      ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
      : null;
  const params = {
    id,
    slug,
    city: listing && listing.id && listing.attributes.publicData.location && listing.attributes.publicData.location.address
      ? createSlug(listing.attributes.publicData.location.address.split(',')[0])
      : null,
    category: listing && listing.id
      ? listing.attributes.publicData.categoriesLabel
        ? createSlug(listing.attributes.publicData.categoriesLabel[0])
        : listing.attributes.publicData.categories
          ? createSlug(listing.attributes.publicData.categories[0].label)
          : ""
      : "",
    subcategory: listing && listing.id
      ? listing.attributes.publicData.subCategoriesLabel
        ? createSlug(listing.attributes.publicData.subCategoriesLabel[0])
        : listing.attributes.publicData.categories && listing.attributes.publicData.categories[0]
          ? createSlug(listing.attributes.publicData.categories[0].subCategory[0].label)
          : ""
      : "",
  }
  const linkProps =
    isPendingApproval || isDraft
      ? {
        name: 'ListingPageVariant',
        params: {
          id,
          slug,
          variant,
        },
      }
      : params && params.id && params.city && params.category && params.subcategory
        ? {
          name: 'ListingCityPage',
          params
        }
        : {
          name: 'ListingPage',
          params
        };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

export const ManageListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    bidEvents,
    onCloseListing,
    onOpenListing,
    history,
    listing,
    isMenuOpen,
    renderSizes,
    onToggleMenu,
    isDummy,
    hasListing,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  if (isDummy) {
    return (
      <div className={classNames(classes, css.eventCardBox, hasListing ? css.postCard : null)}>
        <NamedLink
          className={css.manageLink}
          name={'NewListingPage'}
        >
          {hasListing
            ? <div className={css.CreateListingBody}>
              <h4 className={css.createListingTitle}>
                <FormattedMessage id="ManageListingCard.newPost" /><br />
                <FormattedMessage id="ManageListingCard.newPost2" />
              </h4>
              <Button>
                <FormattedMessage id="ManageListingCard.newBtnLink" />
              </Button>
            </div>
            : <>
              <div className={css.eventTop}>
                <span className={css.status}><FormattedMessage id="ManageListingCard.new" /></span>
                <img src={EventImage} loading='lazy' alt='New Event' />
              </div>
              <div className={css.eventBody}>
                <h3
                  rootclassname={css.title}
                >
                  <span><FormattedMessage id="ManageListingCard.createFutureEvent" /></span>
                </h3>
                <div className={css.manageData}>
                  <span className={css.guestCount}><FormattedMessage id="ManageListingCard.eventType" /></span>
                  <span className={css.guestCount}><FormattedMessage id="ManageListingCard.eventDate" /></span>
                  <span className={css.guestCount}><FormattedMessage id="ManageListingCard.cityState" /></span>
                </div>
              </div>
            </>}
        </NamedLink>

      </div>
    )
  }

  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, state, createdAt, publicData } = currentListing.attributes;
  const {
    eventType,
    eventSize,
    eventDates,
    categoriesLabel,
    eventDuration,
    noOfNewRequests,
    noOfAcceptedRequests,
    uploadedImagesUrls = [],
    eventTypeImageURL,
    city, region
  } = publicData || {};

  const eventStartDate = eventDates && eventDates.length
    ? moment(eventDates[0].start).format("MMM Do, YYYY")
    : null;

  const slug = createSlug(title);
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isExpired = eventDuration != 'not-determined' && (eventStartDate && moment(eventStartDate, "MMM Do YYYY").isBefore(moment()));
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const titleClasses = classNames(css.title, {
    [css.titlePending]: isPendingApproval,
    [css.titleDraft]: isDraft,
  });

  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const isQuotes = bidEvents.length;

  return (
    <div className={classNames(classes, css.eventCardBox)}>
      {isExpired || true // because no need to show three dots
        ? null
        : <div className={css.rightBoxIcon}>
          <span className={css.dropdownIcon}>
            <Menu
              className={classNames(css.menu, css.cardIsOpen)}
              contentPlacementOffset={MENU_CONTENT_OFFSET}
              contentPosition="left"
              useArrow={false}
              onToggleActive={isOpen => {
                const listingOpen = isOpen ? currentListing : null;
                onToggleMenu(listingOpen);
              }}
              isOpen={isMenuOpen}
            >
              <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
                <div className={css.iconWrapper}>
                  <IconCardDashboard type="threedots" isActive={isMenuOpen} />
                </div>
              </MenuLabel>
              <MenuContent rootClassName={css.menuContent}>
                <MenuItem key="event-detail">
                  <NamedLink
                    className={css.manageLink}
                    name={'EventDetailPage'}
                    params={{ eventId: id, slug, tab: "quotes" }}
                  >
                    <FormattedMessage id="ManageListingCard.viewEvent" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="open-close-event">
                  <span
                    className={css.manageLink}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      isClosed ? onOpenListing(id) : onCloseListing(id)
                    }}
                  >
                    <FormattedMessage id="ManageListingCard.isClosedEvent" values={{ isClosed: isClosed ? 'Open' : 'Close' }} />
                  </span>
                </MenuItem>
              </MenuContent>
            </Menu>
          </span>
        </div>}

      {isExpired
        ? <>
          <div className={css.eventTop}>

            {isDraft || isClosed
              ? <span className={css.status}>{isDraft ? "Draft" : "Closed"}</span>
              : null}

            <ResponsiveImage
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
              url={uploadedImagesUrls[0]?.url || eventTypeImageURL}
            />
          </div>
          <div className={css.eventBody}>
            <h3
              rootclassname={titleClasses}
            >
              <span>{formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}</span>
            </h3>
            <div className={css.manageData}>
              <span className={css.guestCount}>{eventType && eventType.label ? eventType.label : eventType}</span>
              <span className={css.date}>{eventDuration == 'not-determined' ? camelCaseEventDuration(eventDuration) : eventStartDate}</span>
              {city && region
                ? <span className={css.date}>{city + ', ' + region}</span>
                : null}
            </div>
          </div>
          <div className={css.bottomButton}>
            <p className={classNames(css.buttonLink, css.expiredButton)}>
              <FormattedMessage id="ManageListingCard.isExpired" />
            </p>
          </div>
        </>
        : <>
          <NamedLink
            className={css.manageLink}
            name={isDraft ? 'EditListingPage' : 'EventDetailPage'}
            params={{ id, slug, type: editListingLinkType, tab: (isDraft ? 'basics' : 'quotes'), eventId: id }}
          >
            <div className={css.eventTop}>

              {isDraft || isClosed
                ? <span className={css.status}>{isDraft ? "Draft" : "Closed"}</span>
                : null}

              <ResponsiveImage
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
                url={uploadedImagesUrls[0]?.url || eventTypeImageURL}
              />
            </div>
            <div className={css.eventBody}>
              <h3
                rootclassname={titleClasses}
              >
                <span>{formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}</span>
              </h3>
              {region && city
                ? <span className={css.date}>{city + ', ' + region}</span>
                : null}
              <div className={css.manageData}>
                {/* <span className={css.date}>{eventStartDate} | {(eventSize && eventSize.label ? eventSize.label : eventSize)} Guests</span>
                <span className={css.guestCount}>{eventType} | {categoriesLabel[0]}</span> */}
              </div>
            </div>
          </NamedLink>

          <div className={css.bottomButton}>
            <NamedLink
              className={isQuotes ? css.isQuotes : ''}
              name={'EventDetailPage'}
              params={{ eventId: id, slug: createSlug(title), tab: 'quotes' }}
            >
              <IconCollection name="ICON_MESSAGE2" />
              Quotes ({bidEvents.length})
            </NamedLink>
            <NamedLink name={'EventDetailPage'} params={{ eventId: id, slug: createSlug(title), tab: 'vendors' }} >
              <IconCollection name="ICON_CHECK_TAB" />
              Vendor List
            </NamedLink>
            <NamedLink name={'EventDetailPage'} params={{ eventId: id, slug: createSlug(title), tab: 'vendors' }} >
              <IconCollection name="ICON_EVENT_TAB" />
              Event Details
            </NamedLink>
            {/* <p className={css.buttonLink}>
              <FormattedMessage id="ManageListingCard.createEventQuotes" />
            </p> */}
          </div>
        </>}
    </div>
  );
};

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
  availabilityEnabled: config.enableAvailability,
};

const { bool, func, shape, string } = PropTypes;

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  // hasClosingError: bool.isRequired,
  // hasOpeningError: bool.isRequired,
  intl: intlShape.isRequired,
  // listing: propTypes.ownListing.isRequired,
  isMenuOpen: bool.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  // onCloseListing: func.isRequired,
  // onOpenListing: func.isRequired,
  onToggleMenu: func.isRequired,
  availabilityEnabled: bool,

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(ManageListingCardComponent);
