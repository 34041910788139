import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const MenuIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return (
    <svg className={classes} width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.25 12.75C4.25 12.1864 4.47388 11.6459 4.8724 11.2474C5.27091 10.8489 5.81141 10.625 6.375 10.625H44.625C45.1886 10.625 45.7291 10.8489 46.1276 11.2474C46.5261 11.6459 46.75 12.1864 46.75 12.75C46.75 13.3136 46.5261 13.8541 46.1276 14.2526C45.7291 14.6511 45.1886 14.875 44.625 14.875H6.375C5.81141 14.875 5.27091 14.6511 4.8724 14.2526C4.47388 13.8541 4.25 13.3136 4.25 12.75ZM4.25 25.568C4.25 25.0044 4.47388 24.4639 4.8724 24.0654C5.27091 23.6669 5.81141 23.443 6.375 23.443H44.625C45.1886 23.443 45.7291 23.6669 46.1276 24.0654C46.5261 24.4639 46.75 25.0044 46.75 25.568C46.75 26.1316 46.5261 26.6721 46.1276 27.0706C45.7291 27.4691 45.1886 27.693 44.625 27.693H6.375C5.81141 27.693 5.27091 27.4691 4.8724 27.0706C4.47388 26.6721 4.25 26.1316 4.25 25.568ZM6.375 36.261C5.81141 36.261 5.27091 36.4849 4.8724 36.8834C4.47388 37.2819 4.25 37.8224 4.25 38.386C4.25 38.9496 4.47388 39.4901 4.8724 39.8886C5.27091 40.2871 5.81141 40.511 6.375 40.511H44.625C45.1886 40.511 45.7291 40.2871 46.1276 39.8886C46.5261 39.4901 46.75 38.9496 46.75 38.386C46.75 37.8224 46.5261 37.2819 46.1276 36.8834C45.7291 36.4849 45.1886 36.261 44.625 36.261H6.375Z" fill="#212121" />
    </svg>

  );
};

const { string } = PropTypes;

MenuIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MenuIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MenuIcon;
