import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { FormattedMessage } from '../../util/reactIntl';

import {
    Button,
    ReviewModal,
    ResponsiveImage,
    ReviewRating,
} from '..';
import { sendReview } from '../../containers/TransactionPage/TransactionPage.duck';

import css from './SendBirdChat.module.css';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { ensureTransaction } from '../../util/data';
import { TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_PROVIDER, transitionIsReviewed } from '../../util/transaction';

const reviewByAuthorId = (transaction, userId) => {
    return transaction.reviews.filter(
        r => !r.attributes.deleted && r.author.id.uuid === userId
    )[0];
};

const ReviewCard = props => {
    const {
        vendor,
        message,
        userType,
        currentUser,
        messageSentByYou,
        eventDetailData,
        currentUserDetails,
        onManageDisableScrolling,
    } = props;


    const { transactionId, eventOwner } = eventDetailData || {};
    const [reviewSubmitted, setReviewSubmitted] = useState(false); // State for modal visibility
    const [isModalOpen, setModalOpen] = useState(false); // State for modal visibility
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const {
        transactionRef,
        sendReviewError,
        sendReviewInProgress,
    } = state.TransactionPage;

    const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
    const transaction = transactions.length > 0 ? transactions[0] : null;
    const { transitions = [] } = (transaction && transaction.id && transaction.attributes) || {};
    const reviewTransitions = userType == 'planner'
        ? transitions.findIndex(({ transition }) => [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition)) > -1
        : transitions.findIndex(({ transition }) => [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition)) > -1;

    let reviewComponent = null;
    const reviewed = transaction && transaction.id && transaction.reviews && transaction.reviews.length == 2;

    const revieweeName = userType == 'planner'
        ? reviewed
            ? vendor.publicData.businessName
            : eventOwner.publicData.businessName
        : reviewed
            ? eventOwner.publicData.businessName
            : vendor.publicData.businessName;

    if (reviewed) {
        if (userType == 'planner') {
            const review = reviewByAuthorId(transaction, vendor.id);

            reviewComponent = review ? (
                <div>
                    <ReviewRating
                        rating={review.attributes.rating}
                        className={css.mobileReviewRating}
                        reviewStarClassName={css.reviewRatingStar}
                    />
                    <p className={css.reviewContent}>{review.attributes.content}</p>
                </div>
            ) : null;
        } else {
            const review = reviewByAuthorId(transaction, eventOwner.id);

            reviewComponent = review ? (
                <div>
                    <ReviewRating
                        rating={review.attributes.rating}
                        className={css.mobileReviewRating}
                        reviewStarClassName={css.reviewRatingStar}
                    />
                    <p className={css.reviewContent}>{review.attributes.content}</p>
                </div>
            ) : null;
        }
    }
    const onSubmitReview = (values) => {
        const transactionRole = userType == 'planner' ? 'customer' : 'provider';
        const currentTransaction = ensureTransaction(transaction);
        const { reviewRating, reviewContent } = values;
        const rating = Number.parseInt(reviewRating, 10);

        dispatch(sendReview(transactionRole, currentTransaction, rating, reviewContent))
            .then(r => [setModalOpen(false), setReviewSubmitted(true)])
            .catch(e => console.log(e, '**** **** => e'));
    }

    return (
        <div className={reviewed ? css.leftMessage : messageSentByYou ? css.rightMessage : css.leftMessage} key={message._id + '_InvoiceCard'}>
            {/* Invoice card */}
            <div style={{ width: '100%', maxWidth: '377px' }}>
                <div className={classNames((reviewed ? css.leftHeader : messageSentByYou ? null : css.leftHeader), css.invoiceHeader)} style={{ flexDirection: (reviewed ? 'row-reverse' : messageSentByYou ? 'row' : 'row-reverse') }}>
                    <div className={css.chatInvoiceData}>
                        <div className={css.nameDate}>{revieweeName}  <span>|  {moment(message.createdAt * 1000).calendar().split(' ')[0] + ', ' + moment(message.createdAt * 1000).format('LT')}</span>
                        </div>
                        <div className={css.messageDetail}>
                            <b>{revieweeName}</b>
                        </div>
                    </div>
                    <div className={css.chatProfileImage}>
                        <ResponsiveImage
                            alt={'profile'}
                            url={messageSentByYou
                                ? currentUser.attributes.profile.publicData && (currentUser.attributes.profile.publicData.logoImageURL || currentUser.attributes.profile.publicData.profileImageURL)
                                    ? currentUser.attributes.profile.publicData.logoImageURL
                                        ? currentUser.attributes.profile.publicData.logoImageURL
                                        : currentUser.attributes.profile.publicData.profileImageURL
                                    : message.plannerImageURL
                                : message.plannerImageURL}
                        />
                    </div>
                </div>

                {reviewComponent}

                <div className={css.invoiceCard} >
                    {reviewTransitions
                        ? <Button
                            disabled={true}
                            className={css.reviewBtn}
                        >
                            <FormattedMessage id="ReviewCard.reviewedButton" />
                        </Button>
                        : <Button
                            className={css.reviewBtn}
                            onClick={() => setModalOpen(true)} // Add onClick handler
                        >
                            <FormattedMessage id="ReviewCard.leaveAReviewButton" />
                        </Button>}
                </div>
            </div>

            <ReviewModal
                userType={userType}
                isOpen={isModalOpen}
                onSubmitReview={onSubmitReview}
                onCloseModal={() => setModalOpen(false)}
                revieweeName={revieweeName}
                reviewSent={reviewSubmitted}
                sendReviewError={sendReviewError}
                sendReviewInProgress={sendReviewInProgress}
                onManageDisableScrolling={onManageDisableScrolling}
            />
        </div>
    );
};

export default ReviewCard;