import {
  createAlgoliaData,
  verifyDotToken,
  sendVerificationToken,
  createDotCustomer,
  sendProposalsForNewVendors,
  updateAlgoliaData
} from '../../util/api';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { denormalisedResponseEntities, uploadCloudinaryFiles } from '../../util/data';

import { initialClearUploadImage } from '../EditListingPage/EditListingPage.duck';
import { currentUserShowSuccess, fetchCurrentUserHasListings } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSetupPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSetupPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSetupPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSetupPage/UPLOAD_IMAGE_ERROR';
export const CLEAR_UPLOAD_IMAGE = 'app/ProfileSetupPage/CLEAR_UPLOAD_IMAGE';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSetupPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSetupPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSetupPage/UPDATE_PROFILE_ERROR';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }
    case CLEAR_UPLOAD_IMAGE: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: null };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadClearImage = () => ({ type: CLEAR_UPLOAD_IMAGE });
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.default', 'variants.square-small', 'variants.square-small2x', 'variants.landscape-crop'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = (actionPayload, listingData) => async (dispatch, getState, sdk) => {
  dispatch(updateProfileRequest());

  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.default', 'variants.square-small', 'variants.square-small2x', 'variants.landscape-crop'],
  };
  const { currentUser } = getState().user;
  const { email, profile } = currentUser.attributes || {};
  const { publicData: uPublicData, protectedData: uProtectedData } = profile || {};
  const { isCompletedSteps, userType, userListingId, profileImageURL, otpVerified = false } = uPublicData || {};
  const { dotUser, dotUserId, phoneNumber } = uProtectedData || {};
  console.log(uProtectedData, uPublicData, '**** **** => uProtectedData, uPublicData');
  
  if (currentUser && currentUser.id) {
    const { image } = getState().ProfileSetupPage;
    const { images } = getState().EditListingPage;
    if (image && image.file) {
      const uploadedUrl = await uploadCloudinaryFiles([image.file], currentUser.id.uuid);
      if (uploadedUrl && uploadedUrl.length)
        Object.assign(actionPayload.publicData, { profileImageURL: uploadedUrl[0].data.secure_url });
        Object.assign(listingData.publicData, { profileImageURL: uploadedUrl[0].data.secure_url });
      dispatch(uploadClearImage());
    }

    if (images && images['brandLogo'] && images['brandLogo'].file) {
      const uploadedUrl = await uploadCloudinaryFiles([images['brandLogo'].file], currentUser.id.uuid);
      if (uploadedUrl && uploadedUrl.length)
        Object.assign(actionPayload.publicData, { logoImageURL: uploadedUrl[0].data.secure_url });
        Object.assign(listingData.publicData, { logoImageURL: uploadedUrl[0].data.secure_url });
      dispatch(initialClearUploadImage());
    } else if (images && Object.keys(images).length) {
      const files = [], imageIds = [];
      for (const key in images) {
        if (Object.prototype.hasOwnProperty.call(images, key)) {
          const element = images[key];
          imageIds.push(element.imageId.uuid);
          files.push(element.file);
        }
      }

      console.log(imageIds, '**** **** => imageIds');
      const uploadedUrl = await uploadCloudinaryFiles(files, currentUser.id.uuid);

      if (uploadedUrl && uploadedUrl.length) {
        const uploadedImagesUrls = (listingData.publicData && listingData.publicData.uploadedImagesUrls) || [];

        uploadedUrl.map((url, ind) => {
          if (imageIds && imageIds[ind]) {
            uploadedImagesUrls.push({
              id: imageIds[ind],
              url: url.data.secure_url
            });
          }

          Object.assign(listingData.publicData, { uploadedImagesUrls });
        });
        dispatch(initialClearUploadImage());
      }
    }
  }

  if (listingData && listingData.token && ((dotUser && dotUser.id) || dotUserId)) {
    try {
      await verifyDotToken({
        userId: dotUserId || (dotUser && dotUser.id),
        token: listingData.token
      });
    } catch (error) {
      console.error(error, '**** verifyDotToken **** => error');
      dispatch(updateProfileError(error));
    }
    dispatch(updateProfileSuccess({}));
    if (actionPayload && actionPayload.protectedData) {
      Object.assign(actionPayload.protectedData, { ...verifiedResult });
    }
  } else {
    const isLastStepForFinishProfileVendor = actionPayload?.publicData?.isCompletedSteps === true &&
      userListingId && isCompletedSteps === false && ['vendor', 'venue'].includes(userType);

    if (isLastStepForFinishProfileVendor) {
      sendProposalsForNewVendors({ listingId: userListingId });
    }

    if (currentUser && currentUser.id && uPublicData && listingData && Object.keys(listingData).length) {
      let userListing;
      if (listingData.token) delete listingData.token;
      const { title, description, availabilityPlan, images, geolocation, publicData = {} } = listingData || {};
      if (uPublicData.userListingId) {
        const { data } = await sdk.ownListings.update({
          id: new UUID(uPublicData.userListingId),
          ...listingData
        });

        userListing = data.data;

        const algoliaData = {
          objectID: uPublicData.userListingId,
          _geoloc: geolocation && geolocation.lat ? { lat: geolocation.lat, lng: geolocation.lng } : {},
          title, description, ...publicData,
          state: actionPayload?.publicData?.isCompletedSteps ? "published" : "draft",
        };

        if (actionPayload) {
          Object.assign(algoliaData, { user: { ...JSON.parse(JSON.stringify(actionPayload)), email } });
          if (actionPayload.publicData) {
            const { userType: nUserType, profileImageURL: nProfileImageURL } = publicData;
            Object.assign(algoliaData.user.publicData, { userType: nUserType || userType, profileImageURL: nProfileImageURL || profileImageURL, userListingId });
          } else {
            Object.assign(algoliaData.user, { publicData: { profileImageURL, userListingId, userType } });
          }
        }
        if (title) {
          Object.assign(algoliaData, { title });
        }
        if (description) {
          Object.assign(algoliaData, { description });
        }
        if (userType) {
          createAlgoliaData(algoliaData);
        }
        // dispatch(updateProfileSuccess({}));
      } else {
        const { data } = await sdk.ownListings.create({
          title,
          description,
          geolocation,
          images,
          publicData: {
            ...publicData,
            availabilityPlan: availabilityPlan || ({
              type: 'availability-plan/time',
              timezone: typeof window !== 'undefined'
                ? getDefaultTimeZoneOnBrowser()
                : 'Etc/UTC',
              entries: [
                { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
                { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
                { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
                { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
                { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
                { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
                { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
              ],
            }),
          },
          availabilityPlan: availabilityPlan || ({
            type: 'availability-plan/time',
            timezone: typeof window !== 'undefined'
              ? getDefaultTimeZoneOnBrowser()
              : 'Etc/UTC',
            entries: [
              { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
              { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
              { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
              { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
              { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
              { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
              { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
            ],
          })
        });

        userListing = data.data;

        if (userListing.id) {
          if (userType) {
            createAlgoliaData({
              objectID: userListing.id.uuid,
              state: "draft",
              _geoloc: geolocation && geolocation.lat ? { lat: geolocation.lat, lng: geolocation.lng } : {},
              title, description, ...publicData
            });
          }

          if (actionPayload) {
            if (actionPayload.publicData) {
              Object.assign(actionPayload.publicData, {
                userListingId: userListing.id.uuid
              });
            } else {
              Object.assign(actionPayload, {
                publicData: {
                  userListingId: userListing.id.uuid
                }
              });
            }
          }
        }
      }
    }
  }

  // For temperarory basis dotUserId: 'b25a8dd3-06d1-47fd-a30e-12a75d8a181a' if (|| !dotUserId &&)
  if (currentUser && currentUser.id && actionPayload && actionPayload.firstName && actionPayload.protectedData && actionPayload.protectedData.phoneCode && (!(dotUser || dotUserId) || !phoneNumber || (phoneNumber && phoneNumber != actionPayload.protectedData.phoneNumber))) {
    try {
      const phone_number = actionPayload.protectedData.phoneNumber.replace(/-/g, "");
      const { data: dotUser } = await createDotCustomer({
        internal_id: currentUser.id.uuid,
        email: currentUser.attributes.email,
        userName: currentUser.attributes.profile.displayName,
        first_name: actionPayload.firstName,
        last_name: actionPayload.lastName,
        country_code: actionPayload.protectedData.phoneCode || '1',
        phone_number,
      });

      if (uProtectedData && uProtectedData.phoneNumber && uProtectedData.phoneNumber != actionPayload.protectedData.phoneNumber) {
        Object.assign(actionPayload.publicData, { otpVerify: false });
      }

      Object.assign(actionPayload.protectedData, { dotUserId: dotUser.id });

    } catch (e) {
      console.log(e, '**** Create Dot Customer **** => e');
      dispatch(updateProfileError(e));
    }
  } else if (!otpVerified && (dotUser || dotUserId)) {
    await sendVerificationToken({ userId: (dotUser || dotUserId) });
  }

  return actionPayload && Object.keys(actionPayload).length
    ? sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updateProfileSuccess(response));
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
        dispatch(fetchCurrentUserHasListings());
        return currentUser;
      })
      .catch(e => dispatch(updateProfileError(storableError(e))))
    : [dispatch(updateProfileSuccess({ data: currentUser })), dispatch(fetchCurrentUserHasListings())];
};

export const loadData = (params, search) => (dispatch) => {
  dispatch(initialClearUploadImage({}));
  return Promise.all([]);
};