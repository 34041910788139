import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import trendingImage from '../../assets/trendingfood.png';
import trendingLogo from '../../assets/trendinglogo.png';

import css from './CategoriesSlider.module.css';
import { PrimaryButton } from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import NamedLink from '../NamedLink/NamedLink';
import { IconProfileSetup, ResponsiveImage } from '..';
import { createSlug } from '../../util/urlHelpers';
import trendingfood from '../../assets/trendingfood.png';
import quoteLogo from '../../assets/quoteLogo.png';

const card = [
    {
        logo: quoteLogo,
        coverImage: trendingfood,
        title: "Olivia Wilson Fancy Porta Potty",
        subText: "Hygine Rentals"
    },
    {
        logo: quoteLogo,
        coverImage: trendingfood,
        title: "Olivia Wilson Fancy Porta Potty",
        subText: "Hygine Rentals"
    },
    {
        logo: quoteLogo,
        coverImage: trendingfood,
        title: "Olivia Wilson Fancy Porta Potty",
        subText: "Hygine Rentals"
    },
    {
        logo: quoteLogo,
        coverImage: trendingfood,
        title: "Olivia Wilson Fancy Porta Potty",
        subText: "Hygine Rentals"
    },
    {
        logo: quoteLogo,
        coverImage: trendingfood,
        title: "Olivia Wilson Fancy Porta Potty",
        subText: "Hygine Rentals"
    },
    {
        logo: quoteLogo,
        coverImage: trendingfood,
        title: "Olivia Wilson Fancy Porta Potty",
        subText: "Hygine Rentals"
    },
    {
        logo: quoteLogo,
        coverImage: trendingfood,
        title: "Olivia Wilson Fancy Porta Potty",
        subText: "Hygine Rentals"
    },
    {
        logo: quoteLogo,
        coverImage: trendingfood,
        title: "Olivia Wilson Fancy Porta Potty",
        subText: "Hygine Rentals"
    },
    {
        logo: quoteLogo,
        coverImage: trendingfood,
        title: "Olivia Wilson Fancy Porta Potty",
        subText: "Hygine Rentals"
    },
]

const CategoriesSlider = props => {
    const { className, rootClassName, currentUser, trendingVendors, onAddOrRemoveToConnected } = props;
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);

    const settings = {
        speed: 500,
        slidesToShow: 3.8,
        // slidesToScroll: 1,
        arrows: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.4,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={classes}>
            <Slider {...settings} className={css.swiperSlide}>
                {card.map(l => {
                    return (
                        <div className={css.sliderCard} key={l}>
                            <div className={css.trendCard}>
                                <div className={css.trendTop}>
                                    {/* <ResponsiveImage url={uploadedImagesUrls && uploadedImagesUrls.length && uploadedImagesUrls[0].url} alt={item.attributes.title} /> */}
                                    <img src={l.coverImage} className={css.cardImage} />
                                    <span className={css.brandLogo}>
                                        <img src={l.logo} />
                                        {/* <ResponsiveImage url={profileImageURL} className={css.logoBrand} alt={item.attributes.title} /> */}
                                    </span>
                                </div>
                                <div className={css.trendingBody}>
                                    <h5>{l.title}</h5>
                                    <div className={css.categoriesText}>
                                        <span>{l.subText}</span>
                                        <span> |
                                            <IconProfileSetup type="star" />
                                            <FormattedMessage id="SectionTrendingVendors.fourStars" values={{ stars: 4 }} />
                                        </span>
                                    </div>
                                    <div>
                                        <NamedLink
                                            name="LandingPage"
                                            className={css.viewDetailButton}
                                        >
                                            <FormattedMessage id="SectionTrendingVendors.viewDetails" />
                                        </NamedLink>
                                        <PrimaryButton
                                            className={css.addList}
                                        >
                                            <IconProfileSetup type='preferred-list' />
                                            <FormattedMessage id="SectionTrendingVendors.addedPreferredList" />
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    );
};

const { node, string } = PropTypes;

CategoriesSlider.defaultProps = {
    className: null,
    rootClassName: null,
};

CategoriesSlider.propTypes = {
    className: string,
    rootClassName: string,
};

export default CategoriesSlider;
