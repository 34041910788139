import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map } from '../../components';
import config from '../../config';

import css from './ListingPage.module.css';

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  render() {
    const { className, rootClassName, geolocation, userType, publicData, listingId, eventStatus } = this.props;

    const { businessName, rangeValue = [] } = publicData || {};

    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const classes = classNames(rootClassName || css.sectionMap, className);
    const cacheKey = listingId
      ? listingId.uuid
        ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}`
        : `${listingId}_${geolocation.lat}_${geolocation.lng}`
      : null;

    const radius = eventStatus == 'ACCEPTED'
      ? 0
      : rangeValue && rangeValue.length
        ? (rangeValue[0] * 1609)
        : (1609 * 30);

    const mapProps = config.maps.fuzzy.enabled
      ? {
        zoom: 13 - (rangeValue[0] < 23 ? 5 : rangeValue[0] < 73 ? 6 : rangeValue[0] > 111 ? 8 : Math.round(rangeValue[0] / 11) < 13 ? Math.round(rangeValue[0] / 13) : 10),
        obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey),
        radius, eventStatus
      }
      : { address, center: geolocation };
    const map = userType != 'venue' && rangeValue && rangeValue.length ? <Map {...mapProps} /> : null;

    return (
      <div className={classes}>
        <h2 className={css.locationLogistics}>
          <FormattedMessage id="ListingPage.locationTitle" />
        </h2>
        {/* <p className={css.locationLogisticsDescription}>
          {businessName}
          <FormattedMessage id="ListingPage.locationSubTitle" />
        </p> */}
        {/* {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : ( */}
        {map
          ? <div className={css.mapLogistics}>{map}</div>
          : null}
        {/* )} */}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  // listingId: propTypes.uuid,
};

export default SectionMapMaybe;
