
import React from 'react';
import moment from 'moment';
import { FormattedMessage } from '../../util/reactIntl';

import { ResponsiveImage } from '../../components';

import css from './SendBirdChat.module.css';

const ChatCard = props => {
    const { messageSentByYou, message, currentUser, receiverDetails, currentUserDetails, renderMedia } = props;

    return (
        <div className={messageSentByYou ? css.rightMessage : css.leftMessage} key={message._id + '_ChatCard'}>
            <div className={css.chatProfileImage}>
                <ResponsiveImage url={messageSentByYou ?
                    currentUser.attributes.profile.publicData.profileImageURL :
                    receiverDetails.profileImageURL}
                    alt='profile-image' />
            </div>
            <div className={css.chatDisplayData}>
                <div className={css.nameDate}>{
                    messageSentByYou ? currentUserDetails.name : receiverDetails.name
                }  <span>|  {moment(message.createdAt).calendar().split(' ')[0] + ', ' + moment(message.createdAt).format('LT')}</span> </div>
                <div className={css.messageDetail}>
                    {message.type !== 'text'
                        ? renderMedia(message)
                        : message.body}</div>
            </div>
        </div>
    );
};

export default ChatCard;
