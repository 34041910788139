import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldCheckboxGroup } from '../../components';

import css from './PlannerDetailsForm.module.css';
import config from '../../config';

const KEY_CODE_ENTER = 13;

const PlannerDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        updateInProgress,
        invalid,
        intl,
        values,
        eventTypes,
        reasonToChooseOptions,
      } = fieldRenderProps;

      //checkbox
      const eventTypeLabel = intl.formatMessage({
        id: 'PlannerDetailsForm.eventTypeLabel',
      });
      const eventTypeValidateMessage = intl.formatMessage({
        id: 'PlannerDetailsForm.eventTypeValidateMessage',
      });
      const reasonToChooseLabel = intl.formatMessage({
        id: 'PlannerDetailsForm.reasonToChooseLabel',
      });
      const eventTypeValidate = validators.required(eventTypeValidateMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || submitInProgress; // || (!values.eventType || (values.eventType && values.eventType.length == 0));

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.planCheckBox}>
            <p className={css.titleName}>{eventTypeLabel}</p>
            <FieldCheckboxGroup
              className={css.features}
              id={"eventType"}
              name={"eventType"}
              options={eventTypes}
            />
          </div>
          <hr className={css.dividerLine} />
          <div className={css.checkboxList}>  
            <p className={css.titleName}>{reasonToChooseLabel}</p>
            <FieldCheckboxGroup
              className={css.features}
              id={"reasonToChoose"}
              name={"reasonToChoose"}
              options={reasonToChooseOptions}
            />
          </div>
          <div className={css.bottomWrapper}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="PlannerDetailsForm.submit" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

PlannerDetailsFormComponent.defaultProps = {
  inProgress: false,
  eventTypes: config.custom.newEventType,
  reasonToChooseOptions: config.custom.reasonToChoose,
};

const { bool, func } = PropTypes;

PlannerDetailsFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PlannerDetailsForm = compose(injectIntl)(PlannerDetailsFormComponent);
PlannerDetailsForm.displayName = 'PlannerDetailsForm';

export default PlannerDetailsForm;
